import React, {Component} from 'react';
import Marker from "./Timeline/Marker";

export const Minute = 60000;

export const DatePart = {
    Minute: Minute,
    Hour:   Minute * 60,
    Day:    Minute * 60 * 24,
    Week:   Minute * 60 * 24 * 7,
    Month:  Minute * 60 * 24 * 7 * 31
};

export const Intervals = {
    [DatePart.Hour]     : DatePart.Minute * 10,
    [DatePart.Day / 3]  : DatePart.Hour / 4,
    [DatePart.Day / 2]  : DatePart.Hour / 2,
    [DatePart.Day]      : DatePart.Hour,
    [DatePart.Day * 2]  : DatePart.Day / 4,
    [DatePart.Week]     : DatePart.Day / 2,
    [DatePart.Week * 2] : DatePart.Day * 4,
    [DatePart.Week * 3] : DatePart.Day * 2,
    [DatePart.Month]    : DatePart.Week,
};


export default class Timeline extends Component {


    state = {
        drag: null,
        point: null,
        width: null,
        frame: new Frame(new Date(2018, 10, 9).getTime() - new Date().getTimezoneOffset(), DatePart.Week * 2)
    };

    container = React.createRef();

    render() {

        const events = {
            onWheel: this.onScroll,
            onMouseDown: this.onMouseDown,
            onMouseMove: this.onMouseMove,
            onMouseUp: this.onMouseUp
        };

        return (
            <Layout>
                <div className="video__scale" style={{background: '#0D1C26'}}>
                    <div className="tl">
                        <button className="tl__prev disabled"/>
                        <div ref={this.container} className="tl__container">
                            <svg width={this.state.width} {...events}>
                                <g transform='translate(0, 20)'>
                                    {this.markers.map(x => <Marker key={x.time} {...x}/>)}
                                </g>
                            </svg>
                        </div>
                        <button className="tl__next"/>
                    </div>
                </div>

                <button onClick={this.zoomIn}>Zoom Out</button>
                <span> | </span>
                <button onClick={this.zoomOut}>Zoom In</button>

                <pre>
                    {JSON.stringify(this.state, null, 4)}
                </pre>

            </Layout>
        )
    }

    componentDidMount() {
        this.onResize();

        window.addEventListener("resize", this.onResize);
        window.addEventListener("scroll", this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('scroll', this.onScroll);
    }


    get markers() {
        const frame = this.state.frame;

        const zoom = frame.size;
        const width = this.state.width / (frame.size / zoom);

        const modes = Object.keys(Intervals).map(Number).filter(x => zoom >= Number(x));
        const mode = Intervals[modes[modes.length - 1]];

        const mspp = frame.size / width;

        return getDates(frame, mode).map(x => {
            return {
                type: getDateKind(x),
                left: (x.getTime() - frame.since) / mspp,
                time: x
            }
        });

    }

    onResize = () => {
        this.setState({width: this.container.current.offsetWidth});
    };

    onScroll = (e) => {
        e.preventDefault();
        this.setState({frame: this.state.frame.zoom(e.deltaY * DatePart.Hour)});
    };

    onMouseMove = (e) => {

        const point = getLocalPoint(e);

        if (this.state.drag == null)
        {
            this.setState({point: point});
            return;
        }

        const moved = point - this.state.drag;

        this.setState({drag: point, frame: this.state.frame.move(moved * (this.state.frame.size / this.state.width))});
    };

    onMouseDown = (e) => {
        this.setState({drag: getLocalPoint(e)});
    };

    onMouseUp = (e) => {
        this.setState({drag: null});
    };

    zoomOut = () => {
        this.setState({frame: this.state.frame.zoomOut(DatePart.Day)})
    };

    zoomIn = () => {
        this.setState({frame: this.state.frame.zoomIn(DatePart.Day)})
    };

}

const Layout = ({children}) => (
    <div className="layout">
        <div className="layout__container">
            <div className="layout__scroll" style={{padding: "50px"}}>
                {children}
            </div>
        </div>
    </div>
);


const getLocalPoint = (e) => {
    const rect = e.target.getBoundingClientRect();

    return e.clientX - rect.left;
};

const getDates = (frame, interval) => {
    let current = frame.since - (frame.since % interval);
    let dates = [];
    while (current <= frame.until) {
        dates.push(new Date(current));
        current += interval;
    }
    return dates;
};

const getTimeKind = (time) => {
    if (time % DatePart.Month === 0)
        return 'month';

    if (time % DatePart.Week === 0)
        return 'week';

    if (time % DatePart.Day === 0)
        return 'day';

    if (time % DatePart.Hour === 0)
        return 'hour';

    if (time % DatePart.Minute === 0)
        return 'minute';
};

const getDateKind = (date) => {
    return getTimeKind(date.getTime())
};

class Frame {
    _time;
    _size;

    static MinSize = DatePart.Hour;
    static MaxSize = DatePart.Month;

    constructor(time, zoom) {
        this._time = time;
        this._size = zoom;
    }

    move(value) {
        return new Frame(parseInt(this._time - value, 0), this._size);
    }

    zoom(value, center) {
        if (center == null)
            center = this._time;

        return new Frame(center, Math.min(Math.max(this._size + value, Frame.MinSize), Frame.MaxSize));
    }

    zoomIn(value) {
        return this.zoom(+value);
    }

    zoomOut(value) {
        return this.zoom(-value);
    }

    get size() {
        return this._size;
    }

    get since() {
        return this._time - this._size / 2;
    }

    get until() {
        return this._time + this._size / 2;
    }

    toJSON() {
        return {
            time: this._time,
            size: this._size,
            mode: getTimeKind(this._size)
        }
    }

}
