import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import cn from "classnames";
import EventsListItem from "./EventsListItem";

import { LoadPersons } from "../../reducers/faces";

const renderDay = (t, day) => {
  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  switch (day) {
    case today.toLocaleDateString():
      return t("today");
    case yesterday.toLocaleDateString():
      return t("yesterday");
    default:
      return day;
  }
};

const EventsList = ({
  t,
  columnMode,
  className,
  day,
  items,
  enableSelection,
  camerasMap,
  onSelect,
  selected,
}) => {
  const isSelected = (eventID) => selected.some((id) => id === eventID);

  return (
    <div
      className={cn("grid", "grid_events", { grid_one: columnMode }, className)}
    >
      <div className="grid__date">{renderDay(t, day)}</div>
      <div className="grid__container">
        {items
          .filter((item) => camerasMap[item.CameraID])
          .map((item) => (
            <EventsListItem
              item={item}
              camerasMap={camerasMap}
              selected={selected}
              enableSelection={enableSelection}
              onSelect={onSelect}
              isSelected={isSelected}
              key={item.ID}
            />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  camerasMap: state.cameras.items.reduce(
    (m, cam) => ({ ...m, [cam.id]: cam }),
    {}
  ),
});

export default translate()(
  connect(mapStateToProps, { LoadPersons })(EventsList)
);
