import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import cn from "classnames";
import update from "immutability-helper";
import ChartTotal from "./ChartTotal";
import ChartBySex from "./ChartBySex";
import ChartByAge from "./ChartByAge";
import ReportsNoData from "../ReportsNoData";
import ReportsLoader from "../ReportsLoader";

import { VisitDurationFetch } from "../../../reducers/reports";
import { chartOptions, NumberToMS } from "../utils";

class VisitDuration extends React.Component {
  state = {
    activeIndex: 0,
    tabs: [
      {
        index: 0,
        filter: "",
        title: this.props.t("common"),
      },
      {
        index: 1,
        filter: "gender",
        title: this.props.t("by-gender"),
      },
      {
        index: 2,
        filter: "age",
        title: this.props.t("by-age"),
      },
    ],
  };

  componentDidMount() {
    this.props.VisitDurationFetch();
  }

  toggleTab = (index) => {
    this.setState({ activeIndex: index });
  };

  render() {
    const {
      t,
      visitDuration,
      visitDurationFetched,
      dates,
      labels,
    } = this.props;
    const { activeIndex, tabs } = this.state;

    const firstLabel = this.props.t("m");

    const newChartOptions = update(chartOptions, {
      tooltips: {
        enabled: { $set: true },
        position: { $set: "average" },
        mode: { $set: "index" },
        intersect: { $set: false },
        titleFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        titleFontSize: { $set: 10 },
        titleFontStyle: { $set: "normal" },
        bodyFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        bodyFontSize: { $set: 10 },
        bodyFontStyle: { $set: "normal" },
        callbacks: {
          $set: {
            title: function (tooltipItems, data) {},
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              label += NumberToMS(t, tooltipItem.value);
              return label;
            },
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: { $set: 60 },
              callback: {
                $set: function (value, index) {
                  return index === 0 ? firstLabel : value / 60;
                },
              },
            },
          },
        ],
      },
    });

    return (
      <div className="chart">
        <div className="chart__head">
          <div className="chart__title title">{t("visit-duration")}</div>
        </div>
        <div className="chart__tabs">
          {tabs.map((x) => (
            <button
              className={cn("chart__tab", {
                active: x.index === activeIndex,
              })}
              onClick={() => this.toggleTab(x.index)}
              key={x.index}
            >
              {x.title}
            </button>
          ))}
        </div>
        {visitDurationFetched ? (
          visitDuration ? (
            visitDuration.current.periods ? (
              <div className="chart__inner">
                {activeIndex === 0 && (
                  <ChartTotal
                    chartOptions={newChartOptions}
                    visitDuration={visitDuration}
                    dates={dates}
                    labels={labels}
                  />
                )}
                {activeIndex === 1 && (
                  <ChartBySex
                    chartOptions={newChartOptions}
                    visitDuration={visitDuration}
                    dates={dates}
                    labels={labels}
                  />
                )}
                {activeIndex === 2 && (
                  <ChartByAge
                    chartOptions={newChartOptions}
                    visitDuration={visitDuration}
                    dates={dates}
                    labels={labels}
                  />
                )}
              </div>
            ) : (
              <ReportsNoData />
            )
          ) : (
            <ReportsNoData
              title={t("reports-no-data")}
              text={t("reports-no-data-text")}
            />
          )
        ) : (
          <ReportsLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visitDuration: state.reports.visitDuration,
  visitDurationFetched: state.reports.visitDurationFetched,
});

export default translate()(
  connect(mapStateToProps, {
    VisitDurationFetch,
  })(VisitDuration)
);
