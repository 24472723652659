import React from "react";
import { Link } from "react-router-dom";
import { gender, ageRange } from "../utils";

const Person = (props) => {
  const { t, event, avatar, name, color, list, sex, age } = props;
  return (
    <div className="person">
      <div className="person__avatar">
        {avatar ? (
          <img className="person__pic" src={avatar} alt={name} />
        ) : (
          <div className="person__placeholder">?</div>
        )}
      </div>
      <div className="person__info">
        {name && (
          <div className="person__list" style={{ backgroundColor: color }}>
            {list}
          </div>
        )}
        <div className="person__name">
          {name ? name : t("not-recognized")}{" "}
          {sex && age && (
            <span className="person__details">
              {`(${gender(sex, t)}, ${ageRange(age)})`}
            </span>
          )}
        </div>
        <div>
          ID:{" "}
          <Link to={`/faces/${event.group ? event.Group : 0}/${event.Guid}`}>
            {event.Guid}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Person;
