import React, { useState } from "react";
import { translate } from "react-i18next";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { personPhotoUrl } from "../../links";
import { NumberToHours, diffTime } from "./utils";

const ReportsEmployees = ({ t, placeId, timeTracking, singleDay, today }) => {
  const [sortField, setSortField] = useState("name");
  const [direction, setDirection] = useState("asc");

  const currentPersons = timeTracking.current.persons;
  const prevPersons = timeTracking.prev.persons;

  const empty = "----";
  const head = [
    {
      title: "status",
      field: "current_location",
    },
    {
      title: "person",
      field: "name",
    },
    {
      title: singleDay ? "start-work" : "avg-start-work",
      field: "avg_first_in",
    },
    {
      title: singleDay ? "end-work" : "avg-end-work",
      field: "avg_last_out",
    },
    {
      title: singleDay ? "time-on-work" : "avg-time-on-work",
      field: "avg_duration",
    },
  ];

  const handleSort = (field) => {
    setDirection("asc");
    field === sortField && direction === "asc" && setDirection("desc");
    setSortField(field);
  };

  const updateValue = (field, value) => {
    if (field === "avg_first_in" || field === "avg_last_out") {
      return parseInt(value.replace(":", ""));
    }
    return value;
  };

  const renderChange = (current, param) => {
    const prev = prevPersons.find((x) => x.id === current.id);
    return (
      prev &&
      current[param] &&
      prev[param] && (
        <span
          className={cn("persons__change change", {
            red: diffTime(t, current, prev, param, true),
            revert: param === "avg_first_in",
          })}
        >
          {diffTime(t, current, prev, param)}
        </span>
      )
    );
  };

  const renderChangeDuration = (current) => {
    const prev = prevPersons.find((x) => x.id === current.id);
    return (
      prev &&
      prev.avg_duration > 0 && (
        <span
          className={cn("persons__change change", {
            red: current.avg_duration - prev.avg_duration,
          })}
        >
          {NumberToHours(t, Math.abs(current.avg_duration - prev.avg_duration))}
        </span>
      )
    );
  };

  const renderValue = (item, param) => {
    return item[param] ? (
      <>
        {item[param]}
        {prevPersons && renderChange(item, param)}
      </>
    ) : (
      empty
    );
  };

  return currentPersons.length > 0 ? (
    <div className="persons persons_reports">
      <div className="persons__head">
        <div className="persons__title">{t("employees")}</div>
        {today && (
          <div className="persons__titles">
            <div className="persons__title persons__title_online">
              {currentPersons.filter((x) => x.current_location === "in").length}{" "}
              {t("online")}
            </div>
            <div className="persons__title persons__title_offline">
              {currentPersons.filter((x) => x.current_location !== "in").length}{" "}
              {t("offline")}
            </div>
          </div>
        )}
      </div>
      <div className="persons__table">
        <div className="persons__thead">
          {head.map((x, index) => (
            <div className="persons__td" key={index}>
              <button
                className={cn("action-sort", {
                  active: x.field === sortField,
                  desc: x.field === sortField && direction === "desc",
                })}
                onClick={() => handleSort(x.field)}
              >
                {t(x.title)}
              </button>
            </div>
          ))}
        </div>
        <div className="persons__tbody">
          {currentPersons
            .sort((a, b) => {
              return sortField === "name"
                ? direction === "asc"
                  ? a.name.localeCompare(b.name)
                  : b.name.localeCompare(a.name)
                : direction === "asc"
                ? updateValue(sortField, a[sortField]) -
                  updateValue(sortField, b[sortField])
                : updateValue(sortField, b[sortField]) -
                  updateValue(sortField, a[sortField]);
            })
            .map((item, index) => (
              <div className="persons__line" key={index}>
                <NavLink
                  className="persons__tr"
                  to={`/reports/time-tracking/${placeId ? placeId : "all"}/${
                    item.id
                  }`}
                  key={index}
                >
                  <div className="persons__td">
                    <div className="persons__avatar">
                      <img
                        className="persons__pic"
                        src={personPhotoUrl(item.id, item.main_photo)}
                        alt={item.name}
                      />
                      <div
                        className={cn("persons__badge", {
                          active: item.current_location,
                        })}
                      />
                    </div>
                  </div>
                  <div className="persons__td">
                    <div className="persons__name">
                      {item.name} {item.last_name}
                    </div>
                  </div>
                  <div className="persons__td">
                    <div className="persons__term">{t("time-start-work")}:</div>
                    <div className="persons__time">
                      {renderValue(item, "avg_first_in")}
                    </div>
                  </div>
                  <div className="persons__td">
                    <div className="persons__term">{t("time-end-work")}:</div>
                    <div className="persons__time">
                      {renderValue(item, "avg_last_out")}
                    </div>
                  </div>
                  <div className="persons__td">
                    <div className="persons__term">{t("time-on-work")}:</div>
                    <div className="persons__time">
                      {item.avg_duration > 0 ? (
                        <>
                          {NumberToHours(t, item.avg_duration)}
                          {prevPersons && renderChangeDuration(item)}
                        </>
                      ) : (
                        empty
                      )}
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <>No persons</>
  );
};

export default translate()(ReportsEmployees);
