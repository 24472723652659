import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import queryString from "query-string";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";

import Tariffs from "../components/Payment/Tariffs";
import History from "../components/Payment/History";
import Refill from "../components/Payment/Refill";
import Modal from "../components/Modal";
import Logo from "../components/Logo";
import Icon from "../components/Icon";
import {
  Archive,
  Persons,
  BalanceLoad,
  CameraRatesLoad,
  PaymentNew,
  TransactionsLoad,
  BankTransferNew,
} from "../reducers/payments";
import { CamerasList } from "../reducers/cameras";
import { checkCompanyOwner, checkPerms, currency, isEurocam } from "../utils";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: false,
    };
  }

  componentDidMount() {
    this.props.BalanceLoad();
    this.props.CameraRatesLoad();
    this.props.TransactionsLoad();
    if (!this.props.cameras.fetched) {
      this.props.CamerasList();
    }
  }

  getTotalParam = () => {
    const values = queryString.parse(this.props.location.search);
    return values.total;
  };

  onPersonalPayment = (total) => {
    // analytics
    ReactGA.event({ category: "User", action: "Payment" });
    !isEurocam && ym("reachGoal", "payment");
    return this.props.PaymentNew(total).then(() => {
      const { confirmRedirectURI } = this.props.payments;
      if (confirmRedirectURI) {
        window.location = confirmRedirectURI;
      }
    });
  };

  onBankTransferPayment = (total) => {
    return this.props.BankTransferNew(total).then(() => {
      this.setState({
        visibleModal: false,
      });
    });
  };

  toggleModal = () => {
    this.setState({
      visibleModal: !this.state.visibleModal,
    });
  };

  render() {
    const { balance, transactions } = this.props.payments;
    const { t, users } = this.props;
    const { visibleModal } = this.state;

    const isPermsBalance = checkPerms(users.user, "payment:balance:read");
    const isPermsTransactions = checkPerms(
      users.user,
      "payment:transactions:read"
    );
    const isCompanyOwner = checkCompanyOwner(users.user);

    const nav = [
      { icon: "cloud", title: "сloud-recording", to: "/payment" },
      { icon: "face", title: "recognition", to: "/payment/recognition" },
      {
        icon: "history",
        title: "payment-history",
        to: "/payment/history",
        hide: !isPermsTransactions,
      },
    ];

    return (
      <div>
        <div className="layout layout_payment">
          <div className="layout__sidebar">
            <div className="layout__head">
              <Logo />
              <div className="layout__title">{t("payment-title")}</div>
            </div>
          </div>
          <div className="layout__container">
            <div className="layout__head">
              {isPermsBalance && balance !== null && (
                <div className="layout__balance">
                  <div className="layout__value">
                    {balance.toFixed(2)} {t(currency)}
                  </div>
                  <div className="layout__label">{t("payment-my-balance")}</div>
                </div>
              )}
              {false && isCompanyOwner && (
                <>
                  <button
                    className="layout__btn btn btn_lg"
                    onClick={() => this.toggleModal()}
                  >
                    {t("payment-refill")}
                  </button>
                  {visibleModal && (
                    <Modal onCloseRequest={() => this.toggleModal()}>
                      <Refill
                        total={this.getTotalParam()}
                        balance={balance}
                        onPersonalPayment={this.onPersonalPayment}
                        onBankTransferPayment={this.onBankTransferPayment}
                      />
                    </Modal>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="layout layout_payment">
          <div className="layout__sidebar">
            <div className="layout__scroll">
              <div className="layout__nav">
                {nav.map(
                  (x, i) =>
                    !x.hide && (
                      <NavLink
                        className="layout__link"
                        activeClassName="active"
                        to={x.to}
                        exact
                        key={i}
                      >
                        <div className="layout__preview">
                          <Icon className="layout__pic" name={x.icon} />
                        </div>
                        <div className="layout__wrap">
                          <div className="layout__name">{t(x.title)}</div>
                        </div>
                      </NavLink>
                    )
                )}
              </div>
            </div>
          </div>
          <div className="layout__container">
            <div className="layout__scroll layout__scroll_bg">
              <Route
                exact
                path="/payment"
                render={() => (
                  <Tariffs type={Archive} isCompanyOwner={isCompanyOwner} />
                )}
              />
              <Route
                path="/payment/recognition"
                render={() => (
                  <Tariffs type={Persons} isCompanyOwner={isCompanyOwner} />
                )}
              />
              {isPermsTransactions && (
                <Route
                  path="/payment/history"
                  render={() => <History transactions={transactions} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  payments: state.payments,
  cameras: state.cameras,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, {
      BalanceLoad,
      CameraRatesLoad,
      PaymentNew,
      TransactionsLoad,
      CamerasList,
      BankTransferNew,
    })
  )(Payment)
);
