import React from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";

const EventsEmpty = props => {
  const { t } = props;
  return (
    <div className="page page_sm page_bg">
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic" name="bell" />
        </div>
        <div className="page__title">{t("events-empty-title")}</div>
        <div className="page__text">{t("events-empty-text")}</div>
      </div>
    </div>
  );
};

export default withRouter(translate()(EventsEmpty));
