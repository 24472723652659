import React, { Component, Fragment } from "react";
import { getDateKind, getDateRangeScale, getDates } from "./utils";
import Marker from "./Marker";
import Label from "./Label";

export default class Axis extends Component {
  render() {
    let markers = this.markers(this.props);

    return (
      <Fragment>
        {markers.map((props) => (
          <Marker key={props.time} {...props} />
        ))}
        {markers.map((props) => (
          <Label key={props.time} {...props} timezone={this.props.timezone} />
        ))}
      </Fragment>
    );
  }

  markers({ viewport }) {
    const config = getDateRangeScale(viewport.window);

    const dates = getDates(
      viewport.since,
      viewport.until,
      config.step,
      config.scale
    );

    return dates.map((date) => {
      let kind = getDateKind(date);
      let time = date.getTime();
      return {
        time: time,
        left: viewport.pointFor(time),
        type: kind,
        major: kind === config.scale,
      };
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.viewport !== this.props.viewport;
  }
}
