import React from "react";

const badges = {
  car: {
    src: "/img/type-car.svg"
  },
  people: {
    src: "/img/type-man.svg"
  }
};

export const EventBadge = ({ type }) => {
  if (false === badges.hasOwnProperty(type)) return null;

  return (
    <div className={`event-badge`}>
      <img src={badges[type].src} alt="" />
    </div>
  );
};
