import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";

import Activate from "../components/Neurobox/Activate";
import Help from "../components/Neurobox/Help";
import Scanning from "../components/Neurobox/Scanning";
import CamerasFound from "../components/Neurobox/CamerasFound";
import Tabs from "../components/Neurobox/Tabs";
import CamerasNotFound from "../components/Neurobox/CamerasNotFound";
import { CamerasList } from "../reducers/cameras";
import { CameraPatch } from "../reducers/camera";
import {
  NeuroboxActivate,
  NeuroboxScanStart,
  NeuroboxScanStop,
  NeuroboxScanClear,
  NeuroboxScanSelectCamera,
  NeuroboxScanSingle,
  NeuroboxScanRemoveCamera,
  NeuroboxScanSetCamera,
  SCAN_STATE_NEUROBOX,
  SCAN_STATE_SCANNING,
  SCAN_STATE_COMPLETE,
} from "../reducers/scan";
import AddedCameras from "../components/Neurobox/AddedCameras";
import { FilterCameras, isEurocam } from "../utils";

const CamerasFoundTab = (props) => {
  const {
    t,
    cameras,
    scan,
    users,
    onClickAdd,
    onChangeTitle,
    NeuroboxScanSingle,
    NeuroboxScanSelectCamera,
  } = props;

  const countSelected = Object.keys(scan.selected).reduce(
    (prevValue, currentValue) =>
      scan.selected[currentValue] === true ? prevValue + 1 : prevValue,
    0
  );

  return (
    <div className="scan scan_lg">
      <div className="scan__title">{t("scan-finish")}</div>
      <div className="scan__text">
        {t("cameras-found")} {cameras.length}
      </div>
      <div className="scan__body">
        <CamerasFound
          cameras={cameras}
          selected={scan.selected}
          scanSingle={NeuroboxScanSingle}
          onSelect={NeuroboxScanSelectCamera}
          onChangeTitle={onChangeTitle}
          token={users.token}
        />
      </div>
      <div className="scan__foot">
        <button
          className="scan__btn btn"
          disabled={countSelected === 0}
          onClick={onClickAdd}
        >
          {t("add")} {t("cameras")} {countSelected > 0 && `(${countSelected})`}
        </button>
      </div>
    </div>
  );
};

const CamerasNotFoundTab = (props) => {
  return (
    <div className="tabs__item">
      <div className="scan scan_lg">
        <div className="scan__body">
          <CamerasNotFound
            onClickRepeat={() => props.NeuroboxScanClear()}
            onClickNeurobox={() => props.history.push("/add")}
            type={props.type}
          />
        </div>
      </div>
    </div>
  );
};

class AddCamera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
      addedCamerasCount: 0,
    };
  }

  componentWillUnmount() {
    this.props.NeuroboxScanClear();
  }

  handleAddCameras = () => {
    const { cameras, selected } = this.props.scan;

    const promises = cameras
      .filter((c) => selected[c.id] === true)
      .map((camera) => {
        camera.use = true;
        camera.active = true;
        return this.props
          .CameraPatch(camera, camera.id, [])
          .then(() => this.props.NeuroboxScanRemoveCamera(camera.id));
      });

    Promise.all(promises)
      .then(this.props.CamerasList)
      .then(() =>
        this.setState({
          added: true,
          addedCamerasCount: promises.length,
        })
      );

    // analytics
    ReactGA.event({ category: "User", action: "Adding_camera" });
    !isEurocam && ym("reachGoal", "adding_camera");
  };

  handleAddedContinue = () => this.props.history.push("/cameras");

  handleAddMore = () => this.setState({ added: false });

  onChangeTitle = (camera, title) =>
    this.props.NeuroboxScanSetCamera({ ...camera, title });

  render() {
    const {
      t,
      scan,
      location,
      NeuroboxActivate,
      NeuroboxScanStart,
      NeuroboxScanStop,
    } = this.props;

    if (this.state.added) {
      return (
        <AddedCameras
          count={this.state.addedCamerasCount}
          onContinue={this.handleAddedContinue}
          onAddMore={this.handleAddMore}
        />
      );
    }

    const cameras = FilterCameras(this.props.scan.cameras),
      type = this.props.match.params.type,
      title =
        type === "app" ? t("connect-camera-app") : t("connect-camera-device");

    return (
      <Tabs
        state={scan.state}
        title={title}
        help={
          <Help
            content={t("connect-camera-neurobox-help")}
            actions={[t("buy-neurobox"), t("download-app")]}
          />
        }
        prepareInfo={location.state && location.state.type}
        tabs={[
          {
            title: t("search-neurobox"),
            state: SCAN_STATE_NEUROBOX,
            component: (
              <Activate
                type={type}
                handleActivate={NeuroboxActivate}
                handleScan={NeuroboxScanStart}
              />
            ),
          },
          {
            title: t("search-device"),
            state: SCAN_STATE_SCANNING,
            component: (
              <Scanning
                cameras={cameras.length}
                timer={scan.timer}
                stop={NeuroboxScanStop}
              />
            ),
          },
          {
            title: t("scan-completion"),
            state: SCAN_STATE_COMPLETE,
            component:
              cameras.length > 0 ? (
                <CamerasFoundTab
                  {...this.props}
                  cameras={cameras}
                  onClickAdd={this.handleAddCameras}
                  onChangeTitle={this.onChangeTitle}
                />
              ) : (
                <CamerasNotFoundTab {...this.props} type={type} />
              ),
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  scan: state.scan.neurobox,
});

export default withRouter(
  translate()(
    connect(
      mapStateToProps,
      bindActionCreators.bind(null, {
        NeuroboxActivate,
        NeuroboxScanStart,
        NeuroboxScanStop,
        NeuroboxScanClear,
        NeuroboxScanSelectCamera,
        NeuroboxScanSingle,
        NeuroboxScanRemoveCamera,
        NeuroboxScanSetCamera,
        CameraPatch,
        CamerasList,
      })
    )(AddCamera)
  )
);
