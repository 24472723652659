import React, {Fragment, useCallback} from "react";
import Slider from "react-slider";
import cn from "classnames";

export function Volume({volume, muted, vertical, onChange}) {
  const classes = cn("video__btn", "video__btn_volume", {
    "volume-1": volume > 0 && volume <= 0.33,
    "volume-2": volume > 0.33 && volume <= 0.66,
    "volume-3": volume > 0.66 && volume <= 1,
    "volume-0": muted
  });

  const handleMute = useCallback(() => {
    onChange(!muted, muted && volume === 0 ? 1 : volume)
  }, [muted, volume, onChange])

  const handleChange = useCallback(value => {
    onChange(value === 0, value)
  }, [onChange])

  return (
    <Fragment>
      <button className={classes} onClick={handleMute} />
      <div className="video__volume">
        <div className="slider">
          <Slider
            // vertical={vertical}
            min={0}
            max={1}
            step={0.01}
            className="slider-rail"
            trackClassName="slider-track"
            thumbClassName="slider-handle"
            onChange={handleChange}
            value={muted ? 0 : volume}
          />
        </div>
      </div>
    </Fragment>
  );
}
