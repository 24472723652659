import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./ActionsDropdown.module.sass";
import Action from "../Action";

const ActionsDropdown = ({ icon, options }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={styles.dropdown}>
        {icon ? (
          <Action
            onClick={() => setVisible(!visible)}
            active={visible}
            icon={icon}
          />
        ) : (
          <div className={styles.head}></div>
        )}
        {visible && (
          <div className={styles.body} onClick={() => setVisible(false)}>
            {options.map((x, index) => (
              <button className={styles.option} onClick={x.onClick} key={index}>
                {x.title}
              </button>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default ActionsDropdown;
