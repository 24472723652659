import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import DayPicker, { DateUtils } from "react-day-picker";
import onClickOutside from "react-onclickoutside";
import dateFormat from "dateformat";
import Time from "./Time";

import { WEEKDAYS_SHORT, MONTHS, FIRST_DAY_OF_WEEK } from "../utils";

const dateWithTime = (date, time) => {
  const split = time.split(":");
  if (split.length !== 2) {
    return [0, 0];
  }

  const hours = parseInt(split[0], 10);
  const mintues = parseInt(split[1], 10);

  return new Date(
    ...[date.getFullYear(), date.getMonth(), date.getDate(), hours, mintues]
  );
};

class Datepicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDatepicker: false,
      selectedDay: undefined,
      from: props.initRange ? props.initRange.from : undefined,
      to: props.initRange ? props.initRange.to : undefined,
      timeFrom: "00:00",
      timeTo: "23:59",
    };
  }

  onFilter = () => {
    if (this.props.onChangeRange && this.state.to && this.state.from) {
      const { from, to, timeFrom, timeTo } = this.state;

      this.props.onChangeRange(
        dateWithTime(from, timeFrom),
        dateWithTime(to, timeTo)
      );
    } else {
      this.props.onChange && this.props.onChange(this.state.selectedDay);
      if (this.props.resetOnSubmit) {
        this.setState({ selectedDay: undefined });
      }
    }
  };

  toggleDatepicker = () => {
    this.setState({
      openDatepicker: !this.state.openDatepicker,
    });
    this.onFilter();
  };

  handleClickOutside = (evt) => {
    if (!this.state.openDatepicker) {
      return;
    }

    this.setState({
      openDatepicker: false,
    });
    if (!this.state.to) {
      this.setState({
        from: undefined,
      });
    }
    !this.props.inline && this.onFilter();
  };

  resetDatepicker = () => {
    this.setState({
      selectedDay: undefined,
      from: undefined,
      to: undefined,
    });

    if (this.props.onChangeRange) {
      this.props.onChangeRange(undefined, undefined);
    }
  };

  handleDayClick = (day, { selected }) => {
    this.setState({ selectedDay: selected ? undefined : day });
  };

  handleRangeClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  };

  onChangeTime = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { className, inline, rangeMode, time, title, t, i18n } = this.props;
    const {
      openDatepicker,
      selectedDay,
      from,
      to,
      timeFrom,
      timeTo,
    } = this.state;
    const modifiers = { start: from, end: to };
    const classes = cn(
      "dp",
      className && className,
      openDatepicker && " open",
      this.props.inline && "dp_inline"
    );
    return (
      <div className={classes}>
        <div className="dp__wrap">
          {!inline && (
            <div className="dp__head">
              <div className="dp__field" onClick={this.toggleDatepicker}>
                <div
                  className={
                    "dp__title " +
                    (selectedDay || (from && to) ? "selected" : "")
                  }
                >
                  {rangeMode
                    ? from
                      ? dateFormat(from, "dd.mm.yyyy") +
                        "-" +
                        (to
                          ? dateFormat(to, "dd.mm.yyyy") +
                            " / " +
                            timeFrom +
                            "-" +
                            timeTo
                          : "")
                      : title
                    : selectedDay
                    ? dateFormat(selectedDay, "dd.mm.yyyy")
                    : title}
                </div>
                <div className="dp__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                  >
                    <path d="M14,11 L9,11 L9,16 L14,16 L14,11 Z M13,0 L13,2 L5,2 L5,0 L3,0 L3,2 L2,2 C0.89,2 0.01,2.9 0.01,4 L0,18 C0,19.1 0.89,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L15,2 L15,0 L13,0 Z M16,18 L2,18 L2,7 L16,7 L16,18 Z" />
                  </svg>
                </div>
              </div>
            </div>
          )}
          <div className="dp__body">
            <DayPicker
              selectedDays={rangeMode ? [from, { from, to }] : selectedDay}
              onDayClick={
                rangeMode ? this.handleRangeClick : this.handleDayClick
              }
              modifiers={modifiers}
              locale={i18n.language}
              months={MONTHS[i18n.language.slice(0, 2)]}
              weekdaysShort={WEEKDAYS_SHORT[i18n.language.slice(0, 2)]}
              firstDayOfWeek={FIRST_DAY_OF_WEEK[i18n.language.slice(0, 2)]}
              showOutsideDays
            />
            {time && (
              <div className="dp__time">
                <Time
                  timeFrom={timeFrom}
                  timeTo={timeTo}
                  onChangeTime={this.onChangeTime}
                  label={t("time")}
                />
              </div>
            )}
            <div className="dp__actions">
              <button
                className="dp__btn btn"
                onClick={this.toggleDatepicker}
                disabled={rangeMode ? !from || !to : !selectedDay}
              >
                <span className="btn__title">{t("apply")}</span>
              </button>
              <button
                className="dp__btn btn btn_white"
                onClick={this.resetDatepicker}
                disabled={rangeMode ? !from || !to : !selectedDay}
              >
                <span className="btn__title">{t("reset")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(onClickOutside(Datepicker));
