import React, { Component } from "react";
import Axis from "./Axis";
import Events from "./Events";

import { EventItem, Viewport } from "./types";
import { animate, DateUnit, getLocalX } from "./utils";
import Cursor from "./Cursor";
import Play from "./Play";
import Selection from "./Selection";

// Height = 64 (major = 32, minor = 24)
// FontSize = 10
export default class Timeline extends Component {
  /**
   * Last drag X pointer
   * @type {?number}
   * @private
   */
  _drag = null;

  _dragHappend = false;

  /**
   * Graph area element reference
   * @type {{current}}
   * @private
   */
  _area = React.createRef();

  state = {
    view: null,
    play: Date.now(),
    cursor: null,
  };

  render() {
    return (
      <div className="tl">
        <button className="tl__prev" onClick={this.movePrev} />
        <div ref={this._area} className="tl__container">
          {this.state.view != null && this.renderTimeline(this.state)}
        </div>
        <button className="tl__next" onClick={this.moveNext} />
      </div>
    );
  }

  /**
   *
   * @param {Viewport} view
   * @returns {*}
   */
  renderTimeline({ view }) {
    const events = {
      onMouseDown: this.onMouseDown,
      onMouseMove: this.onMouseMove,
      onMouseOut: this.onMouseOut,
      onMouseUp: this.onMouseUp,
    };

    /**
     *
     * @type {Object.<string, EventItem[]>}
     */
    const itemsJoined = {};

    // Join events into groups by time
    (this.props.items || [])
      .sort((a, b) => {
        if (a.since > b.since) return 1;
        if (a.since < b.since) return -1;
        return 0;
      })
      .forEach((item) => {
        const group = itemsJoined[item.type] || [];
        const event = new EventItem(
          item.type,
          item.since * 1000,
          item.until * 1000
        );

        // Get latest event in group
        let latest = group[group.length - 1];
        // Remove second condition to group all event types
        if (latest == null || item.type === "archive") {
          group.push(event);
        } else if (event.since - latest.until <= view.density * 3) {
          latest.include(event);
        } else {
          group.push(event);
        }
        itemsJoined[item.type] = group;
      });

    const items = [].concat.apply([], Object.values(itemsJoined));

    return (
      <svg
        width={view.width}
        height={70}
        {...events}
        style={{ position: "relative" }}
      >
        <g transform="translate(0, 6)">
          <g>
            <Group>
              <Axis viewport={view} timezone={this.props.timezone} />
            </Group>

            <Group y={40}>
              <Events viewport={view} events={items} />
            </Group>

            <Group y={35}>
              {this.props.playing !== null && (
                <Play left={view.pointFor(this.props.playing)} />
              )}
            </Group>

            <Group y={48} interactive>
              <Selection
                viewport={view}
                {...this.state.range}
                onChange={this.onRangeChange}
              />
            </Group>
          </g>

          <Group y={-5}>
            {this.state.cursor && (
              <Cursor
                x={this.state.cursor}
                time={view.timeAt(this.state.cursor)}
                timezone={this.props.timezone}
              />
            )}
          </Group>
        </g>
      </svg>
    );
  }

  componentDidMount() {
    const view = new Viewport(
      this.props.focus || Date.now(),
      DateUnit.Hour,
      this._area.current.offsetWidth
    );

    this.setState({ view }, (_) => {
      // Initial information notification
      this.notifyMove();
      this.notifyZoom();
    });

    window.addEventListener("resize", this.onResize);

    this._area.current.addEventListener("touchstart", this.onTouch);
    this._area.current.addEventListener("touchmove", this.onTouchMove);
    this._area.current.addEventListener("touchend", this.onTouchEnd);

    // TODO: see https://learn.javascript.ru/mousewheel#zoopark-wheel-v-raznyh-brauzerah
    this._area.current.addEventListener("wheel", this.onWheel);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);

    this._area.current.removeEventListener("touchstart", this.onTouch);
    this._area.current.removeEventListener("touchmove", this.onTouchMove);
    this._area.current.removeEventListener("touchend", this.onTouchEnd);
    this._area.current.removeEventListener("wheel", this.onWheel);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // TODO: This is auto scroll for keep play marker always visible
    // let view = this.state.view;
    // let playing = this.props.playing;
    // if (view) {
    //     if (playing > view.until)
    //     {
    //         this.moveNext();
    //     }
    // }
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, range: props.range };
  }

  onTouch = (event) => {
    event.preventDefault();
    if (event.changedTouches.length !== 1) return;

    const touch = event.changedTouches[0];

    this._drag = getLocalX(touch, this._area.current);

    this.setState({ cursor: null });
  };

  onTouchMove = (event) => {
    event.preventDefault();
    if (event.changedTouches.length !== 1) return;

    const touch = event.changedTouches[0];

    let x = getLocalX(touch, this._area.current);

    if (this._drag !== null) {
      this.setState(
        {
          view: this.state.view.move(
            (this._drag - x) * this.state.view.density
          ),
        },
        (_) => {
          this.notifyMove();
        }
      );
      this._drag = x;
      this._dragHappend = true;
    }
  };

  onTouchEnd = (event) => {
    event.preventDefault();
    if (this._dragHappend === false) this.onMouseUp(event.changedTouches[0]);

    this._dragHappend = false;
    this._drag = null;
  };

  onResize = (event) => {
    this.setState({
      view: this.state.view.resize(this._area.current.offsetWidth),
    });
  };

  onWheel = (event) => {
    if (null == event) return;

    event.preventDefault();

    let cursor = Math.round(getLocalX(event, this._area.current));
    // let factor = event.deltaY * this.state.view.density;
    let factor = event.deltaY * this.state.view.density;
    let center = this.state.view.timeAt(cursor);

    this.setState({ view: this.state.view.zoom(factor, center) }, (_) => {
      this.notifyZoom();
    });
  };

  onMouseUp = (event) => {
    if (this._dragHappend === false && this.state.range == null) {
      let cursor = getLocalX(event, this._area.current);
      let cursorTime = this.state.view.timeAt(cursor);
      let cursorSeconds = cursorTime / 1000;

      let insideEvent = (_) =>
        this.props.items.some(
          (x) =>
            x.since <= cursorSeconds &&
            (x.until >= cursorSeconds || x.until === 0)
        );

      if (this.props.onSeek && insideEvent()) this.props.onSeek(cursorTime);
    }

    this._drag = null;
    this._dragHappend = false;
  };

  onMouseMove = (event) => {
    let x = getLocalX(event, this._area.current);

    if (this._drag !== null) {
      this.setState(
        {
          view: this.state.view.move(
            (this._drag - x) * this.state.view.density
          ),
        },
        (_) => {
          this.notifyMove();
        }
      );
      this._drag = x;
      this._dragHappend = true;
    } else if (this.state.cursor !== x) {
      this.setState({ cursor: x });
    }
  };

  onMouseOut = (event) => {
    this._drag = null;
    this.setState({ cursor: null });
  };

  onMouseDown = (event) => {
    event.preventDefault();

    this._drag = getLocalX(event, this._area.current);

    this.setState({ cursor: null });
  };

  onRangeChange = (since, until) => {
    if (this.props.onRangeChanged) this.props.onRangeChanged(since, until);
  };

  movePrev = (e) => {
    this.moveTo(this.state.view.center - this.state.view.window / 2);
  };

  moveNext = (e) => {
    this.moveTo(this.state.view.center + this.state.view.window / 2);
  };

  zoom = (value) => {
    const target = this.state.view.window + value;
    animate({
      draw: (p) => {
        let diff = target - this.state.view.window;
        this.setState({ view: this.state.view.zoom(diff * p) }, (_) => {
          this.notifyZoom();
        });
      },
      timing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      duration: 800,
    });
  };

  moveTo = (time, zoom) => {
    // TODO: Change zoom to day + (2|4) hour
    animate({
      draw: (p) => {
        let timeDiff = time - this.state.view.center;
        let zoomDiff = zoom == null ? 0 : zoom - this.state.view.window;
        this.setState(
          { view: this.state.view.move(timeDiff * p).zoom(zoomDiff * p) },
          (_) => {
            this.notifyZoom();
            this.notifyMove();
          }
        );
      },
      timing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      duration: 1000,
    });
  };

  notifyMove = () => {
    this.props.onMove &&
      this.props.onMove(
        this.state.view.center,
        this.state.view.since,
        this.state.view.until
      );
  };

  notifyZoom = () => {
    this.props.onScaleChanged &&
      this.props.onScaleChanged(
        this.state.view.window,
        this.state.view.since,
        this.state.view.until
      );
  };
}

function Group({ x, y, children, interactive = false }) {
  return (
    <g
      style={{ pointerEvents: interactive ? "visiblePainted" : "none" }}
      transform={`translate(${x || 0}, ${y || 0})`}
    >
      {children}
    </g>
  );
}

