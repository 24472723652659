import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../Icon";
import Switch from "../../Switch";
import DropdownActions from "../../DropdownActions";

const PlaceTypeVisits = 1;
const PlaceTypeTracking = 2;

const cameraFields = {
  [PlaceTypeVisits]: "visits_id",
  [PlaceTypeTracking]: "time_tracking_id",
};

class CameraGroups extends React.Component {
  renderDropdown(group, index, type) {
    const {
      t,
      id,
      cameras,
      groups,
      updateGroup,
      placeType,
      disabled,
    } = this.props;

    let usedCameras = [];
    groups.forEach(function (x) {
      x.entrance_camera && usedCameras.push(x.entrance_camera);
      x.exit_camera && usedCameras.push(x.exit_camera);
    });

    const camerasWithoutTariff = cameras.filter(
      (camera) => !camera.face_recognition_reports && camera.neurobox_id
    );

    const placeId = (camera) => camera.places[cameraFields[placeType]];

    return (
      <DropdownActions
        field
        value={group[type] && cameras.filter((x) => x.id === group[type])[0]}
        disabled={disabled}
      >
        <div>
          <div className="dropdown__caption">
            {t("monitoring-tariff-connected")}
          </div>
          <Switch
            radio
            className="switch_dropdown"
            content={t("not-chosen")}
            value={!group[type]}
            onChange={() => updateGroup(index, type, null)}
          />
          {cameras
            .filter(
              (camera) =>
                (!placeId(camera) && !usedCameras.includes(camera.id)) ||
                (placeId(camera) === id && !usedCameras.includes(camera.id)) ||
                camera.id === group[type]
            )
            .map(
              (camera) =>
                camera.face_recognition_reports && (
                  <Switch
                    key={camera.id}
                    radio
                    className="switch_dropdown"
                    content={camera.title}
                    value={camera.id === group[type]}
                    onChange={() => updateGroup(index, type, camera.id)}
                  />
                )
            )}
          {camerasWithoutTariff.length > 0 && (
            <div className="dropdown__caption">
              {t("cameras-without-tariff")}
            </div>
          )}
          {camerasWithoutTariff.map((camera) => (
            <Link
              to={`/connect-recognition/${camera.id}`}
              className="dropdown__option dropdown__option_icon"
              key={camera.id}
            >
              <Icon name="plus" />
              {camera.title}
            </Link>
          ))}
        </div>
      </DropdownActions>
    );
  }

  render() {
    const { t, groups, addGroup, removeGroup, disabled } = this.props;

    const types = [
      {
        title: t("entrance-camera"),
        type: "entrance_camera",
      },
      {
        title: t("exit-camera"),
        type: "exit_camera",
      },
    ];

    return (
      <div className="create__section">
        <div className="create__flex">
          <div className="create__title">{t("cameras-capitalize")}</div>
          {!disabled && (
            <button className="create__action" onClick={addGroup}>
              {t("add-group")}
            </button>
          )}
        </div>
        <div className="create__content">{t("places-cameras-info")}</div>
        {groups.map((group, index) => (
          <div className="create__group" key={index}>
            <div className="create__flex">
              <div className="create__subtitle">
                {t("group")} {index + 1}
              </div>
              {index > 0 && (
                <button
                  className="create__action"
                  onClick={() => removeGroup(index)}
                >
                  {t("remove")}
                </button>
              )}
            </div>
            {types.map((x, i) => (
              <div className="create__field field" key={i}>
                <div className="field__label">{x.title}</div>
                <div className="field__wrap">
                  {this.renderDropdown(group, index, x.type)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default CameraGroups;
