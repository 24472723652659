import React, { Component } from "react";
import cn from "classnames";
import Icon from "./Icon";
import Modal from "./Modal";

class ModalLauncher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.show || false,
    };
  }

  handleToggleModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showModal: !this.state.showModal });
    document.body.classList.toggle("no-scroll");
    this.props.onClose && this.props.onClose();
  };

  render() {
    const {
      className,
      buttonClassName,
      label,
      link,
      action,
      icon,
      modalClassName,
      children,
      disabled,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className={className}>
        {link ? (
          <button
            className={cn("btn-link", buttonClassName)}
            onClick={this.handleToggleModal}
          >
            {label}
          </button>
        ) : action ? (
          <button
            className={cn("action", buttonClassName)}
            onClick={this.handleToggleModal}
          >
            <Icon name={icon} />
          </button>
        ) : (
          <button
            disabled={disabled}
            className={cn("btn", buttonClassName)}
            type="button"
            onClick={this.handleToggleModal}
          >
            {icon && <Icon className="btn__icon" name={icon} />}
            <span className="btn__label">{label}</span>
          </button>
        )}

        {showModal && (
          <Modal
            className={cn(modalClassName)}
            onCloseRequest={this.handleToggleModal}
          >
            {React.cloneElement(children, {
              closeModal: this.handleToggleModal,
            })}
          </Modal>
        )}
      </div>
    );
  }
}

export default ModalLauncher;
