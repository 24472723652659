import React from "react";
import { translate } from "react-i18next";
import Loader from "../Loader";

const ReportsLoader = props => {
  const { t } = props;

  return (
    <div className="chart__loader">
      <div className="chart__box">
        <div className="chart__icon">
          <Loader color="white" />
        </div>
        <div className="chart__subtitle">{t("data-loading")}</div>
      </div>
    </div>
  );
};

export default translate()(ReportsLoader);
