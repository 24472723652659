import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Nav.module.sass";
import Counter from "../Counter";
import Icon from "../Icon";

import { checkPerms, isEurocam } from "../../utils";

const Nav = (props) => {
  const { t, user, events } = props;

  const navList = [
    {
      url: "/cameras",
      icon: "camera",
      title: "menu-watch",
      hide: !checkPerms(user, "camera:page"),
    },
    {
      url: "/events",
      events: true,
      icon: "notifications",
      title: "menu-events",
      hide: !checkPerms(user, "event:page"),
    },
    {
      url: "/faces",
      icon: "face",
      title: "menu-faces",
      hide: !checkPerms(user, "person:page"),
    },
    {
      url: "/reports",
      icon: "reports",
      title: "menu-monitoring",
      hide: !checkPerms(user, "reports:page"),
    },
    {
      url: "/profile",
      icon: "settings",
      title: "menu-profile",
      hide: false,
    },
    {
      url: "/payment",
      icon: "payment",
      title: "menu-payment",
      hide: !checkPerms(user, "payment:page"),
    },
    // {
    //   url: "/neuroboxes",
    //   icon: "box",
    //   title: "menu-neuroboxes"
    // }
  ];

  return (
    <nav className={styles.nav}>
      {navList
        .filter((x) => !x.hide)
        .map((x, index) => (
          <NavLink
            className={styles.item}
            activeClassName={styles.active}
            to={x.url}
            key={index}
            strict
          >
            {x.events && events.newCount > 0 && (
              <Counter className={styles.counter} value={events.newCount} />
            )}
            <div className={styles.preview}>
              <Icon name={x.icon} />
            </div>
            <span>{t(x.title)}</span>
          </NavLink>
        ))}
      {
        <a className={styles.item} href="mailto:info@neurocam.com">
          <div className={styles.preview}>
            <Icon name="help" />
          </div>
          <span>{t("menu-help")}</span>
        </a>
      }
    </nav>
  );
};

const mapStateToProps = (state) => ({
  cameras: state.cameras,
  events: state.events,
});

export default withRouter(translate()(connect(mapStateToProps)(Nav)));
