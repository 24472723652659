import HttpClient from "../http";

const initialState = {
  items: [],
  fetched: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMERAS_LIST:
      return { ...state, items: action.payload, fetched: true };
    default:
      return state;
  }
};

const CAMERAS_LIST = "CAMERAS_LIST";
export const CamerasList = () => async dispatch => {
  const response = await HttpClient.get("/video/cameras");
  dispatch({ type: CAMERAS_LIST, payload: response.data });
};
