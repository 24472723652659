import React from "react";
import format from "dateformat";
import { DateFormats } from "./utils";
import moment from "moment-timezone";

export default function Label({ left, time, major, type, timezone }) {
  let top = major ? 15 : 30;
  // TODO format for timezone
  let fmt = major ? DateFormats.Major[type] : DateFormats.Minor[type];

  return (
    <text
      className={`label ${major ? "major" : "minor"}`}
      x={left}
      y={top}
      textAnchor="middle"
    >
      {format(moment.tz(time, timezone).format("L LT"), fmt)}
    </text>
  );
}
