import React, {Component, Fragment} from 'react'
import Event from "./Event";

export default class Events extends Component
{

    render() {
        return <Fragment>
            {this.events(this.props).map((x, i) => <Event key={i} {...x} />)}
        </Fragment>
    }

    events({events, viewport}) {
        return events
            .filter(event => {
                return (event.since >= viewport.since && event.since <= viewport.until)
                    || (event.until >= viewport.since && event.until <= viewport.until)
                    || (event.since <= viewport.since && event.until >= viewport.until)
            })
            .map(event => {
                return {
                    left: viewport.pointFor(event.since),
                    type: event.type,
                    width: Math.max(0, event.duration / viewport.density),
                }
            })
    }

}