import React, { useState } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Sidebar.module.sass";
import FacesSearch from "./FacesSearch";
import Nav from "./Nav";
import PlacesCameras from "./PlacesCameras";
import Demography from "./Demography";
import Dates from "./Dates";
import Visits from "./Visits";

import { FacesSetFilter } from "../../../reducers/faces";

const Sidebar = ({
  personsFetched,
  filters,
  FacesSetFilter,
  personsEvents,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.inactive]: !personsFetched || loading || file,
      })}
    >
      <FacesSearch
        file={file}
        setFile={setFile}
        loading={loading}
        setLoading={setLoading}
      />
      {filters.persons ? (
        <Nav />
      ) : (
        <>
          <PlacesCameras filters={filters} setFilter={FacesSetFilter} />
          <Demography filters={filters} setFilter={FacesSetFilter} />
          <Dates filters={filters} setFilter={FacesSetFilter} />
          <Visits
            filters={filters}
            setFilter={FacesSetFilter}
            events={personsEvents}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  personsFetched: state.faces.personsFetched,
  filters: state.faces.filters,
  personsEvents: state.faces.personsEvents,
});

export default translate()(
  connect(mapStateToProps, {
    FacesSetFilter,
  })(Sidebar)
);
