import React from "react";
// import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { Bar } from "react-chartjs-2";
import update from "immutability-helper";
import { chartOptions } from "../utils";
import ReportsChangings from "../ReportsChangings";
import ReportsChangingsPercentage from "../ReportsChangingsPercentage";

class ChartNewReturning extends React.Component {
  render() {
    const { t, footfall, dates, labels } = this.props;

    const newChartOptions = update(chartOptions, {
      plugins: {
        $set: {
          datalabels: {
            offset: 0,
            font: {
              family: "'Aeroport-Bold', sans-serif",
              size: 10,
            },
          },
        },
      },
      tooltips: {
        enabled: { $set: true },
        position: { $set: "average" },
        mode: { $set: "index" },
        intersect: { $set: false },
        titleFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        titleFontSize: { $set: 10 },
        titleFontStyle: { $set: "normal" },
        bodyFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        bodyFontSize: { $set: 10 },
        bodyFontStyle: { $set: "normal" },
        callbacks: {
          $set: {
            title: function (tooltipItems, data) {
              var total = 0;
              tooltipItems.forEach(function (tooltipItem) {
                total +=
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
              });
              return `${t("total")} ${total} ${t("man-short")}`;
            },
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: { $set: true },
            ticks: {
              padding: {
                $set: 20,
              },
            },
          },
        ],
        yAxes: [
          {
            stacked: { $set: true },
            ticks: {
              suggestedMax: {
                $set: footfall.current.max + 1,
              },
            },
          },
        ],
      },
    });

    const current = footfall.current;
    const prev = footfall.prev;

    let dataReturned = [];
    let dataNew = [];

    dates.forEach((date) => {
      let returned = 0;
      let newValue = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          returned = x.returned;
          newValue = x.new;
        }
      });

      dataReturned.push(returned);
      dataNew.push(newValue);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: t("repeat-visits"),
          maxBarThickness: 4,
          backgroundColor: "#abb0b4",
          hoverBackgroundColor: "#abb0b4",
          data: dataReturned,
          datalabels: { anchor: "start", align: "bottom", color: "#abb0b4" },
        },
        {
          label: t("new-visits"),
          maxBarThickness: 4,
          backgroundColor: "#42d475",
          hoverBackgroundColor: "#42d475",
          data: dataNew,
          datalabels: { anchor: "end", align: "top", color: "#42d475" },
        },
      ],
    };

    const newPercent = Math.round(
      (current.new * 100) / (current.new + current.returned)
    );
    const newPercentPrev = Math.round(
      (prev.new * 100) / (prev.new + prev.returned)
    );
    const returnedPercent = 100 - newPercent;
    const returnedPercentPrev = 100 - newPercentPrev;

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Bar data={chartData} options={newChartOptions} height={360} />
        </div>
        <div className="chart__foot">
          <div className="chart__row">
            <div className="chart__cell">
              <div className="chart__preview">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                >
                  <polygon
                    fill="#42D475"
                    points="18 0 18 2.999 21 3 21 5 18 5 18 8 16 8 16 5 13 5 13 3 16 3 16 0"
                  />
                  <path
                    fill="#0D1C26"
                    d="M8,6.9 C8.75025775,6.9 9.44352451,7.30025772 9.81865338,7.94999999 C10.1937823,8.59974225 10.1937823,9.40025775 9.81865338,10.05 C9.44352451,10.6997423 8.75025775,11.1 8,11.1 C6.84020203,11.1 5.9,10.159798 5.9,9 C5.9,7.84020203 6.84020203,6.9 8,6.9 Z M8,15.9 C10.97,15.9 14.1,17.36 14.1,18 L14.1,19.1 L1.9,19.1 L1.9,18 C1.9,17.36 5.03,15.9 8,15.9 Z M8,5 C5.79,5 4,6.79 4,9 C4,11.21 5.79,13 8,13 C10.21,13 12,11.21 12,9 C12,6.79 10.21,5 8,5 Z M8,14 C5.33,14 0,15.34 0,18 L0,21 L16,21 L16,18 C16,15.34 10.67,14 8,14 Z"
                  />
                </svg>
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {newPercent}%
                  <span className="chart__total"> | {current.new}</span>
                  <ReportsChangingsPercentage
                    current={newPercent}
                    prev={newPercentPrev}
                  />
                </div>
                <div className="chart__label">{t("new-visits")}</div>
                {/* <Link to="/events" className="chart__label">
                  {t("new-visits")}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                >
                  <polygon
                    fill="#0D1C26"
                    points="11.991 6 7.491 10.512 6 12 6.003 12.003 6 12.007 7.491 13.493 11.991 18 13.491 16.5 8.984 12.002 13.491 7.5"
                  />
                  <polygon
                    fill="#42D475"
                    points="1.5 0 6 4.512 7.491 6 7.488 6.003 7.491 6.007 6 7.493 1.5 12 0 10.5 4.507 6.002 0 1.5"
                  />
                </svg>
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {current.return_factor}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option={"return_factor"}
                  />
                </div>
                <div className="chart__label">{t("rate-of-return")}</div>
                {/* <Link to="/events" className="chart__label">
                  {t("rate-of-return")}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                >
                  <path
                    fill="#24323A"
                    d="M18.5,2.2 L18.5,0 L15.687,2.75 L18.5,5.5 L18.5,3.3 C20.362,3.3 21.875,4.78 21.875,6.6 C21.875,8.42 20.362,9.9 18.5,9.9 C16.638,9.9 15.125,8.42 15.125,6.6 L14,6.6 C14,9.031 16.014,11 18.5,11 C20.986,11 23,9.031 23,6.6 C23,4.169 20.986,2.2 18.5,2.2 Z"
                    opacity=".4"
                  />
                  <path
                    fill="#0D1C26"
                    d="M8,8.9 C8.75025775,8.9 9.44352451,9.30025772 9.81865338,9.94999999 C10.1937823,10.5997423 10.1937823,11.4002577 9.81865338,12.05 C9.44352451,12.6997423 8.75025775,13.1 8,13.1 C6.84020203,13.1 5.9,12.159798 5.9,11 C5.9,9.84020203 6.84020203,8.9 8,8.9 Z M8,17.9 C10.97,17.9 14.1,19.36 14.1,20 L14.1,21.1 L1.9,21.1 L1.9,20 C1.9,19.36 5.03,17.9 8,17.9 Z M8,7 C5.79,7 4,8.79 4,11 C4,13.21 5.79,15 8,15 C10.21,15 12,13.21 12,11 C12,8.79 10.21,7 8,7 Z M8,16 C5.33,16 0,17.34 0,20 L0,23 L16,23 L16,20 C16,17.34 10.67,16 8,16 Z"
                  />
                </svg>
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {returnedPercent}%
                  <span className="chart__total"> | {current.returned}</span>
                  <ReportsChangingsPercentage
                    current={returnedPercent}
                    prev={returnedPercentPrev}
                  />
                </div>
                <div className="chart__label">{t("repeat-visits")}</div>
                {/* <Link to="/events" className="chart__label">
                  {t("repeat-visits")}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="chart__lines">
            <div
              className="chart__line"
              style={{
                flex: `1 1 ${newPercent}%`,
                background: chartData.datasets[1].backgroundColor,
              }}
            />
            <div
              className="chart__line"
              style={{
                flex: `1 1 ${returnedPercent}%`,
                background: chartData.datasets[0].backgroundColor,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartNewReturning);
