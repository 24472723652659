import HttpClient from "../http";
import { CamerasList } from "./cameras";

export const Archive = "archive";
export const Persons = "persons";

const initialState = {
  balance: null,
  confirmRedirectURI: null,
  cameraRates: [],
  cameraRatesMap: {
    [Archive]: {},
    [Persons]: {},
  },
  cameraRatesResult: {
    Success: false,
    Message: "",
  },
  cameraRatesFetched: false,
  transactions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_LOADED:
      return { ...state, balance: (action.payload.Balance || 0) / 100 };
    case CAMERA_RATES_LOAD:
      return {
        ...state,
        cameraRates: action.rates || [],
        cameraRatesMap: {
          [Archive]: action[Archive],
          [Persons]: action[Persons],
        },
        cameraRatesFetched: true,
      };
    case CAMERA_RATES_PURCHASE:
      return { ...state, cameraRatesResult: action.payload };
    case PAYMENT_NEW:
      return { ...state, confirmRedirectURI: action.payload.RedirectURI };
    case TRANSACTIONS_LOAD:
      return { ...state, transactions: action.payload.Transactions || [] };
    default:
      return state;
  }
};

const BALANCE_LOADED = "BALANCE_LOADED";
export const BalanceLoad = () => async (dispatch) => {
  const response = await HttpClient.get("/payments/v1/balance");
  return dispatch({ type: BALANCE_LOADED, payload: response.data });
};

const CAMERA_RATES_LOAD = "CAMERA_RATES_LOAD";
export const CameraRatesLoad = () => async (dispatch) => {
  const response = await HttpClient.get("/payments/v1/camera_rates");
  const rates = response.data.Rates || [];

  const reduceByID = (items) =>
    items.reduce((m, item) => ({ ...m, [item.CameraID]: item }), {});

  return dispatch({
    type: CAMERA_RATES_LOAD,
    rates,
    [Archive]: reduceByID(rates.filter((c) => c.Type === Archive)),
    [Persons]: reduceByID(rates.filter((c) => c.Type === Persons)),
  });
};

const CAMERA_RATES_PURCHASE = "CAMERA_RATE_PURCHASE";
export const CameraRatesPurchase = (cameraID, rateID) => async (dispatch) => {
  const response = await HttpClient.post("/payments/v1/camera_rates", {
    CameraID: cameraID,
    Rate: rateID,
  });

  if (response.data.Success) {
    dispatch(CameraRatesLoad());
    dispatch(TransactionsLoad());
    dispatch(CamerasList());
  }

  return dispatch({ type: CAMERA_RATES_PURCHASE, payload: response.data });
};

const CAMERA_RATES_CANCEL = "CAMERA_RATES_CANCEL";
export const CameraRatesCancel = (id) => async (dispatch) => {
  const response = await HttpClient.delete(`/payments/v1/camera_rates/${id}`);

  if (response.data.Success) {
    dispatch(CameraRatesLoad());
    dispatch(TransactionsLoad());
    dispatch(CamerasList());
    dispatch(BalanceLoad());
  }

  return dispatch({ type: CAMERA_RATES_CANCEL, payload: response.data });
};

const CAMERA_RATES_PATCH = "CAMERA_RATES_PATCH";
export const CameraRatesPatch = (id, data) => async (dispatch) => {
  const response = await HttpClient.patch(
    `/payments/v1/camera_rates/${id}`,
    data
  );

  dispatch(CameraRatesLoad());

  return dispatch({ type: CAMERA_RATES_PATCH, payload: response.data });
};

const PAYMENT_NEW = "PAYMENT_NEW";
export const PaymentNew = (total) => async (dispatch) => {
  const response = await HttpClient.post("/payments/v1/deposit", {
    Total: total,
  });
  return dispatch({ type: PAYMENT_NEW, payload: response.data });
};

export const BankTransferNew = (form) => async (dispatch) => {
  return HttpClient.post("/users/bank-transfer", form)
}

const TRANSACTIONS_LOAD = "TRANSACTIONS_LOAD";
export const TransactionsLoad = () => async (dispatch) => {
  const response = await HttpClient.get("/payments/v1/transactions");
  return dispatch({ type: TRANSACTIONS_LOAD, payload: response.data });
};
