import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import { numeralize } from "../../../utils";

class ReportsPlaces extends React.Component {
  renderNavLink = (type, place) => {
    const cameras = place.groups.reduce(
      (a, b) => a + (b.entrance_camera && +1) + (b.exit_camera && +1),
      0
    );
    return (
      <NavLink
        className="layout__link"
        activeClassName="active"
        to={
          type === 1
            ? `/reports/${place.id}`
            : `/reports/time-tracking/${place.id}`
        }
        key={place.id}
      >
        <div className="layout__wrap">
          <div className="layout__name">{place.title}</div>
          <div className="layout__info">
            {cameras}{" "}
            {numeralize(this.props.i18n.language, cameras, [
              "камера",
              "камеры",
              "камер",
              "camera",
              "cameras",
            ])}
          </div>
        </div>
        <div className="layout__items">{place.value}</div>
      </NavLink>
    );
  };

  render() {
    const { t, type, places } = this.props;
    return (
      places && (
        <div className="layout__nav">
          <NavLink
            className="layout__link"
            activeClassName="active"
            exact
            to={type === 1 ? "/reports" : "/reports/time-tracking"}
          >
            <div className="layout__wrap">
              <div className="layout__name">{t("all-places")}</div>
            </div>
          </NavLink>
          {places.map((place) => this.renderNavLink(type, place))}
        </div>
      )
    );
  }
}

export default translate()(withRouter(ReportsPlaces));
