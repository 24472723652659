import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";
import Modal from "../Modal";
import Switch from "../Switch";

import { CameraRatesCancel, CameraRatesPatch } from "../../reducers/payments";
import { toast } from "react-toastify";

export const RateTitle = (rate, t) => {
  switch (rate.Type) {
    case "archive":
      return rate.ArciveOnlyEvents
        ? t("tariffs-event-recording") + " " + rate.ArchiveDays
        : t("tariffs-full-record") + " " + rate.ArchiveDays;

    case "persons":
	return rate.Celebrity ? t("base-celebrity") : t("base-own");

    default:
      return "unknown rate";
  }
};

class CameraRateItem extends React.Component {
  state = {
    showModal: false,
  };

  handleToggleModal = (showModal) => this.setState({ showModal });

  until = () => new Date(this.props.rate.Until * 1000).toLocaleDateString();

  render() {
    const {
      t,
      isCompanyOwner,
      camera,
      rate,
      onCancel,
      onSetAutoUpdate,
    } = this.props;
    const { showModal } = this.state;

    console.log(this.props);

    return (
      <div className="tariffs__item" key={rate.ID}>
        <div className="tariffs__preview">
          <Icon className="tariffs__pic" name="camera" />
        </div>
        <div className="tariffs__wrap">
          <div className="tariffs__name">
            <NavLink to={`/cameras/${camera.id}`}>{camera.title}</NavLink>
          </div>
          <div className="tariffs__row tariffs__row_connected">
            <div className="tariffs__cell">
              <span className="color-gray">
                {t("tariff")}: {RateTitle(rate.Rate, t)}
              </span>
            </div>
            <div className="tariffs__cell">
              <span className="color-gray">{t("tariffs-paid-to")}:</span>{" "}
              {this.until()}
            </div>
            <div className="tariffs__cell">
              <Switch
                content={t("tariffs-auto-renewal")}
                toggler
                value={rate.AutoUpdate || false}
                onChange={onSetAutoUpdate}
                disabled={!isCompanyOwner}
              />
            </div>
            {showModal && (
              <Modal
                className="modal_md"
                onCloseRequest={() => this.handleToggleModal(false)}
              >
                <div className="connect">
                  <div className="connect__title title">
                    {t("tariffs-cancel-title")}
                  </div>
                  <div className="connect__details">
                    <div className="connect__detail">
                      {t("tariffs-cancel-text")}
                    </div>
                    <div className="connect__detail">
                      <span className="bold">
                        {t("tariffs-paid-to-title")}:
                      </span>{" "}
                      {this.until()}
                    </div>
                  </div>
                  <div className="connect__col">
                    <button
                      className="btn btn_lg"
                      onClick={() => onCancel(rate.ID)}
                    >
                      {t("disable")}
                    </button>
                  </div>
                </div>
              </Modal>
            )}

            <div className="tariffs__cell">
              {isCompanyOwner && (
                <a
                  href="#/"
                  className="tariffs__action"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleToggleModal(true);
                  }}
                >
                  {t("disable")}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CameraItem = ({ t, isCompanyOwner, camera, type }) => {
  const connectURL =
    type === "persons"
      ? `/connect-recognition/${camera.id}`
      : `/connect-cloud/${camera.id}`;

  const checkAvailability = (e) => {
    if (type === "persons" && !camera.neurobox_id) {
      e.preventDefault();
      toast.error(t("camera-should-be-connected-via-neurobox"));
    }
  };

  return (
    <div className="tariffs__item" key={camera.id}>
      <div className="tariffs__preview">
        <Icon className="tariffs__pic" name="camera" />
      </div>
      <div className="tariffs__wrap">
        <div className="tariffs__row tariffs__row_unconnected">
          <div className="tariffs__cell">
            <div className="tariffs__name">
              <NavLink to={`/cameras/${camera.id}`}>{camera.title}</NavLink>
            </div>
          </div>
          <div className="tariffs__cell">
            {isCompanyOwner && (
              <NavLink
                onClick={checkAvailability}
                className="tariffs__action"
                to={connectURL}
              >
                {t("connect")}
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

class Tariffs extends React.Component {
  onSetAutoUpdate = (rate) =>
    this.props.CameraRatesPatch(rate.ID, {
      AutoUpdate: !rate.AutoUpdate,
      SetAutoUpdate: true,
    });

  renderConnectedCameras = () => {
    const {
      t,
      isCompanyOwner,
      cameras,
      cameraRatesMap,
      CameraRatesCancel,
    } = this.props;

    return cameras
      .filter(
        (c) =>
          cameraRatesMap[c.id] && cameraRatesMap[c.id].Type === this.props.type
      )
      .map((c) => (
        <CameraRateItem
          isCompanyOwner={isCompanyOwner}
          t={t}
          camera={c}
          rate={cameraRatesMap[c.id]}
          onCancel={CameraRatesCancel}
          onSetAutoUpdate={() => this.onSetAutoUpdate(cameraRatesMap[c.id])}
          key={c.id}
        />
      ));
  };

  renderDisconnectedCameras = () => {
    const { t, isCompanyOwner, cameras, cameraRatesMap } = this.props;
    return cameras
      .filter((c) => !cameraRatesMap[c.id])
      .filter((c) => !c.shared)
      .map((c) => (
        <CameraItem
          t={t}
          isCompanyOwner={isCompanyOwner}
          camera={c}
          type={this.props.type}
          key={c.id}
        />
      ));
  };

  render() {
    const { cameras, cameraRatesMap, t } = this.props;
    const hasDisconnectedCameras =
      cameras.filter((c) => !cameraRatesMap[c.id] && !c.shared).length > 0;

    return (
      <div className="tariffs">
        {Object.keys(cameraRatesMap).length > 0 && (
          <div className="tariffs__section">
            <div className="tariffs__title title">{t("connected-cameras")}</div>
            <div className="tariffs__list">{this.renderConnectedCameras()}</div>
          </div>
        )}
        {hasDisconnectedCameras && (
          <div className="tariffs__section">
            <div className="tariffs__title title">
              {t("cameras-without-tariff")}
            </div>
            <div className="tariffs__list">
              {this.renderDisconnectedCameras()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  cameraRatesMap: state.payments.cameraRatesMap[ownProps.type],
  cameras: state.cameras.items,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, { CameraRatesCancel, CameraRatesPatch })
  )(Tariffs)
);
