import React from "react";
import { translate } from "react-i18next";
import { Line } from "react-chartjs-2";
import { NumberToMS } from "../utils";
import Icon from "../../Icon";
import ReportsChangings from "../ReportsChangings";

class ChartTotal extends React.Component {
  render() {
    const { t, chartOptions, visitDuration, dates, labels } = this.props;

    const current = visitDuration.current;
    const prev = visitDuration.prev;

    const dataMales = [];
    const dataFemales = [];

    dates.forEach((date) => {
      let males = 0;
      let females = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          males = x.males;
          females = x.females;
        }
      });

      dataMales.push(males);
      dataFemales.push(females);
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: t("man-plural"),
          fill: "none",
          backgroundColor: "#1DA1F2",
          borderColor: "#1DA1F2",
          data: dataMales,
        },
        {
          label: t("woman-plural"),
          fill: "none",
          backgroundColor: "#f30027",
          borderColor: "#f30027",
          data: dataFemales,
        },
      ],
    };

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Line data={data} options={chartOptions} height={360} />
        </div>
        <div className="chart__foot">
          <div className="chart__row">
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="male" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {NumberToMS(t, current.males)}{" "}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option="males"
                    type={t("sec")}
                    time
                    t={t}
                  />
                </div>
                <div className="chart__label">
                  {t("avg-visit-duration-men")}
                </div>
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="female" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {NumberToMS(t, current.females)}{" "}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option="females"
                    type={t("sec")}
                    time
                    t={t}
                  />
                </div>
                <div className="chart__label">
                  {t("avg-visit-duration-women")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartTotal);
