import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Action.module.sass";
import Icon from "../Icon";

const Action = ({
  className,
  icon,
  to,
  onClick,
  active,
  activeLine,
  small,
  src,
  alt,
}) => {
  const classes = cn(
    styles.action,
    { [styles.small]: small },
    { [styles.active]: active },
    { [styles.line]: activeLine },
    className
  );

  const content = () => {
    return icon ? <Icon name={icon} /> : <img src={src} alt={alt} />;
  };

  return to ? (
    <Link className={classes} to={to}>
      {content()}
    </Link>
  ) : (
    <button className={classes} onClick={onClick}>
      {content()}
    </button>
  );
};

export default Action;
