import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Header.module.sass";
import Action from "../Action";
import Button from "../Button";
import ActionsDropdown from "../ActionsDropdown";

const Header = ({
  t,
  back,
  title,
  selection,
  menu,
  actions,
  rightButton,
  dropdown,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.left}>
        {back && (
          <Action to={back.to} onClick={back.onClick} icon={back.icon} />
        )}
        {title && <div className="title">{title}</div>}
        {menu && (
          <div className={styles.menu}>
            {menu.map((x, index) => (
              <NavLink
                to={x.to}
                isActive={x.isActive}
                activeClassName={styles.active}
                onClick={x.onClick}
                key={index}
              >
                {x.title}
              </NavLink>
            ))}
          </div>
        )}
        {selection && (
          <>
            <div className={styles.selected}>
              <strong>{t("selected")}</strong> {selection.selected.length}
            </div>
            {selection.isSelectAll && (
              <button
                className={styles.buttonLink}
                onClick={selection.onSelectAll}
              >
                {t("select-all")}
              </button>
            )}
            {selection.isUnselectAll && (
              <button
                className={styles.buttonLink}
                onClick={selection.onUnselectAll}
              >
                {t("clear")}
              </button>
            )}
          </>
        )}
      </div>
      <div className={styles.right}>
        {actions && (
          <div className={styles.actions}>
            {actions.map((x, index) => (
              <Action
                className={{ [styles.onlyDesktop]: x.onlyDesktop }}
                to={x.to}
                onClick={x.onClick}
                active={x.active}
                activeLine
                icon={x.icon}
                key={index}
              />
            ))}
          </div>
        )}
        {rightButton && (
          <Button
            className={cn(styles.button, {
              [styles.plus]: rightButton.icon === "plus",
            })}
            to={rightButton.to}
            onClick={rightButton.onClick}
            icon={rightButton.icon && rightButton.icon}
            title={rightButton.title}
            loading={rightButton.loading}
            disabled={rightButton.disabled}
            large
          />
        )}
        {dropdown && (
          <ActionsDropdown icon="group" options={dropdown.options} />
        )}
      </div>
    </div>
  );
};

export default translate()(Header);
