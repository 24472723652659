import React from "react";
import PropTypes from "prop-types";

import EventAlert from "./Events/EventsAlert";

const eventNotification = ({ event }) => <EventAlert event={event} />;

eventNotification.propTypes = {
  event: PropTypes.objectOf({
    ID: PropTypes.string.isRequired,
    CameraID: PropTypes.number.isRequired,
    Object: PropTypes.string.isRequired,
    Person: PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    Group: PropTypes.objectOf({
      Title: PropTypes.string.isRequired,
      Color: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const EventNotification = eventNotification;
