import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import Icon from "../Icon";
import Loader from "../Loader";
import Switch from "../Switch";
import CopyLink from "../CopyLink";
import ShareButtons from "../ShareButtons";
import { videoArchiveUrl } from "../../links";
import Timestamp from "../Timestamp";
import { SimplePlayer } from "../Player/SimplePlayer";
import Person from "../Person";
import EventsList from "./EventsList";
import { Archive } from "../../reducers/payments";
import { eventImage, guidMainPhotoUrl } from "../../links";

class EventsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleShare: false,
      url: null,
    };
  }

  componentDidMount() {
    this.props.setFilter({ cameraId: this.props.match.params.id });
    this.props.markEvent(this.props.match.params.eventId);
    this.props
      .getEvent(this.props.match.params.id, this.props.match.params.eventId)
      .then(() => this.setState({ url: this.getEventURL() }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.eventId !== this.props.match.params.eventId) {
      this.setState({ url: "" });
      this.props.markEvent(this.props.match.params.eventId);
      this.props
        .getEvent(this.props.match.params.id, this.props.match.params.eventId)
        .then(() => this.setState({ url: this.getEventURL() }));
    }
  }

  scrollToItem = () => {
    this.refBody.current &&
      this.refBody.current.scrollTo({
        top: 100,
        behavior: "smooth",
      });
  };

  toggleShare = () => {
    this.setState({
      visibleShare: !this.state.visibleShare,
    });
  };

  getCamera = () =>
    this.props.cameras.find(
      (item) => item.id === parseInt(this.props.match.params.id, 10)
    );

  getEventsList = () =>
    Object.keys(this.props.itemsByDays).reduce(
      (acc, curr) => [...acc, ...this.props.itemsByDays[curr]],
      []
    );

  getEventURL = () => {
    const camera = this.getCamera();

    if (!camera || !this.props.event) {
      return "";
    }
    const { Timestamp, Until } = this.props.event;

    return videoArchiveUrl(camera.id, Timestamp, Until);
  };

  goCamera = (e, cameraId) => {
    e.preventDefault();
    this.props.history.push(`/cameras/${cameraId}`);
  };

  goCameraEvents = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/events/${this.props.match.params.id}`,
      state: this.props.location.state,
    });
  };

  renderEventTime = () =>
    this.props.event.Timestamp && (
      <Timestamp value={this.props.event.Timestamp} />
    );

  renderTitle = () => {
    const camera = this.getCamera();
    return camera && camera.title;
  };

  renderPerson = (event) => {
    const { t } = this.props;
    const person = event.Person;

    console.log(event);

    if (!person) {
      return (
        <Person
          event={event}
          avatar={guidMainPhotoUrl(event.Guid)}
          list={t("unknown-person")}
          sex={event.Sex}
          age={event.Age}
          t={t}
        />
      );
    }

    const group = event.Group;

    return (
      <Person
        event={event}
        avatar={guidMainPhotoUrl(event.Guid)}
        list={group && group.title}
        color={group?.color}
        name={`${person.name} ${person.last_name}`}
      />
    );
  };

  render() {
    const { visibleShare } = this.state;
    const {
      t,
      event,
      hasMore,
      itemsByDays,
      days,
      eventsFetch,
      fetched,
      cameraRatesMap,
    } = this.props;

    const isPersonEvent = event.Object === "person";
    const showPlayer = cameraRatesMap[event.CameraID];

    return (
      <div className="layout__container layout__container_event">
        <div className="layout__head">
          <button
            className="layout__close action"
            onClick={this.goCameraEvents}
          >
            <Icon name="close" />
          </button>
          <div className="layout__title">{this.renderEventTime()}</div>
        </div>
        <div className="layout__scroll">
          {event.CameraID ? (
            <div className="inner">
              <div className="inner__container">
                {showPlayer ? (
                  <SimplePlayer key={event.ID} url={this.state.url} />
                ) : (
                  event.CameraID && (
                    <div className="inner__placeholder">
                      <img
                        src={eventImage(event.CameraID, event.ID)}
                        alt={event.ID}
                      />
                    </div>
                  )
                )}
                <div className="inner__wrap">
                  {isPersonEvent && this.renderPerson(event)}
                  <div className="content">
                    <p>
                      <strong>{t("camera-title")}</strong>
                      {": "}
                      <a
                        href="#/"
                        onClick={(e) => this.goCamera(e, event.CameraID)}
                      >
                        <span className="color-red">{this.renderTitle()}</span>
                      </a>
                    </p>
                    <p>
                      <strong>{t("event-time")}</strong>{" "}
                      <Timestamp value={event.Timestamp} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="inner__sidebar inner__sidebar_events">
                {visibleShare ? (
                  <div className="inner__head">
                    <button
                      className="inner__preview"
                      onClick={this.toggleShare}
                    >
                      <Icon className="inner__pic" src="next-circle" />
                    </button>
                    <div className="inner__title">{t("events-item-share")}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="inner__body">
                  {visibleShare ? (
                    <div className="share">
                      <Switch
                        className="share__switch"
                        content={t("events-item-link-access")}
                        toggler="right"
                      />
                      <div className="share__link">
                        <CopyLink link="zpl://project?pid=5b8037386475dfb…" />
                      </div>
                      <ShareButtons />
                    </div>
                  ) : fetched ? (
                    <InfiniteScroll
                      initialLoad={false}
                      loadMore={eventsFetch}
                      hasMore={hasMore}
                      useWindow={false}
                    >
                      {days.map((day) => (
                        <EventsList
                          key={day}
                          day={day}
                          items={itemsByDays[day]}
                          columnMode={true}
                        />
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cameraRatesMap: state.payments.cameraRatesMap[Archive],
});

export default translate()(withRouter(connect(mapStateToProps)(EventsItem)));
