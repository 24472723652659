import React from "react";
import moment from "moment-timezone";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import {
  SignIn,
  ResetPassword,
  Registration,
  LoadToken,
} from "../reducers/users";

import "url-search-params-polyfill";
import ReactHtmlParser from "react-html-parser";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";

import Switch from "./Switch";
import { isEurocam } from "../utils";

class UsersLogin extends React.Component {
  state = {
    errors: "",
    username: "",
    password: "",
    login: true,
    resetPassword: false,
    resetEmail: "",
    resetSuccess: false,
    registration: {
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
    },
    emailDisabled: false,
    termsAccepted: false,
    registrationErrors: {},
  };

  constructor(props) {
    super(props);

    const email = new URLSearchParams(this.props.location.search).get("email");

    if (email) {
      this.state = {
        ...this.state,
        login: false,
        emailDisabled: true,
        registration: { ...this.state.registration, Email: email },
      };
    }
  }

  changeTab = () => {
    this.setState({
      login: !this.state.login,
    });
  };

  toggleReset = (e) => {
    e.preventDefault();
    this.setState({
      resetPassword: !this.state.resetPassword,
    });
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    this.setState({ errors: "" });

    try {
      await this.props.SignIn(this.state.username, this.state.password);
      this.props.history.replace("/");
    } catch (err) {
      console.log(err);
      this.setState({
        errors: err.response ? err.response.data : err.toString(),
      });
    }
  };

  handleResetPassword = (e) => {
    e.preventDefault();

    this.props
      .ResetPassword(this.state.resetEmail)
      .then(() => this.setState({ resetSuccess: true }))
      .catch((err) => console.log(err));
  };

  handleRegistration = (e) => {
    e.preventDefault();

    // analytics
    ReactGA.event({ category: "User", action: "Registration" });
    !isEurocam && ym("reachGoal", "registration");

    this.setState({
      registrationErrors: {},
    });

    const { registration } = this.state;
    this.props
      .Registration({
        ...registration,
        ConfirmPassword: registration.Password,
        Timezone: moment.tz.guess(),
      })
      .then((resp) => {
        if (resp.data.Ok) {
          localStorage.setItem("token", resp.data.Token);
          window.location = "/";
        } else {
          console.log(resp.data.Errors);
          this.setState({
            registrationErrors: resp.data.Errors,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  renderError = (field) =>
    this.state.registrationErrors &&
    this.state.registrationErrors[field] &&
    this.props.t(`registration-errors-${this.state.registrationErrors[field]}`);

  render() {
    const {
      login,
      resetPassword,
      resetSuccess,
      registration,
      emailDisabled,
    } = this.state;
    const { t } = this.props;
    const contentApprove =
      t("login-agree") +
      ` <a href='/pages/terms' target='_blank'>` +
      t("login-terms") +
      `</a><br> ` +
      t("login-and") +
      ` <a href='/pages/policy' target='_blank'>` +
      t("login-policy") +
      `</a>`;

    return (
      <div className="login">
        <img className="login__bg" src="/img/bg.jpg" alt="" />
        <div className="login__container">
          <div className="login__logo">
            <img className="login__pic" src="/img/neurocam.svg" alt="" />
          </div>
          {resetPassword ? (
            <div>
              {!resetSuccess ? (
                <div className="login__reset">
                  <form onSubmit={this.handleResetPassword}>
                    <div className="login__title title">
                      {t("login-password-recovery")}
                    </div>
                    <div className="login__field field">
                      <div className="field__label">E-mail</div>
                      <div className="field__wrap">
                        <input
                          required
                          className="field__input"
                          type="email"
                          value={this.state.resetEmail}
                          onChange={(e) =>
                            this.setState({ resetEmail: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="login__foot">
                      <button className="btn btn_lg" type="submit">
                        {t("restore")}
                      </button>
                      <button
                        className="btn btn_lg btn_white"
                        onClick={this.toggleReset}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="login__result">
                  <div className="login__text">
                    {t("login-password-recovery-text")}
                  </div>
                  <div className="login__foot">
                    <button
                      className="btn btn_lg"
                      onClick={() =>
                        this.setState({
                          resetSuccess: false,
                          resetPassword: false,
                        })
                      }
                    >
                      {t("continue")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="login__main">
              <div className="login__nav">
                <button
                  className={"login__btn " + (login && "active")}
                  onClick={this.changeTab}
                >
                  {t("login-entrance")}
                </button>
                <button
                  className={"login__btn " + (!login && "active")}
                  onClick={this.changeTab}
                >
                  {t("login-registration")}
                </button>
              </div>
              <div className="login__tabs">
                {login ? (
                  <form className="login__tab">
                    <div className="login__fieldset">
                      <div className="login__field field">
                        <div className="field__label">E-mail</div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type="email"
                            value={this.username}
                            onChange={(e) =>
                              this.setState({ username: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="login__field field">
                        <div className="field__label">
                          {t("login-password")}
                        </div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type="password"
                            value={this.password}
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                        </div>
                        <div className="field__error">
                          {this.state.errors &&
                            this.props.t(`login-${this.state.errors.trim()}`)}
                        </div>
                      </div>
                      <div className="login__actions">
                        <a
                          className="login__link"
                          onClick={this.toggleReset}
                          href="about:blank"
                        >
                          {t("login-password-forgot")}
                        </a>
                      </div>
                    </div>
                    <div className="login__foot">
                      <button
                        className="btn btn_lg"
                        onClick={this.handleSignIn}
                      >
                        {t("enter")}
                      </button>
                    </div>
                  </form>
                ) : (
                  <form
                    className="login__tab"
                    onSubmit={this.handleRegistration}
                  >
                    <div className="login__fieldset">
                      <div className="login__field field">
                        <div className="field__label">{t("name")}</div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type="text"
                            value={registration.FirstName}
                            onChange={(e) =>
                              this.setState({
                                registration: {
                                  ...registration,
                                  FirstName: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="field__error">
                          {this.renderError("FirstName")}
                        </div>
                      </div>
                      <div className="login__field field">
                        <div className="field__label">{t("surname")}</div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type="text"
                            value={registration.LastName}
                            onChange={(e) =>
                              this.setState({
                                registration: {
                                  ...registration,
                                  LastName: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="field__error">
                          {this.renderError("LastName")}
                        </div>
                      </div>
                      <div className="login__field field">
                        <div className="field__label">E-mail</div>
                        <div className="field__wrap">
                          <input
                            disabled={emailDisabled}
                            className="field__input"
                            type="email"
                            value={registration.Email}
                            onChange={(e) =>
                              this.setState({
                                registration: {
                                  ...registration,
                                  Email: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="field__error">
                          {this.renderError("Email")}
                        </div>
                      </div>
                      <div className="login__field field">
                        <div className="field__label">
                          {t("login-password")}
                        </div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type="password"
                            value={registration.Password}
                            onChange={(e) =>
                              this.setState({
                                registration: {
                                  ...registration,
                                  Password: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="field__error">
                          {this.renderError("Password")}
                        </div>
                      </div>
                      <div className="login__actions">
                        <Switch
                          className="login__switch"
                          content={ReactHtmlParser(contentApprove)}
                          value={this.state.termsAccepted}
                          onChange={() =>
                            this.setState({
                              termsAccepted: !this.state.termsAccepted,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="login__foot">
                      <button
                        className="btn btn_lg"
                        type="submit"
                        disabled={!this.state.termsAccepted}
                      >
                        {t("register")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = bindActionCreators.bind(null, {
  SignIn,
  ResetPassword,
  Registration,
  LoadToken,
});

export default withRouter(
  translate()(connect(null, mapDispatchToProps)(UsersLogin))
);
