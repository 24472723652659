import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon";
import { Archive } from "../../reducers/payments";
import Rates from "../../rates.js";

class CamerasNav extends React.Component {
  renderArchiveTitle = rate => {
    const rateInfo = Rates.archiveTarrifsByID[rate.ID];
    const { t } = this.props;
    if (!rateInfo) return "";
    return `${rateInfo.days} ` + (rateInfo.daysText ? t("day") : t("days"));
  };

  renderArchiveDays = item => {
    const cameraRate = this.props.cameraRates[item.id];
    const { t } = this.props;
    return (
      <div className="layout__info">
        {cameraRate && cameraRate.Rate.ArciveOnlyEvents
          ? t("events-storage")
          : t("archive-storage")}
        {": "}
        {cameraRate ? (
          <span className="color-red">
            {this.renderArchiveTitle(cameraRate.Rate)}
          </span>
        ) : (
          <span>{t("nav-not-connected")}</span>
        )}
      </div>
    );
  };

  render() {
    const { items, t } = this.props;
    return (
      <div className="layout__nav">
        {items.map((item, index) => (
          <NavLink
            className={"layout__link " + (item.online ? "" : "disabled")}
            activeClassName="active"
            to={`/cameras/${item.id}`}
            key={index}
          >
            <div className="layout__preview">
              <div className="layout__status" />
              <Icon className="layout__pic" name="camera" />
              {item.shared && (
                <div
                  className="layout__share"
                  data-tip={t("owner") + ": " + item.owner_info.name}
                  data-class="tooltip"
                  data-place="right"
                  data-effect="solid"
                >
                  <Icon className="layout__pic" name="share-sm" />
                  <ReactTooltip />
                </div>
              )}
            </div>
            <div className="layout__wrap">
              <div className="layout__name">{item.title}</div>
              {!item.shared && this.renderArchiveDays(item)}
            </div>
          </NavLink>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cameraRates: state.payments.cameraRatesMap[Archive]
});

export default translate()(connect(mapStateToProps)(CamerasNav));
