import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { NavLink } from "react-router-dom";

import TimestampValue from "../Timestamp";
import { RateTitle } from "./Tariffs";
import { currency } from "../../utils";

class Transaction extends React.PureComponent {
  color = () => (this.props.Total > 0 ? "color-green" : "color-red");

  render() {
    const { t, camera, ID, Description, Total, Timestamp, RateID, Rate } =
      this.props;

    return (
      <div className="history__item">
        <div className="history__row">
          <div className="history__cell">
            # {ID} {t(`transaction-description-${Description}`, Description)}
          </div>
          <div className={`history__cell ${this.color()}`}>
            {Total ? Total / 100 : 0} {t(currency)}
          </div>
        </div>
        <div className="history__row">
          <div className="history__cell">
            <span className="color-gray">{t("date")}:</span>{" "}
            <TimestampValue value={Timestamp} />
          </div>
          {RateID > 0 && (
            <div className="history__cell">
              <span className="color-gray">{t("tariff")}:</span>{" "}
              {RateTitle(Rate, t)}
            </div>
          )}
          {camera && (
            <div className="history__cell">
              <span className="color-gray">{t("camera")}:</span>{" "}
              <NavLink to={`/cameras/${camera.id}`}>{camera.title}</NavLink>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class History extends React.Component {
  cameraTitle = (id) => this.props.camerasMap[id];

  renderTransaction = (tx) => (
    <Transaction
      {...tx}
      key={tx.ID}
      t={this.props.t}
      camera={this.cameraTitle(tx.CameraID)}
    />
  );

  renderTransactions = () =>
    this.props.transactions.map((item) => this.renderTransaction(item));

  render() {
    return <div className="history">{this.renderTransactions()}</div>;
  }
}

const mapStateToProps = (state) => ({
  camerasMap: state.cameras.items.reduce(
    (m, cam) => ({ ...m, [cam.id]: cam }),
    {}
  ),
});

export default translate()(connect(mapStateToProps)(History));
