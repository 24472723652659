import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { eventImage, eventRoiImage } from "../../links";
import Timestamp from "../Timestamp";

const EventImage = (event) =>
  event.ROI
    ? eventRoiImage(event.CameraID, event.ID)
    : eventImage(event.CameraID, event.ID);

class EventsAlert extends React.Component {
  state = {
    // TODO: we need to disable audio sounds
    enabledAudio: true,
  };

  renderCameraTitle = (id) =>
    this.props.camerasMap[id] && this.props.camerasMap[id].title;

  render() {
    const { event, t } = this.props;
    return (
      <Link className="event" to={`/events/${event.CameraID}/${event.ID}`}>
        <audio autoPlay>
          <source src="/audio/notification.mp3" />
        </audio>
        <div className="event__preview">
          {event.Group && (
            <div
              className="event__color"
              style={{ background: event.Group.Color }}
            />
          )}
          <img src={EventImage(event)} alt="" className="event__pic" />
        </div>
        <div className="event__wrap">
          <div className="event__row">
            <div className="event__cell">
              <div className="event__title">
                {event.Person
                  ? `${event.Person.name} ${event.Person.last_name}`
                  : t("not-identified")}
              </div>
            </div>
            <div className="event__cell">
              <div className="event__view">{t("events-alert-watch")}</div>
            </div>
          </div>
          <div className="event__row">
            <div className="event__cell">
              Камера «{this.renderCameraTitle(event.CameraID)}»
            </div>
            <div className="event__cell color-gray">
              <Timestamp value={event.Timestamp} />
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  camerasMap: state.cameras.items.reduce(
    (m, cam) => ({ ...m, [cam.id]: cam }),
    {}
  ),
});

export default translate()(connect(mapStateToProps)(EventsAlert));
