import React from "react";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";

import DownloadApp from "../components/DownloadApp";
import Box from "../components/Box";
import Logo from "../components/Logo";
import { currency } from "../utils";

const boxes = [
  {
    id: 0,
    title: "Neurobox #3",
    description: "Neurobox for Mac",
    code: "f235-f25-f2656-f236t",
    added: "21 сентября 2018г. 15:38",
    connectedCameras: 6,
    sensor: 3,
    sensorMax: 4,
    bitrate: "4 000",
    cameras: [
      {
        on: true,
        title: " Офис",
      },
      {
        on: true,
        title: " Офис",
      },
      {
        on: true,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
    ],
  },
  {
    id: 1,
    title: "Neurobox #3",
    description: "Neurobox for Mac",
    code: "f235-f25-f2656-f236t",
    added: "21 сентября 2018г. 15:38",
    connectedCameras: 6,
    sensor: 3,
    sensorMax: 4,
    bitrate: "4 000",
    cameras: [
      {
        on: true,
        title: " Офис",
      },
      {
        on: true,
        title: " Офис",
      },
      {
        on: true,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
      {
        on: false,
        title: " Офис",
      },
    ],
  },
];

class Neuroboxes extends React.Component {
  renderDetails = () => {
    const { t } = this.props;
    return (
      <div className="boxes__details">
        <Link to="" className="boxes__buy">
          {t("neuroboxes-buy")}
        </Link>
        <div className="boxes__promo">
          {t("neuroboxes-download-app-for")}{" "}
          <a href="https://api.neurocam.com/updates/neurobox-latest-mac.dmg">
            MacOS
          </a>{" "}
          {t("neuroboxes-or")}{" "}
          <a href="https://api.neurocam.com/updates/neurobox-latest-windows.exe">
            Windows
          </a>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="boxes">
        <div className="boxes__head">
          <Logo />
          <div className="boxes__title title">{t("neuroboxes")}</div>
          {this.renderDetails()}
        </div>
        <div className="boxes__body">
          {this.renderDetails()}
          <div className="boxes__list">
            {boxes.map((x) => (
              <Box className="boxes__item" box={x} key={x.id} />
            ))}
          </div>
          <div className="boxes__empty">
            <div className="boxes__subtitle">
              {t("neuroboxes-any-connected")}
            </div>
            <div className="boxes__text">
              {t("neuroboxes-any-connected-text")}
            </div>
          </div>
          <div className="boxes__add add add_boxes">
            <div className="add__row">
              <div className="add__col">
                <div className="add__preview">
                  <img
                    src="/img/neurobox-device.png"
                    alt=""
                    className="add__pic"
                  />
                </div>
                <div className="add__wrap">
                  <div className="add__head">
                    <div className="add__price">4 900 {t(currency)}</div>
                    <div className="add__title title">
                      {t("neuroboxes-buy")}
                    </div>
                  </div>
                  <div className="add__content">
                    {t("neuroboxes-any-connected-text")}
                  </div>
                  <div className="add__foot">
                    <button className="btn btn_lg">
                      {t("neuroboxes-go-to-shop")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="add__col">
                <div className="add__preview">
                  <div className="add__app">
                    <img src="/img/neurobox.svg" alt="" className="add__pic" />
                    <div className="add__counter counter">2</div>
                  </div>
                </div>
                <div className="add__wrap">
                  <div className="add__head">
                    <div className="add__price">{t("neuroboxes-free")}</div>
                    <div className="add__title title">
                      {t("neuroboxes-download-app")}
                    </div>
                  </div>
                  <div className="add__content">
                    {t("neuroboxes-any-connected-text")}
                  </div>
                  <div className="add__foot">
                    <DownloadApp />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(Neuroboxes);
