import React from "react";

// TODO: Replace with hook
import { translate } from "react-i18next";

export const Online = translate()(
  ({ t, online, paused, disabled, onClick }) => {
    return (
      <div
        className={`video__online ${(online && !paused) || "offline"}`}
        disabled={disabled}
        onClick={onClick}
      >
        {t("online")}
      </div>
    );
  }
);
