import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { translate } from "react-i18next";
import Header from "../Header";
import Layout from "./Layout";
import Persons from "./Persons";

import {
  LoadPersons,
  FacesSetFilter,
  SavePerson,
  DeletePerson,
} from "../../reducers/faces";
import { checkPerms } from "../../utils";

const Main = ({
  t,
  persons,
  personsFetched,
  personsTotal,
  filters,
  users,
  groups,
  FacesSetFilter,
  SavePerson,
  DeletePerson,
  personsById,
  match,
}) => {
  const [tableView, setTableView] = useState(
    localStorage.getItem("facesTableView") === "true"
  );

  const [selection, setSelection] = useState(false);
  const [selected, setSelected] = useState([]);

  const isPermsCreate = checkPerms(users.user, "person:create");
  const isPermsUpdate = checkPerms(users.user, "person:update");
  const isPermsDelete = checkPerms(users.user, "person:delete");

  useEffect(() => {
    localStorage.setItem("facesTableView", tableView);
  }, [tableView]);

  const { id } = useParams();

  useEffect(() => {
    FacesSetFilter({
      persons: !match.path.includes("visitors"),
      person_list: id ? id : null,
    });
  }, [match.path, id, FacesSetFilter]);

  const toggleSelection = () => setSelection(!selection);

  const onSelect = (id) =>
    setSelected(
      selected.includes(id)
        ? selected.filter((i) => i !== id)
        : [...selected, id]
    );

  const onSelectAll = () => setSelected(persons.map((i) => i.guid));

  const onUnselectAll = () => setSelected([]);

  const onSelectedPerform = async (action) => {
    if (selected.length === 0) return;
    try {
      const futures = selected.map((id) => action(id));

      await Promise.all([...futures]);
      toast.success(t("data-update-success"));
    } catch (e) {
      console.log(e);
      if (
        e.response.status === 400 &&
        e.response.data.type === "camera-rate-max-persons-exceeded"
      ) {
        const { rate_persons, camera_id } = e.response.data.details;

        toast.error(
          `${t("max-person-camera-title")} ${camera_id} ${t("exceeded")}. ${t(
            "max-person-by-tariff"
          )}: ${rate_persons}.`
        );
      } else {
        toast.error(t(`data-update-error`));
      }
    } finally {
      setSelection([]);
    }
  };

  const onSelectedChangeGroup = async (group) =>
    onSelectedPerform(
      async (id) =>
        await SavePerson({
          ...personsById[id].person_info,
          group_id: group.id,
          guid: id,
        })
    );

  const onSelectedDelete = () =>
    onSelectedPerform(async (id) => await DeletePerson(id));

  const { pathname } = useLocation();

  const menu = [
    {
      to: "/faces",
      isActive: () => !pathname.includes("visitors"),
      title: t("person-base"),
      onClick: (e) => !personsFetched && e.preventDefault(),
    },
    {
      to: "/faces/visitors",
      title: t("visitors"),
      onClick: (e) => !personsFetched && e.preventDefault(),
    },
  ];

  const selectionActions = isPermsDelete && [
    { icon: "trash", onClick: onSelectedDelete },
  ];

  const actions = [
    isPermsUpdate && {
      icon: "selection",
      active: selection,
      onClick: toggleSelection,
    },
    {
      icon: "grid-lines",
      active: tableView,
      onClick: () => setTableView(true),
      onlyDesktop: true,
    },
    {
      icon: "grid",
      active: !tableView,
      onClick: () => setTableView(false),
      onlyDesktop: true,
    },
  ];

  const dropdownActions = groups.map((group) => {
    return {
      title: group.title,
      onClick: () => onSelectedChangeGroup(group),
    };
  });

  return (
    <Layout
      header={
        selection ? (
          <>
            <Header
              back={{ icon: "close-sm", onClick: () => setSelection(false) }}
              selection={{
                selected: selected,
                isSelectAll: selected.length !== persons.length,
                onSelectAll: onSelectAll,
                isUnselectAll: selected.length > 0,
                onUnselectAll: onUnselectAll,
              }}
              actions={selectionActions}
              dropdown={
                filters.persons && {
                  icon: "group",
                  options: dropdownActions,
                }
              }
            />
          </>
        ) : (
          <Header
            menu={menu}
            actions={actions}
            rightButton={{
              to: "/faces/add-person",
              icon: "plus",
              title: t("add"),
              hide: !isPermsCreate,
            }}
          />
        )
      }
    >
      <Persons
        personsFetched={personsFetched}
        persons={persons}
        filters={filters}
        setFilter={FacesSetFilter}
        items={persons}
        total={personsTotal}
        tableView={tableView}
        selection={selection}
        selected={selected}
        onSelect={onSelect}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  groups: state.faces.groups,
  persons: state.faces.persons,
  personsFetched: state.faces.personsFetched,
  personsTotal: state.faces.personsTotal,
  personsById: state.faces.persons?.reduce(
    (acc, person) => ({ ...acc, [person.guid]: person }),
    {}
  ),
  filters: state.faces.filters,
});

export default translate()(
  withRouter(
    connect(mapStateToProps, {
      LoadPersons,
      FacesSetFilter,
      SavePerson,
      DeletePerson,
    })(Main)
  )
);
