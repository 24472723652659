import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./Form.module.sass";
import Icon from "../../../Icon";
import Button from "../../../Button";
import Header from "../../../Header";
import PopupConfirm from "../../../PopupConfirm";
import Field from "../../../Field";
import Select from "../../../Select";
import Layout from "../../Layout";
import Photos from "../../Photos";

import {
  CreatePerson,
  MovePerson,
  SavePerson,
  DeletePerson,
  DeletePersonPhoto,
  FavoritePersonPhoto,
} from "../../../../reducers/faces";
import { guidMainPhotoUrl } from "../../../../links";

const Form = ({
  t,
  person,
  history,
  groups,
  CreatePerson,
  MovePerson,
  SavePerson,
  DeletePerson,
  DeletePersonPhoto,
  FavoritePersonPhoto,
}) => {
  const person_info = person?.person_info;
  const group_info = person?.group_info;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(person_info ? person_info.name : "");
  const [lastName, setLastName] = useState(
    person_info ? person_info.last_name : ""
  );
  const [description, setDescription] = useState(
    person_info ? person_info.description : ""
  );
  const [groupId, setGroupId] = useState(group_info?.id);
  const [photos, setPhotos] = useState(
    person?.guid
      ? person?.person_info
        ? person_info.photos
        : [
            {
              person_id: person.guid,
              image_id: person.photo_id,
              favorite: true,
            },
          ]
      : []
  );
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const id = person?.guid;

  const isAddButtonDisabled = () =>
    loading || name === "" || !groupId || photos.length === 0;

  const onCreate = async () => {
    setLoading(true);

    try {
      const person = {
        name: name,
        last_name: lastName,
        description: description,
        group_id: groupId,
      };

      const result = await CreatePerson(person, photos);

      switch (result) {
        case 206:
          toast.success(t(`person-create-success-partial`));
          break;
        case 204:
          toast.error(t(`person-create-error-faces-not-found`));
          break;
        default:
          toast.success(t(`person-create-success`));
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.type === "camera-rate-max-persons-exceeded"
      ) {
        const { rate_persons, camera_id } = e.response.data.details;

        toast.error(
          `${t("max-person-camera-title")} ${camera_id} ${t("exceeded")}. ${t(
            "max-person-by-tariff"
          )}: ${rate_persons}.`
        );
      }
    } finally {
      setLoading(false);
      history.push("/faces");
    }
  };

  console.log(person);

  const onMove = async () => {
    setLoading(true);

    try {
      await MovePerson({
        id,
        name,
        last_name: lastName,
        description,
        group_id: groupId ? groupId : null,
      });

      toast.success(t(`data-update-success`));
    } catch (e) {
      console.log(e);
      toast.error(t(`data-update-error`));
    } finally {
      setLoading(false);
      history.push("/faces");
    }
  };

  const onSave = async () => {
    setLoading(true);

    try {
      await SavePerson({
        guid: id,
        name,
        last_name: lastName,
        description,
        group_id: groupId ? groupId : null,
        images: photos,
      });

      toast.success(t(`data-update-success`));
    } catch (e) {
      console.log(e);
      if (
        e.response.status === 400 &&
        e.response.data.type === "camera-rate-max-persons-exceeded"
      ) {
        const { rate_persons, camera_id } = e.response.data.details;

        toast.error(
          `${t("max-person-camera-title")} ${camera_id} ${t("exceeded")}. ${t(
            "max-person-by-tariff"
          )}: ${rate_persons}.`
        );
      } else {
        toast.error(t(`data-update-error`));
      }
    } finally {
      setLoading(false);
      history.push("/faces");
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await DeletePerson(id);
      toast.success(t("person-delete-success"));
    } catch (e) {
      console.log(e);
      toast.error(t("person-delete-error"));
    } finally {
      setLoading(false);
      history.push(`/faces`);
    }
  };

  const onRemovePhoto = async (_personId, imageId) => {
    setLoading(true);
    try {
      await DeletePersonPhoto(id, imageId);
      toast.success(t(`data-update-success`));
    } catch (e) {
      console.log(e);
      toast.error(t(`data-update-error`));
    } finally {
      setLoading(false);
    }
  };

  const onFavoritePhoto = async (_personId, imageId) => {
    setLoading(true);
    try {
      await FavoritePersonPhoto(id, imageId);
      toast.success(t(`data-update-success`));
    } catch (e) {
      console.log(e);
      toast.error(t(`data-update-error`));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      header={
        <>
          <Header
            back={{ icon: "close", onClick: () => history.goBack() }}
            title={t(
              person && person.guid && person.person_info
                ? "persons-edit"
                : "persons-new"
            )}
            actions={
              person?.guid &&
              person?.person_info && [
                {
                  icon: "trash",
                  onClick: () => setVisibleConfirm(true),
                },
              ]
            }
            rightButton={{
              title: person?.guid ? t("save") : t("add"),
              onClick: person?.guid
                ? person?.person_info
                  ? onSave
                  : onMove
                : onCreate,
              loading: loading,
              disabled: isAddButtonDisabled(),
            }}
          />
          {visibleConfirm && (
            <PopupConfirm
              title={t("person-removing")}
              content={`${t("person-removing-msg")} «${name}»?`}
              buttonTitle={t("person-remove")}
              onClick={onDelete}
              onClose={() => setVisibleConfirm(false)}
            />
          )}
        </>
      }
    >
      <div className={styles.form}>
        <div className={styles.inner}>
          <div className={styles.avatar}>
            {person?.photo_id ? (
              <img src={guidMainPhotoUrl(person.guid)} alt="Avatar" />
            ) : (
              <Icon name="face" />
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className="subtitle">{t("persons-description")}</div>
              <Field
                label={t("name")}
                placeholder={t("name-enter")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Field
                label={t("surname")}
                placeholder={t("surname-enter")}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <Field label={t("persons-list")} required>
                <Select
                  options={groups.map((x) => ({
                    title: x.title,
                    value: x.id,
                    color: x.color,
                  }))}
                  action={
                    groups.length === 0 && {
                      title: "Add new list",
                      url: "/faces/add-list",
                    }
                  }
                  value={groupId}
                  onChange={setGroupId}
                />
              </Field>
              <Field
                label={t("description")}
                placeholder={t("persons-description-enter")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className={styles.section}>
              <div className="subtitle">
                {t("persons-photos")} <span className="color-red">*</span>
              </div>
              <Photos
                photos={photos}
                onChange={setPhotos}
                onRemove={onRemovePhoto}
                onFavorite={onFavoritePhoto}
                disabled={person?.guid && !person?.person_info}
              />
            </div>
            <div className={styles.foot}>
              <Button
                title={person?.guid ? t("save") : t("add")}
                onClick={
                  person?.guid
                    ? person?.person_info
                      ? onSave
                      : onMove
                    : onCreate
                }
                disabled={isAddButtonDisabled()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state, ownProps) => ({
  groups: state.faces.groups,
});

export default translate()(
  withRouter(
    connect(mapStateToProps, {
      CreatePerson,
      MovePerson,
      SavePerson,
      DeletePerson,
      DeletePersonPhoto,
      FavoritePersonPhoto,
    })(Form)
  )
);
