import axios from "axios";

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://api.neurocam.com";
// process.env.REACT_APP_BASE_URL || "https://api-dev.neurocam.com";

export const BASE_WS_URL = BASE_URL.startsWith("https")
  ? `${BASE_URL.replace("https://", "wss://")}`
  : `${BASE_URL.replace("http://", "ws://")}`;

export const parseURL = (url) => {
  const urlParts = url.split("//", 2);
  let scheme, urlWithoutScheme;
  if (urlParts.length > 1) {
    scheme = urlParts[0].slice(0, 4);
    if (scheme !== "http" && scheme !== "https" && scheme !== "rtsp") {
      throw new Error("unsupported schema");
    }
    urlWithoutScheme = urlParts[1];
  } else {
    urlWithoutScheme = urlParts[0];
  }
  const response = { scheme };

  let cleanURL;
  const split = urlWithoutScheme.split("@");
  if (split.length > 1) {
    const auth = split[0];
    const authParts = auth.split(":", 2);
    response.username = authParts[0];
    if (authParts.length > 1) {
      response.password = authParts[1];
    }
    cleanURL = split[1];
  } else {
    cleanURL = split[0];
  }

  const splitCleanURL = cleanURL.split(":");
  response.host = splitCleanURL[0];

  if (splitCleanURL.length > 1) {
    const portSplit = splitCleanURL[1].split("/");
    response.customPort = true;
    response.port = portSplit[0];
    response.uri = portSplit.length > 1 ? portSplit.splice(1).join("/") : "";
  } else {
    response.customPort = false;
    response.port = response.scheme === "rtsp" ? 554 : 80;
    response.uri = splitCleanURL[1];
  }

  return response;
};

const GEO_DATA_URL = "https://ipapi.co/json/";
export const GeoData = {
  ip: async () => {
    const response = await axios.get(GEO_DATA_URL);
    return response.data.ip;
  },
};

export default axios.create({
  baseURL: BASE_URL,
});
