import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./PersonsGroup.module.sass";
import Icon from "../../Icon";
import Button from "../../Button";
import Field from "../../Field";
import Switch from "../../Switch";
import Header from "../../Header";
import PopupConfirm from "../../PopupConfirm";
import Layout from "../Layout";
import Colors from "./Colors";

import { CreateGroup, SaveGroup, DeleteGroup } from "../../../reducers/faces";
import { Persons } from "../../../reducers/payments";
import { checkPerms } from "../../../utils";

const PersonsGroup = ({
  t,
  history,
  id,
  users,
  cameras,
  available,
  ratesMap,
  group,
  CreateGroup,
  SaveGroup,
  DeleteGroup,
}) => {
  const availableCameras = available;
  const allCameras = cameras;

  const [edit, setEdit] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(true);
  const [connected, setConnected] = useState([]);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  useEffect(() => {
    if (!id || !group) {
      setColor("");
      setTitle("");
      setActive(true);
      setEdit(false);

      const allConnected = [];
      availableCameras.forEach((x) => {
        allConnected.push(x.id);
      });
      setConnected(allConnected);
    } else {
      setColor(group.color);
      setTitle(group.title);
      setGroupId(group.id);
      setActive(group.active);
      setEdit(true);
      setConnected(group.cameras);
    }
  }, [group, id, availableCameras]);

  const canSave = () => title !== "" && color !== "";

  const isConnected = (cameraId) => {
    return connected.includes(cameraId);
  };

  const toggleConnected = (cameraId) => {
    connected.includes(cameraId)
      ? setConnected(connected.filter((x) => x !== cameraId))
      : setConnected([...connected, cameraId]);
  };

  const setAllConnected = () => {
    const allConnected = [];
    availableCameras.forEach((x) => {
      allConnected.push(x.id);
    });
    setConnected(allConnected);
  };

  const onSave = async () => {
    setLoading(true);

    const saveFunc = edit ? SaveGroup : CreateGroup;

    try {
      await saveFunc({ id: groupId, title, color, active, cameras: connected });

      toast.success(
        edit ? t("data-update-success") : t("person-list-create-success")
      );
    } catch (e) {
      console.log(e.response);
      if (
        e.response.status === 400 &&
        e.response.data.type === "camera-rate-max-persons-exceeded"
      ) {
        const { rate_persons, camera_id } = e.response.data.details;

        toast.error(
          `${t("max-person-camera-title")} ${camera_id} ${t("exceeded")}. ${t(
            "max-person-by-tariff"
          )}: ${rate_persons}.`
        );
      } else {
        toast.error(
          edit ? t("data-update-error") : t("person-list-create-error")
        );
      }
    } finally {
      setLoading(false);
      history.push("/faces");
    }
  };

  const onRemove = async () => {
    setLoading(true);

    try {
      await DeleteGroup(id);
      toast.success(t("person-list-delete-success"));
    } catch (e) {
      console.log(e);
      toast.error(t("person-list-delete-error"));
    } finally {
      setLoading(false);
      history.push("/faces");
    }
  };

  const rateTitle = (cameraId) => {
    const cameraRate = ratesMap[cameraId];
    if (!cameraRate) return ``;

    return cameraRate.Rate.Reports && cameraRate.Rate.Celebrity ? t("base-with-celeb") : t("base-own");
  };

  const isPermsDelete = checkPerms(users.user, "person_group:delete");

  return (
    <Layout
      header={
        <>
          <Header
            back={{ icon: "close", to: "/faces" }}
            title={edit ? group?.title : t("persons-new-list")}
            actions={
              isPermsDelete &&
              edit && [
                { icon: "trash", onClick: () => setVisibleConfirm(true) },
              ]
            }
            rightButton={{
              title: edit ? t("save") : t("add"),
              onClick: onSave,
              disabled: !canSave(),
            }}
          />
          {visibleConfirm && (
            <PopupConfirm
              title={t("persons-list-removing")}
              content={t("persons-list-removing-msg")}
              buttonTitle={t("list-remove")}
              onClick={onRemove}
              onClose={() => setVisibleConfirm(false)}
            />
          )}
        </>
      }
    >
      <div className={styles.form}>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className="subtitle">{t("information")}</div>
            <Field
              label={t("list-title")}
              placeholder={t("list-title-enter")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Field label={t("list-label-color")}>
              <Colors value={color} onChange={setColor} />
            </Field>
          </div>
          {availableCameras.length > 0 && (
            <div className={styles.section}>
              <div className="subtitle">
                {t("snap-cameras")}{" "}
                <button onClick={setAllConnected}>{t("select-all")}</button>
              </div>
              {availableCameras.map((x, i) => (
                <Switch
                  className={styles.switch}
                  content={`${x.title} ${rateTitle(x.id)}`}
                  value={isConnected(x.id)}
                  onChange={() => toggleConnected(x.id)}
                  key={i}
                />
              ))}
            </div>
          )}
          {allCameras.length > 0 && (
            <div className={styles.section}>
              <div className="subtitle">{t("connect-face-recognition")}</div>
              {allCameras.map((x, i) => (
                <button
                  className={styles.add}
                  key={i}
                  onClick={() => history.push(`/connect-recognition/${x.id}`)}
                >
                  <Icon name="plus" />
                  {x.title}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className={styles.foot}>
          <Button
            title={edit ? t("save") : t("add")}
            loading={loading}
            onClick={onSave}
            disabled={!canSave()}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state, ownProps) => ({
  users: state.users,
  group: state.faces.groups.find((g) => g.id === parseInt(ownProps.id, 10)),
  cameras: state.cameras.items.filter(
    (c) =>
      c.neurobox_id > 0 && !(c.id in state.payments.cameraRatesMap[Persons])
  ),
  available: state.cameras.items.filter(
    (c) => c.id in state.payments.cameraRatesMap[Persons]
  ),
  ratesMap: state.payments.cameraRatesMap[Persons],
});

export default translate()(
  withRouter(
    connect(mapStateToProps, { CreateGroup, SaveGroup, DeleteGroup })(
      PersonsGroup
    )
  )
);
