import React from "react";
import cn from "classnames";
import Icon from "./Icon";

class Modal extends React.Component {
  render() {
    const { className, children, onCloseRequest } = this.props;
    return (
      <div className={cn("modal", className)}>
        <div className="modal__container">
          <button
            className="modal__close action action_sm"
            onClick={onCloseRequest}
          >
            <Icon name="close-sm" />
          </button>
          {children}
        </div>
        <div className="modal__overlay" onClick={onCloseRequest} />
      </div>
    );
  }
}

export default Modal;
