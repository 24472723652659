import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import { Line } from "react-chartjs-2";
import ReportsChangings from "../ReportsChangings";
import { NumberToMS } from "../utils";

class ChartByAge extends React.Component {
  toggleLine = (index) => {
    let lineChart = this.reference.chartInstance;

    lineChart.config.data.datasets[index].hidden = !lineChart.config.data
      .datasets[index].hidden;

    lineChart.update();

    document
      .querySelector(`.chart__preview_${index}`)
      .classList.toggle("disabled");
  };

  render() {
    const { t, chartOptions, visitDuration, dates, labels } = this.props;

    const current = visitDuration.current;
    const prev = visitDuration.prev;

    const dataAge0 = [];
    const dataAge18 = [];
    const dataAge25 = [];
    const dataAge35 = [];
    const dataAge45 = [];
    const dataAge55 = [];

    dates.forEach((date) => {
      let age0 = 0;
      let age18 = 0;
      let age25 = 0;
      let age35 = 0;
      let age45 = 0;
      let age55 = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          age0 = x.age0;
          age18 = x.age18;
          age25 = x.age25;
          age35 = x.age35;
          age45 = x.age45;
          age55 = x.age55;
        }
      });

      dataAge0.push(age0);
      dataAge18.push(age18);
      dataAge25.push(age25);
      dataAge35.push(age35);
      dataAge45.push(age45);
      dataAge55.push(age55);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "< 18",
          hidden: false,
          fill: "none",
          backgroundColor: "#42d475",
          borderColor: "#42d475",
          data: dataAge0,
          value: "age0",
          seconds: current.age0,
          to: "/events",
        },
        {
          label: "18-24",
          hidden: false,
          fill: "none",
          backgroundColor: "#ffc000",
          borderColor: "#ffc000",
          data: dataAge18,
          value: "age18",
          seconds: current.age18,
          to: "/events",
        },
        {
          label: "25-34",
          hidden: false,
          fill: "none",
          backgroundColor: "#f30027",
          borderColor: "#f30027",
          data: dataAge25,
          value: "age25",
          seconds: current.age25,
          to: "/events",
        },
        {
          label: "35-44",
          hidden: false,
          fill: "none",
          backgroundColor: "#7c50ff",
          borderColor: "#7c50ff",
          data: dataAge35,
          value: "age35",
          seconds: current.age35,
          to: "/events",
        },
        {
          label: "45-54",
          hidden: false,
          fill: "none",
          backgroundColor: "#c664fb",
          borderColor: "#c664fb",
          data: dataAge45,
          value: "age45",
          seconds: current.age45,
          to: "/events",
        },
        {
          label: "> 55",
          hidden: false,
          fill: "none",
          backgroundColor: "#7fdacd",
          borderColor: "#7fdacd",
          data: dataAge55,
          value: "age55",
          seconds: current.age55,
          to: "/events",
        },
      ],
    };

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Line
            data={chartData}
            options={chartOptions}
            height={360}
            ref={(reference) => (this.reference = reference)}
          />
        </div>
        <div className="chart__foot">
          <div className="chart__row chart__row_age">
            {chartData.datasets.map((x, i) => (
              <div className="chart__cell" key={i}>
                <div
                  className={cn("chart__preview", `chart__preview_${i}`)}
                  onClick={() => this.toggleLine(i)}
                  style={{
                    backgroundColor: x.borderColor,
                    borderColor: x.borderColor,
                  }}
                />
                <div className="chart__group">
                  <div className="chart__value">{x.label}</div>
                  <div className="chart__label">
                    {t("avg")} {NumberToMS(t, x.seconds)}
                  </div>
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option={x.value}
                    type={t("sec")}
                    baseline
                    time
                    t={t}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartByAge);
