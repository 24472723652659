import React from "react";
import { translate } from "react-i18next";
import { Line } from "react-chartjs-2";
import { NumberToMS } from "../utils";
import Icon from "../../Icon";
import ReportsChangings from "../ReportsChangings";

class ChartTotal extends React.Component {
  render() {
    const { t, chartOptions, visitDuration, dates, labels } = this.props;

    const current = visitDuration.current;
    const prev = visitDuration.prev;

    const data = [];

    dates.forEach((date) => {
      let duration = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          duration = x.duration;
        }
      });

      data.push(duration);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          fill: "none",
          backgroundColor: "#f30027",
          borderColor: "#f30027",
          data: data,
        },
      ],
    };

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Line data={chartData} options={chartOptions} height={360} />
        </div>
        <div className="chart__foot">
          <div className="chart__row chart__row_center">
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="time" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {NumberToMS(t, current.duration)}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option={"duration"}
                    type={t("sec")}
                    time
                    t={t}
                  />
                </div>
                <div className="chart__label">{t("avg-visit")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartTotal);
