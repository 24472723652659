import React from "react";
import cn from "classnames";
import { kFormatter } from "../../utils";
import { NumberToMS } from "./utils";

const ReportsChangings = (props) => {
  const { time, t, baseline, prev, current, option, type } = props;

  let value = 0;

  if (prev.periods) {
    if (current[option] !== 0) {
      value = current[option] - prev[option];
    }
  }

  return (
    Math.abs(value) !== 0 && (
      <span
        className={cn(
          "chart__change change",
          {
            red: value < 0,
          },
          {
            baseline: baseline,
          }
        )}
      >
        {time && NumberToMS(t, Math.abs(value))}
        {!time && kFormatter(value)}
        {!time && type && type}
      </span>
    )
  );
};

export default ReportsChangings;
