import React from "react";
import { translate } from "react-i18next";
import Switch from "../Switch";
import Time from "../Time";

const defaultSettings = {
  around_clock: true,
  detection_objects: {
    people: true,
    car: true
  },
  notifications: {
    webapp: true
  },
  working_hours: {}
};

class SensorSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorOn: true,
      settings: props.camera.detector_enabled
        ? props.camera.detector_settings || defaultSettings
        : defaultSettings
    };
  }

  toggleSensor = () => {
    this.setState({
      sensorOn: !this.state.sensorOn
    });
  };

  onChange = (key, value) =>
    this.setState({ settings: { ...this.state.settings, [key]: value } });

  onChangeWorkingHours = (day, values) => {
    let workingHours = this.state.settings.working_hours || {};

    workingHours = {
      ...workingHours,
      [day]: {
        ...workingHours[day],
        ...values
      }
    };

    this.setState({
      settings: { ...this.state.settings, working_hours: workingHours }
    });
  };

  onSave = () =>
    this.props
      .onSave({
        ...this.props.camera,
        detector_enabled: this.state.sensorOn,
        detector_settings: {
          ...this.props.camera.detector_settings,
          ...this.state.settings
        }
      })
      .then(() => {
        if (this.props.closeModal) {
          this.props.closeModal();
        }
      });

  renderWorkingHoursCheckbox = (day, title) => {
    const wh =
      (this.state.settings.working_hours &&
        this.state.settings.working_hours[day]) ||
      {};

    return (
      <Switch
        disabled={this.state.settings.around_clock}
        content={title}
        value={wh.enabled}
        onChange={e =>
          this.onChangeWorkingHours(day, {
            enabled: e.target.checked
          })
        }
      />
    );
  };

  renderWorkingHoursTime = day => {
    const wh =
      (this.state.settings.working_hours &&
        this.state.settings.working_hours[day]) ||
      {};

    return (
      <Time
        disabled={this.state.settings.around_clock}
        timeFrom={wh.start}
        timeTo={wh.end}
        onChangeTime={e => {
          this.setState({
            settings: {
              ...this.state.settings,
              working_hours: {
                ...this.state.settings.working_hours,
                [day]: {
                  ...this.state.settings.working_hours[day],
                  [e.target.name === "timeFrom" ? "start" : "end"]: e.target
                    .value
                }
              }
            }
          });
        }}
      />
    );
  };

  render() {
    const { sensorOn, settings } = this.state;
    const { camera, t } = this.props;
    return (
      <div className="sensor">
        <div className="sensor__container">
          <div className="sensor__title title">
            {t("sensor-settings-title")}
          </div>
          <div className="sensor__flex">
            <div className="sensor__info">
              <span className="sensor__label">{t("camera")}:</span>
              {camera.title}
            </div>
            <div className="sensor__info">
              <Switch
                value={sensorOn}
                content={t("sensor-settings-detector-enabled")}
                toggler
                onChange={this.toggleSensor}
              />
            </div>
          </div>
          <div className={"sensor__area " + (sensorOn ? "" : "disabled")}>
            <div className="sensor__subtitle">
              {t("sensor-settings-event-responce")}
            </div>
            <div className="sensor__group">
              <div className="sensor__event">
                <div className="sensor__col">
                  <img
                    src="/img/type-man.svg"
                    className={
                      "sensor__pic " +
                      (settings.detection_objects.people ? "" : "disabled")
                    }
                    alt=""
                  />
                </div>
                <div className="sensor__col">
                  <div className="sensor__category">{t("man-in-frame")}</div>
                  <div className="sensor__description">
                    {t("man-in-frame-text")}
                  </div>
                </div>
                <div className="sensor__col">
                  <Switch
                    toggler
                    content={
                      settings.detection_objects.people
                        ? t("settings-on")
                        : t("settings-off")
                    }
                    value={settings.detection_objects.people}
                    onChange={e =>
                      this.onChange("detection_objects", {
                        ...this.state.settings.detection_objects,
                        people: e.target.checked
                      })
                    }
                  />
                </div>
              </div>
              <div className="sensor__event">
                <div className="sensor__col">
                  <img
                    src="/img/type-car.svg"
                    className={
                      "sensor__pic " +
                      (settings.detection_objects.car ? "" : "disabled")
                    }
                    alt=""
                  />
                </div>
                <div className="sensor__col">
                  <div className="sensor__category">{t("car-in-frame")}</div>
                  <div className="sensor__description">
                    {t("car-in-frame-text")}
                  </div>
                </div>
                <div className="sensor__col">
                  <Switch
                    toggler
                    content={
                      settings.detection_objects.car
                        ? t("settings-on")
                        : t("settings-off")
                    }
                    value={settings.detection_objects.car}
                    onChange={e =>
                      this.onChange("detection_objects", {
                        ...this.state.settings.detection_objects,
                        car: e.target.checked
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="sensor__head">
              <div className="sensor__subtitle">
                {t("sensor-settings-time")}
              </div>
              <Switch
                content={t("24-hours")}
                toggler
                value={settings.around_clock}
                onChange={e => this.onChange("around_clock", e.target.checked)}
              />
            </div>
            <div className="sensor__hint">{t("sensor-settings-hint")}</div>
            <div className="sensor__group">
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("monday", t("monday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("monday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("tuesday", t("tuesday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("tuesday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("wednesday", t("wednesday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("wednesday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("thursday", t("thursday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("thursday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("friday", t("friday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("friday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("saturday", t("saturday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("saturday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("sunday", t("sunday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("sunday")}
                </div>
              </div>
            </div>
            <div className="sensor__subtitle">{t("notifications-title")}</div>
            <div className="sensor__group">
              <div className="sensor__subscribe">
                <div className="sensor__col">
                  <Switch
                    content={t("notifications-site")}
                    value={settings.notifications.webapp}
                    onChange={e =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            webapp: e.target.checked
                          }
                        }
                      })
                    }
                  />
                </div>
                <div className="sensor__col">
                  {/* <Switch
                    content={t("sensor-settings-sound")}
                    toggler
                    value={settings.notifications.webapp_sound}
                    onChange={e =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            webapp_sound: e.target.checked
                          }
                        }
                      })
                    }
                  /> */}
                </div>
              </div>
              <div className="sensor__subscribe">
                <div className="sensor__col">
                  <Switch
                    content={t("notifications-telegram")}
                    value={settings.notifications.telegram}
                    onChange={e =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            telegram: e.target.checked
                          }
                        }
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sensor__foot">
            <button className="sensor__btn btn" onClick={this.onSave}>
              {t("save-settings")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(SensorSettings);
