import React from "react";
import { translate } from "react-i18next";

class SharingEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="sharing">
        <img className="sharing__bg" src="/img/bg.jpg" alt="" />
        <div className="sharing__container">
          <div className="sharing__head">
            <div className="sharing__logo">
              <img src="/img/neurocam.svg" alt="" />
            </div>
            <div className="sharing__group">
              <div className="sharing__title">{t("service")}</div>
              <div className="sharing__actions">
                <a href="/">{t("enter")}</a>
                <a href="/">{t("register")}</a>
              </div>
            </div>
          </div>
          <div className="sharing__body">
            <div className="sharing__player">Здесь будет плеер</div>
            <div className="sharing__about">
              <div className="sharing__event title">
                Автомобиль и человек в кадре
              </div>
              <div className="sharing__info">
                <p>
                  <strong>{t("camera-title")}:</strong> Парковка
                </p>
                <p>
                  <strong>{t("owner")}:</strong> Иван Петров{" "}
                  <a href="/">jduncan@janyx.com</a>
                </p>
                <p>
                  <strong>{t("event-time")}:</strong> 25 августа 2018 г. 12:32:43
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(SharingEvent);
