import React, { useState } from "react";
import cn from "classnames";
import styles from "./Carousel.module.sass";
import Loader from "../../../../components/Loader";
import { personPhotoUrl, guidMainPhotoUrl } from "../../../../links";

const Carousel = ({ person }) => {
  const [index, setIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const photos = person.person_info?.photos;

  const goNext = () => {
    setLoaded(false);
    index + 1 === photos.length ? setIndex(0) : setIndex(index + 1);
  };

  const goPrev = () => {
    setLoaded(false);
    index === 0 ? setIndex(photos.length - 1) : setIndex(index - 1);
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.preview}>
        {!loaded && <Loader />}
        <img
          className={cn({ [styles.loaded]: loaded })}
          src={
            photos?.length > 1
              ? personPhotoUrl(photos[index].person_id, photos[index].image_id)
              : guidMainPhotoUrl(person.guid)
          }
          onLoad={() => setLoaded(true)}
          alt="Avatar"
        />
      </div>
      {photos?.length > 1 && (
        <div className={styles.nav}>
          <button className={styles.action} onClick={goPrev}></button>
          <div className={styles.status}>
            {index + 1}/{photos.length}
          </div>
          <button className={styles.action} onClick={goNext}></button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
