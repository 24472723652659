import React, { Component } from "react";
import moment from "moment-timezone";

export default class Cursor extends Component {
  render() {
    const { timezone } = this.props;

    return (
      <svg width="72" x={this.props.x - 36} height="70">
        <path
          fill="#F30027"
          fillRule="evenodd"
          d="M44,28 L38.1213203,33.8786797 C36.9497475,35.0502525 35.0502525,35.0502525 33.8786797,33.8786797 L28,28 L3.0020839,28 C1.34407874,28 0,26.6495018 0,25.0035095 L0,2.99649048 C0,1.34157448 1.34240766,0 3.0020839,0 L68.9979161,0 C70.6559213,0 72,1.3504982 72,2.99649048 L72,25.0035095 C72,26.6584255 70.6575923,28 68.9979161,28 L44,28 Z"
        ></path>
        <line
          x1={35.5}
          x2={35.5}
          y1={40}
          y2={64}
          stroke="#F30027"
          strokeWidth={3}
          strokeLinecap="round"
        />
        <text
          x={36}
          y={18}
          width={72}
          height={36}
          fill={"#fff"}
          fontSize={12}
          textAnchor={"middle"}
        >
          {moment.tz(this.props.time, timezone).format("HH:mm:ss")}
        </text>
      </svg>
    );
  }
}
