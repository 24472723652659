import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { translate } from "react-i18next";
import format from "dateformat";
import moment from "moment-timezone";
import Icon from "../Icon";
import Footfall from "./Footfall";
import FootfallMap from "./FootfallMap";
import VisitDuration from "./VisitDuration";
import FrequencyVisits from "./FrequencyVisits";
import { chartUnits, selectedDates } from "./utils";
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
} from "date-fns";

class ReportsVisitorAnalisys extends React.Component {
  // renderUpload() {
  //   return (
  //     <button className="analisys__btn btn">
  //       {this.props.t("upload-report")}
  //     </button>
  //   );
  // }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.setFilter({ place: this.props.id }, 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.setFilter({ place: this.props.id }, 1);
    }
  }

  render() {
    const {
      t,
      canEdit,
      place,
      filters,
      isPermsFootfall,
      isPermsMap,
      isPermsDuration,
      isPermsFrequency,
    } = this.props;

    let range = [];
    let labels = [];

    let days = eachDayOfInterval({
      start: filters.period.since,
      end: filters.period.until,
    });
    let isoDays = [];
    for (let index = 0; index < days.length; index++) {
      isoDays.push(moment(days[index]).format(`YYYY-MM-DDT00:00:00`) + "Z");
    }

    let weeks = eachWeekOfInterval(
      {
        start: filters.period.since,
        end: filters.period.until,
      },
      { weekStartsOn: 1 }
    );
    let isoWeeks = [];
    for (let index = 0; index < weeks.length; index++) {
      isoWeeks.push(moment(weeks[index]).format(`YYYY-MM-DDT00:00:00`) + "Z");
    }

    let months = eachMonthOfInterval({
      start: filters.period.since,
      end: filters.period.until,
    });
    let isoMonths = [];
    for (let index = 0; index < months.length; index++) {
      isoMonths.push(moment(months[index]).format(`YYYY-MM-DDT00:00:00`) + "Z");
    }

    const daysCount = days.length;

    if (daysCount === 1) {
      range = Array(24)
        .fill()
        .map((x, i) => {
          let hour = i;
          if (i <= 9) {
            hour = "0" + i;
          }
          return moment(days[0]).format(`YYYY-MM-DDT${hour}:00:00`) + "Z";
        });
    } else if (daysCount > 1 && daysCount <= 30) {
      range = isoDays;
    } else if (daysCount > 30 && daysCount <= 60) {
      range = isoWeeks;
    } else if (daysCount > 60) {
      range = isoMonths;
    }

    range.map((x) => labels.push(format(x, chartUnits(filters))));

    return (
      <div className="analisys">
        <div className="analisys__container">
          <div className="analisys__head">
            <div className="analisys__title title">
              {canEdit ? place.title : t("all-places")}
            </div>
            {canEdit && (
              <Link
                className="analisys__action action action_sm"
                to={`/reports/${place.id}/edit`}
              >
                <Icon name="edit" />
              </Link>
            )}
            <div className="analisys__title analisys__title_date title">
              {selectedDates(t, filters)}
            </div>
            {/* {this.renderUpload()} */}
          </div>
          <div className="analisys__body">
            {isPermsFootfall && (
              <Footfall filters={filters} dates={range} labels={labels} />
            )}
            {isPermsMap && <FootfallMap />}
            {isPermsDuration && (
              <VisitDuration filters={filters} dates={range} labels={labels} />
            )}
            {isPermsFrequency && <FrequencyVisits />}
          </div>
          {/* <div className="analisys__foot">{this.renderUpload()}</div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  timezone: state.users.user && state.users.user.Timezone,
  place:
    state.reports.places.find((p) => p.id.toString() === ownProps.id) || {},
});

export default translate()(
  withRouter(connect(mapStateToProps)(ReportsVisitorAnalisys))
);
