import HttpClient from "../http";
import i18n from "../i18n";
import { isEmpty, isNil } from "ramda";
import { tzOffset } from "./users";

const DEFAULT_LIMIT = 10;

export const AvailableObjects = [
  { title: i18n.t("man"), object: "people", icon: "type-man" },
  { title: i18n.t("car"), object: "car", icon: "type-car" },
  { title: i18n.t("faces"), object: "person", icon: "type-face" },
];

const localDate = (ts) => new Date(ts * 1000).toLocaleDateString();

const initialState = {
  fetched: false,
  hasMore: true,
  total: 0,
  filters: {
    cameraId: null,
    period: null,
    objects: [],
    groups: [],
    personName: "",
    age: "",
    gender: "",
  },
  newCount: 0,
  newFetch: false,
  itemsByDays: {},
  days: [],
  loading: false,
  prevTimestamp: 0,
  nextTimestamp: 0,
  cameras: [],
  camerasEventsCount: {},
  event: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_FETCH_START:
      return { ...state, loading: true };
    case EVENTS_FETCH:
      // TODO group events on backend
      const objects = action.payload.objects || [];
      const events = objects.map((i) => ({
        ...i,
        Date: localDate(i.Timestamp),
      }));

      const payloadDays = events
        .sort((a, b) => (a.Timestamp > b.Timestamp ? -1 : 1))
        .map((event) => event.Date);

      const days = new Set(state.days.concat(payloadDays));

      const eventsByDays = events.reduce((g, item) => {
        if (item.Date in g) {
          const index = g[item.Date].findIndex((i) => i.ID === item.ID);

          if (index < 0) return { ...g, [item.Date]: [...g[item.Date], item] };

          return {
            ...g,
            [item.Date]: [
              ...g[item.Date].slice(0, index),
              item,
              ...g[item.Date].slice(index + 1),
            ],
          };
        }

        return { ...g, [item.Date]: [item] };
      }, state.itemsByDays);

      return {
        ...state,
        fetched: true,
        total: action.payload.total,
        days: [...days],
        itemsByDays: eventsByDays,
        nextTimestamp:
          events.length > 0
            ? events[events.length - 1].Timestamp
            : state.nextTimestamp,
        prevTimestamp: state.nextTimestamp,
        hasMore: true,
        loading: false,
      };
    case EVENTS_CAMERAS_FETCH:
      return {
        ...state,
        cameras: action.payload || [],
      };
    case EVENTS_SET_FILTER:
      return {
        ...state,
        fetched: false,
        days: initialState.days,
        itemsByDays: initialState.itemsByDays,
        nextTimestamp: 0,
        prevTimestamp: 0,
        hasMore: false,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case EVENTS_CLEAR:
      return { ...initialState, newCount: state.newCount };
    case EVENTS_GET:
      return { ...state, event: action.payload };
    case EVENTS_MARK_VIEWED:
      return { ...state, event: { ...state.event, viewed: true } };
    case EVENTS_FETCH_COUNTS_NEW:
      return { ...state, newCount: action.payload.count };
    case EVENTS_SET_NEW_FETCH:
      return { ...state, newFetch: action.payload, hasMore: false };
    case EVENTS_UPDATE:
      const lastDayKey = Object.keys(state.itemsByDays)[0];
      const lastDays = state.itemsByDays[lastDayKey] || [];
      return {
        ...state,
        itemsByDays: {
          ...state.itemsByDays,
          [lastDayKey]: [action.payload, ...lastDays],
        },
      };
    case EVENTS_DELETE:
      return {
        ...state,
        fetched: false,
        days: initialState.days,
        itemsByDays: initialState.itemsByDays,
        nextTimestamp: 0,
        prevTimestamp: 0,
        hasMore: false,
      };
    case EVENTS_FETCH_CAMERA_COUNTS_NEW:
      return {
        ...state,
        camerasEventsCount: {
          ...state.camerasEventsCount,
          [action.payload.cameraId]: action.payload.count,
        },
      };
    default:
      return state;
  }
};

const EVENTS_FETCH_START = "EVENTS_FETCH_START";
const EVENTS_FETCH = "EVENTS_FETCH";
export const EventsFetch = (prev = false) => async (dispatch, getState) => {
  const { events } = getState();

  if (events.loading) return;

  await dispatch({ type: EVENTS_FETCH_START });

  let url = `/archive/events?page_limit=${DEFAULT_LIMIT}`;
  if (events.nextTimestamp > 0 && !prev) {
    url = url + `&page_ts=${events.nextTimestamp}`;
  }

  if (events.prevTimestamp > 0 && prev) {
    url = url + `&page_ts=${events.prevTimestamp}`;
  }

  if (events.filters.cameraId) {
    url = url + `&camera_id=${events.filters.cameraId}`;
  }

  if (events.filters.period) {
    const { from, to } = events.filters.period;
    url += `&begin=${tzOffset(getState(), from.getTime())}`;
    url += `&end=${tzOffset(getState(), to.getTime())}`;
  }

  if (events.filters.objects && events.filters.objects.length > 0) {
    const objects = events.filters.objects.join(",");
    url = url + `&objects=${objects}`;
  }

  if (
    events.filters.groups &&
    events.filters.groups.length > 0 &&
    !events.filters.personIds
  ) {
    const persons = events.filters.groups.reduce(
      (list, g) => [...list, ...g.persons],
      []
    );
    if (persons.length > 0) {
      url = url + `&person_id=${persons.join(",")}`;
    }
  }

  //if (events.filters.personName) {
  //  const personName = events.filters.personName.toLowerCase();
  //  const { faces } = getState();

  //  const personIds = faces.persons
  //    .map((p) => ({ ...p, fullName: `${p.name} ${p.last_name}` }))
  //    .filter((p) => p.fullName.toLowerCase().includes(personName))
  //    .map((p) => p.id);

  //  url = url + `&person_id=${[...personIds, -10].join(",")}`;
  //}

  // TODO debug personNmae as guid
  if (events.filters.personName) {
    const personName = events.filters.personName.toLowerCase();

    url = url + `&guid=${personName}`;
  }

  if (events.filters.age) {
    if (events.filters.age === "age0") {
      url = url + `&age_from=0&age_to=17`;
    } else if (events.filters.age === "age18") {
      url = url + `&age_from=18&age_to=24`;
    } else if (events.filters.age === "age25") {
      url = url + `&age_from=25&age_to=34`;
    } else if (events.filters.age === "age35") {
      url = url + `&age_from=35&age_to=44`;
    } else if (events.filters.age === "age45") {
      url = url + `&age_from=45&age_to=54`;
    } else if (events.filters.age === "age55") {
      url = url + `&age_from=55&age_to=110`;
    }
  }

  if (events.filters.gender) {
    url = url + `&gender=${events.filters.gender}`;
  }

  const response = await HttpClient.get(url);
  return dispatch({ type: EVENTS_FETCH, payload: response.data });
};

const EVENTS_CAMERAS_FETCH = "EVENTS_CAMERAS_FETCH";
export const EventsCamerasFetch = () => async (dispatch, getState) => {
  const response = await HttpClient.get("/archive/events/cameras");

  await dispatch({ type: EVENTS_CAMERAS_FETCH, payload: response.data });

  getState().events.cameras.map((camera) =>
    dispatch(EventsCameraCountsNew(camera.id))
  );
};

const EVENTS_SET_FILTER = "EVENTS_SET_FILTER";
export const EventsSetFilter = (filter) => async (dispatch) => {
  await dispatch({ type: EVENTS_SET_FILTER, payload: filter });
  return dispatch(EventsFetch());
};

const EVENTS_CLEAR = "EVENTS_CLEAR";
export const EventsClear = () => (dispatch) => {
  return dispatch({ type: EVENTS_CLEAR });
};

const EVENTS_GET = "EVENTS_GET";
export const EventsGet = (cameraId, id) => async (dispatch) => {
  const response = await HttpClient.get(`/archive/events/${cameraId}/${id}`);
  return dispatch({ type: EVENTS_GET, payload: response.data });
};

const EVENTS_MARK_VIEWED = "EVENTS_MARK_VIEWED";
export const EventsMarkViewed = (
  id,
  viewed = true,
  eventsReload = false
) => async (dispatch, getState) => {
  const { event } = getState().events;
  const eventId = id || event.id;

  await HttpClient.post(`/archive/events/mark?ids=${eventId}&viewed=${viewed}`);

  if (event.CameraID) dispatch(EventsCameraCountsNew(event.CameraID));

  dispatch(EventsCountsNew());
  dispatch(EventsFetch(true));

  if (eventsReload) {
    // TODO: remove when add events grouping on backend
    dispatch({ type: EVENTS_DELETE });
    dispatch(EventsFetch());
  }

  return dispatch({ type: EVENTS_MARK_VIEWED });
};

const EVENTS_DELETE = "EVENTS_DELETE";
export const EventsDelete = (id) => async (dispatch) => {
  await HttpClient.delete(`/archive/events/${id}`);

  await dispatch({ type: EVENTS_DELETE });

  return dispatch(EventsFetch());
};

const EVENTS_FETCH_COUNTS_NEW = "EVENTS_FETCH_COUNTS_NEW";
export const EventsCountsNew = () => async (dispatch) => {
  const response = await HttpClient.get("/archive/events/count_new");
  return dispatch({ type: EVENTS_FETCH_COUNTS_NEW, payload: response.data });
};

const EVENTS_SET_NEW_FETCH = "EVENTS_SET_NEW_FETCH";
export const EventsSetNewFetch = (val) => (dispatch) => {
  return dispatch({ type: EVENTS_SET_NEW_FETCH, payload: val });
};

const EVENTS_UPDATE = "EVENTS_UPDATE";
export const EventsUpdate = (e) => (dispatch, getState) => {
  const {
    events: { filters },
  } = getState();

  const hasComplexFilters = Object.keys(filters)
    .filter((key) => !isEmpty(filters[key]) && !isNil(filters[key]))
    .some((key) => key !== "cameraId");

  if (
    !hasComplexFilters &&
    (filters.cameraId === e.CameraID || !filters.cameraId)
  ) {
    return dispatch({ type: EVENTS_UPDATE, payload: e });
  }
};

const EVENTS_FETCH_CAMERA_COUNTS_NEW = "EVENTS_FETCH_CAMERA_COUNTS_NEW";
export const EventsCameraCountsNew = (cameraId) => async (dispatch) => {
  const response = await HttpClient.get(
    `/archive/events/count_new?camera_id=${cameraId}`
  );

  return dispatch({
    type: EVENTS_FETCH_CAMERA_COUNTS_NEW,
    payload: { count: response.data.count, cameraId },
  });
};
