import React, { useState } from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";

const Search = ({
  t,
  value,
  onChange,
  onClose,
  placeholder,
  file,
  onSearchByPhoto,
  loading,
}) => {
  const [uploading, setUploading] = useState(false);

  const openUploading = () => {
    setUploading(true);
  };

  const closeUploading = () => {
    setUploading(false);
    onClose && onClose();
  };

  return (
    <div className={styles.search}>
      <div className={styles.head}>
        <div className={styles.icon}>
          <Icon name={uploading ? "photo" : "search"} />
        </div>
        {uploading ? (
          <div className={styles.title}>{t("search-by-photo")}</div>
        ) : (
          <input
            className={cn(styles.input, { [styles.pr0]: onSearchByPhoto })}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        )}
        {onSearchByPhoto &&
          (uploading ? (
            <button
              className={cn("action", styles.action)}
              onClick={() => closeUploading(false)}
              disabled={loading}
            >
              {loading ? <Loader /> : <Icon name={"close-sm"} />}
            </button>
          ) : (
            <button
              className={cn("action", styles.action)}
              onClick={() => openUploading(true)}
            >
              <Icon name={"photo"} />
            </button>
          ))}
      </div>
      {uploading && (
        <div className={styles.wrap}>
          <div className={cn(styles.upload, { [styles.active]: file })}>
            {file ? (
              <img className={styles.pic} src={file} alt="" />
            ) : (
              <div className={styles.group}>
                <Icon name="face" />
                <div className={styles.formats}>JPEG, PNG</div>
              </div>
            )}
            <div className={styles.change}>
              {file && <div className={styles.label}>{t("change-photo")}</div>}
              <input
                className={styles.file}
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={onSearchByPhoto}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default translate()(Search);
