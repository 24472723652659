import React from "react";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import TariffTitle from "../components/TariffTitle";
import ConnectTariff from "../components/ConnectTariff";
import { BalanceLoad } from "../reducers/payments";
import { CameraInfo } from "../reducers/camera";
import Rates from "../rates";
import { currency } from "../utils";

const personRate = (t, rate) => ({
  ...rate,
  name:
    rate.reports && rate.celebrity ? t("tariff-with-celeb") : t("tariff-own"),
});

const Option = ({ children, active, onClick }) => {
  const className = "features__option" + (active ? " active" : "");
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

class ConnectRecognition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleTooltip: true,
      showModal: false,
      tariffWithoutReports: false,
      payPerMonth: true,
      tariffs: Rates.recognitionTariffs.month,
      selectedTariff: {},
    };
  }

  componentDidMount() {
    if (this.props.camera.id !== this.props.match.params.id) {
      this.props.CameraInfo(this.props.match.params.id).then(() => {
        if (!this.props.camera.neurobox_id)
          toast.error(this.props.t("camera-should-be-connected-via-neurobox"));
      });
    }
    this.props.BalanceLoad();
  }

  handleToggleModal = (rate) => (e) => {
    e.preventDefault();
    this.setState({ showModal: !this.state.showModal });
    if (!this.state.showModal) {
      this.setState({
        selectedTariff: rate,
      });
    }
  };

  togglePeriod = () => {
    const group = Rates.recognitionTariffs;

    if (this.state.payPerMonth === true) {
      this.setState({
        tariffs: group.year,
      });
    } else {
      this.setState({ tariffs: group.month });
    }

    this.setState({
      visibleTooltip: false,
      payPerMonth: !this.state.payPerMonth,
    });
  };

  renderDetails = () => {
    const { t } = this.props;
    return (
      <div className="features__details">
        <div className="features__detail">
          <span className="bold">{t("camera")}:</span> {this.props.camera.title}
        </div>
        <div className="features__detail">
          <span className="bold">{t("connect-cloud-balance")}:</span>{" "}
          {this.props.balance} {t(currency)}{" "}
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/payment");
            }}
          >
            {t("connect-cloud-refill-balance")}
          </a>
        </div>
      </div>
    );
  };

  renderRate = (rate, index) => {
    const { t } = this.props;
    return (
      <div
        className="features__period"
        onClick={this.handleToggleModal(rate)}
        key={index}
      >
        <div className="features__days">
          {rate.reports && <TariffTitle rate={rate} red />}
        </div>
        <div className="features__price">
          <div className="features__actual">
            {rate.price} {t(currency)}
          </div>
          {rate.priceOld && (
            <div className="features__before">
              {rate.priceOld} {t(currency)}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderRates = () => {
    const byPayType = this.state.tariffWithoutReports
      ? Rates.recognitionTariffs
      : Rates.reportTarrifs;
    const rates = byPayType[this.state.payPerMonth ? "month" : "year"];
    return rates.map((rate, index) =>
      this.renderRate(Rates.recognitionTarrifsByID[rate], index)
    );
  };

  render() {
    const { showModal, selectedTariff } = this.state;
    const { t, camera } = this.props;

    if (camera.face_recognition_enabled) {
      return <Redirect to="/cameras" />;
    }

    return (
      <div className="features">
        <div className="features__head">
          <div className="features__title title">
            {t("connect-recognition-title")}
          </div>
          {this.renderDetails()}
          <Link className="features__action action" to="/payment">
            <Icon name="close-sm" />
          </Link>
        </div>
        <div className="features__body">
          {this.renderDetails()}
          <div className="features__steps">
            <div className="features__step">
              <div className="features__heading">
                <span className="color-red">1.</span> {t("choose-type-tariff")}
              </div>
              <div className="features__list features__list_flex">
                <Option
                  active={this.state.tariffWithoutReports === false}
                  onClick={() => this.setState({ tariffWithoutReports: false })}
                >
                  <div className="features__subtitle">
                    {t("recognition-reports")}
                  </div>
                  <div className="features__text">
                    {t("recognition-reports-text")}
                  </div>
                </Option>
              </div>
            </div>
            <div className="features__step">
              <div className="features__heading">
                <span className="color-red">2.</span>{" "}
                {t("select-payment-period")}
              </div>
              <div className="features__list features__list_flex">
                <Option
                  active={this.state.payPerMonth === true}
                  onClick={() => this.setState({ payPerMonth: true })}
                >
                  <div className="features__subtitle">
                    {t("payment-for-month")}
                  </div>
                  <div className="features__text">{t("monthly-payment")}</div>
                </Option>
                <Option
                  active={this.state.payPerMonth === false}
                  onClick={() => this.setState({ payPerMonth: false })}
                >
                  <div className="features__free">+ 2 {t("free-months")}</div>
                  <div className="features__subtitle">
                    {t("payment-for-year")}
                  </div>
                  <div className="features__text">
                    {t("recognition-yearly-payment")}
                  </div>
                </Option>
              </div>
            </div>
            <div className="features__step features__step_lg">
              <div className="features__heading">
                <span className="color-red">3.</span>{" "}
                {t("recognition-max-persons")}
              </div>
              <div className="features__list">{this.renderRates()}</div>
              <div className="features__info">
                <p>
                  <strong>{t("recognition-info")}</strong>
                </p>
                {/* <ul>
                  <li>{t("recognition-info-1")}</li>
                  <li>{t("recognition-info-2")}</li>
                  <li>{t("recognition-info-3")}</li>
                  <li>{t("recognition-info-4")}</li>
                  <li>{t("recognition-info-5")}</li>
                  <li>{t("recognition-info-6")}</li>
                </ul> */}
              </div>
            </div>
          </div>
          {showModal && (
            <Modal
              className="modal_md"
              onCloseRequest={this.handleToggleModal(false)}
            >
              <ConnectTariff tariff={personRate(t, selectedTariff)} />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  camera: state.camera.camera.Camera,
  balance: state.payments.balance,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, { BalanceLoad, CameraInfo })
  )(ConnectRecognition)
);
