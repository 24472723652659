import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import Icon from "../Icon";
import { EventsSetFilter } from "../../reducers/events";

class EventsSearch extends React.Component {
  searchTimeout = null;

  state = {
    upload: false,
    file: null
  };

  toggleUpload = () => {
    this.setState({
      upload: !this.state.upload,
      file: null
    });
    this.removeBlobImage();
  };

  handleChange = event => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    });
    this.removeBlobImage();
  };

  removeBlobImage = () => {
    if (this.state.file) {
      URL.revokeObjectURL(this.state.file);
    }
  };

  handleSearch = e => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    const { value } = e.target;
    const { EventsSetFilter } = this.props;

    this.searchTimeout = setTimeout(
      () => EventsSetFilter({ personName: value }),
      1000
    );
  };

  render() {
    const { upload, file } = this.state;
    const { t } = this.props;
    return (
      <div className="search">
        {upload ? (
          <div className="search__container">
            <div className="search__head">
              <div className="search__icon">
                <Icon name="photo" />
                {/* <Loader /> */}
              </div>
              <div className="search__title">{t("search-by-photo")}</div>
              <button
                className="search__action action"
                onClick={this.toggleUpload}
              >
                <Icon name="close-sm" />
              </button>
            </div>
            <div className="search__wrap">
              <div className={"search__upload " + (file && "active")}>
                {file ? (
                  <img className="search__pic" src={file} alt="" />
                ) : (
                  <div className="search__group">
                    <Icon name="face" />
                    <div className="search__formats">JPEG, PNG</div>
                  </div>
                )}
                <div className="search__change">
                  <span className="search__label">{t("change-photo")}</span>
                  <input
                    className="search__file"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="search__head">
            <div className="search__icon">
              <Icon name="search" />
            </div>
            <input
              className="search__input"
              type="text"
              placeholder={t("search")}
              onChange={this.handleSearch}
            />
            {/* <button
              className="search__action action"
              onClick={this.toggleUpload}
            >
              <Icon name="photo" />
            </button> */}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  personName: state.events.filters.personName
});

export default translate()(
  connect(
    mapStateToProps,
    { EventsSetFilter }
  )(EventsSearch)
);
