import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { translate } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import Icon from "./Icon";

//TODO: Convert component to uncontrolled (remove state usage)
class DropdownActions extends React.Component {
  state = {
    opened: false,
  };

  renderItem = (item) => (
    <Fragment>
      {item.color && (
        <div
          className="dropdown__color"
          style={{ backgroundColor: item.color }}
        />
      )}
      {item.title}
    </Fragment>
  );

  render() {
    const {
      t,
      caption,
      actions,
      icon,
      field,
      className,
      options,
      value,
      onChange,
      disabled,
      children,
      link,
    } = this.props;
    return (
      <div
        className={cn(
          "dropdown",
          { dropdown_action: actions },
          { dropdown_field: field },
          className,
          {
            open: !disabled && this.state.opened,
          },
          { disabled: disabled }
        )}
      >
        <div className="dropdown__wrap">
          {icon ? (
            <button
              className="dropdown__action action"
              onClick={this.toggleDropdown}
            >
              <Icon name={icon} />
            </button>
          ) : (
            <div className="dropdown__head" onClick={this.toggleDropdown}>
              {value ? this.renderItem(value) : t("not-chosen")}
            </div>
          )}
          {!disabled && (
            <div className="dropdown__body" onClick={this.toggleDropdown}>
              {children ? (
                children
              ) : (
                <div>
                  {caption && (
                    <div className="dropdown__caption">{caption}</div>
                  )}
                  {options
                    .filter((x) => !x.hide)
                    .map((item, index) => (
                      <div
                        className={cn("dropdown__option", item.className)}
                        key={index}
                        onClick={() => onChange && onChange(item)}
                      >
                        {this.renderItem(item)}
                      </div>
                    ))}
                  {link && (
                    <Link
                      to={link}
                      className="dropdown__option dropdown__option_add"
                    >
                      <Icon name="plus" />
                      <div className="dropdown__label">
                        {t("create-new-list")}
                      </div>
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleDropdown = (_) => {
    this.setState({ opened: !this.state.opened });
  };

  handleClickOutside = (evt) => {
    this.setState({ opened: false });
  };
}

export default translate()(onClickOutside(DropdownActions));
