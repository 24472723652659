import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { translate } from "react-i18next";
import Icon from "../Icon";
import Counter from "../Counter";

import { Archive } from "../../reducers/payments";
import Rates from "../../rates.js";

class EventsNav extends React.Component {
  renderArchiveTitle = (rate) => {
    const rateInfo = Rates.archiveTarrifsByID[rate.ID];
    const { t } = this.props;
    if (!rateInfo) return "";
    return (
      `${rateInfo.days} ` + (rateInfo.daysText ? t("rate-day") : t("rate-days"))
    );
  };

  renderArchiveDays = (item) => {
    const cameraRate = this.props.cameraRatesMap[item.id];
    const { t } = this.props;
    return (
      <div className="layout__info">
        {cameraRate && cameraRate.Rate.ArciveOnlyEvents
          ? t("events-storage")
          : t("archive-storage")}
        {": "}
        {cameraRate ? (
          <span className="color-red">
            {this.renderArchiveTitle(cameraRate.Rate)}
          </span>
        ) : (
          <span>{t("nav-not-connected")}</span>
        )}
      </div>
    );
  };

  renderNavLink = (eventCamera) => {
    const { t, camerasMap, eventsCamerasCount } = this.props;
    const camera = camerasMap[eventCamera.id];

    return (
      <NavLink
        className="layout__link"
        activeClassName="active"
        to={`/events/${eventCamera.id}`}
        exact={true}
        key={eventCamera.id}
      >
        <div className="layout__preview">
          <Icon className="layout__pic" name="camera" />
          {camera && camera.shared && (
            <div
              className="layout__share"
              data-tip={t("owner") + ": " + camera.owner_info.name}
              data-class="tooltip"
              data-place="right"
              data-effect="solid"
            >
              <Icon className="layout__pic" name="share-sm" />
              <ReactTooltip />
            </div>
          )}
        </div>
        <div className="layout__wrap">
          <div className="layout__name">{eventCamera.title}</div>
          {camera && !camera.shared && this.renderArchiveDays(eventCamera)}
        </div>
        {eventsCamerasCount[eventCamera.id] > 0 && (
          <Counter
            className="layout__counter"
            value={eventsCamerasCount[eventCamera.id]}
          />
        )}
      </NavLink>
    );
  };

  render() {
    const { t, items } = this.props;
    return (
      <div className="layout__nav">
        <NavLink
          className="layout__link"
          activeClassName="active"
          exact
          to="/events"
        >
          <div className="layout__wrap">
            <div className="layout__name">{t("all-cameras")}</div>
          </div>
        </NavLink>
        {items.map((item) => this.renderNavLink(item))}
        <ReactTooltip />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventsCamerasCount: state.events.camerasEventsCount,
  camerasMap: state.cameras.items.reduce(
    (m, cam) => ({ ...m, [cam.id]: cam }),
    {}
  ),
  cameraRatesMap: state.payments.cameraRatesMap[Archive],
});

export default translate()(withRouter(connect(mapStateToProps)(EventsNav)));
