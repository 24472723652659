import { eachDayOfInterval } from "date-fns";
import format from "dateformat";

// common chart options
export const chartOptions = {
  layout: {
    padding: {
      top: 24,
    },
  },
  plugins: { datalabels: { display: false } },
  maintainAspectRatio: false,
  legend: { display: false },
  tooltips: { enabled: false },
  hover: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          padding: 10,
          fontFamily: "'Aeroport-Regular', sans-serif",
          fontColor: "#868D92",
          fontSize: 10,
        },
        gridLines: {
          drawBorder: false,
          drawTicks: false,
          color: "#e8e8e8",
          zeroLineColor: "#e8e8e8",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          padding: 10,
          min: 0,
          fontFamily: "'Aeroport-Regular', sans-serif",
          fontColor: "#868D92",
          fontSize: 12,
          precision: 0,
        },
        gridLines: {
          drawBorder: false,
          drawTicks: false,
          color: "#e8e8e8",
          zeroLineColor: "#e8e8e8",
        },
      },
    ],
  },
  elements: { point: { radius: 0 } },
};

// count reports period
export const countPeriod = (since, until) => {
  const days = eachDayOfInterval({
    start: since,
    end: until,
  }).length;

  if (days === 1) {
    return "hour";
  } else if (days > 1 && days <= 30) {
    return "day";
  } else if (days > 30 && days <= 60) {
    return "week";
  } else if (days > 60) {
    return "month";
  }
};

// chart units
export const chartUnits = (filters) => {
  const period = countPeriod(filters.period.since, filters.period.until);
  if (period === "hour") {
    return "UTC:HH";
  } else if (period === "day") {
    return "UTC:dd/mm/yy";
  } else if (period === "week") {
    return "UTC:dd/mm/yy";
  } else if (period === "month") {
    return "UTC:mmm";
  }
};

// number to minutes and seconds
export const NumberToMS = (t, number) => {
  const minutes = Math.floor(number / 60);
  const seconds = number - minutes * 60;
  return `${minutes} ${t("m")} ${seconds} ${t("s")}`;
};

// number to hours and minutes
export const NumberToHours = (t, number) => {
  const hours = Math.floor(number / 3600);
  const minutes = Math.round((number - hours * 3600) / 60);
  if (hours > 0) return `${hours} ${t("h")} ${minutes} ${t("m")}`;
  return `${minutes} ${t("m")}`;
};

// selected dates
export const selectedDates = (t, filters, getValue) => {
  const todayFormated = format(new Date(), "dd/mm/yy");
  const sinceFormated = format(filters.period.since, "dd/mm/yy");
  const untilFormated = format(filters.period.until, "dd/mm/yy");

  const singleDay = sinceFormated === untilFormated;
  const today =
    sinceFormated === todayFormated && untilFormated === todayFormated;

  if (getValue === "today") {
    return today;
  } else if (getValue === "singleDay") {
    return singleDay;
  } else {
    return today
      ? t("today")
      : singleDay
      ? sinceFormated
      : `${sinceFormated} - ${untilFormated}`;
  }
};

// diff time
export const diffTime = (t, current, prev, param, sign) => {
  if (current[param] && prev[param]) {
    const currentMinutes =
        parseInt(current[param].slice(0, 2)) * 60 +
        parseInt(current[param].slice(-2)),
      prevMinutes =
        parseInt(prev[param].slice(0, 2)) * 60 +
        parseInt(prev[param].slice(-2)),
      totalMinutes = currentMinutes - prevMinutes,
      absMinutes = Math.abs(totalMinutes);
    if (sign) {
      return totalMinutes < 0;
    } else {
      return NumberToHours(t, absMinutes * 60);
    }
  }
};

// diff duration
export const diffDuration = (t, current, prev, param, sign) => {
  if (current[param] && prev[param]) {
    const totalMs = current[param] - prev[param],
      absMs = Math.abs(totalMs);
    if (sign) {
      return totalMs < 0;
    } else {
      return NumberToHours(t, absMs);
    }
  }
};
