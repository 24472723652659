import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";
import Loader from "../Loader";
import ReportsStats from "./ReportsStats";
import ReportsEmployees from "./ReportsEmployees";
import ReportsNoData from "./ReportsNoData";

import { selectedDates } from "./utils";

import { TimeTrackingFetch } from "../../reducers/reports";

class ReportsTimeTracking extends React.Component {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.setFilter({ placeTimeTracking: this.props.id }, 2);
    } else {
      this.props.TimeTrackingFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.setFilter({ placeTimeTracking: this.props.id }, 2);
    }
  }

  render() {
    const {
      t,
      canEdit,
      place,
      timeTrackingFetched,
      timeTracking,
      filters,
    } = this.props;

    // console.log(timeTrackingFetched, timeTracking);

    return timeTrackingFetched ? (
      <div className="analisys">
        <div className="analisys__container">
          <div className="analisys__head">
            <div className="analisys__title title">
              {canEdit ? place.title : t("all-places")}
            </div>
            {canEdit && (
              <Link
                className="analisys__action action action_sm"
                to={`/reports/time-tracking/${place.id}/edit`}
              >
                <Icon name="edit" />
              </Link>
            )}
            <div className="analisys__title analisys__title_date title">
              {selectedDates(t, filters)}
            </div>
          </div>
          <div className="analisys__body">
            {timeTracking && timeTracking.current.persons ? (
              <>
                <ReportsStats
                  timeTracking={timeTracking}
                  singleDay={selectedDates(t, filters, "singleDay")}
                />
                <ReportsEmployees
                  placeId={place.id}
                  timeTracking={timeTracking}
                  singleDay={selectedDates(t, filters, "singleDay")}
                  today={selectedDates(t, filters, "today")}
                />
              </>
            ) : (
              <ReportsNoData timeTracking />
            )}
          </div>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  timeTracking: state.reports.timeTracking,
  timeTrackingFetched: state.reports.timeTrackingFetched,
  place:
    state.reports.places.find((p) => p.id.toString() === ownProps.id) || {},
});

export default translate()(
  withRouter(
    connect(mapStateToProps, {
      TimeTrackingFetch,
    })(ReportsTimeTracking)
  )
);
