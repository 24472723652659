import React from "react";
import { withRouter, Link } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";

const ReportsEmpty = (props) => {
  const { t, type } = props;

  const url =
    type === 1 ? "/reports/add-place" : "/reports/time-tracking/add-place";

  return (
    <div className="page page_sm page_empty">
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic" name="camera-lg" />
        </div>
        <div className="page__title">{t("place-empty-title")}</div>
        <div className="page__text">{t("place-empty-text")}</div>
        <Link className="btn" to={url}>
          {t("place-add")}
        </Link>
      </div>
    </div>
  );
};

export default withRouter(translate()(ReportsEmpty));
