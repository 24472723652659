import { BASE_URL, BASE_WS_URL, parseURL } from "./http";

// TODO: Refactor token getter
export const token = () => localStorage.getItem("token");

export const videoStreamUrl = (cameraId) =>
  `${BASE_URL}/broadcast/${cameraId}/playlist.m3u8?token=${token()}`;

export const videoArchiveUrl = (cameraId, since, until) =>
  `${BASE_URL}/archive/hls/${cameraId}/playlist.m3u8?ts1=${since}&ts2=${until}&token=${token()}`;

export const videoExportUrl = (cameraId, since, until) =>
  `${BASE_URL}/archive/export/${cameraId}?ts1=${since}&ts2=${until}&token=${token()}`;

export const cameraImageUrl = (id) =>
  `${BASE_URL}/broadcast/${id}/preview?token=${token()}`;

export const wsMJpegStreamUrl = (cameraId) =>
  `${BASE_WS_URL}/broadcast/${cameraId}/mjpeg?token=${token()}`;

export const httpProxyUrl = (cameraId) => {
  const { host } = parseURL(BASE_URL);
  return `http://${host}:8557/proxy/?cid=${cameraId}&token=${token()}`;
};

export const personPhotoUrl = (personId, imageId) =>
  `${BASE_URL}/guids-api/${personId}/photo/${imageId}?token=${token()}`;

export const guidMainPhotoUrl = (guid) =>
  `${BASE_URL}/guids-api/${guid}/main?token=${token()}`;

export const guidPhotoUrl = (guid, photoId) =>
  `${BASE_URL}/guids-api/${guid}/photo/${photoId}?token=${token()}`;

export const eventImage = (cameraId, eventId) =>
  `${BASE_URL}/archive/events/${cameraId}/${eventId}/image?token=${token()}`;

export const eventRoiImage = (cameraId, eventId) =>
  `${BASE_URL}/archive/events/${cameraId}/${eventId}/roi?token=${token()}`;
