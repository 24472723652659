import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Page.module.sass";
import Action from "../Action";
import Button from "../Button";

const Page = ({
  visibleSidebar,
  toggleSidebar,
  title,
  sidebar,
  header,
  adding,
  children,
}) => {
  const [visibleFilters, setVisibleFilters] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.left}>
          <Action
            className={styles.toggle}
            active={visibleSidebar}
            onClick={toggleSidebar}
            icon="menu"
          />
          <Action
            className={styles.logo}
            to="/"
            src="/img/neurobox.svg"
            alt="Neurocam"
          />
          <div className="title">{title}</div>
          <Action
            className={styles.toggleMobile}
            active={visibleFilters}
            onClick={() => setVisibleFilters(!visibleFilters)}
            icon="filters"
          />
          {adding && <Button className={styles.add} to={adding} icon="plus" />}
        </div>
        <div className={styles.right}>{header}</div>
      </div>
      <div className={styles.body}>
        {(isMobile ? visibleFilters : visibleSidebar) && (
          <div className={styles.left}>{sidebar}</div>
        )}
        <div className={styles.right}>{children}</div>
      </div>
    </div>
  );
};

export default Page;
