import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import Switch from "../../Switch";

const ExcludeGroups = ({
  t,
  isPermsGroupCreate,
  groups,
  excludeGroups,
  updateExcludeGroups,
  clearExcludeGroups,
  disabled,
}) => {
  const [enabled, setEnabled] = useState(false);

  async function fetchExcludeGroups(excludeGroups) {
    excludeGroups.length > 0 && setEnabled(true);
  }

  useEffect(() => {
    fetchExcludeGroups(excludeGroups);
  }, [excludeGroups]);

  const toggle = () => {
    setEnabled(!enabled);
    enabled && clearExcludeGroups();
  };

  return (
    <div className="create__group">
      <Switch
        toggler
        content={t("exclude-watchlists")}
        value={enabled}
        onChange={toggle}
        disabled={disabled}
      />
      <div className="create__inner">
        <div className="create__info">{t("select-observation-groups")}</div>
        {groups.length > 0
          ? groups.map((x) => (
              <Switch
                className="create__group"
                content={x.title}
                key={x.id}
                disabled={!enabled}
                value={excludeGroups.includes(x.id)}
                onChange={(e) => updateExcludeGroups(e.target.checked, x.id)}
              />
            ))
          : isPermsGroupCreate && (
              <NavLink to="/faces/add-list">{t("create-new-list")}</NavLink>
            )}
      </div>
    </div>
  );
};

export default translate()(ExcludeGroups);
