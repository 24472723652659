import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import styles from "./GridView.module.sass";
import Switch from "../../../Switch";
import Icon from "../../../Icon";
import { guidMainPhotoUrl } from "../../../../links";

import { ageRange } from "../../../../utils";

const GridView = ({
  t,
  items,
  isPersons,
  selection,
  onSelect,
  isSelected,
  groupName,
  groupColor,
}) => {
  return (
    <div className={styles.list}>
      {items.map((item) => (
        <div className={styles.item} key={item.guid}>
          {selection && (
            <Switch
              className={styles.switch}
              value={isSelected(item.guid)}
              onChange={(e) => onSelect(item.guid, e.target.checked)}
            />
          )}
          <Link
            className={styles.link}
            to={`/faces/${item.group_info?.id || 0}/${item.guid}`}
          >
            <div className={styles.avatar}>
              {item.guid ? (
                <img src={guidMainPhotoUrl(item.guid)} alt="Avatar" />
              ) : (
                <Icon name="face" />
              )}
              {item.online && <div className={styles.online}></div>}
            </div>
            <div className={styles.details}>
              {item.group_info ? (
                <div
                  className={styles.group}
                  style={{ background: groupColor(item.group_info.id) }}
                >
                  {groupName(item.group_info.id)}
                </div>
              ) : (
                <div className={styles.group}>{t("visitors")}</div>
              )}
              <div className={styles.name}>
                {isPersons
                  ? item.person_info
                    ? `${item.person_info.name} ${item.person_info.last_name}`
                    : "No name"
                  : `id: ${item.guid}`}
              </div>
              <div className={styles.about}>
                {item.person_info?.description}
              </div>
              <div className={styles.foot}>
                {!isPersons && (
                  <>
                    <div className={styles.option}>
                      <Icon
                        className={
                          item.gender && item.gender === 1
                            ? styles.male
                            : styles.female
                        }
                        name={
                          item.gender && item.gender === 1 ? "male" : "female"
                        }
                      />{" "}
                      {t(item.gender === 1 ? "male" : "female")}
                    </div>
                    <div className={styles.option}>
                      <Icon name="calendar" /> {ageRange(item.age)}
                    </div>
                  </>
                )}
                <div className={styles.option}>
                  <Icon name="notifications" /> {item.events_count}
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default translate()(GridView);
