import React, { useState, useEffect } from "react";
import { translate } from "react-i18next";
import Page from "../Page";
import Sidebar from "./Sidebar";

const Layout = ({ t, header, children }) => {
  const [visibleSidebar, setVisibleSidebar] = useState(
    localStorage.getItem("hideSidebarFaces") !== "true"
  );

  useEffect(() => {
    localStorage.setItem("hideSidebarFaces", !visibleSidebar);
  }, [visibleSidebar]);

  return (
    <Page
      title={t("all-persons")}
      visibleSidebar={visibleSidebar}
      toggleSidebar={() => setVisibleSidebar(!visibleSidebar)}
      sidebar={<Sidebar />}
      header={header}
      adding="/faces/add-person"
    >
      {children}
    </Page>
  );
};

export default translate()(Layout);
