import React from "react";
import { translate } from "react-i18next";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
// import Icon from "../Icon";
import Switch from "../Switch";
import ModalLauncher from "../ModalLauncher";
import Confirm from "../Confirm";
// import { httpProxyUrl } from "../../links";

const renderBitrate = (value, label) => {
  const intValue = parseInt(value, 10);
  return Math.round(intValue / 1024) + " " + label;
};

export class UrlInput extends React.Component {
  state = {
    protocol: "",
    host: "",
    uri: "",
  };

  componentDidMount() {
    this.setUrlParts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) this.setUrlParts();
  }

  render() {
    const { protocol, host } = this.state;
    const url = `${protocol}://${host}`;
    return (
      <div className="field">
        <input className="field__input" type="text" value={url} />
      </div>
    );
  }

  setUrlParts = () => {
    const { url } = this.props;
    const splitProtocol = url.split("://");
    const splitHost = splitProtocol[1].split("/");

    this.setState({
      url,
      protocol: splitProtocol[0],
      host: splitHost[0],
      uri: splitHost.slice(1).join("/") || "",
    });
  };

  buildURL = (uri) => {
    const { protocol, host } = this.state;
    return `${protocol}://${host}/${uri}`;
  };

  onChange = (e) => this.props.onChange(this.buildURL(e.target.value));
}

class CamerasSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.camera.active,
      stream_url: props.camera.stream_url,
      title: props.camera.title,
      loading: false,
      redirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.camera.id !== this.props.camera.id) {
      this.setState({
        active: this.props.camera.active,
        stream_url: this.props.camera.stream_url,
        title: this.props.camera.title,
        loading: false,
        redirect: false,
      });
    }
  }

  handleSaveButtonClick = () => {
    this.setState({ loading: true });
    this.props
      .onSave({
        ...this.props.camera,
        ...{
          active: this.state.active,
          stream_url: this.state.stream_url,
          title: this.state.title,
        },
      })
      .then(() => {
        this.setState({ loading: false });
        toast.success(this.props.t(`settings-saved-successfully`));
      })
      .catch((err) => console.log(err));
  };

  handleActiveClick = () => {
    this.setState({ loading: true });
    this.props
      .onSave({
        ...this.props.camera,
        active: !this.state.active,
      })
      .then(() => {
        this.setState({ loading: false, active: !this.state.active });
      })
      .catch((err) => console.log(err));
  };

  handleRemoveButtonClick = () => {
    this.setState({ loading: true });
    this.props.onRemove().then(() => {
      this.setState({ redirect: true });
    });
  };

  renderCodec = () =>
    this.props.camera.video_codec.includes("H.264") ? "H.264" : "MJpeg";

  renderNeuroboxTitle = () => {
    const { neurobox } = this.props;
    return `Neurobox ${neurobox}`;
  };

  render() {
    const { camera, isPermsUpdate, isPermsDelete, t } = this.props;

    if (!camera.id || this.state.redirect) {
      return <Redirect to="/cameras" />;
    }

    return (
      <div className="settings">
        <div className="settings__section">
          <div className="settings__body">
            <div className="settings__actions">
              <Switch
                value={this.state.active}
                onChange={() => this.handleActiveClick()}
                content={t("cameras-settings-camera-on")}
                toggler
                disabled={!isPermsUpdate}
              />
              {isPermsUpdate && isPermsDelete && (
                <ModalLauncher
                  className="settings__action"
                  modalClassName="modal_md"
                  buttonClassName="action_sm"
                  action
                  icon="trash"
                >
                  <Confirm
                    title={
                      t("cameras-settings-camera-removing") +
                      ` «${camera.title}»`
                    }
                    button={t("cameras-settings-camera-remove")}
                    onClick={this.handleRemoveButtonClick}
                  >
                    <p>{t("cameras-settings-camera-remove-msg")}</p>
                  </Confirm>
                </ModalLauncher>
              )}
            </div>
          </div>
        </div>
        <div className="settings__list">
          <div className="settings__item">
            <span className="settings__param">{t("video-codec")}</span>
            <span className="settings__value">
              {this.props.camera.video_codec && this.renderCodec()}
            </span>
          </div>
          <div className="settings__item">
            <span className="settings__param">{t("bitrate")}</span>
            <span className="settings__value">
              {camera.bitrate &&
                renderBitrate(camera.bitrate, t("bitrate-sec"))}
            </span>
          </div>
          <div className="settings__item">
            <span className="settings__param">{t("resolution")}</span>
            <span className="settings__value">{camera.resolution}</span>
          </div>
          <div className="settings__item">
            <span className="settings__param">{t("framerate")}</span>
            <span className="settings__value">
              {camera.frame_rate && camera.frame_rate + " " + t("frames-sec")}
            </span>
          </div>
        </div>
        {camera.neurobox_id && (
          <div className="settings__section">
            <div className="settings__title">
              {t("cameras-settings-connected-neurobox")}
            </div>
            <div className="settings__body">
              <div className="settings__name">{this.renderNeuroboxTitle()}</div>
            </div>
          </div>
        )}
        <div className="settings__section">
          <div className="settings__title">{t("cameras-settings-basic")}</div>
          <div className="settings__body">
            <div className="settings__field field">
              <div className="field__label">{t("camera-title")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  value={this.state.title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  disabled={!isPermsUpdate}
                />
              </div>
            </div>
            <div className="settings__field field">
              <div className="field__label">
                {t("cameras-settings-link-video-stream")}
              </div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  value={this.state.stream_url}
                  onChange={(e) =>
                    this.setState({ stream_url: e.target.value })
                  }
                  disabled={!isPermsUpdate}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {camera.neurobox_id && (
          <div className="settings__section">
            <div className="settings__title">{t("additionally")}</div>
            <div className="settings__body">
              <div className="settings__content">
                {t("cameras-settings-additionally-text")}
              </div>
              {isPermsUpdate && (
                <a
                  href={httpProxyUrl(camera.id)}
                  className="settings__link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("cameras-settings-open-web-interface")}
                </a>
              )}
            </div>
          </div>
        )} */}
        {isPermsUpdate && (
          <div className="settings__foot">
            <button
              disabled={this.state.loading}
              className="settings__btn btn"
              onClick={this.handleSaveButtonClick}
            >
              {t("save-settings")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default translate()(CamerasSettings);
