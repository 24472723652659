import React, { useState } from "react";
import cn from "classnames";
import styles from "./Filter.module.sass";

const Filter = ({ title, value, children }) => {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.filter}>
      <div
        className={cn(styles.head, { [styles.active]: active })}
        onClick={() => setActive(!active)}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{value}</div>
      </div>
      {active && <div>{children}</div>}
    </div>
  );
};

export default Filter;
