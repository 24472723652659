import React, { useContext } from "react";
import { translate } from "react-i18next";
import { DeviceContext } from "../contexts/DeviceContext";
import Icon from "./Icon";

export const Export = translate()(({ t, onClick, onClose }) => (
  <div className="export">
    <div className="export__col">
      <div className="export__title">{t("export-archive")}</div>
      <div className="export__description">{t("export-archive-text")}</div>
    </div>
    <div className="export__col">
      <button className="btn" onClick={onClick}>
        {t("download")}
      </button>
      <button className="export__action action action_white" onClick={onClose}>
        <Icon name="close-sm" />
      </button>
    </div>
  </div>
));

export const ExportButton = translate()(({ t, onClick }) => {
  // FIXME: This is a temporary solution. Move condition to top component
  const { isMobile } = useContext(DeviceContext);
  if (isMobile) return null;

  return (
    <button className="action action_white action_export" onClick={onClick}>
      <Icon name="export" />
      <span className="action__label">{t("export-archive")}</span>
    </button>
  );
});
