import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import { diffDuration, diffTime, NumberToHours } from "./utils";

class ReportsStats extends React.Component {
  render() {
    const { t, timeTracking, singleDay } = this.props;

    const current = timeTracking && timeTracking.current;
    const prev = timeTracking && timeTracking.prev;

    const empty = "----";
    const stats = [
      {
        value: current.average_in ? current.average_in : empty,
        minus: prev.persons && diffTime(t, current, prev, "average_in", true),
        change: prev.persons && diffTime(t, current, prev, "average_in"),
        content: t(singleDay ? "time-start-work" : "avg-time-start-work"),
      },
      {
        value: current.average_out ? current.average_out : empty,
        minus: prev.persons && diffTime(t, current, prev, "average_out", true),
        change: prev.persons && diffTime(t, current, prev, "average_out"),
        content: t(singleDay ? "time-end-work" : "avg-time-end-work"),
      },
      {
        value:
          current.average_duration > 0
            ? NumberToHours(t, current.average_duration)
            : empty,
        minus:
          prev.persons &&
          diffDuration(t, current, prev, "average_duration", true),
        change:
          prev.persons && diffDuration(t, current, prev, "average_duration"),
        content: t(singleDay ? "time-on-work" : "avg-time-on-work"),
      },
      {
        value: current.persons.length,
        content: t("employees-plural"),
      },
    ];

    return (
      <div className="stats">
        <div className="stats__container">
          {stats.map((x, index) => (
            <div className="stats__cell" key={index}>
              <div className="stats__group">
                <div className="stats__value">{x.value}</div>
                {x.change && (
                  <div
                    className={cn("stats__change change", {
                      red: x.minus,
                      revert: index === 0,
                    })}
                  >
                    {x.change}
                  </div>
                )}
              </div>
              <div className="stats__content">{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default translate()(ReportsStats);
