import React from "react";
import styles from "./PopupConfirm.module.sass";
import Button from "../Button";
import Popup from "../Popup";

const PopupConfirm = ({ title, content, buttonTitle, onClick, onClose }) => {
  const handleClick = () => {
    onClick();
    onClose();
  };

  return (
    <Popup onClose={onClose}>
      <div className={styles.confirm}>
        <div className="title">{title}</div>
        <div className={styles.content}>{content}</div>
        <div className={styles.foot}>
          <Button onClick={handleClick} title={buttonTitle} large />
        </div>
      </div>
    </Popup>
  );
};

export default PopupConfirm;
