import React from "react";
import { BASE_WS_URL } from "../http";
import { EventsCountsNew, EventsUpdate } from "./events";
import { CameraInfo } from "./camera";
import { CamerasList } from "./cameras";
import { EventNotification } from "../components/Notification";

import { toast } from "react-toastify";
import { windowActive } from "../utils";

const reconnectInterval = 5 * 1000;

export const NOTIFICATION_CONNECT = "NOTIFICATION_CONNECT";
export const NOTIFICATION_DISCONNECT = "NOTIFICATION_DISCONNECT";
export const NOTIFICATION_MESSAGE = "NOTIFICATION_MESSAGE";
export const NOTIFICATION_MARKER = "NOTIFICATION_MARKER";
export const NOTIFICATION_EVENT = "NOTIFICATION_EVENT";
export const NOTIFICATION_CAMERA_UPDATE = "NOTIFICATION_CAMERA_UPDATE";

const createMiddleware = () => {
  let socket;
  let reconnect = false;

  const connect = (store) => {
    if (socket) {
      socket.close();
    }
    reconnect = true;

    const { dispatch, getState } = store;
    const { token } = getState().users;

    socket = new WebSocket(`${BASE_WS_URL}/notifications/?token=${token}`);

    socket.onmessage = (message) => {
      try {
        const notification = JSON.parse(message.data);
        switch (notification.type) {
          case "EVENT":
            return dispatch({
              type: NOTIFICATION_EVENT,
              payload: notification.Event,
	      scheduled: notification.Scheduled,
            });
          case "CAMERA_UPDATE":
            return dispatch({
              type: NOTIFICATION_CAMERA_UPDATE,
              payload: notification.ID,
            });
          case "MARKER": {
            return dispatch({
              type: NOTIFICATION_MARKER,
              payload: notification.marker,
            });
          }
          default:
            return dispatch({ type: NOTIFICATION_MESSAGE, payload: message });
        }
      } catch (e) {
        console.log("parse notification failed", e);
      }
    };

    socket.onopen = () => {};

    socket.onclose = () => {
      if (reconnect) {
        setTimeout(() => connect(store), reconnectInterval);
      }
    };

    socket.onerror = (err) => {
      console.log("websocket error ", err);
      socket.close();
    };
  };

  const disconnect = () => {
    reconnect = false;
    if (socket) {
      socket.close();
    }
  };

  return (store) => (next) => (action) => {
    const { dispatch, getState } = store;

    switch (action.type) {
      case NOTIFICATION_CONNECT:
        connect(store);
        break;
      case NOTIFICATION_DISCONNECT:
        disconnect();
        break;
      case NOTIFICATION_MESSAGE:
        console.log("message: ", action.payload);
        break;
      case NOTIFICATION_MARKER:
        // dispatch(AddMarker(action.payload))
        break;
      case NOTIFICATION_EVENT:
        dispatch(EventsCountsNew());
        dispatch(EventsUpdate(action.payload));

        if (
          //    windowActive() &&
          action.scheduled &&
          getState().camera.camera.Camera.id !== action.payload.CameraID
        ) {
          toast(<EventNotification event={action.payload} />, {
            className: "Toastify__toast--event",
            closeOnClick: true,
          });
        }
        break;

      case NOTIFICATION_CAMERA_UPDATE:
        const { camera } = getState().camera;
        if (camera.Camera.ID === action.payload) {
          dispatch(CameraInfo(action.payload));
        }
        dispatch(CamerasList());
        break;
      default:
        next(action);
    }
  };
};

export default createMiddleware();
