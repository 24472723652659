import React from "react";

class Confirm extends React.Component {
  render() {
    const { title, children, button, onClick, closeModal } = this.props;
    return (
      <div className="confirm">
        <div className="confirm__title title">{title}</div>
        <div className="confirm__container">{children}</div>
        <div className="confirm__foot">
          <button
            className="btn btn_lg"
            onClick={() => {
              closeModal();
              onClick();
            }}
          >
            {button}
          </button>
        </div>
      </div>
    );
  }
}

export default Confirm;
