import React from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";
import Loader from "../Loader";
import ReportsEmpty from "./ReportsEmpty";

class ReportsGrid extends React.Component {
  render() {
    const {
      t,
      isPermsCreate,
      isPermsVisitorAnalysis,
      isPermsTimeTracking,
      type,
      places,
      placesFetched,
      filters,
      children,
    } = this.props;
    const url =
      type === 1 ? "/reports/add-place" : "/reports/time-tracking/add-place";

    return (
      <div className="layout__container layout__container_reports">
        <div className="layout__head">
          <div className="layout__tabs">
            {isPermsVisitorAnalysis && (
              <NavLink
                className="layout__tab"
                activeClassName="active"
                to={filters.place ? `/reports/${filters.place}` : `/reports`}
                exact
              >
                <div className="layout__icon">
                  <Icon name="group" />
                </div>
                <div className="layout__subtitle">{t("visitor-analysis")}</div>
              </NavLink>
            )}
            {isPermsTimeTracking && (
              <NavLink
                className="layout__tab"
                activeClassName="active"
                to={
                  filters.placeTimeTracking
                    ? `/reports/time-tracking/${filters.placeTimeTracking}`
                    : `/reports/time-tracking`
                }
              >
                <div className="layout__icon">
                  <Icon name="time" />
                </div>
                <div className="layout__subtitle">{t("time-tracking")}</div>
              </NavLink>
            )}
          </div>
          <div className="layout__right">
            {isPermsCreate && (
              <Link to={url} className="layout__btn btn btn_lg btn_new">
                <Icon className="btn__icon" name="plus" />
                <span className="btn__label">{t("place")}</span>
              </Link>
            )}
          </div>
        </div>
        <div className="layout__scroll">
          {placesFetched ? (
            places.filter((x) => x.type === type).length > 0 ? (
              children
            ) : (
              <ReportsEmpty type={type} />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

export default translate()(withRouter(ReportsGrid));
