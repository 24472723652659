import React from "react";

export function withTabVisibility(WrappedComponent) {
  return class extends React.Component {
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange",
    };

    wrapped = React.createRef();

    constructor(props) {
      super(props);

      for (let key in this.keys) {
        if (key in document) {
          this.eventKey = key;
          this.eventName = this.keys[key];
          break;
        }
      }
    }

    componentDidMount() {
      if (this.eventName) {
        document.addEventListener(this.eventName, this.onChangedVisibility);
      }
    }

    componentWillUnmount() {
      document.removeEventListener(this.eventName, this.onChangedVisibility);
    }

    onChangedVisibility = (e) => {
      const isVisible = !e.target[this.eventKey];
      if (typeof this.wrapped.current.onTabVisibleChange === "function") {
        this.wrapped.current.onTabVisibleChange(isVisible);
      }
    };

    render() {
      return <WrappedComponent ref={this.wrapped} {...this.props} />;
    }
  };
}
