import React from "react";

const Policy = props => {
  return (
    <div className="pages__article">
      <h1>Политика конфиденциальности</h1>
      <p>
        Настоящий документ «Политика конфиденциальности» (далее по тексту –
        «Политика») представляет собой правила использования обществом с
        ограниченной ответственностью «Нейрокэм» (далее – «Правообладатель»)
        персональной информации Пользователя.
      </p>
      <h3>1. Общие положения</h3>
      <p>
        1.1. Настоящая Политика является неотъемлемой частью Пользовательского
        соглашения (далее – «Соглашение»), размещенного и/или доступного в сети
        Интернет по адресу: https://neurocam.com/pages/policy, а также иных
        заключаемых с Пользователем договоров, когда это прямо предусмотрено их
        условиями.
      </p>
      <p>
        1.2. Заключая Соглашение вы свободно, своей волей и в своих интересах
        даете письменное согласие на следующие способы обработки Персональной
        информации: запись, систематизация, хранение, уточнение (обновление,
        изменение), извлечение, использование, передача (предоставление, доступ)
        третьим лицам, в том числе трансграничная передача на территорию
        иностранных государств, обезличивание, блокирование, удаление,
        уничтожение в установленных настоящей Политикой целях с использованием
        средств автоматизации или без использования таких средств по усмотрению
        Правообладателя.
      </p>
      <p>
        1.3. К настоящей Политике, включая толкование ее положений и порядок
        принятия, исполнения, изменения и прекращения, подлежит применению
        законодательство Российской Федерации.
      </p>
      <p>
        1.4. Здесь и далее в Политике используются термины и определения,
        предусмотренные Соглашением, а также иными заключаемыми с Пользователем
        договорами, если иное не предусмотрено настоящей Политикой или не
        вытекает из ее существа. В иных случаях толкование применяемого в
        Политике термина производится в соответствии с действующим
        законодательством Российской Федерации, обычаями делового оборота, или
        научной доктриной.
      </p>
      <h3>2. Персональная информация</h3>
      <p>2.1. Под персональной информацией в настоящей Политике понимается:</p>
      <p>
        2.1.1. Информация, которую Пользователь предоставляет о себе
        самостоятельно при регистрации или авторизации, а также в процессе
        дальнейшего использования Сервиса, включая персональные данные
        Пользователя.
      </p>
      <p>
        2.1.2. Данные, которые передаются в автоматическом режиме в зависимости
        от настроек программного обеспечения Пользователя в обезличенном виде.
      </p>
      <p>
        2.2. Правообладатель вправе устанавливать требования к составу
        Персональной информации Пользователя, которая должна обязательно
        предоставляться для использования Сервиса. Если определенная информация
        не помечена Правообладателем как обязательная, ее предоставление или
        раскрытие осуществляется Пользователем на свое усмотрение.
      </p>
      <p>
        2.3. При регистрации Пользователем обязательно указываются фамилия, имя,
        адрес электронной почты, пароль.
      </p>
      <p>
        Дополнительно с целью заполнения профиля в Сервисе Пользователь может
        предоставить следующие данные: абонентский номер телефона, часовой пояс.
      </p>
      <p>
        Пользователь, действующий от имени юридического лица или индивидуального
        предпринимателя, также предоставляет информацию о представляемом
        юридическом лице: наименование, адрес, ИНН, банковские реквизиты,
        рабочий номер телефона. Сведения об организации Пользователя не
        относятся к персональным данным.
      </p>
      <p>
        При направлении Пользователем обращения Правообладателю через форму
        обратной связи обязательно указываются: имя, фамилия, адрес электронной
        почты.
      </p>
      <p>
        2.4. Правообладатель не осуществляет проверку достоверности
        предоставляемой Персональной информации и наличия у Пользователя
        необходимого согласия на ее обработку в соответствии с настоящей
        Политикой, полагая, что Пользователь действует добросовестно,
        осмотрительно и прилагает все необходимые усилия к поддержанию такой
        информации в актуальном состоянии и получению всех необходимых согласий
        субъектов персональных данных.
      </p>
      <p>
        2.5. Пользователь осознает и принимает возможность использования в
        Приложении Правообладателя программного обеспечения третьих лиц, в
        результате чего такие лица могут получать и передавать указанные в
        п.2.1.2 данные в обезличенном виде.
      </p>
      <p>
        К указанному программному обеспечению третьих лиц относятся системы
        сбора аналитических данных Google Analytics, Яндекс.Метрика, Facebook
        Pixel, VK Pixel.
      </p>
      <p>
        Состав и условия сбора обезличенных данных с использованием программного
        обеспечения третьих лиц определяются непосредственно их
        правообладателями и могут включать:
      </p>
      <ul>
        <li>данные браузера (тип, версия, cookie);</li>
        <li>данные устройства и место его положения;</li>
        <li>данные операционной системы (тип, версия, разрешение экрана);</li>
        <li>данные запроса (время, источник перехода, IP-адрес).</li>
      </ul>
      <p>
        2.6. Правообладатель не несет ответственность за порядок использования
        Персональной информации Пользователя третьими лицами, с которыми
        Пользователь взаимодействует в рамках использования Сервиса.{" "}
      </p>
      <h3>3. Цели обработки Персональной информации </h3>
      <p>
        3.1. Правообладатель осуществляет обработку, в том числе сбор и хранение
        только той Персональной информации, которая необходима для заключения и
        исполнения договоров с Пользователями.
      </p>
      <p>
        3.2. Правообладатель вправе использовать Персональную информацию в
        следующих целях:
      </p>
      <p>
        3.2.1. Заключения Соглашения на использование Сервиса, а равно
        заключения иных договоров (сделок) с использованием Сервиса.
      </p>
      <p>
        Заключение Соглашения и иных договоров, оформление заказов на оказание
        информационных услуг осуществляются в дистанционном порядке посредством
        обмена электронными документами, которые подписываются простой
        электронной подписью. При этом роль ключа простой электронной подписи
        Пользователя могут выполнять пара логин (адрес электронной почты) –
        пароль. Поэтому они запрашиваются при регистрации.
      </p>
      <p>
        3.2.2. Идентификация Пользователя в рамках исполнения обязательств по
        заключенному с ним Соглашению и иным договорам.
      </p>
      <p>
        Личный кабинет Пользователя связан с адресом электронной почты
        Пользователя.
      </p>
      <p>
        3.2.3 Исполнение обязательств по заключенному Соглашению и иным
        договорам, включая предоставление Пользователю доступа к Сервису и
        технической поддержки.
      </p>
      <p>
        Доступ к функциональным возможностям Сервиса предоставляется с
        использованием программных средств Приложения, в т.ч. Личного кабинета.
        Обращения в службу поддержки принимаются через формы в Приложении или с
        использованием электронной почты Пользователя.
      </p>
      <p>
        При исполнении договоров выставляются счета и ведется бухгалтерский
        учет, которые могут содержать данные Пользователя.
      </p>
      <p>
        3.2.4. Обеспечение связи с Пользователем в целях информационного
        обслуживания в рамках Сервиса и улучшения качества Сервиса по
        заключенным Соглашению и иным договорам, в том числе в порядке
        нотификации с привлечением третьих лиц.
      </p>
      <p>
        Связь с Пользователем осуществляется через электронную почту или по
        абонентскому номеру телефона, указанным Пользователем.
      </p>
      <p>
        3.2.5. Обеспечение безопасности и конфиденциальности Персональной
        информации Пользователя.
      </p>
      <p>
        В целях проверки подозрительных действий в Приложении собираются
        информация об используемом Пользователем IP-адресе и браузере.
      </p>
      <p>
        3.2.6. Проведение маркетинговых, статистических и иных исследований на
        основе обезличенных данных в целях улучшения качества предоставляемого
        Сервиса.
      </p>
      <p>
        Данные из систем сбора аналитических данных, указанных в п. 2.5.
        Политики, в обезличенном виде собираются и анализируются, чтобы узнать,
        сколько времени Пользователи проводят в различных раздела Приложения, их
        интересах и предпочтениях.
      </p>
      <p>
        3.2.7. Использование обезличенных данных для таргетинга рекламных и/или
        информационных материалов по возрасту, полу, другим признакам.
      </p>
      <h3>4. Требования к защите Персональной информации </h3>
      <p>
        4.1. Правообладатель осуществляет хранение Персональной информации и
        обеспечивает ее охрану от несанкционированного доступа и распространения
        в соответствии с внутренними правилами и регламентами.{" "}
      </p>
      <p>
        4.2. В отношении Персональной информации Пользователя сохраняется ее
        конфиденциальность, за исключением случаев, когда технология
        предоставляемого Сервиса или настройки используемого Пользователем
        программного обеспечения предусматривают открытый обмен информацией с
        иными участниками и пользователями сети Интернет.
      </p>
      <p>
        4.3. В целях повышения качества Сервиса, Правообладатель вправе хранить
        лог-файлы о действиях, совершенных Пользователем в рамках использования
        Сервиса, а также в связи с заключением и исполнением Пользователем
        Соглашения и иных договоров в течение 1 (Одного) года.
      </p>
      <h3>5. Передача информации</h3>
      <p>
        5.1. Правообладатель вправе передать Персональную информацию третьим
        лицам в следующих случаях:
      </p>
      <ul>
        <li>
          Пользователь выразил свое согласие на такие действия, включая случаи
          применения Пользователем настроек используемого программного
          обеспечения, не ограничивающих предоставление определенной информации;
        </li>
        <li>
          Передача необходима в рамках использования Пользователем
          функциональных возможностей Сервиса по заключенному Соглашению или
          договору.
        </li>
        <li>
          Привлечение третьих лиц для оказания Правообладателю услуг по
          обработке информации в рамках установленных настоящей Политикой целей
          использования Персональной информации (аналитическим сервисам,
          сервисам рассылок и т.д.).
        </li>
        <li>
          В связи с передачей Приложения Правообладателя во владение,
          пользование или собственность такого третьего лица, или уступкой прав
          по заключенным с Пользователем договорам в пользу третьего лица;
        </li>
        <li>
          По запросу суда или иного уполномоченного государственного органа в
          рамках установленной законодательством процедуры;
        </li>
        <li>
          Для защиты прав и законных интересов Правообладателя в связи с
          нарушением заключенных с Пользователем Договоров.
        </li>
      </ul>
      <h3>6. Изменение и удаление Персональной информации </h3>
      <p>
        6.1. Пользователь вправе в любое время самостоятельно отредактировать в
        своем Личном кабинете предоставленную им при регистрации или авторизации
        Персональную информацию, за исключением адреса электронной почты.{" "}
      </p>
      <p>
        6.2. В случае прекращения заключенного Соглашения или иного договора,
        Пользователь вправе отозвать согласие на обработку его персональных
        данных путем направления Правообладателю запроса на удаление своей
        учетной записи по адресу, указанному в настоящей Политике.
      </p>
      <h3>7. Изменение Политики конфиденциальности</h3>
      <p>
        7.1. Настоящая Политика может быть изменена или прекращена
        Правообладателем в одностороннем порядке без предварительного
        уведомления Пользователя. Новая редакция Политики вступает в силу с
        момента ее размещения на Сайте Правообладателя, если иное не
        предусмотрено новой редакцией Политики.
      </p>
      <p>
        7.2. Действующая редакция Политики находится на Сайте Правообладателя в
        сети Интернет по адресу{" "}
        <a href="https://neurocam.com/pages/policy">
          https://neurocam.com/pages/policy
        </a>
      </p>
      <br />
      <p>Реквизиты Правообладателя:</p>
      <p>
        Общество с ограниченной ответственностью «Нейрокэм»;
        <br />
        ОГРН: 1177746595960;
        <br />
        ИНН: 77044255108;
        <br />
        Юридический адрес: 119270, г. Москва, Лужнецкая наб, дом 2/4, строение
        17, офис 305;
      </p>
      <p>
        e-mail: <a href="mailto:info@neurocam.com">info@neurocam.com</a>
      </p>
      <p>Действующая редакция Политики от 20.01.2019 г.</p>
    </div>
  );
};

export default Policy;
