import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon";
import PreparingToConnect from "./PreparingToConnect";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePrepareInfo: false
    };
  }

  renderTab = () => {
    const tab = this.props.tabs.find(t => t.state === this.props.state);
    return tab.component;
  };

  renderPrepareInfo = () => {
    return (
      <PreparingToConnect
        type={this.props.prepareInfo}
        onContinue={() => this.setState({ hidePrepareInfo: true })}
      />
    );
  };

  renderWizard = () => {
    const { tabs, state, error } = this.props;

    return (
      <div className="tabs">
        <div className="tabs__nav">
          {tabs.map((tab, idx) => {
            return (
              <div
                key={idx}
                className={`tabs__btn ${state === tab.state && "active"}`}
              >
                <div className="tabs__counter">{idx + 1}</div>
                <div className="tabs__title">{tab.title}</div>
              </div>
            );
          })}
        </div>

        {/* TODO improve error display */}
        {error && <p>{`scan error: ${error}`}</p>}

        <div className="tabs__container">
          <div className="tabs__item">{this.renderTab()}</div>
        </div>
      </div>
    );
  };

  render() {
    const { title, right, prepareInfo } = this.props;
    return (
      <div className="adding">
        <header className="adding__header">
          <div className="adding__title">{title}</div>
          <div className="adding__right">{right}</div>
          <Link className="adding__close action" to="/">
            <Icon name="close-sm" />
          </Link>
        </header>
        <div className="adding__body">
          {prepareInfo && !this.state.hidePrepareInfo
            ? this.renderPrepareInfo()
            : this.renderWizard()}
        </div>
        <ReactTooltip ref="tooltip" />
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      state: PropTypes.number.isRequired,
      component: PropTypes.object.isRequired
    })
  ),
  state: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  right: PropTypes.object,
  help: PropTypes.object,
  error: PropTypes.string
};

export default translate()(Tabs);
