import React from "react";
import { translate } from "react-i18next";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      Current: "",
      Password: "",
      Confirm: ""
    };
  }

  onClickSave = () => {
    this.setState({ errors: null });
    this.props
      .onSave(this.state.Current, this.state.Password, this.state.Confirm)
      .then(() => {
        this.props.onComplete(true);
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          this.setState({ errors: err.response.data });
        } else {
          console.log(err);
          this.props.onComplete(false);
        }
      });
  };

  render() {
    const { errors, Current, Password, Confirm } = this.state;
    const { t } = this.props;

    return (
      <div className="password">
        <div className="password__title title">{t("change-password")}</div>
        <div className="password__field field">
          <div className="field__label">{t("change-password-old")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="password"
              value={Current}
              onChange={e => this.setState({ Current: e.target.value })}
            />
            {errors &&
              errors.Current && (
                <div className="field__error">{t("change-password-wrong")}</div>
              )}
          </div>
        </div>
        <div className="password__field field">
          <div className="field__label">{t("change-password-new")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="password"
              value={Password}
              onChange={e => this.setState({ Password: e.target.value })}
            />
          </div>
        </div>
        <div className="password__field field">
          <div className="field__label">{t("change-password-new-repeat")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="password"
              value={Confirm}
              onChange={e => this.setState({ Confirm: e.target.value })}
            />
            {errors &&
              errors.Confirm && (
                <div className="field__error">
                  {t("change-password-passwords-no-match")}
                </div>
              )}
          </div>
        </div>
        <div className="password__foot">
          <button className="btn btn_lg" onClick={this.onClickSave}>
            {t("change-password")}
          </button>
        </div>
      </div>
    );
  }
}

export default translate()(ChangePassword);
