import React from "react";
import * as cn from "classnames";
import { translate } from "react-i18next";
import onClickOutside from "react-onclickoutside";

import Switch from "./Switch";
import Icon from "./Icon";

const contentByType = type => {
  switch (type) {
    case "people":
      return "man-in-frame";
    case "car":
      return "car-in-frame";
    case "person":
      return "face-in-frame";
    default:
      return "";
  }
};

// item.object === "people" ? t("man-in-frame") : t("car-in-frame");

//TODO: Convert component to uncontrolled (remove state usage)
class Dropdown extends React.Component {
  state = {
    opened: false,
    checked: {}
  };

  static getDerivedStateFromProps(props, state) {
    const selected = props.selected || [];

    return {
      ...state,
      // Auto check elements passed from parent component on initial creation
      checked: {
        ...selected.reduce((all, item) => ({ ...all, [item]: true }), {}),
        ...state.checked
      }
    };
  }

  onCheck = (object, value) => {
    const checked = { ...this.state.checked, [object]: value };

    this.setState({ checked }, _ => {
      this.props.onChange &&
        this.props.onChange(
          Object.keys(checked)
            .filter(key => checked[key])
            .map(key => key)
        );
    });
  };

  render() {
    const { t, field, placeholder, className, adding } = this.props;
    return (
      <div
        className={cn("dropdown", { dropdown_field: field }, className, {
          open: this.state.opened
        })}
      >
        <div className="dropdown__wrap">
          <div
            className={cn("dropdown__head", {
              dropdown__head_placeholder: placeholder
            })}
            onClick={this.toggleDropdown}
          >
            {this.props.title}
          </div>
          <div className="dropdown__body">
            {this.props.checkboxes.map((item, index) => (
              <Switch
                key={index}
                className="switch_dropdown"
                content={t(contentByType(item.object))}
                value={!!this.state.checked[item.object]}
                onChange={e => this.onCheck(item.object, e.target.checked)}
              />
            ))}
            {adding && (
              <div className="dropdown__option dropdown__option_add">
                <Icon name="plus" />
                <div className="dropdown__label">{t("create-new-list")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  toggleDropdown = _ => {
    this.setState({ opened: !this.state.opened });
  };

  handleClickOutside = evt => {
    this.setState({ opened: false });
  };
}

export default translate()(onClickOutside(Dropdown));
