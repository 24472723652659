import React, { useState, useEffect } from "react";
import { translate } from "react-i18next";
import Slider from "react-slider";
import styles from "./Visits.module.sass";
import Filter from "../../../Filter";
import Field from "../../../Field";
import Button from "../../../Button";

const Visits = ({ t, filters, setFilter, events }) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  useEffect(() => {
    setFrom(filters.events_count ? filters.events_count[0] : events?.min || "");
    setTo(filters.events_count ? filters.events_count[1] : events?.max || "");
    setMin(events?.min || "");
    setMax(events?.max || "");
  }, [filters.events_count, events]);

  return (
    <Filter
      className={styles.places}
      title={t("number-of-visits")}
      value={t("select-all")}
    >
      <div className={styles.visits}>
        <Slider
          className={styles.slider}
          thumbClassName={styles.thumb}
          trackClassName={styles.track}
          min={min}
          max={max}
          defaultValue={[from, to]}
          value={[from, to]}
          onChange={(state) => {
            setFrom(state[0]);
            setTo(state[1]);
          }}
          pearling
        />
        <div className={styles.fields}>
          <Field
            className={styles.field}
            label={t("min")}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
          <Field
            className={styles.field}
            label={t("max")}
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        <div className={styles.foot}>
          <Button
            title={t("apply")}
            onClick={() => setFilter({ events_count: [from, to] })}
          />
          <Button
            title={t("reset")}
            onClick={() => {
              setFrom(min);
              setTo(max);
            }}
            white
          />
        </div>
      </div>
    </Filter>
  );
};

export default translate()(Visits);
