import React, {useEffect, useState} from "react";
import {DeviceContext} from "./DeviceContext";

/**
 * @see https://github.com/juliangruber/is-mobile/blob/master/index.js
 * @type {RegExp}
 */
const mobileRE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

export const DeviceProvider = ({children}) => {

    const [state, setState] = useState({
        isMobile: false
    });

    useEffect(() => {
        let isMobile = mobileRE.test(navigator.userAgent);
        if (isMobile !== state.isMobile)
            setState({isMobile});
    }, [state]);

    return (
        <DeviceContext.Provider value={state}>
            {children}
        </DeviceContext.Provider>
    )
};
