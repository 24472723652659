import React, { useState } from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Select.module.sass";

const Select = ({ t, options, value, onChange, placeholder, action }) => {
  const [visible, setVisible] = useState(false);

  const selectedOption = options.find((x) => x.value === value);

  const handleChange = (value) => {
    onChange(value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.select, { [styles.open]: visible })}>
        <div className={styles.inner}>
          <div
            className={cn(styles.head, { [styles.placeholder]: !value })}
            onClick={() => setVisible(!visible)}
          >
            {selectedOption?.color && (
              <div
                className={styles.color}
                style={{ background: selectedOption?.color }}
              ></div>
            )}
            {selectedOption?.title
              ? selectedOption.title
              : placeholder
              ? placeholder
              : t("not-chosen")}
          </div>
          {visible && (
            <div className={styles.dropdown}>
              {options.map((x, index) => (
                <div
                  className={styles.option}
                  onClick={() => handleChange(x.value)}
                  key={index}
                >
                  {x.color && (
                    <div
                      className={styles.color}
                      style={{ background: x.color }}
                    ></div>
                  )}
                  {x.title}
                </div>
              ))}
              {action && (
                <Link
                  to={action.url}
                  className={cn(styles.option, styles.action)}
                >
                  {action.title}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default translate()(Select);
