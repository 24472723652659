import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Info.module.sass";
import Icon from "../../../Icon";
import Carousel from "../Carousel";

import { formatVisit } from "../../utils";
import { ageRange } from "../../../../utils";

const Info = ({ t, person }) => {
  const data = person.person_info
    ? [
        {
          title: "statistics",
          items: [
            {
              icon: "first-visit",
              label: "first-visit",
              value: formatVisit(person.first_visit),
            },
            {
              icon: "last-visit",
              label: "last-visit",
              value: formatVisit(person.last_visit),
            },
            {
              icon: "time",
              label: "avg-time",
              value: person.average_visit_duration,
            },
            {
              icon: "notifications",
              label: "events",
              value: person.events_count,
            },
          ],
        },
      ]
    : [
        {
          title: "information",
          items: [
            {
              icon: person.gender === 1 ? "male" : "female",
              label: "gender",
              value: person.gender === 1 ? t("male") : t("female"),
            },
            {
              icon: "calendar",
              label: "age",
              value: ageRange(person.age),
            },
            {
              icon: "notifications",
              label: "events",
              value: person.events_count,
            },
          ],
        },
        {
          title: "statistics",
          items: [
            {
              icon: "first-visit",
              label: "first-visit",
              value: formatVisit(person.first_visit),
            },
            {
              icon: "last-visit",
              label: "last-visit",
              value: formatVisit(person.last_visit),
            },
            {
              icon: "time",
              label: "avg-time",
              value: person.average_visit_duration,
            },
          ],
        },
      ];
  const group = person.group_info;

  return (
    <div className={styles.info}>
      <div className={styles.sidebar}>
        <Carousel person={person} />
      </div>
      <div className={styles.container}>
        {person.person_info ? (
          <div className={styles.details}>
            <div className={styles.head}>
              {group && (
                <div
                  className={styles.group}
                  style={{ background: group.color }}
                >
                  {group.title}
                </div>
              )}
              <div className={styles.id}>id: {person.guid}</div>
            </div>
            <div className={styles.name}>
              {person.person_info?.name} {person.person_info?.last_name}
            </div>
            <div className={styles.description}>
              {person.person_info?.description}
            </div>
          </div>
        ) : (
          <div className={styles.details}>
            <div className={styles.head}>
              <div className={styles.group}>{t("visitors")}</div>
            </div>
            <div className={styles.name}>id: {person.guid}</div>
            <div className={styles.description}></div>
          </div>
        )}
        <div className={styles.data}>
          {data.map((x, i) => (
            <div className={styles.section} key={i}>
              <div className={styles.title}>{t(x.title)}</div>
              <div className={styles.grid}>
                {x.items.map((y, j) => (
                  <div className={styles.cell} key={j}>
                    <div
                      className={cn(styles.preview, {
                        [styles.male]: y.icon === "male",
                        [styles.female]: y.icon === "female",
                      })}
                    >
                      <Icon name={y.icon} />
                    </div>
                    <div className={styles.box}>
                      <div className={styles.label}>{t(y.label)}</div>
                      <div className={styles.value}>
                        {y.value ? y.value : "---"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default translate()(Info);
