import React from "react";
import { Route } from "react-router-dom";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import cn from "classnames";
import Icon from "../components/Icon";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import CamerasNav from "../components/Cameras/CamerasNav";
import CamerasGrid from "../components/Cameras/CamerasGrid";
import CamerasItem from "../components/Cameras/CamerasItem";
import CamerasEmpty from "../components/Cameras/CamerasEmpty";
import { checkPerms } from "../utils";

class Cameras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSearch: false,
      hideSidebarCameras: localStorage.getItem("hideSidebarCameras") === "true",
      searchValue: "",
    };

    this.searchInput = React.createRef();
  }

  toggleSidebar = () => {
    this.setState(
      { hideSidebarCameras: !this.state.hideSidebarCameras },
      () => {
        localStorage.setItem(
          "hideSidebarCameras",
          this.state.hideSidebarCameras
        );
      }
    );
  };

  toggleSearch = () => {
    if (this.state.visibleSearch) {
      this.setState({
        searchValue: "",
      });
    } else {
      this.searchInput.current.focus();
    }

    this.setState({
      visibleSearch: !this.state.visibleSearch,
    });
  };

  handleSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  render() {
    const { t, users } = this.props;
    const { visibleSearch, hideSidebarCameras, searchValue } = this.state;
    const isPermsCreate = checkPerms(users.user, "camera:create");

    if (!this.props.cameras.fetched) {
      return <Loader width="32" />;
    }

    if (!this.props.cameras.items || this.props.cameras.items.length === 0) {
      return <CamerasEmpty />;
    }

    let items = this.props.cameras.items.filter((item) => {
      return item.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });

    return (
      <div className={"layout " + (hideSidebarCameras ? "hide-sidebar" : "")}>
        <div className="layout__sidebar">
          <div
            className={"layout__head " + (visibleSearch ? "show-search" : "")}
          >
            <Logo />
            <button
              className="layout__action action"
              onClick={this.toggleSidebar}
            >
              <Icon name="menu" />
            </button>
            <div className="layout__title">{t("cameras-layout-title")}</div>
            {!hideSidebarCameras && (
              <button
                className={cn("layout__search", "action", {
                  "no-perms": !isPermsCreate,
                })}
                onClick={this.toggleSearch}
              >
                {visibleSearch ? (
                  <Icon name="close-sm" />
                ) : (
                  <Icon name="search" />
                )}
              </button>
            )}
            <div className="layout__field">
              <input
                className="layout__input"
                placeholder={t("enter-camera-name")}
                type="text"
                ref={this.searchInput}
                onChange={this.handleSearch}
              />
            </div>
          </div>
          <div className="layout__scroll">
            <CamerasNav
              items={items}
              usersInfo={this.props.users.usersInfo}
              user={this.props.users.user}
            />
          </div>
        </div>
        <Route
          exact
          path="/cameras"
          render={() => <CamerasGrid items={items} />}
        />
        <Route
          path={`/cameras/:id`}
          render={(props) => (
            <CamerasItem
              key={props.match.params.id}
              id={props.match.params.id}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  cameras: state.cameras,
  rates: state.payments,
});

export default translate()(connect(mapStateToProps)(Cameras));
