import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";

class Help extends React.Component {
  render() {
    const { content, actions, t } = this.props;
    return (
      <div className="help">
        <div className="help__icon">
          <Icon className="help__pic" name="help" />
        </div>
        <div className="help__content">
          <div className="help__text">{content}</div>
          <div className="help__actions">
            {actions.map((action, i) => (
              <Link key={i} to="" className="help__action">
                {action}
              </Link>
            ))}
          </div>
        </div>
        <button className="help__btn btn">{t("hide")}</button>
      </div>
    );
  }
}

export default translate()(Help);
