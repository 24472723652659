import React from "react";
import { translate } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import Icon from "../Icon";

const CamerasNotFound = ({ t, onClickRepeat, onClickNeurobox, type }) => {
  console.log(type);
  return (
    <div className="page page_sm page_adding">
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic" name="camera-lg" />
        </div>
        <div className="page__title">{t("wizard-not-found-scan-complete")}</div>
        <div className="page__text">
          <p>{t("wizard-not-found-cameras")}</p>
          <br />
          <p>{ReactHtmlParser(t("wizard-not-found-cameras-" + type))}</p>
        </div>
        <div className="page__foot">
          <button className="page__btn btn btn_lg" onClick={onClickRepeat}>
            {t("wizard-not-found-repeat")}
          </button>
        </div>
        {/* <div className="page__foot">
          <a href="#/" onClick={onClickNeurobox}>
            {t("wizard-not-found-via-neurobox")}
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default translate()(CamerasNotFound);
