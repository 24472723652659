import HttpClient from "../http";

const initialState = {
  archive: {},
  camera_id: null,
  markers: [],
  fetched: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ARCHIVE:
      return { ...state, archive: action.payload, fetched: true };
    case MARKERS:
      return {
        ...state,
        markers: mergeMarkers(state.markers, action.payload),
        camera_id: action.camera_id,
      };
    case MARKERS_ADD:
      if (action.payload.camera !== state.camera_id) return state;
      return {
        ...state,
        markers: mergeMarkers(state.markers, [action.payload]),
      };
    case MARKERS_CLEAR:
      return { ...state, markers: [] };
    default:
      return state;
  }
};

const ARCHIVE = "ARCHIVE";
export const ArchiveItem = (id, ts1, ts2) => async (dispatch) => {
  const response = await HttpClient.get(
    `/archive/playlist/${id}?ts1=${ts1}&ts2=${ts2}`
  );
  dispatch({ type: ARCHIVE, payload: response.data });
};

const MARKERS = "MARKERS";
const MARKERS_ADD = "MARKERS_ADD";
const MARKERS_CLEAR = "MARKERS_CLEAR";

export const GetCameraMarkers = (id, since, until) => (dispatch, getState) => {
  if (getState().archive.camera_id !== id) dispatch({ type: MARKERS_CLEAR });

  HttpClient.get(
    `/archive/camera/${id}/markers?ts1=${getSeconds(since)}&ts2=${getSeconds(
      until
    )}`
  )
    .then((x) => ({ type: MARKERS, payload: x.data, camera_id: id }))
    .then(dispatch);
};

export const AddMarker = (marker) => ({ type: MARKERS_ADD, payload: marker });
export const clearMarkers = { type: MARKERS_CLEAR };

const isSame = (a, b) => a.since === b.since && a.type === b.type;

const getSeconds = (time) => {
  return Math.floor(new Date(time).getTime() / 1000);
};

const mergeMarkers = (source, another) => {
  return [
    ...another,
    ...source.filter((a) => another.every((b) => !isSame(a, b))),
  ];
};
