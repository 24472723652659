import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import cn from "classnames";
import { wsMJpegStreamUrl, cameraImageUrl } from "../../links";
import Icon from "../Icon";
import ModalLauncher from "../ModalLauncher";
import ConnectCamera from "../ConnectCamera";
import State from "../State";
import Loader from "../Loader";
import { withTabVisibility } from "../TabVisibility";
import { checkPerms } from "../../utils";

const timeout = 90000;

const MJpegCameraImage = ({
  className,
  id,
  play,
  onLoading,
  onError,
  onPlay,
}) => {
  let websocket;
  let isDone;

  const wsURL = wsMJpegStreamUrl(id);
  const imURL = cameraImageUrl(id);

  const startWebsocket = () => {
    if (onLoading) onLoading(true);

    if (websocket) {
      websocket.close();
    }

    websocket = new WebSocket(wsURL);

    const connectionTimeout = setTimeout(() => {
      onError && onError();
      isDone = true;
      websocket.close();
    }, timeout);

    websocket.onmessage = (e) => {
      const target = document.getElementById(`cam-preview-${id}`);
      if (target == null) return;
      const blob = new Blob([e.data], { type: "image/jpeg" });
      const image = window.URL.createObjectURL(blob);
      target.src = image;

      clearTimeout(connectionTimeout);

      if (onLoading) onLoading(false);
      if (onPlay) onPlay();
    };

    websocket.onclose = (e) => {
      if (isDone) return;

      console.error(`mjpeg: lost connection camera ${id}`, e);
      setTimeout(() => startWebsocket(), 2000);
    };
  };

  useEffect(() => {
    if (play) {
      console.log("play true");
      isDone = false;
      startWebsocket();
    } else {
      console.log("play false");
      isDone = true;
      if (websocket) websocket.close();
    }

    return () => {
      isDone = true;
      if (websocket) websocket.close();
    };
  }, [id, play]);

  return (
    <img id={`cam-preview-${id}`} className={className} src={imURL} alt="" />
  );
};

const CameraGridItem = ({ camera, play }) => {
  const { id, active, title } = camera;

  const [error, setError] = useState(false);
  const [online, setOnline] = useState(camera.online);
  const [loading, setLoading] = useState(true);

  const notConnected = (active && online === false) || error;

  return (
    <div
      className={cn("grid__item", {
        disabled: !active || notConnected,
      })}
    >
      <Link className="grid__link" to={`/cameras/${id}`}>
        {!active && <State className="grid__state" type="camera-off" />}
        {notConnected && <State className="grid__state" type="no-connection" />}
        {!active && (
          <img
            id={`cam-preview-${id}`}
            className="grid__pic"
            src={cameraImageUrl(id)}
            alt=""
          />
        )}

        {active && (
          <MJpegCameraImage
            play={play}
            className={cn("grid__pic", loading && "loading")}
            id={id}
            onLoading={(isLoading) => setLoading(isLoading)}
            onError={() => setError(true)}
            onPlay={() => {
              setOnline(true);
              setError(false);
              setLoading(false);
            }}
          />
        )}
      </Link>
      <div className="grid__foot">
        {online ? (
          loading ? (
            <Loader className="grid__loader" width="16" color="#F30027" />
          ) : (
            <div className="grid__online" />
          )
        ) : (
          <div className="grid__offline" />
        )}
        <div className="grid__title">{title}</div>
      </div>
    </div>
  );
};

class CamerasGrid extends React.Component {
  invisibleTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      smallView: localStorage.getItem("camerasSmallView") === "true",
      play: true,
    };
  }

  toggleView = (e) => {
    if (!e.target.classList.contains("active")) {
      this.setState({ smallView: !this.state.smallView }, () => {
        localStorage.setItem("camerasSmallView", this.state.smallView);
      });
    }
  };

  onTabVisibleChange = (visible) => {
    clearTimeout(this.invisibleTimeout);

    if (!visible) {
      this.invisibleTimeout = setTimeout(
        () => this.setState({ play: false }),
        1000
      );
    } else {
      this.setState({ play: true });
    }
  };

  render() {
    const { smallView } = this.state;
    const { t } = this.props;
    return (
      <div className="layout__container layout__container_cameras">
        <div className="layout__head">
          <div className="layout__actions">
            <button
              className={"layout__action action " + (smallView ? "" : "active")}
              onClick={this.toggleView}
            >
              <Icon name="grid" />
            </button>
            <button
              className={"layout__action action " + (smallView ? "active" : "")}
              onClick={this.toggleView}
            >
              <Icon name="grid-sm" />
            </button>
          </div>
          {checkPerms(this.props.users?.user, "camera:create") && (
            <ModalLauncher
              className="layout__right"
              buttonClassName="layout__btn btn_lg btn_add"
              label={t("grid-add-camera")}
              icon="plus"
            >
              <ConnectCamera />
            </ModalLauncher>
          )}
        </div>
        <div className="layout__scroll">
          <div
            className={cn("grid", "grid_cameras", {
              grid_three: smallView,
            })}
          >
            <div className="grid__container">
              {this.props.items.map((item, index) => (
                <CameraGridItem
                  key={index}
                  camera={item}
                  play={this.state.play}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default translate()(
  connect(mapStateToProps)(withTabVisibility(CamerasGrid))
);
