import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withRouter, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import cn from "classnames";
import Icon from "../../Icon";
import Loader from "../../Loader";
import ModalLauncher from "../../ModalLauncher";
import Confirm from "../../Confirm";

import CameraGroups from "./CameraGroups";
// import MonitoringSchedule from "./MonitoringSchedule";
import ExcludeGroups from "./ExcludeGroups";
import TrackingGroups from "./TrackingGroups";

import { CreatePlace, SavePlace, DeletePlace } from "../../../reducers/reports";
import { LoadGroups } from "../../../reducers/faces";
import { checkPerms } from "../../../utils";

const ReportsPlace = ({
  t,
  type,
  history,
  users,
  cameras,
  faceGroups,
  LoadGroups,
  place,
  setFilter,
  CreatePlace,
  SavePlace,
  DeletePlace,
}) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(place.title);
  const [groups, setGroups] = useState(place.groups);
  const [exclude_groups, setExcludeGroups] = useState(place.exclude_groups);
  const [tracking_groups, setTrackingGroups] = useState(place.tracking_groups);

  useEffect(() => {
    LoadGroups();
  }, [LoadGroups]);

  const { id } = place;

  const isButtonDisabled = () =>
    loading ||
    title === "" ||
    !groups[0]?.entrance_camera ||
    (type === 2 && tracking_groups.length === 0);

  const addGroup = () => {
    setGroups((groups) => [
      ...groups,
      { entrance_camera: null, exit_camera: null },
    ]);
  };

  const removeGroup = (index) => {
    setGroups((groups) => groups.filter((item, i) => index !== i));
  };

  const updateGroup = (index, key, id) => {
    const newGroups = [...groups];
    newGroups[index][key] = id;
    setGroups(newGroups);
  };

  const updateExcludeGroups = (checked, id) => {
    checked
      ? setExcludeGroups((exclude_groups) => [...exclude_groups, id])
      : setExcludeGroups((exclude_groups) =>
          exclude_groups.filter((item) => item !== id)
        );
  };

  const clearExcludeGroups = () => {
    setExcludeGroups([]);
  };

  const updateTrackingGroups = (checked, id) => {
    checked
      ? setTrackingGroups((tracking_groups) => [...tracking_groups, id])
      : setTrackingGroups((tracking_groups) =>
          tracking_groups.filter((item) => item !== id)
        );
  };

  const onCreate = async () => {
    setLoading(true);

    try {
      const place = {
        title: title,
        groups: groups,
        exclude_groups: exclude_groups,
        tracking_groups: tracking_groups,
        type: type,
      };

      const result = await CreatePlace(place);

      switch (result) {
        case 206:
          toast.success(t(`person-create-success-partial`));
          break;
        case 204:
          toast.error(t(`person-create-error-faces-not-found`));
          break;
        default:
          toast.success(t(`place-create-success`));
      }
    } catch (e) {
      console.log(e);
      toast.error(
        t(
          e.response.status === 409
            ? "person-create-error-snapped-list"
            : "place-create-error"
        )
      );
    } finally {
      setLoading(false);
      type === 1
        ? history.push(`/reports`)
        : history.push(`/reports/time-tracking`);
    }
  };

  const onSave = async () => {
    setLoading(true);

    try {
      await SavePlace({
        id: id,
        title: title,
        groups: groups,
        exclude_groups: exclude_groups,
        tracking_groups: tracking_groups,
        type: type,
      });
      toast.success(t(`data-update-success`));
    } catch (e) {
      console.log(e);
      toast.error(t(`data-update-error`));
    } finally {
      setLoading(false);
      type === 1
        ? history.push(`/reports/${id}`)
        : history.push(`/reports/time-tracking/${id}`);
    }
  };

  const onDelete = async () => {
    setLoading(true);

    try {
      type === 1
        ? setFilter(
            {
              place: null,
            },
            type
          )
        : setFilter(
            {
              placeTimeTracking: null,
            },
            type
          );

      await DeletePlace(id);

      toast.success(t("place-delete-success"));
    } catch (e) {
      console.log(e);
      toast.error(t("place-delete-error"));
    } finally {
      setLoading(false);
      history.push(`/reports`);
      type === 1
        ? history.push("/reports")
        : history.push("/reports/time-tracking");
    }
  };

  const isPermsDelete = checkPerms(users.user, "place:delete");
  const isPermsUpdate = checkPerms(users.user, "place:update");
  const isPermsGroupCreate = checkPerms(users.user, "person_group:create");

  return (
    <div className="layout__container layout__container_report">
      <div className="layout__head">
        <NavLink
          className="layout__close action"
          to={
            id
              ? type === 1
                ? `/reports/${id}`
                : `/reports/time-tracking/${id}`
              : type === 1
              ? "/reports"
              : "/reports/time-tracking"
          }
        >
          <Icon name="close" />
        </NavLink>
        <div className="layout__title">
          {id
            ? title
            : `${t("new-places")} | ${
                type === 1 ? t("visitor-analysis") : t("time-tracking")
              }`}
        </div>
        {isPermsDelete && id && (
          <ModalLauncher
            className="modal_md"
            btnClassName="layout__action action"
            action
            icon="trash"
          >
            <Confirm
              title={t("place-removing")}
              button={t("place-remove")}
              onClick={onDelete}
            >
              <p>
                {t("place-removing-msg")} «{title}
                »?
              </p>
            </Confirm>
          </ModalLauncher>
        )}
        {isPermsUpdate && (
          <button
            className={cn("layout__btn", "btn", "btn_lg", "btn_add", {
              btn_loading: loading,
            })}
            disabled={isButtonDisabled()}
            onClick={id ? onSave : onCreate}
          >
            {id ? t("save") : t("add")}
            {loading && <Loader color="white" />}
          </button>
        )}
      </div>
      <div className="layout__scroll">
        <div className="create">
          <div className="create__container create__container_center">
            <div className="create__section">
              <div className="create__title">{t("information")}</div>
              <div className="create__field field">
                <div className="field__label">{t("name-places")}</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t("name-places-enter")}
                    disabled={!isPermsUpdate}
                  />
                </div>
              </div>
            </div>
            <CameraGroups
              t={t}
              id={id}
              cameras={cameras}
              groups={groups}
              addGroup={addGroup}
              removeGroup={removeGroup}
              updateGroup={updateGroup}
              placeType={type}
              disabled={!isPermsUpdate}
            />
            <div className="create__section">
              {/* <MonitoringSchedule /> */}
              {type === 1 ? (
                <ExcludeGroups
                  isPermsGroupCreate={isPermsGroupCreate}
                  groups={faceGroups}
                  excludeGroups={exclude_groups}
                  updateExcludeGroups={updateExcludeGroups}
                  clearExcludeGroups={clearExcludeGroups}
                  disabled={!isPermsUpdate}
                />
              ) : (
                <TrackingGroups
                  isPermsGroupCreate={isPermsGroupCreate}
                  groups={faceGroups}
                  trackingGroups={tracking_groups}
                  updateTrackingGroups={updateTrackingGroups}
                  disabled={!isPermsUpdate}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  users: state.users,
  cameras: state.cameras.items,
  faceGroups: state.faces.groups,
  place: (state.reports.places &&
    state.reports.places.find((p) => p.id.toString() === ownProps.id)) || {
    id: null,
    title: "",
    groups: [{ entrance_camera: null, exit_camera: null }],
    exclude_groups: [],
    tracking_groups: [],
  },
});

export default translate()(
  withRouter(
    connect(mapStateToProps, {
      CreatePlace,
      SavePlace,
      DeletePlace,
      LoadGroups,
    })(ReportsPlace)
  )
);
