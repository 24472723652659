import {clampNumber, getTime} from "./utils";
import {DatePart} from "../Timeline2";

export class Viewport
{

    /**
     * Viewport width in pixels
     * @type {number}
     * @private
     */
    _width = null;

    /**
     * Viewport width in ms
     * @type {number}
     * @private
     */
    _window = null;

    /**
     *
     * @type {number}
     * @private
     */
    _center = null;

    static MinSize = DatePart.Minute * 2;
    static MaxSize = DatePart.Day * 25;

    constructor(center, window, width) {
        this._window = window;
        this._center = getTime(center);
        this._width = width;
    }

    get since() {
        return this._center - this._window / 2 - 0.5;
    }

    get until() {
        return this._center + this._window / 2 - 0.5;
    }

    get width() {
        return this._width;
    }

    /**
     * Returns viewport size in `ms`
     * @return {number}
     */
    get window() {
        return this._window;
    }

    /**
     * Returns viewport center
     * @return {number}
     */
    get center() {
        return this._center;
    }

    /**
     * Returns `ms` density per `px`
     * @return {number}
     */
    get density() {
        return this._window / this._width;
    }

    /**
     * Move viewport to given ms
     * @param {number} value
     * @return {Viewport}
     */
    move(value) {
        return new Viewport(this._center + Math.round(value), this._window, this.width);
    }

    /**
     * Zoom viewport to given ms
     * @param {number} value
     * @param {number?} center
     * @return {Viewport}
     */
    zoom(value, center) {
        if (center == null)
            center = this.center;

        let factor = Math.round(value);

        // let focusPx = this.pointFor(center);
        // let focusInPercents = focusPx / (this.width / 100) - 50;

        // Calculate new window size
        let window = clampNumber(this._window + factor, Viewport.MinSize, Viewport.MaxSize);

        return new Viewport(this.center, window, this.width);
    }

    resize(width) {
        return new Viewport(this._center, this._window, Number(width))
    }

    /**
     * Return time at the given point for given width
     * @param {number} point
     * @return {number}
     */
    timeAt(point) {
        return Math.round(this.density * point + this.since);
    }

    /**
     * Return x point for time
     * @param time
     * @return {number}
     */
    pointFor(time) {
        return (getTime(time) - this.since) / this.density
    }

}

export class EventItem
{
    /**
     * @private
     */
    _type = null;
    /**
     * @private
     */
    _since = null;
    /**
     * @private
     */
    _until = null;

    /**
     * @private
     */
    _hidden = null;

    constructor(type, since, until) {
        this._type = type;
        this._since = new Date(since).getTime();
        this._until = new Date(until).getTime();
    }

    get type() {
        return this._type;
    }

    get since() {
        return this._since;
    }

    get until() {
        return this._until;
    }

    get duration() {
        return this._until - this._since;
    }

    /**
     *
     * @param {EventItem} event
     */
    include = (event) => {
        this._until = event.until;
        this._hidden = [...this._hidden || [], event];
    }

}