import React from "react";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import cn from "classnames";
import Icon from "../components/Icon";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import EventsSidebar from "../components/Events/EventsSidebar";
import EventsGrid from "../components/Events/EventsGrid";
import EventsItem from "../components/Events/EventsItem";
import {
  EventsFetch,
  EventsCamerasFetch,
  EventsSetFilter,
  EventsClear,
  EventsGet,
  EventsMarkViewed,
  EventsSetNewFetch,
} from "../reducers/events";
import { LoadGroups } from "../reducers/faces";

class Events extends React.Component {
  refreshTimer = null;
  refreshReload = true;

  constructor(props) {
    super(props);
    this.state = {
      visibleFilters: false,
      hideSidebarEvents: localStorage.getItem("hideSidebarEvents") === "true",
    };
  }

  componentDidMount() {
    this.props.EventsCamerasFetch();
    this.props.LoadGroups();
    window.addEventListener("scroll", this.handleScroll, true);

    this.refreshTimer = setInterval(this.handleRefreshInterval, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    this.props.EventsClear();
    window.removeEventListener("scroll", this.handleScroll);

    clearInterval(this.refreshTimer);
  }

  toggleSidebar = () => {
    this.setState({ hideSidebarEvents: !this.state.hideSidebarEvents }, () => {
      localStorage.setItem("hideSidebarEvents", this.state.hideSidebarEvents);
    });
  };

  toggleFilters = () => {
    this.setState({ visibleFilters: !this.state.visibleFilters });
  };

  handleScroll = (e) => {
    this.refreshReload = e.target.scrollTop < 150;

    if (e.target.scrollTop <= 50 && !this.props.events.newFetch) {
      this.props.EventsSetNewFetch(true);
    } else if (e.target.scrollTop > 50 && this.props.events.newFetch) {
      //this.props.EventsSetNewFetch(false);
    }
  };

  handleRefreshInterval = () => {
    if (this.refreshReload) document.location.reload();
  };

  render() {
    const { hideSidebarEvents } = this.state;
    const { t } = this.props;

    if (!this.props.cameras.fetched) {
      return <Loader width="32" />;
    }

    const classes = cn(
      "layout",
      hideSidebarEvents && "hide-sidebar",
      this.state.visibleFilters && "show-filters"
    );

    return (
      <div className={classes}>
        <div className="layout__sidebar">
          <div className="layout__head">
            <Logo />
            <button
              className="layout__action action"
              onClick={this.toggleSidebar}
            >
              <Icon name="menu" />
            </button>
            <div className="layout__title">{t("events")}</div>
            <button
              className="layout__toggle action"
              onClick={this.toggleFilters}
            >
              <Icon name="filters" />
            </button>
          </div>
          <EventsSidebar
            items={this.props.events.cameras}
            setFilter={this.props.EventsSetFilter}
            {...this.props.events}
          />
        </div>
        <Route
          path={`/events/:id/:eventId`}
          render={(props) => (
            <EventsItem
              {...this.props.events}
              id={props.match.params.id}
              getEvent={this.props.EventsGet}
              markEvent={this.props.EventsMarkViewed}
              setFilter={this.props.EventsSetFilter}
              eventsFetch={this.props.EventsFetch}
            />
          )}
        />
        <Route
          exact
          path="/events/:id?"
          render={() => (
            <EventsGrid
              fetch={this.props.EventsFetch}
              items={this.props.events.cameras}
              setFilter={this.props.EventsSetFilter}
              {...this.props.events}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.events,
  cameras: state.cameras,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, {
      EventsFetch,
      EventsCamerasFetch,
      EventsSetFilter,
      EventsClear,
      EventsGet,
      EventsMarkViewed,
      EventsSetNewFetch,
      LoadGroups,
    })
  )(Events)
);
