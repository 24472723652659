import React from "react";
import { NavLink } from "react-router-dom";

const Logo = props => {
  return <NavLink className="action action_logo" to="/">
      <img className="icon" src="/img/neurobox.svg" alt="" />
    </NavLink>;
};

export default Logo;
