import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import ReportsPage from "../components/Reports/ReportsPage";
import ReportsGrid from "../components/Reports/ReportsGrid";
import ReportsPlace from "../components/Reports/ReportsPlace";
import ReportsTimeTracking from "../components/Reports/ReportsTimeTracking";
import ReportsEmployee from "../components/Reports/ReportsEmployee";
import ReportsVisitorAnalysis from "../components/Reports/ReportsVisitorAnalysis";

import { PlacesFetch, ReportsSetFilter } from "../reducers/reports";
import { checkPerms } from "../utils";

class Reports extends React.Component {
  componentDidMount() {
    this.props.PlacesFetch();
  }

  render() {
    const {
      users,
      places,
      placesFetched,
      ReportsSetFilter,
      reports,
    } = this.props;

    const isPermsCreate = checkPerms(users.user, "place:create");
    const isPermsFootfall = checkPerms(users.user, "reports:visitors:footfall");
    const isPermsMap = checkPerms(users.user, "reports:visitors:map");
    const isPermsDuration = checkPerms(users.user, "reports:visitors:duration");
    const isPermsFrequency = checkPerms(
      users.user,
      "reports:visitors:frequency"
    );

    const isPermsVisitorAnalysis =
      isPermsFootfall || isPermsMap || isPermsDuration || isPermsFrequency;

    const isPermsTimeTracking = checkPerms(users.user, "reports:work_time");

    return (
      <Switch>
        <Route
          exact
          path="/reports"
          render={() =>
            isPermsVisitorAnalysis ? (
              <ReportsPage
                type={1}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsGrid
                  isPermsCreate={isPermsCreate}
                  isPermsVisitorAnalysis={isPermsVisitorAnalysis}
                  isPermsTimeTracking={isPermsTimeTracking}
                  type={1}
                  places={places}
                  placesFetched={placesFetched}
                  {...reports}
                >
                  <ReportsVisitorAnalysis
                    isPermsFootfall={isPermsFootfall}
                    isPermsMap={isPermsMap}
                    isPermsDuration={isPermsDuration}
                    isPermsFrequency={isPermsFrequency}
                    places={places}
                    setFilter={ReportsSetFilter}
                    {...reports}
                  />
                </ReportsGrid>
              </ReportsPage>
            ) : (
              <Redirect to="/reports/time-tracking" />
            )
          }
        />
        {isPermsCreate && isPermsVisitorAnalysis && (
          <Route
            exact
            path="/reports/add-place"
            render={() => (
              <ReportsPage
                type={1}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsPlace type={1} />
              </ReportsPage>
            )}
          />
        )}
        <Route
          exact
          path="/reports/time-tracking"
          render={() =>
            isPermsTimeTracking ? (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsGrid
                  isPermsCreate={isPermsCreate}
                  isPermsVisitorAnalysis={isPermsVisitorAnalysis}
                  isPermsTimeTracking={isPermsTimeTracking}
                  type={2}
                  places={places}
                  placesFetched={placesFetched}
                  {...reports}
                >
                  <ReportsTimeTracking
                    setFilter={ReportsSetFilter}
                    {...reports}
                  />
                </ReportsGrid>
              </ReportsPage>
            ) : (
              <Redirect to="/reports" />
            )
          }
        />
        {isPermsCreate && isPermsTimeTracking && (
          <Route
            exact
            path="/reports/time-tracking/add-place"
            render={() => (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsPlace type={2} />
              </ReportsPage>
            )}
          />
        )}
        {isPermsTimeTracking && (
          <Route
            exact
            path="/reports/time-tracking/:id"
            render={(props) => (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsGrid
                  isPermsCreate={isPermsCreate}
                  isPermsVisitorAnalysis={isPermsVisitorAnalysis}
                  isPermsTimeTracking={isPermsTimeTracking}
                  type={2}
                  places={places}
                  placesFetched={placesFetched}
                  {...reports}
                >
                  <ReportsTimeTracking
                    id={props.match.params.id}
                    canEdit
                    places={places}
                    setFilter={ReportsSetFilter}
                    {...reports}
                  />
                </ReportsGrid>
              </ReportsPage>
            )}
          />
        )}
        {isPermsTimeTracking && (
          <Route
            exact
            path="/reports/time-tracking/:id/edit"
            render={(props) => (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                {placesFetched && (
                  <ReportsPlace
                    type={2}
                    id={props.match.params.id}
                    setFilter={ReportsSetFilter}
                  />
                )}
              </ReportsPage>
            )}
          />
        )}
        {isPermsTimeTracking && (
          <Route
            exact
            path="/reports/time-tracking/all/:workingTimeId"
            render={(props) => (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsEmployee
                  id={props.match.params.workingTimeId}
                  {...reports}
                />
              </ReportsPage>
            )}
          />
        )}
        {isPermsTimeTracking && (
          <Route
            exact
            path="/reports/time-tracking/:id/:workingTimeId"
            render={(props) => (
              <ReportsPage
                type={2}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsEmployee
                  id={props.match.params.workingTimeId}
                  {...reports}
                />
              </ReportsPage>
            )}
          />
        )}
        {isPermsVisitorAnalysis && (
          <Route
            exact
            path="/reports/:id"
            render={(props) => (
              <ReportsPage
                type={1}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                <ReportsGrid
                  isPermsCreate={isPermsCreate}
                  isPermsVisitorAnalysis={isPermsVisitorAnalysis}
                  isPermsTimeTracking={isPermsTimeTracking}
                  type={1}
                  places={places}
                  placesFetched={placesFetched}
                  {...reports}
                >
                  <ReportsVisitorAnalysis
                    isPermsFootfall={isPermsFootfall}
                    isPermsMap={isPermsMap}
                    isPermsDuration={isPermsDuration}
                    isPermsFrequency={isPermsFrequency}
                    id={props.match.params.id}
                    canEdit
                    places={places}
                    setFilter={ReportsSetFilter}
                    {...reports}
                  />
                </ReportsGrid>
              </ReportsPage>
            )}
          />
        )}
        {isPermsVisitorAnalysis && (
          <Route
            exact
            path="/reports/:id/edit"
            render={(props) => (
              <ReportsPage
                type={1}
                places={places}
                setFilter={ReportsSetFilter}
                {...reports}
              >
                {placesFetched && (
                  <ReportsPlace
                    type={1}
                    id={props.match.params.id}
                    setFilter={ReportsSetFilter}
                  />
                )}
              </ReportsPage>
            )}
          />
        )}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  places: state.reports.places,
  placesFetched: state.reports.placesFetched,
  reports: state.reports,
});

export default translate()(
  connect(mapStateToProps, {
    PlacesFetch,
    ReportsSetFilter,
  })(Reports)
);
