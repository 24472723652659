import React from "react";
// import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import { Line } from "react-chartjs-2";
import update from "immutability-helper";
import { chartOptions } from "../utils";
import ReportsChangingsPercentage from "../ReportsChangingsPercentage";
import { numeralize } from "../../../utils";

class ChartByAge extends React.Component {
  toggleLine = (index) => {
    let lineChart = this.reference.chartInstance;

    lineChart.config.data.datasets[index].hidden = !lineChart.config.data
      .datasets[index].hidden;

    lineChart.update();

    document
      .querySelector(`.chart__preview_${index}`)
      .classList.toggle("disabled");
  };

  render() {
    const {
      t,
      i18n,
      footfall,
      dates,
      labels,
      // onEventsClick,
    } = this.props;

    const newChartOptions = update(chartOptions, {
      tooltips: {
        enabled: { $set: true },
        position: { $set: "average" },
        mode: { $set: "index" },
        intersect: { $set: false },
        titleFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        titleFontSize: { $set: 10 },
        titleFontStyle: { $set: "normal" },
        bodyFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        bodyFontSize: { $set: 10 },
        bodyFontStyle: { $set: "normal" },
        callbacks: {
          $set: {
            title: function (tooltipItems, data) {
              var total = 0;
              tooltipItems.forEach(function (tooltipItem) {
                total +=
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
              });
              return `${t("total")} ${total} ${t("man-short")}`;
            },
          },
        },
      },
    });

    const current = footfall.current;
    const prev = footfall.prev;
    const percentage = (age) => {
      return Math.round((current[age] / current.total) * 100);
    };
    const percentagePrev = (age) => {
      return Math.round((prev[age] / prev.total) * 100);
    };

    let dataAge0 = [];
    let dataAge18 = [];
    let dataAge25 = [];
    let dataAge35 = [];
    let dataAge45 = [];
    let dataAge55 = [];

    dates.forEach((date) => {
      let age0 = 0;
      let age18 = 0;
      let age25 = 0;
      let age35 = 0;
      let age45 = 0;
      let age55 = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          age0 = x.age0;
          age18 = x.age18;
          age25 = x.age25;
          age35 = x.age35;
          age45 = x.age45;
          age55 = x.age55;
        }
      });

      dataAge0.push(age0);
      dataAge18.push(age18);
      dataAge25.push(age25);
      dataAge35.push(age35);
      dataAge45.push(age45);
      dataAge55.push(age55);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "< 18",
          hidden: false,
          fill: false,
          backgroundColor: "#42d475",
          borderColor: "#42d475",
          data: dataAge0,
          value: "age0",
          people: current.age0,
          to: "/events",
        },
        {
          label: "18-24",
          hidden: false,
          fill: false,
          backgroundColor: "#ffc000",
          borderColor: "#ffc000",
          data: dataAge18,
          value: "age18",
          people: current.age18,
          to: "/events",
        },
        {
          label: "25-34",
          hidden: false,
          fill: false,
          backgroundColor: "#f30027",
          borderColor: "#f30027",
          data: dataAge25,
          value: "age25",
          people: current.age25,
          to: "/events",
        },
        {
          label: "35-44",
          hidden: false,
          fill: false,
          backgroundColor: "#7c50ff",
          borderColor: "#7c50ff",
          data: dataAge35,
          value: "age35",
          people: current.age35,
          to: "/events",
        },
        {
          label: "45-54",
          hidden: false,
          fill: false,
          backgroundColor: "#c664fb",
          borderColor: "#c664fb",
          data: dataAge45,
          value: "age45",
          people: current.age45,
          to: "/events",
        },
        {
          label: "> 55",
          hidden: false,
          fill: false,
          backgroundColor: "#7fdacd",
          borderColor: "#7fdacd",
          data: dataAge55,
          value: "age55",
          people: current.age55,
          to: "/events",
        },
      ],
    };

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Line
            data={chartData}
            options={newChartOptions}
            height={360}
            ref={(reference) => (this.reference = reference)}
          />
        </div>
        <div className="chart__foot">
          <div className="chart__row chart__row_age">
            {chartData.datasets.map((x, i) => (
              <div className="chart__cell" key={i}>
                <div
                  className={cn("chart__preview", `chart__preview_${i}`)}
                  onClick={() => this.toggleLine(i)}
                  style={{
                    backgroundColor: x.borderColor,
                    borderColor: x.borderColor,
                  }}
                />
                <div className="chart__group">
                  <div className="chart__value">
                    {x.label}
                    <span className="chart__total">
                      {" "}
                      | {percentage(x.value)}%
                    </span>
                    <ReportsChangingsPercentage
                      current={percentage(x.value)}
                      prev={percentagePrev(x.value)}
                    />
                  </div>
                  <div className="chart__label">
                    {x.people}{" "}
                    {numeralize(i18n.language, x.people, [
                      "человек",
                      "человека",
                      "человек",
                      "visitor",
                      "visitors",
                    ])}
                  </div>
                  {/* <Link
                    to={x.to}
                    onClick={() => onEventsClick("age", x.label)}
                    className="chart__label"
                  >
                    {x.people}{" "}
                    {numeralize(i18n.language, x.people, [
                      "человек",
                      "человека",
                      "человек",
                      "man",
                      "people",
                    ])}
                    <span className="chart__chevron" />
                  </Link> */}
                </div>
              </div>
            ))}
          </div>
          <div className="chart__lines">
            {chartData.datasets.map(
              (x, i) =>
                percentage(x.value) !== 0 && (
                  <div
                    className="chart__line"
                    style={{
                      flex: `1 1 ${percentage(x.value)}%`,
                      background: x.borderColor,
                    }}
                    key={i}
                  />
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartByAge);
