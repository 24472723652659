import React from "react";
import Switch from "../../Switch";

const ReportsVisitorFilter = ({ t, filters, setFilter }) => {
  const filtersList = [
    {
      filter: "unique",
      title: t("visits"),
      items: [
        { label: t("all"), value: 0 },
        { label: t("only-unique"), value: 1 },
      ],
    },
    {
      filter: "gender",
      title: t("gender"),
      items: [
        { label: t("all-visitors"), value: null },
        { label: t("only-men"), value: "male" },
        { label: t("only-woman"), value: "female" },
      ],
    },
    {
      filter: "age",
      title: t("age"),
      items: [
        { label: t("all"), value: null },
        { label: t("less-18-years"), value: "age0" },
        { label: t("between-18-24-years-old"), value: "age18" },
        { label: t("between-25-34-years-old"), value: "age25" },
        { label: t("between-35-44-years-old"), value: "age35" },
        { label: t("between-45-54-years-old"), value: "age45" },
        { label: t("more-55-years"), value: "age55" },
      ],
    },
  ];

  const isSelected = (filter, value) => filters[filter] === value;

  const toggleSelect = (filter, value) => {
    filter === "gender" && setFilter({ gender: value }, 1);
    filter === "age" && setFilter({ age: value }, 1);
    filter === "unique" && setFilter({ unique: value }, 1);
  };

  return filtersList.map((x, i) => (
    <React.Fragment key={i}>
      <div className="accordion__label">{x.title}</div>
      {x.items.map((y, i) => (
        <Switch
          content={y.label}
          key={i}
          value={isSelected(x.filter, y.value)}
          onChange={() => toggleSelect(x.filter, y.value)}
          radio
        />
      ))}
    </React.Fragment>
  ));
};

export default ReportsVisitorFilter;
