export const archiveTarrifsByID = {
  1: {
    days: "3",
    daysText: "дня",
    price: 5,
    month: 1,
    rate_id: 1,
  },
  2: {
    days: "7",
    price: 9,
    month: 1,
    rate_id: 2,
  },
  3: {
    days: "30",
    price: 18,
    month: 1,
    rate_id: 3,
  },
  4: {
    days: "60",
    price: 30,
    month: 1,
    rate_id: 4,
  },
  5: {
    days: "3",
    daysText: "дня",
    priceOld: 60,
    price: 50,
    month: 12,
    rate_id: 5,
  },
  6: {
    days: "7",
    priceOld: 108,
    price: 90,
    month: 12,
    rate_id: 6,
  },
  7: {
    days: "30",
    priceOld: 216,
    price: 180,
    month: 12,
    rate_id: 7,
  },
  8: {
    days: "60",
    priceOld: 360,
    price: 300,
    month: 12,
    rate_id: 8,
  },
  9: {
    days: "3",
    daysText: "дня",
    price: 4,
    month: 1,
    rate_id: 9,
    archiveOnlyEvents: true,
  },
  10: {
    days: "7",
    price: 6,
    month: 1,
    rate_id: 10,
    archiveOnlyEvents: true,
  },
  11: {
    days: "30",
    price: 12,
    month: 1,
    rate_id: 11,
    archiveOnlyEvents: true,
  },
  12: {
    days: "60",
    price: 20,
    month: 1,
    rate_id: 12,
    archiveOnlyEvents: true,
  },
  13: {
    days: "3",
    daysText: "дня",
    priceOld: 48,
    price: 40,
    month: 12,
    rate_id: 13,
    archiveOnlyEvents: true,
  },
  14: {
    days: "7",
    priceOld: 72,
    price: 60,
    month: 12,
    rate_id: 14,
    archiveOnlyEvents: true,
  },
  15: {
    days: "30",
    priceOld: 144,
    price: 120,
    month: 12,
    rate_id: 15,
    archiveOnlyEvents: true,
  },
  16: {
    days: "60",
    priceOld: 240,
    price: 200,
    month: 12,
    rate_id: 16,
    archiveOnlyEvents: true,
  },
};

export const archiveFullTarrifs = {
  month: [1, 2, 3, 4],
  year: [5, 6, 7, 8],
};

export const archiveEventsTarrifs = {
  month: [9, 10, 11, 12],
  year: [13, 14, 15, 16],
};

export const recognitionTarrifsByID = {
  // month
  // 17: { persons: 5, price: 0, month: 1, rate_id: 17 },
  //18: { persons: 50, price: 39, month: 1, rate_id: 18 },
  //19: { persons: 500, price: 69, month: 1, rate_id: 19 },
  //20: { persons: 5000, price: 99, month: 1, rate_id: 20 },

  // month + report
  25: {
    persons: 5,
    price: 99,
    month: 1,
    rate_id: 25,
    reports: true,
    detectionLimit: 1000,
  },
  27: {
    persons: 50,
    price: 139,
    month: 1,
    rate_id: 27,
    reports: true,
    detectionLimit: 3000,
  },
  29: {
    persons: 500,
    price: 199,
    month: 1,
    rate_id: 29,
    reports: true,
    detectionLimit: -1,
  },
  // 31: { persons: 5000, price: 199, month: 1, rate_id: 31, reports: true },

  // year
  // 21: { persons: 5, price: 0, month: 12, rate_id: 21 },
  //22: { persons: 50, priceOld: 468, price: 390, month: 12, rate_id: 22 },
  //23: { persons: 500, priceOld: 828, price: 690, month: 12, rate_id: 23 },
  //24: { persons: 5000, priceOld: 1188, price: 990, month: 12, rate_id: 24 },

  // year + report
  26: {
    persons: 50,
    priceOld: 1188,
    price: 990,
    month: 12,
    rate_id: 26,
    reports: true,
    detectionLimit: 1000,
  },
  28: {
    persons: 50,
    priceOld: 1668,
    price: 1390,
    month: 12,
    rate_id: 28,
    reports: true,
    detectionLimit: 3000,
  },
  30: {
    persons: 500,
    priceOld: 2388,
    price: 1990,
    month: 12,
    rate_id: 30,
    reports: true,
    detectionLimit: -1,
  },
};

export const recognitionTariffs = {
  month: [],
  year: [],
};

export const reportTarrifs = {
  month: [25, 27, 29],
  year: [26, 28, 30],
};
