import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import update from "immutability-helper";
import format from "dateformat";
import { chartOptions, NumberToHours } from "../utils";

const Period = ({ t, item }) => {
  const newChartOptions = update(chartOptions, {
    layout: {
      padding: {
        top: { $set: 0 },
      },
    },
    scales: {
      xAxes: [
        {
          position: { $set: "top" },
          stacked: { $set: true },
          ticks: {
            padding: { $set: 0 },
            fontSize: { $set: 10 },
            stepSize: { $set: 10800 },
            callback: {
              $set: function (value) {
                const hours = value / 3600;
                return hours < 10 ? `0${hours}:00` : `${hours}:00`;
              },
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: { $set: true },
        },
      ],
    },
  });

  const workingTime = [];
  const empty = "----";

  item.working_time.length > 0
    ? item.working_time.map((x, index) =>
        workingTime.push({
          label: `Dataset ${index}`,
          maxBarThickness: 4,
          backgroundColor: x.work ? "#f30027" : "transparent",
          data: [x.until - x.since],
        })
      )
    : workingTime.push({
        label: `Dataset 0`,
        maxBarThickness: 4,
        backgroundColor: "transparent",
        data: [86400],
      });

  const data = {
    datasets: workingTime,
  };

  return (
    <div className="tracking__row">
      <div className="tracking__cell">
        <div className="tracking__date">
          {format(item.period, "dd/MM/yyyy")}
        </div>
      </div>
      <div className="tracking__cell">
        <div className="tracking__term">{t("time-start-work")}:</div>
        <div className="tracking__time">
          {item.first_in && item.first_in !== "" ? item.first_in : empty}
        </div>
      </div>
      <div className="tracking__cell">
        <HorizontalBar data={data} height={60} options={newChartOptions} />
      </div>
      <div className="tracking__cell">
        <div className="tracking__term">{t("time-end-work")}:</div>
        <div className="tracking__time">
          {item.last_out && item.last_out !== "" ? item.last_out : empty}
        </div>
      </div>
      <div className="tracking__cell">
        <div className="tracking__term">{t("time-on-work")}:</div>
        <div className="tracking__time">
          {item.duration > 0 ? NumberToHours(t, item.duration) : empty}
        </div>
      </div>
    </div>
  );
};

export default Period;
