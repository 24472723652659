import React, { useCallback, useEffect, useRef, useState } from "react";
import Hls, { ErrorTypes } from "hls.js";

const hlsOptions = {
  manifestLoadingTimeOut: 90000,
};

export const Video = ({
  url,
  pause,
  playing,
  volume,
  muted = true,
  onLoad,
  onStart,
  onError,
  onPlaying,
  onDuration,
  onWaiting,
}) => {
  const hls = useRef();
  const video = useRef();

  // iOS don't start playing by script if device stays in EnergySave move
  // In this case we need to show Play button and tell parent components what video is ready
  const [autoPlay, setAutoPlay] = useState(() => false);

  const play = useCallback(() => {
    if (video.current != null) {
      let promise = video.current.play();
      if (promise != null)
        promise.then(
          () => {},
          (e) => {
            if (e.code === 0) {
              onStart && onStart();
              setAutoPlay(true);
            }
          }
        );
    }
  }, [onStart]);

  const handleError = useCallback(
    (domError, hlsError) => {
      hlsError == null
        ? handleDomError(url, domError, onError)
        : handleHlsError(url, hlsError, onError, hls.current);
    },
    [onError, url]
  );

  const handlePlaying = useCallback(
    (e) => {
      onPlaying(video.current.currentTime);
    },
    [video, onPlaying]
  );

  const handleStart = useCallback(
    (e) => {
      onStart && onStart();
    },
    [onStart]
  );

  const handleLoadStart = useCallback(
    (e) => {
      onLoad && onLoad();
    },
    [onLoad]
  );

  const handleDuration = useCallback(
    (e) => {
      if (video.current != null)
        onDuration && onDuration(video.current.duration);
    },
    [onDuration]
  );

  const handleWaiting = useCallback(
    (e) => {
      onWaiting && onWaiting();
    },
    [onWaiting]
  );

  useEffect(() => {
    if (Hls.isSupported() && url) {
      hls.current = new Hls(hlsOptions);
      hls.current.loadSource(url);
      hls.current.attachMedia(video.current);
      hls.current.on(Hls.Events.MANIFEST_PARSED, play);
      hls.current.on(Hls.Events.ERROR, handleError);
    } else {
      if (video.current.canPlayType("application/vnd.apple.mpegurl")) {
        video.current.src = url;
        video.current.addEventListener("loadedmetadata", play);
      }
    }

    return () => {
      if (hls.current != null) {
        hls.current.destroy();
        hls.current = null;
      }

      if (video.current == null) return;

      video.current.removeEventListener("loadedmetadata", play);
    };
  }, [handleError, play, url, video]);

  useEffect(() => {
    if (video.current) pause ? video.current.pause() : play();
  }, [pause, play]);

  useEffect(() => {
    if (video.current != null && volume) video.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (video.current != null && playing != null)
      video.current.currentTime = playing;
  }, [playing]);

  return (
    <video
      ref={video}
      muted={muted}
      width="100%"
      height="100%"
      onError={handleError}
      onPlaying={handleStart}
      onLoadStart={handleLoadStart}
      onDurationChange={handleDuration}
      onTimeUpdate={handlePlaying}
      onWaiting={handleWaiting}
      playsInline
      autoPlay={autoPlay}
    />
  );
};

export const handleDomError = (url, error, onFatal) => {
  onFatal && onFatal();
};

export const handleHlsError = (url, error, onFatal, hls) => {
  if (error == null || error.fatal !== true) return;

  switch (error.type) {
    case ErrorTypes.NETWORK_ERROR:
      onFatal && onFatal();
      break;
    case ErrorTypes.MEDIA_ERROR:
      hls.recoverMediaError();
      break;
    default:
      onFatal && onFatal();
      break;
  }
};
