import React from "react";
import styles from "./Colors.module.sass";
import Icon from "../../../Icon";

const colors = [
  {
    id: 0,
    color: "#24323a",
  },
  {
    id: 1,
    color: "#ffc413",
  },
  {
    id: 2,
    color: "#f30027",
  },
  {
    id: 3,
    color: "#42d475",
  },
  {
    id: 4,
    color: "#426cd4",
  },
  {
    id: 5,
    color: "#a942d4",
  },
  {
    id: 6,
    color: "#42d4bf",
  },
  {
    id: 7,
    color: "#42bfd4",
  },
];

const Colors = ({ value, onChange }) => {
  return (
    <div className={styles.colors}>
      {colors.map((x, i) => (
        <label className={styles.item} key={i}>
          <input
            className={styles.input}
            type="radio"
            name="color"
            checked={value === x.color}
            onChange={() => onChange(x.color)}
          />
          <span className={styles.circle} style={{ background: x.color }}>
            <Icon name="success" />
          </span>
        </label>
      ))}
    </div>
  );
};

export default Colors;
