import React from "react";
import { translate } from "react-i18next";
import { parseURL } from "../../http";
import Icon from "../Icon";
import Loader from "../Loader";
import Switch from "../Switch";

import { wsMJpegStreamUrl } from "../../links";

const CONNECTION_STATE_CONNECTING = 1;
const CONNECTION_STATE_CONNECTED = 2;
const CONNECTION_STATE_NEED_LOGIN = 3;
const CONNECTION_STATE_NEED_URI = 4;
const CONNECTION_STATE_FAILED = 5;

class CameraFound extends React.Component {
  constructor(props) {
    super(props);
    this.cameraUrl = parseURL(props.camera.stream_url);

    this.state = {
      connectionState: null,
      previewImageSrc: "",

      cameraLogin: this.cameraUrl.username || "",
      cameraPassword: this.cameraUrl.password || "",
      cameraURI: this.cameraUrl.uri || "",

      loginError: false,
      uriError: false
    };

    if (this.props.camera.need_login) {
      this.state.connectionState = CONNECTION_STATE_NEED_LOGIN;
    } else {
      this.state.connectionState = CONNECTION_STATE_CONNECTING;
    }
  }

  componentDidMount() {
    if (this.state.connectionState === CONNECTION_STATE_CONNECTING) {
      this.openSocket();
    }
  }

  componentWillUnmount() {
    this.closeSocket();
  }

  openSocket = () => {
    this.closeSocket();

    this.socket = new WebSocket(wsMJpegStreamUrl(this.props.camera.id));
    this.socket.binaryType = "arraybuffer";

    this.socket.onmessage = e => {
      const blob = new Blob([e.data], { type: "image/jpeg" });
      const image = window.URL.createObjectURL(blob);
      this.setState({
        previewImageSrc: image,
        connectionState: CONNECTION_STATE_CONNECTED
      });
      if (this.props.onChangeState) {
        this.props.onChangeState(true);
      }
    };

    this.socket.onerror = e => {
      this.setState({ connectionState: CONNECTION_STATE_FAILED });
      if (this.props.onChangeState) {
        this.props.onChangeState(false);
      }
    };
  };

  closeSocket = () => {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  };

  isConnected = () => this.state.connectionState === CONNECTION_STATE_CONNECTED;

  isSwitchDisabled = () => !this.isConnected();

  handleScanSingle = () => {
    const { cameraLogin, cameraPassword, cameraURI } = this.state;
    const { camera } = this.props;

    this.setState({
      connectionState: CONNECTION_STATE_CONNECTING,
      loginError: false,
      uriError: false
    });

    let schema = camera.stream_url.split("://")[0];
    let part = camera.stream_url.slice(7);
    if (part.search("@") !== -1) {
      part = part.split("@")[1];
    }

    const baseUrl = part.split("/").slice(0, 1);
    let newStreamUrl;
    if (cameraLogin && cameraPassword) {
      newStreamUrl = `${schema}://${cameraLogin}:${cameraPassword}@${baseUrl}/${cameraURI}`;
    } else {
      newStreamUrl = `${schema}://${baseUrl}/${cameraURI}`;
    }

    this.props
      .scanSingle(
        this.props.camera.id,
        cameraLogin,
        cameraPassword,
        newStreamUrl
      )
      .then(() => {
        if (this.props.camera.need_login) {
          this.setState({
            connectionState: CONNECTION_STATE_NEED_LOGIN,
            loginError: true
          });
        } else {
          this.openSocket();
        }
      })
      .catch(err => {
        console.log("single scan error: ", err);
        this.setState({
          connectionState: CONNECTION_STATE_NEED_URI,
          uriError: true
        });
      });
  };

  handleChangeURI = () => {
    this.setState(
      { connectionState: CONNECTION_STATE_NEED_URI },
      this.closeSocket
    );
  };

  renderStaticPartURL = () => {
    const { scheme, host, port } = this.cameraUrl;
    return `${scheme}://${host}:${port}/`;
  };

  renderPreview = () => {
    return (
      <div className="cams__preview">
        <img src={this.state.previewImageSrc} alt="" className="cams__pic" />
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div className="cams__loader">
        <Loader width="40" />
      </div>
    );
  };

  renderConnectionFailed = () => {
    const { t } = this.props;
    return (
      <div className="cams__empty">
        <div className="cams__icon">
          <Icon className="cams__pic" name="camera" />
        </div>
        <div className="cams__subtitle">{t("could-not-get-camera-image")}</div>
        <button className="cams__action">Редактировать настройки камеры</button>
      </div>
    );
  };

  renderBodyAuth = () => {
    const { t } = this.props;

    return (
      <div className={`cams__auth ${this.state.loginError ? "error" : ""}`}>
        <svg
          className="cams__lock"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="21"
          viewBox="0 0 16 21"
        >
          <path d="M8,16 C9.1,16 10,15.1 10,14 C10,12.9 9.1,12 8,12 C6.9,12 6,12.9 6,14 C6,15.1 6.9,16 8,16 Z M14,7 L13,7 L13,5 C13,2.24 10.76,0 8,0 C5.24,0 3,2.24 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 Z M4.9,5 C4.9,3.29 6.29,1.9 8,1.9 C9.71,1.9 11.1,3.29 11.1,5 L11.1,7 L4.9,7 L4.9,5 Z M14,19 L2,19 L2,9 L14,9 L14,19 Z" />
        </svg>
        <div className="cams__title">
          {!this.state.loginError
            ? t("camera-secured-password")
            : t("wrong-login-password")}
        </div>
        <div className="cams__text">
          {t("camera-connect-enter-login-password")}
        </div>
        <div className="cams__fieldset">
          <div className="cams__field field">
            <div className="field__label">{t("login")}</div>
            <div className="field__wrap">
              <input
                type="text"
                className={`field__input ${
                  this.state.loginError ? "error" : ""
                }`}
                value={this.state.cameraLogin}
                onChange={e => this.setState({ cameraLogin: e.target.value })}
              />
            </div>
          </div>
          <div className="cams__field field">
            <div className="field__label">{t("password")}</div>
            <div className="field__wrap">
              <input
                type="password"
                className={`field__input ${
                  this.state.loginError ? "error" : ""
                }`}
                value={this.state.cameraPassword}
                onChange={e =>
                  this.setState({ cameraPassword: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="cams__actions">
          <button
            className="cams__btn btn"
            disabled={this.state.cameraLogin.length === 0}
            onClick={() => this.handleScanSingle()}
          >
            {t("camera-connect")}
          </button>
        </div>
      </div>
    );
  };

  renderBodyURI = () => {
    const { t } = this.props;
    return (
      <div className="cams__form">
        <button
          className="cams__close"
          onClick={() => {
            this.setState({ cameraURI: this.cameraUrl.uri });
            this.handleScanSingle();
          }}
        >
          <Icon className="cams__pic" name="close-sm" />
        </button>
        <div className="cams__title">{t("edit-link-to-stream")}</div>
        <div className="cams__text">
          <p>{t("camera-connect-enter-link-to-stream")}</p>
          <a
            href="https://help.neurocam.com/%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BA%D0%B0%D0%BC%D0%B5%D1%80/%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B0-%D0%BD%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D0%BF%D0%BE%D1%82%D0%BE%D0%BA"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("what-it-is")}
          </a>
        </div>
        <div className="cams__fieldset">
          <div className="cams__field field">
            <div className="field__label">{t("link-to-stream")}</div>
            <div className="field__wrap">
              <label className="url">
                <span className="url__static">
                  {this.renderStaticPartURL()}
                </span>
                <span className="url__edit">
                  <input
                    type="text"
                    className={`url__input ${
                      this.state.uriError ? "error" : ""
                    }`}
                    value={this.state.cameraURI}
                    onChange={e => this.setState({ cameraURI: e.target.value })}
                  />
                </span>
              </label>
            </div>
            {this.state.uriError && (
              <div className="field__error field__error_static">
                {t("wrong-link-to-stream")}
              </div>
            )}
          </div>
        </div>
        <div className="cams__actions">
          <button
            className="cams__btn btn"
            onClick={() => this.handleScanSingle()}
          >
            {t("save")}
          </button>
          <button
            className="cams__btn btn btn_white"
            onClick={() => this.setState({ cameraURI: this.cameraUrl.uri })}
          >
            {t("reset")}
          </button>
        </div>
      </div>
    );
  };

  renderBody = () => {
    switch (this.state.connectionState) {
      case CONNECTION_STATE_CONNECTED:
        return this.renderPreview();
      case CONNECTION_STATE_FAILED:
        return this.renderConnectionFailed();
      case CONNECTION_STATE_NEED_LOGIN:
        return this.renderBodyAuth();
      case CONNECTION_STATE_NEED_URI:
        return this.renderBodyURI();
      default:
        return this.renderLoading();
    }
  };

  render() {
    const {
      t,
      singleMode,
      camera,
      selected,
      onSelect,
      onChangeTitle
    } = this.props;
    return (
      <div className={`cams__item ${this.isConnected() && "selected"}`}>
        <div className="cams__head">
          <div className="cams__flex">
            {!singleMode && (
              <Switch
                className="switch_cams"
                disabled={this.isSwitchDisabled()}
                value={selected}
                onChange={() => onSelect(camera.id, !selected)}
              />
            )}
            <div className="cams__field field">
              <div className="field__wrap">
                <input
                  disabled={!this.isConnected()}
                  type="text"
                  className="field__input"
                  defaultValue={camera.title}
                  onChange={e => onChangeTitle(camera, e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="cams__details">
            <div className="cams__detail">
              <input
                disabled
                className="cams__url"
                defaultValue={camera.stream_url}
              />
              <button
                className={"cams__edit action action_sm"}
                onClick={this.handleChangeURI}
              >
                <Icon name="edit.svg" />
              </button>
            </div>
            <div className="cams__info">
              <span className="cams__label">{t("video-codec")} </span>
              {this.cameraUrl.scheme === "rtsp" ? "H.264" : "MJpeg"}
            </div>
          </div>
        </div>
        <div className="cams__body">{this.renderBody()}</div>
      </div>
    );
  }
}

class CamerasFound extends React.Component {
  render() {
    const { singleMode, cameras, selected } = this.props;
    return (
      <div className={"cams " + (singleMode ? "cams_single" : "")}>
        {cameras.map(c => (
          <CameraFound
            {...this.props}
            key={c.id}
            camera={c}
            selected={selected && selected[c.id]}
          />
        ))}
      </div>
    );
  }
}

export default translate()(CamerasFound);
