import React from "react";

const Rules = props => {
  return (
    <div className="pages__article">
      <h1>Правила оказания информационных услуг</h1>
      <p>Настоящий документ «Правила оказания информационных услуг» представляет собой обязательные условия, на которых допускается заключение и исполнение договоров на оказание информационных услуг с использованием Сервиса ООО «Нейрокэм».</p>
      <h3>1. Термины и определения</h3>
      <p>1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения:</p>
      <p>1.1.1. <strong>Правила</strong> – текст настоящего документа со всеми приложениями, изменениями и дополнениями к нему, размещенный на Сайте Исполнителя и доступный в сети Интернет по адресу: <a href="https://neurocam.com/pages/rules">https://neurocam.com/pages/rules</a></p>
      <p>1.1.2. <strong>Договор</strong> – договор оказания возмездных Услуг, вместе со всеми относящимися к нему Обязательными документами, который заключается и исполняется Сторонами в порядке, предусмотренном настоящими Правилами.</p>
      <p>1.1.3. <strong>Услуги</strong> – информационные услуги по обеспечению доступа Заказчика к дополнительным функциональным возможностям Сервиса, перечисленные в Тарифах Исполнителя.</p>
      <p>1.1.4. <strong>Исполнитель</strong> – Общество с ограниченной ответственностью «Нейрокэм», ОГРН: 1177746595960; Юридический адрес: 119270, г. Москва, Лужнецкая наб, дом 2/4, строение 17, офис 305.</p>
      <p>1.1.5. <strong>Заказчик</strong> – лицо, способное совершить Акцепт на условиях настоящих Правил (применительно к порядку заключения Договора) либо совершившее Акцепт Оферты на условиях настоящих Правил (применительно к исполнению заключенного Договора).</p>
      <p>1.1.6. <strong>Заказ</strong> – совершение Заказчиком действий по выбору и/или самостоятельному определению условий оказания Услуг, перечисленных в соответствующем разделе Приложения или его Личного кабинета, необходимых для оформления отдельного Договора.</p>
      <p>1.1.7. <strong>Оферта</strong> – оформляемое на основании Заказа предложение Исполнителя о заключении Договора, выражаемое путем выставления счета на оплату Услуг либо направления Заказа на оплату через платежную систему с использованием Личного кабинета Заказчика.</p>
      <p>1.1.8. <strong>Акцепт</strong> – полное и безоговорочное принятие Оферты на условиях настоящих Правил путем совершения Заказчиком действий, указанных в разделе 9 Правил, создающее Договор между Заказчиком и Исполнителем.</p>
      <p>1.1.9. <strong>Сайт Исполнителя / Сайт</strong> – автоматизированная информационная система, доступная в сети Интернет по сетевому адресу (включая поддомены): <a href="http://neurocam.com/">http://neurocam.com/</a></p>
      <p>1.1.10. <strong>Мобильное приложение</strong> – предназначенная для установки и использования на мобильных устройствах программа для ЭВМ, позволяющая пользоваться различными опциями Сервиса.</p>
      <p>1.1.11. <strong>Приложение</strong> – интегрированный программный комплекс для управления видеонаблюдением, хранения и обработки видеопотока, включающий Сайт, Мобильное приложение и иные программы для ЭВМ и/или базы данных, на основе которых реализуется Сервис.</p>
      <p>1.1.12. <strong>Сервис</strong> – совокупность функциональных возможностей Приложения, доступ к которым предоставляется Заказчику.</p>
      <p>1.1.13. <strong>Личный кабинет</strong> – персональный раздел Приложения, к которому Заказчик получает доступ после прохождения регистрации и/или авторизации в Приложении. Личный кабинет предназначен для хранения персональной информации Заказчика, использования функциональных возможностей Сервиса, оформления Заказов, просмотра информации о совершенных Заказах, текущем состоянии Лицевого счета, и получения уведомлений в порядке нотификации.</p>
      <p>1.1.14. <strong>Лицевой счет</strong> – информация о внесенных Заказчиком и списанных в рамках исполнения Договоров денежных средствах в счет оплаты оказываемых Услуг по указанным Договорам. Доступ к Лицевому счету предоставляется Заказчику с использованием его Личного кабинета.</p>
      <p>1.2. В настоящих Правилах могут быть использованы термины и определения, не определенные в п.1.1. Правил. В этом случае толкование такого термина производится в соответствии с текстом Правил. В случае отсутствия однозначного толкования термина или определения в тексте Правил следует руководствоваться его толкованием, определенным: в первую очередь – документами, образующими Договор между Сторонами, во вторую очередь - законодательством Российской Федерации, и в последующем - обычаями делового оборота и научной доктриной.</p>
      <p>1.3. Любая ссылка в настоящих Правилах на пункт (раздел Правил) и/или их условия, означает соответствующую ссылку на настоящие Правила (их раздел) и/или их условия.</p>
      <h3>2. Предмет Договора</h3>
      <p>2.1. Исполнитель обязуется при наличии технической возможности оказывать Услуги на основании размещенных Заказов, а Заказчик принимать и оплачивать Услуги на условиях настоящих Правил.</p>
      <p>2.2. Наименование, состав и стоимость Услуг, а также прочие необходимые условия Договора определяются на основании сведений и материалов, предоставленных Заказчиком при оформлении Заказа, в соответствии с условиями Обязательных документов.</p>
      <p>2.3. Обязательным условием оказания Исполнителем Услуг является безоговорочное принятие и соблюдение Заказчиком, применяемых к отношениям Сторон по Договору требований и положений, определенных следующими документами («Обязательные документы»):</p>
      <p>2.3.1. <strong>Пользовательское соглашение</strong>, размещенное и/или доступное в сети Интернет по адресу <a href="https://neurocam.com/pages/terms">https://neurocam.com/pages/terms</a>, и включающее общие условия регистрации в Приложении и использования Сервиса Исполнителя;</p>
      <p>2.3.2. <strong>Политика конфиденциальности</strong>, размещенная и/или доступная в сети Интернет по адресу <a href="https://neurocam.com/pages/policy">https://neurocam.com/pages/policy</a>, и содержащая правила предоставления и использования персональной информации, включая персональные данные Заказчика.</p>
      <p>2.3.3. <strong>Лицензионное соглашение с конечным пользователем</strong> - размещенные и/или доступные в сети Интернет по адресу <a href="https://neurocam.com/pages/rules">https://neurocam.com/pages/rules</a> обязательные условия использования Мобильного приложения.</p>
      <p>2.3.4. <strong>Тарифы</strong>, размещенные и/или доступные в сети Интернет по адресу <a href="https://neurocam.com/">https://neurocam.com/</a>, применяемые Исполнителем для расчета стоимости Услуг на дату оформления Заказа и включающие применительно к соответствующему виду и составу Услуг сведения о предлагаемом Исполнителем наименовании и стоимости Услуг, а также иные необходимые условия их оказания.</p>
      <p>2.4. Указанные в п.2.3. Правил обязательные для Сторон документы составляют неотъемлемую часть заключаемого в соответствии с Правилами Договора.</p>
      <h3>3. Права и обязанности Сторон</h3>
      <p>3.1. <strong>Исполнитель обязуется:</strong></p>
      <p>3.1.1. Оказывать Услуги в полном соответствии с Договором, включая Обязательные документы, и требованиями законодательства Российской Федерации;</p>
      <p>3.1.2. Своевременно извещать Заказчика о ходе оказания Услуг;</p>
      <p>3.1.2. Своевременно извещать Заказчика о ходе оказания Услуг;</p>
      <p>3.2. <strong>Исполнитель вправе:</strong></p>
      <p>3.2.1. Не приступать к оказанию Услуг до их оплаты;</p>
      <p>3.2.2. Приостанавливать работу Сервиса для проведения профилактических работ;</p>
      <p>3.2.3. Приостанавливать оказание Услуг в случае нарушения Заказчиком требований, предусмотренных Договором, Обязательными документами, а также в иных случаях, установленных законодательством Российской Федерации;</p>
      <p>3.2.4. Осуществлять ограничение отдельных действий Заказчика, если такие действия создают угрозу для нормального функционирования Сервиса;</p>
      <p>3.2.5. Изменять в одностороннем порядке Правила и указанные в них Обязательные документы без уведомления Заказчика в отношении Услуг, по которым с Заказчиком отсутствует действующий Договор;</p>
      <p>3.2.6. Отказаться в одностороннем порядке от исполнения Договора в случаях, предусмотренных действующим законодательством  Российской Федерации и/или настоящими Правилами;</p>
      <p>3.2.7. Осуществлять иные права, предусмотренные действующим законодательством  Российской Федерации, а также настоящими Правилами, включая Обязательные документы.</p>
      <p>3.3. <strong>Заказчик обязуется:</strong></p>
      <p>3.3.1. Соблюдать требования Договора, включая Обязательные документы, а также законодательства Российской Федерации;</p>
      <p>3.3.2. Осуществлять проверку Заказа до совершения Акцепта;</p>
      <p>3.3.3. Оплачивать Услуги по Договору в полном объеме в установленные сроки;</p>
      <p>3.3.4. Проверять наличие уведомлений Исполнителя в Приложении Исполнителя (в том числе - в Личном кабинете Заказчика) и по электронному адресу, указанному Заказчиком при оформлении Заказа, а также просматривать информацию о ходе оказания Услуг.</p>
      <p>3.3.5. Выполнять иные обязанности, предусмотренные Договором, включая Обязательные документы, а также действующим законодательством Российской Федерации.</p>
      <p>3.4. <strong>Заказчик вправе:</strong></p>
      <p>3.4.1. Проверять ход и качество оказания Услуг по размещенным Заказам;</p>
      <p>3.4.2. Отказаться в одностороннем порядке от исполнения Договора в случаях, предусмотренных действующим законодательством  Российской Федерации и/или настоящими Правилами;</p>
      <p>3.4.3. Осуществлять иные права, предусмотренные Договором, включая Обязательные документы, а также действующим законодательством  Российской Федерации.</p>
      <h3>4. Стоимость Услуг и порядок расчетов</h3>
      <p>4.1. Стоимость Услуг по Договору определяется в соответствии с Тарифами Исполнителя, действующими на дату оформления касающегося их Заказа, в зависимости от наименования, состава и (при необходимости) срока предоставления Услуг. Стороны при согласовании Заказа вправе установить индивидуальные условия оказания услуг.</p>
      <p>4.2. Оплата Услуг производится Заказчиком путем внесения аванса в размере 100% стоимости соответствующих Услуг.</p>
      <p>4.3. Расчеты по Договору осуществляются путем безналичных перечислений на расчетный счет Исполнителя либо с использованием указанных при размещении Заказа на Сайте платежных сервисов.</p>
      <p>4.4. Заказчик считается исполнившим свои обязательства по оплате Услуг с момента поступления денежных средств на расчетный счет Исполнителя в установленном размере при безналичной форме оплаты либо предоставления информации о совершенном Заказчиком платеже оператором платежного сервиса, действующим на основании договора с Исполнителем.</p>
      <h3>5. Сдача Услуг</h3>
      <p>5.1. Услуги считаются оказанными Исполнителем с момента обеспечения возможности использования Заказчиком соответствующих дополнительных функциональных возможностей Сервиса посредством открытия и резервирования доступа к ним Заказчика на соответствующий срок во внутренней учетной системе Исполнителя.</p>
      <p>5.2. Заказчик обязан знакомиться в Личном кабинете с информацией о заказанных и оказанных Услугах, составе и сроках предоставления доступа к дополнительным функциональным возможностям Сервиса, а также внесенных и списанных в рамках исполнения Договоров денежных средствах в счет оплаты Услуг по указанным Договорам.</p>
      <p>5.3. Стороны признают и безоговорочно соглашаются, что сведения об оказанных Услугах указываются в Личном кабинете на основе данных внутренней учетной системы Исполнителя.</p>
      <p>5.4. Претензии Заказчиков – физических лиц в связи с оказанием Услуг могут быть направлены Исполнителем в течение 3 (Трёх) рабочих дней с момента их оказания. В случае отсутствия в течение установленного срока претензии от Заказчика о ненадлежащем оказании Услуг Исполнителем, Услуги считаются принятыми Заказчиком в полном объеме, а их стоимость возврату не подлежит. За пределами установленного настоящим пунктом срока претензии Заказчика Исполнителем не принимаются и не рассматриваются.</p>
      <p>5.5. Порядок сдачи Услуг Заказчикам – юридическим лицам и предпринимателям:</p>
      <p>5.5.1. Исполнитель в течение 5 (Пяти) рабочих дней с момента оказания Услуг составляет и направляет по адресу электронной почты Заказчика Акт об оказанных услугах (далее - Акт) в электронной форме. По требованию Заказчика и за его счет Исполнителем может быть изготовлена копия Акта на бумажном носителе и направлена по адресу, указанному Заказчиком.</p>
      <p>5.5.2. Стороны признают и безоговорочно соглашаются, что сведения об оказанных Услугах указываются в Акте на основе данных внутренней учетной системы Исполнителя.</p>
      <p>5.5.3. Заказчик обязан ознакомиться с Актом в электронной форме в течение 3 (Трёх) рабочих дней с момента его предоставления.</p>
      <p>5.5.4. В случае непредставления Заказчиком в установленный срок Исполнителю письменных возражений по Акту, Услуги считаются принятыми Заказчиком и подлежат оплате в полном объеме. При этом Акт, подписанный со стороны Исполнителя, имеет полную юридическую силу.</p>
      <p>5.5.5. Порядок выставления счетов-фактур Заказчику определяется в соответствии с законодательством РФ.</p>
      <h3>6. Гарантии сторон</h3>
      <p>6.1. Исполнитель гарантирует:</p>
      <p>1) принадлежность ему прав на использование Приложения в объеме, необходимом для надлежащего выполнения обязательств по Договору;</p>
      <p>2) оказание Услуг по Договору в полном соответствии с настоящими Правилами и Обязательными документами.</p>
      <p>6.2. Исполнитель не предоставляет никаких подразумеваемых или выраженных явным образом в Правилах или Приложении гарантий в отношении: </p>
      <p>6.2.1. соответствия дополнительных функциональных возможностей Сервиса требованиям и ожиданиям Заказчика, их пригодности для определенной цели, и отсутствия конструктивных недостатков;</p>
      <p>6.2.2. предоставления непрерывного доступа к Сервису, отсутствия ошибок в его программном обеспечении, возможности и сроков их устранения.</p>
      <p>6.3. Заказчик гарантирует использование Сервиса в соответствии с условиями настоящих Правил и Обязательных документов.</p>
      <h3>7. Ответственность сторон</h3>
      <p>7.1. За неисполнение или ненадлежащее исполнение принятых по  Договору обязательств Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации.</p>
      <p>7.2. Исполнитель не отвечает за встречное исполнение обязательств по  оказанию Услуг в случае просрочки оплаты Услуг, и иных случаях полного или частичного неисполнения Заказчиком обязательств по Договору, а также наличия обстоятельств, очевидно свидетельствующих о том, что такое исполнение не будет произведено в установленный срок.</p>
      <p>7.3. В случае невозможности использования или ненадлежащего качества Услуг, Заказчик должен направить Исполнителю письменную претензию с подробным указанием обстоятельств и периода отсутствия доступа к дополнительным функциональным возможностям Сервиса или их ненадлежащей работы.</p>
      <p>7.4. В случае признания претензии обоснованной, доступ Заказчика к соответствующим функциональным возможностям Сервиса продлевается Исполнителем для компенсации допущенного нарушения. Требования Заказчика, признаваемого потребителем, заявляются и удовлетворяются в установленном законом порядке.</p>
      <p>7.5. Ответственность Исполнителя по Договору в любом случае ограничивается возмещением причиненного Заказчику реального ущерба в сумме, не превышающей оплаченную стоимость Услуг по соответствующему Договору.</p>
      <p>7.6. Заказчик самостоятельно в полном объеме несет ответственность за соответствие размещаемой им информации и материалов требованиям законодательства, в том числе за их содержание и правомерность использования в них объектов интеллектуальной собственности, наличие необходимых разрешений и лицензий, а также за совершение иных действий в рамках гарантий, предоставленных в п.6.3. настоящих Правил.</p>
      <p>7.7. В случае если оказание Услуг по Договору вследствие нарушения гарантий Заказчика повлекло предъявление к Исполнителю претензий, исков и/или предписаний по возмещению убытков (выплате компенсаций) со стороны третьих лиц и/или государственных органов либо возбуждение дела об административном правонарушении, Заказчик обязуется незамедлительно по требованию Исполнителя предоставить ему всю запрашиваемую информацию, касающуюся предмета спора, и содействовать Исполнителю в урегулировании таких претензий, а также возместить все убытки (включая судебные расходы, расходы по уплате штрафов), причиненные Исполнителю вследствие предъявления, рассмотрения и исполнения таких претензий, исков, предписаний, а равно привлечением к административной ответственности в связи с нарушением прав третьих лиц и/или действующего законодательства в результате оказания Услуг.</p>
      <p>7.8. Сумма убытков может быть удержана Исполнителем из денежных средств Заказчика, поступивших в счет оплаты Услуг по Договору, в том числе путем списания с Лицевого счета Заказчика.</p>
      <p>7.9. В случае нарушения Заказчиком условий Договора, повлекших его досрочное прекращение, оставшаяся по Договору сумма удерживается в качестве штрафной неустойки в связи с допущенными нарушениями.</p>
      <h3>8. Обстоятельства непреодолимой силы (форс-мажор)</h3>
      <p>8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, вызванное обстоятельствами непреодолимой силы, возникшими после его заключения. К таким обстоятельствам, в частности, Стороны относят: стихийные бедствия; природные и промышленные катастрофы; террористические акты; военные действия; гражданские беспорядки; принятие органами государственной власти или органами местного самоуправления актов, содержащих запреты или ограничения в отношении деятельности Сторон по Договору; иные обстоятельства, которые не могут быть заранее предвидены или предотвращены и делают невозможным исполнение обязательств Сторон по Договору.</p>
      <p>8.2. При наступлении обстоятельств непреодолимой силы, препятствующих исполнению обязательств по Договору, срок оказания Сторонами своих обязательств переносится соразмерно времени действия таких обстоятельств, а также времени, требуемого для устранения их последствий, но не более 60 (Шестидесяти) календарных  дней. В случае если обстоятельства непреодолимой силы продолжают действовать свыше указанного срока, либо когда при их наступлении обеим Сторонам становится очевидным, что они будут действовать более этого срока, Договор прекращает свое действие.</p>
      <h3>9. Акцепт Оферты и заключение Договора</h3>
      <p>9.1. Акцепт Оферты Заказчиком создает Договор между Заказчиком и Исполнителем (статьи 433, 438 Гражданского Кодекса Российской Федерации) на условиях настоящих Правил.</p>
      <p>9.2. Акцепт Оферты совершается Заказчиком путем выполнения совокупности указанных ниже действий:</p>
      <p>9.2.1. оформление и направление Заказа с помощью программных средств на Сайте Исполнителя;</p>
      <p>9.2.2. внесение авансового платежа за оказание Услуг в сумме, определяемой соответствующим Тарифом на дату оплаты.</p>
      <p>9.3. Договор считается заключенным с момента получения Исполнителем Акцепта Оферты.</p>
      <p>9.4. Во избежание сомнений начало использования Заказчиком Услуг дополнительно свидетельствует о заключении Договора на их оказание в соответствии с условиями настоящих Правил.</p>
      <p>9.5. На основании Правил с Заказчиком может быть заключено неограниченное количество Договоров.</p>
      <h3>10. Срок действия и изменение Правил</h3>
      <p>10.1. Правила вступают в силу с момента размещения на Сайте Исполнителя и действуют до момента их отмены Исполнителем.</p>
      <p>10.2. Исполнитель оставляет за собой право внести изменения в условия Правил и/или отменить Правила в любой момент по своему усмотрению. Сведения об изменении или отзыве Правил доводятся до Заказчика по выбору Исполнителя посредством размещения на Сайте Исполнителя, в Личном кабинете Заказчика, либо путем направления соответствующего уведомления на электронный или почтовый адрес, указанный Заказчиком при заключении Договора или в ходе его исполнения.</p>
      <p>10.3. В случае отзыва Правил или внесения изменений в Правила, указанные решения вступают в силу с момента доведения об этом сведений до Заказчика, если иной срок вступления их в силу не определен Правилами или дополнительно при таком сообщении.</p>
      <p>10.4. Указанные в Правилах обязательные для Сторон документы утверждаются, дополняются и изменяются Исполнителем по собственному усмотрению и доводятся до сведения Заказчика в порядке, предусмотренном для уведомления Заказчика об изменении Правил.</p>
      <h3>11. Срок действия, изменение и расторжение Договора</h3>
      <p>11.1. Договор вступает в силу с момента Акцепта Правил Заказчиком и действует: а) до момента полного исполнения Сторонами обязательств по Договору,  либо б) до момента досрочного расторжения Договора.</p>
      <p>11.2. В случае отмены Правил Исполнителем в течение срока действия Договора, Договор считается действующим на условиях Правил в последней редакции со всеми Обязательными документами.  </p>
      <p>11.3.  Договор может быть расторгнут:</p>
      <p>11.3.1. По соглашению Сторон в любое время.</p>
      <p>11.3.2. По инициативе любой из Сторон с письменным уведомлением другой Стороны об отказе от исполнения Договора не менее чем за 15 (Пятнадцать) календарных дней.</p>
      <p>11.3.3. По иным основаниям, предусмотренным Правилами или действующим законодательством.</p>
      <p>11.4. В случае отказа Заказчика от исполнения Договора в части информационных услуг после предоставления Заказчику приобретенного доступа к функциональным возможностям Сайта, стоимость соответствующих Услуг не возвращается.</p>
      <h3>12. Условия конфиденциальности</h3>
      <p>12.1. Стороны соглашаются сохранять в тайне и считать конфиденциальными условия каждого заключенного Договора, а также всю информацию, полученную одной Стороной от другой Стороны при заключении и исполнении такого Договора (далее — «Конфиденциальная информация»), и не должны раскрывать, разглашать, обнародовать или иным способом не предоставлять такую информацию какой-либо третьей стороне без предварительного письменного разрешения передающей эту информацию Стороны.</p>
      <p>12.2. Каждая из Сторон предпримет все необходимые меры для защиты Конфиденциальной информации как минимум с такой же степенью заботливости, с какой она защищает собственную Конфиденциальную информацию. Доступ к Конфиденциальной информации будет предоставлен только тем сотрудникам каждой из Сторон, которым он обоснованно необходим для выполнения служебных обязанностей по исполнению Договора. Каждая из Сторон обяжет таких своих сотрудников принять те же обязательства по обеспечению сохранности Конфиденциальной информации, которые предусмотрены настоящими Правилами в отношении Сторон.</p>
      <p>12.3. Обработка персональных данных Заказчика (при их наличии) осуществляется в соответствии с Политикой конфиденциальности Исполнителя.</p>
      <p>12.4. Исполнитель вправе запрашивать дополнительную информацию, такую как ксерокопии документов, удостоверяющих личность или кредитных карт, в случае необходимости проверки личности или в целях предотвращения мошенничества. Если такая дополнительная информация представлена Исполнителю, то ее использование и охрана осуществляется в соответствии с условиями п.12.3. Правил.</p>
      <p>12.5. Обязательство по сохранению в тайне Конфиденциальной информации действительно в пределах срока действия заключенного Договора и в течение 5 (Пяти) лет после прекращения его действия, если Сторонами отдельно не будет оговорено иное.</p>
      <h3>13. Соглашение об аналоге собственноручной подписи</h3>
      <p>13.1. Стороны вправе использовать при заключении Договора, оформлении Заказов, а также направлении уведомлений по Договору факсимильное воспроизведение подписи или простую электронную подпись.</p>
      <p>13.2. Допускается обмен документами между Сторонами посредством факсимильной связи или электронной почты. При этом документы, переданные в установленном Правилами порядке, имеют полную юридическую силу при условии наличия подтверждения о доставке включающего их сообщения получателю.</p>
      <p>13.3. При использовании Сторонами электронной почты направляемый с ее помощью электронный документ считается подписанным простой электронной подписью отправителя, созданной с использованием адреса его электронной почты.</p>
      <p>13.4. По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью.</p>
      <p>13.5. Получатель электронного документа определяет лицо, подписавшее такой документ, по используемому им адресу электронной почты.</p>
      <p>13.6. В остальной части порядок применения простой электронной подписи Сторонами регулируется Пользовательским соглашением.</p>
      <h3>14. Заключительные положения</h3>
      <p>14.1. Договор, его заключение и исполнение регулируется действующим законодательством Российской Федерации. Все вопросы, не урегулированные Правилами или урегулированные не полностью, регулируются в соответствии с материальным правом Российской Федерации.</p>
      <p>14.2. Споры по Правилам и/или Договору разрешаются в предварительном претензионном порядке. В случае недостижения Сторонами согласия споры подлежат рассмотрению в суде в соответствии с процессуальным правом Российской Федерации.</p>
      <p>14.3. Любые уведомления и документы по Договору, если иное не предусмотрено Правилами, могут направляться одной Стороной другой Стороне: 1) по электронной почте; 2) направления Заказчику электронного уведомления в Личном кабинете; 3) по факсу; 4) почтой с уведомлением о вручении или курьерской службой с подтверждением доставки.</p>
      <p>14.4. В случае если одно или более положений Правил или Договора являются по какой-либо причине недействительными, не имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого положения Правил или Договора, которые остаются в силе.</p>
      <p>14.5. Не вступая в противоречие с условиями Правил, Стороны вправе в любое время оформить заключенный Договор на оказание Услуг в форме письменного документа, выражающего содержание действующих на момент его оформления Правил, указанных в них Обязательных документов и размещенного Заказа.</p>
      <h3>15. Реквизиты Исполнителя</h3>
      <p>Общество с ограниченной ответственностью «Нейрокэм»;<br />ОГРН: 1177746595960;<br />ИНН: 77044255108;<br />Юридический адрес: 119270, г. Москва, Лужнецкая наб, дом 2/4, строение 17, офис 305;<br />Банковские реквизиты:<br />Расчетный счет: 40702810602300008134<br />Банк: АО "АЛЬФА-БАНК"<br />БИК: 044525593<br />Корр. счет: 30101810200000000593</p>
      <p>e-mail: <a href="mailto:info@neurocam.com">info@neurocam.com</a></p>
      <p>Редакция от 20.01.2019г.</p>
    </div>
  );
};

export default Rules;
