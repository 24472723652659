import React from "react";
import { Link, withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./TableView.module.sass";
import Icon from "../../../Icon";
import Action from "../../../Action";
import Switch from "../../../Switch";

import { formatVisit } from "../../utils";
import { ageRange } from "../../../../utils";
import { guidMainPhotoUrl } from "../../../../links";

const TableView = ({
  t,
  items,
  isPersons,
  filters,
  setFilter,
  selection,
  onSelect,
  isSelected,
  groupName,
  groupColor,
  history,
}) => {
  const shortGroupName = (id) =>
    groupName(id)
      .split(" ")
      .map((x) => x.slice(0, 1))
      .join("");

  const createPerson = (e, item) => {
    e.preventDefault();
    history.push(`/faces/${item.group_info?.id || 0}/${item.guid}/edit`);
  };

  const TableAction = ({ className, title, option }) => {
    const handleClick = () => {
      option && setFilter({ sort_by: option, sort_desc: !filters.sort_desc });
    };

    return (
      <div className={cn(styles.td, className)}>
        <button
          className={cn(
            "action-sort",
            { active: filters.sort_by === option },
            { desc: filters.sort_by === option && !filters.sort_desc },
            { "no-active": !option }
          )}
          onClick={handleClick}
        >
          {t(title)}
        </button>
      </div>
    );
  };

  return (
    <div className={cn(styles.table, { [styles.selection]: selection })}>
      <div className={styles.head}>
        {isPersons && <TableAction className={styles.tdGroup} title="list" />}
        <div className={cn(styles.td, styles.tdAvatar)}></div>
        <TableAction className={styles.tdName} title="person" />
        <TableAction
          className={styles.tdVisit}
          title="first-visit"
          option="first_visit"
        />
        <TableAction
          className={styles.tdVisit}
          title="last-visit"
          option="last_visit"
        />
        <TableAction
          className={styles.tdEvents}
          title="events"
          option="events_count"
        />
        {!isPersons && (
          <>
            <TableAction
              className={styles.tdGender}
              title="gender"
              option="gender"
            />
            <TableAction className={styles.tdAge} title="age" option="age" />
            <div className={cn(styles.td, styles.tdAdd)}></div>
          </>
        )}
      </div>
      <div className={styles.body}>
        {items.map((item) => (
          <div className={styles.line} key={item.guid}>
            {selection && (
              <Switch
                className={styles.switch}
                value={isSelected(item.guid)}
                onChange={(e) => onSelect(item.guid, e.target.checked)}
              />
            )}
            <Link
              className={styles.item}
              to={`/faces/${item.group_info?.id || 0}/${item.guid}`}
            >
              {isPersons && (
                <div className={cn(styles.td, styles.tdGRoup)}>
                  {item.group_info ? (
                    <div
                      className={styles.group}
                      style={{ background: groupColor(item.group_info.id) }}
                      title={groupName(item.group_info.id)}
                    >
                      {shortGroupName(item.group_info.id)}
                    </div>
                  ) : (
                    <div className={styles.group}>{t("visitors-short")}</div>
                  )}
                </div>
              )}
              <div className={cn(styles.td, styles.tdAvatar)}>
                <div className={styles.avatar}>
                  {item.guid ? (
                    <img src={guidMainPhotoUrl(item.guid)} alt="Avatar" />
                  ) : (
                    <Icon name="face" />
                  )}
                  {item.online && <div className={styles.online}></div>}
                </div>
              </div>
              <div className={cn(styles.td, styles.tdName)}>
                <div className={styles.name}>
                  {isPersons
                    ? item.person_info
                      ? `${item.person_info.name} ${item.person_info.last_name}`
                      : "No name"
                    : `id: ${item.guid}`}
                </div>
              </div>
              <div className={cn(styles.td, styles.tdVisit)}>
                {formatVisit(item.first_visit)}
              </div>
              <div className={cn(styles.td, styles.tdVisit)}>
                {formatVisit(item.last_visit)}
              </div>
              <div className={cn(styles.td, styles.tdEvents)}>
                {item.events_count}
              </div>
              {!isPersons && (
                <>
                  <div className={cn(styles.td, styles.tdGender)}>
                    <Icon
                      className={
                        item.gender && item.gender === 1
                          ? styles.male
                          : styles.female
                      }
                      name={
                        item.gender && item.gender === 1 ? "male" : "female"
                      }
                    />
                  </div>
                  <div className={cn(styles.td, styles.tdAge)}>
                    {isPersons ? item.age : ageRange(item.age)}
                  </div>

                  <div className={cn(styles.td, styles.tdAdd)}>
                    <Action
                      onClick={(e) => createPerson(e, item)}
                      icon="plus"
                      small
                    />
                  </div>
                </>
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(translate()(TableView));
