export const translations = {
  monday: "Понедельник",
  tuesday: "Вторник",
  wednesday: "Среда",
  thursday: "Четверг",
  friday: "Пятница",
  saturday: "Суббота",
  sunday: "Воскресенье",
  mondayShort: "Пн",
  tuesdayShort: "Вт",
  wednesdayShort: "Ср",
  thursdayShort: "Чт",
  fridayShort: "Пт",
  saturdayShort: "Сб",
  sundayShort: "Вс",
  day: "День",
  month: "Месяц",
  year: "Год",
  "year-short": "г.",
  hide: "Скрыть",
  remove: "Удалить",
  save: "Сохранить",
  clear: "Очистить",
  soon: "Скоро",
  rub: "руб.",
  eur: "EUR",
  validity: "Срок действия",
  time: "Время",
  from: "с",
  to: "до",
  of: "из",
  apply: "Применить",
  download: "Скачать",
  restore: "Восстановить",
  cancel: "Отмена",
  events: "События",
  bitrate: "Битрейт:",
  "bitrate-sec": "кбит/сек",
  "video-codec": "Видео-кодек:",
  resolution: "Разрешение:",
  framerate: "Фреймрейт:",
  group: "Группа",
  "frames-sec": "кадров/сек",
  "events-storage": "Хранение событий",
  "archive-storage": "Хранение архива",
  "person-recognition": "Распознавание лиц",
  "error-occurred": "Произошла ошибка, попробуйте позже",
  "menu-watch": "Наблюдение",
  "menu-events": "События",
  "menu-faces": "Лица",
  "menu-monitoring": "Мониторинг",
  "menu-profile": "Настройки",
  "menu-payment": "Оплата",
  "menu-neuroboxes": "Нейробоксы",
  "menu-help": "Помощь",
  "cameras-layout-title": "МОИ КАМЕРЫ",
  "empty-title": "Нет подключенных камер",
  "empty-text":
    "В настоящий момент нет подключенных камер. Нажмите кнопку, чтобы подключить",
  "empty-label-download": "Скачать программу Neurobox для Windows или MacOS",
  "empty-label-buy": "Купить устройство Neurobox",
  "empty-label-add": "Подключить камеру",
  auth: "Авторизация",
  login: "Логин",
  username: "Имя пользователя",
  password: "Пароль",
  "userlogin-signin-button": "Войти",
  "added-cameras-title": "Камеры успешно подключены",
  "added-cameras-text":
    "Камеры подключены и доступны в разделе «Наблюдение». Нажмите «Продолжить», чтобы перейти к просмотру или повторите сканирование, что добавить новые камеры",
  "added-cameras-more": "Повторить сканирование",
  continue: "Продолжить",
  connect: "Подключить",
  disable: "Отключить",
  "connect-camera-device": "Подключение камеры через устройство neurobox",
  "connect-camera-app": "Подключение камеры через программу neurobox",
  "choose-another-connection-method": "Выбрать другой способ подключения",
  "connect-camera-neurobox-help":
    "Для подключения камеры с помощью Neurobox вам понадобится купить устройство или установить программу на компьютер или ноутбук",
  "connect-camera-directly": "Подключить камеру напрямую",
  "connect-camera-directly-help":
    "Для того, чтобы подключить камеру к сервису, вам понадобится выделенный IP-адрес. Также должен быть назначен и открыт внешний порт камеры, которую вы собираетесь подключить. Для получения выделенного IP-адреса вы можете обратиться к вашему интернет-провайдеру. Для более простого подключения камер мы рекомендуем использовать наше решение — «Neurobox»",
  "buy-neurobox": "Купить устройство Neurobox",
  "download-app": "Скачать программу для Windows и MacOS",
  "search-neurobox": "Поиск Neurobox",
  "search-device": "Поиск устройств",
  "search-camera": "Поиск камеры",
  "scan-completion": "Завершение сканирования",
  "enter-device-id": "Введите идентификатор",
  "copy-app-id": "Скопируйте идентификатор из установленной программы Neurobox",
  "copy-device-id":
    "Скопируйте идентификатор с обратной стороны устройства Neurobox",
  "example-camera-id": "Например: hGpAe2",
  "connect-neurobox-error":
    "Не удалось установить соединение с Neurobox. Проверьте, что ваш Neurobox работает и подключен к Интернет",
  "scan-start": "Запустить сканирование",
  "scan-finish": "Сканирование завершено",
  "cameras-found": "Найдено камер",
  "camera-found": "Камера найдена",
  "camera-settings": "Параметры камеры",
  add: "Добавить",
  cameras: "камеры",
  "cameras-capitalize": "Камеры",
  date: "Дата",
  tariff: "Тариф",
  camera: "Камера",
  "instruction-ports": "Инструкция по настройке портов",
  "instruction-ip":
    "Если вы знаете адрес видеопотока вашей камеры, то скопируйте его в поле ниже. Если вы не знаете адрес видеопотока, то мы попробуем найти его автоматически. Для этого укажите ваш ip-адрес и логин/пароль камеры. Если вы меняли стандартный порт камеры, то также укажите его",
  "ip-address": "IP-адрес",
  "ip-address-determined": "IP-адрес определен автоматически",
  "enter-id-or-stream": "Введите ваш IP-адрес или адрес видеопотока камеры",
  "unique-login-and-password": "На камере установлен уникальный логин/пароль",
  port: "Порт",
  "port-set": "Указать порт вручную",
  "camera-secured-password": "Камера защищена паролем",
  "camera-connect-enter-login-password":
    "Чтобы подключиться к камере, введите логин/пароль",
  "camera-connect-enter-link-to-stream":
    "Чтобы подключить камеру, введите ссылку на видеопоток камеры",
  "camera-connect": "Подключиться к камере",
  "wrong-login-password": "Неверный логин/пароль",
  "link-to-stream": "Ссылка на видеопоток",
  "wrong-link-to-stream": "Неверно указана ccылка на видеопоток",
  "edit-link-to-stream": "Редактировать ссылку на видеопоток",
  reset: "Сбросить",
  complete: "Завершить",
  "could-not-get-camera-image": "Не удалось получить изображение с камеры",
  "what-it-is": "Что это такое?",
  scanning: "Идет сканирование...",
  online: "Онлайн",
  offline: "Офлайн",
  "grid-add-camera": "Подключить",
  "grid-camera-disconnected": "Нет соединения с камерой",
  "grid-camera-not-active": "Камера отключена",
  "wizard-activate-internal-error": "Внутренняя ошибка, попробуйте позже",
  "wizard-activate-error-token_not_found":
    "Вы ввели неправильный идентификатор",
  "wizard-activate-error-offline":
    "Не удалось установить соединение с Neurobox. Проверьте, что ваш Neurobox работает и подключен к Интернет",
  "wizard-activate-error-not_owner":
    "Этот Neurobox уже привязан к другому аккаунту",
  "wizard-add-camera": "Добавить камеру",
  "wizard-not-found-scan-complete": "Сканирование завершено",
  "wizard-not-found-cameras": "Камер не найдено",
  "wizard-not-found-cameras-app":
    "Убедитесь, что камера работает и подключена к той же сети, что и компьютер с программой Neurobox, с помощью кабеля или через Wi-Fi. Если все сделано правильно, но камера все равно не находится при сканировании, <a href='#' class='js-open-intercom'>сообщите нам</a> модель камеры и мы поможем её подключить.",
  "wizard-not-found-cameras-device":
    "Убедитесь, что камера работает и подключена к той же сети, что и устройство Neurobox, с помощью кабеля или через Wi-Fi. Если все сделано правильно, но камера все равно не находится при сканировании, <a href='#' class='js-open-intercom'>сообщите нам</a> модель камеры и мы поможем её подключить.",
  "wizard-not-found-cameras-directly":
    "Убедитесь, что камера работает и подключена к маршрутизатору с помощью кабеля или через Wi-Fi. Также проверьте, что произведена <a href='https://help.neurocam.com/articles/2756842' target='_blank'>настройка портов</a> в вашем маршрутизаторе. Проверить открыт ли нужный порт можно <a href='https://portchecker.co/check' target='_blank'>здесь</a>. Если все сделано правильно, но камера все равно не находится при сканировании, сообщите нам модель камеры и мы поможем её подключить.",
  "wizard-not-found-repeat": "Повторить сканирование",
  "wizard-not-found-via-neurobox": "Подключить камеру с помощью Neurobox",
  "registration-errors-Required minimum 6 symbols":
    "Минимальная длина 6 символов",
  "registration-errors-Required": "Поле обязательно для заполнения",
  "registration-errors-Email already exist": "Такой email уже зарегистрирован",
  "login-bad credentials": "Неверный логин или пароль",
  "login-Unauthorized": "Ошибка при авторизации неверный логин или пароль",
  "transaction-description-deposit": "Пополнение счета",
  "transaction-description-registration_bonus": "Бонус при регистрации",
  "transaction-description-purchase": "Подключение тарифа",
  "transaction-description-cancel": "Отключение тарифа",
  "transaction-description-purchase-archive": "Подключение записи в облако",
  "transaction-description-cancel-archive": "Отключение записи в облако",
  "transaction-description-purchase-persons": "Подключение распознавания лиц",
  "transaction-description-cancel-persons": "Отключение распознавания лиц",
  "rate-day": "дня",
  "rate-days": "дней",
  "nav-not-connected": "Не подключено",
  "all-cameras": "Все камеры",
  owner: "Владелец",
  "camera-rates-purchase-error-not own camera":
    "Вы не являетесь владельцем камеры",
  "camera-rates-purchase-error-not enough money":
    "Недостаточно средств на счете",
  "camera-rates-purchase-error-camera already have rate":
    "Запись в облако для камеры уже подключена",
  "camera-rates-purchase-success": "Тариф успешно подключен",
  man: "Человек",
  "man-lowercase": "человек",
  "man-short": "чел.",
  car: "Автомобиль",
  faces: "Лица",
  "camera-access-closing": "Закрытие доступа к камере",
  "camera-access-close": "Закрыть доступ",
  user: "Пользователю",
  "camera-access-will-not-allowed-access-camera":
    "будет закрыт доступ к камере",
  "camera-access-send-invitation": "Отправить приглашение",
  "camera-access-user-email": "E-mail пользователя...",
  "camera-access-invitee-list": "Список приглашенных",
  "camera-options-archive-title": "Запись архива в\xa0облако",
  "camera-options-archive-enabled": "Запись архива в облако включена",
  "camera-options-archive-disabled": "Запись архива в облако отключена",
  "camera-options-connect": "Подключить",
  "camera-options-connect-text":
    "Подключите постоянную запись архива в облако или запись только важных событий",
  "camera-options-tariff-paid": "Тариф оплачен до:",
  "camera-options-autopayment": "Автоплатеж",
  "camera-options-tariff-deactivation": "Отключение тарифа",
  "camera-options-attention":
    "Внимание! Тариф будет отключен. Средства за неиспользованный период будут возвращены на счет.",
  "camera-options-disconnect": "Отключить",
  "camera-options-archiving-enabled-text":
    "Детектор присутствия выявляет важные события в кадре, такие как появление человека или автомобиля.",
  "camera-options-archiving-disabled-text":
    "Сначала необходимо подключить запись архива в облако.",
  "camera-options-detector-title": "Детектор присутствия",
  "camera-options-detector-enabled": "Детектор присутствия включен",
  "camera-options-detector-disabled": "Детектор присутствия отключен",
  "camera-options-term-storage": "Срок хранения",
  "camera-options-events": "событий",
  "camera-options-archive": "архива",
  "camera-options-days": "дня",
  "camera-options-configure-detector": "Настроить",
  "go-to-date": "Перейти к дате...",
  "cameras-item-tabs-options": "Настроить опции",
  "cameras-item-tabs-events": "События",
  "cameras-item-tabs-access": "Доступ к камере",
  "cameras-item-tabs-settings": "Настройки камеры",
  "cameras-settings-camera-on": "Камера включена",
  "cameras-settings-camera-removing": "Удаление камеры",
  "cameras-settings-camera-remove": "Удалить камеру",
  "cameras-settings-camera-remove-msg":
    "Камера будет удалена, архив и события будут также удалены",
  "cameras-settings-owner": "Владелец камеры:",
  "cameras-settings-connected-neurobox": "Подключено через Neurobox:",
  "cameras-settings-notifications": "Уведомления",
  "cameras-settings-notifications-text":
    "Вы можете включить оповещения на e-mail о включении/выключении камеры",
  "cameras-settings-notifications-on": "Включить уведомления",
  "cameras-settings-basic": "Основные настройки",
  "camera-title": "Название камеры",
  "cameras-settings-link-video-stream": "Ссылка на видеопоток",
  additionally: "Дополнительно",
  "cameras-settings-additionally-text":
    "Вы можете удаленно подключиться к веб-интерфейсу камеры и произвести необходимые настройки. Доступно только при подключении камеры с помощью Neurobox",
  "cameras-settings-open-web-interface": "Открыть настройки камеры",
  "sensor-settings-title": "Настройка детектора присутствия",
  "sensor-settings-detector-enabled": "Детектор присутствия включен",
  "sensor-settings-event-responce": "Реагирование на события",
  "man-in-frame": "Человек в кадре",
  "man-in-frame-text":
    "Детектор будет реагировать на появление человека в кадре",
  "settings-on": "Вкл",
  "settings-off": "Выкл",
  "car-in-frame": "Автомобиль в кадре",
  "car-in-frame-text":
    "Детектор будет реагировать на появление автомобиля в кадре",
  "face-in-frame": "Лицо в кадре",
  "sensor-settings-time": "Время работы детектора",
  "24-hours": "Круглосуточно",
  "sensor-settings-hint":
    "Настройте время работы детектора, чтобы избежать ненужных уведомлений",
  "notifications-title": "Типы уведомлений",
  "notifications-site": "Уведомления на сайте",
  "notifications-email": "Уведомления на E-mail",
  "notifications-telegram": "Уведомления в Telegram",
  "sensor-settings-sound": "Звук",
  "save-settings": "Сохранить настройки",
  "events-alert-watch": "Смотреть",
  "events-empty-title": "Нет событий",
  "events-empty-text":
    "Архив событий доступен для камер с подлюченными модулями аналитики — распознаванием лиц и детектором присутствия. Если для камеры также подключена запись архива в облако, вы сможете просматривать короткие видео-ролики событий",
  "events-empty-connect": "Подключение тарифа и настройка детектора",
  "events-grid-delete-selected": "Удалить выбранные",
  "events-grid-mark-viewed": "Отметить как просмотренные",
  "events-grid-mark-unread": "Отметить как непросмотренные",
  selected: "Выбрано:",
  "select-all": "Выбрать все",
  "select-date-range": "Выбрать диапазон дат...",
  select: "Выбрать",
  "events-grid-events": "события",
  "event-time": "Время события",
  "events-itevent-recordingem-share": "Поделиться",
  "events-item-link-access": "Доступ по ссылке",
  viewed: "Просмотрено",
  "preparing-to-connect-title": "Подготовка к подключению",
  "preparing-to-connect-text-app-1":
    "Установите программу на компьютер с доступом в Интернет",
  "preparing-to-connect-text-app-2":
    "Включите камеру и подключите её к той же сети",
  "preparing-to-connect-text-app-3":
    "Компьютер с работающей программой Neurobox должен всегда оставаться включённым — во время и после добавления камер!",
  "preparing-to-connect-device-text-1":
    "Включите устройство Neurobox и подключите его к роутеру с доступом в Интернет",
  "preparing-to-connect-device-text-2":
    "Включите камеру и подключите её к той же сети",
  "preparing-to-connect-": "",
  "scanning-text": "Это может занять несколько минут",
  "refill-error": "Ошибка при создании платежа, попробуйте позже",
  "refill-company-name": "Название компании",
  "refill-legal-address": "Юридический адрес",
  "refill-itn": "ИНН",
  "refill-iec": "КПП",
  "refill-phone-number": "Номер телефона",
  "refill-mailing-address": "Почтовый адрес",
  "refill-invoice": "Счет",
  "refill-invoice-billed": "выставлен",
  "refill-invoice-text":
    "Деньги автоматически поступят на ваш счет в течение 1-2 рабочих дней после оплаты.Закрывающие документы будут сформированы и также доступны для скачивания в разделе «История операций»",
  "refill-invoice-download": "Скачать счет",
  "refill-title": "Пополнение счета",
  "refill-current-balance": "Текущий баланс:",
  "refill-amount": "Сумма",
  "refill-method": "Способ пополнения",
  "refill-credit-card-electronic-money":
    "Кредитной картой / электронными деньгами",
  "refill-bank-transfer": "Банковский перевод для юридических лиц",
  "refill-bank-transfer-soon": "Банковский перевод для юридических лиц (скоро)",
  "refill-bank-transfer-success": "Запрос на счет отправлен",
  "tariffs-event-recording": "Запись событий",
  "tariffs-full-record": "Полная запись",
  "tariffs-paid-to": "Оплачен до",
  "tariffs-auto-renewal": "Автопродление",
  "tariffs-cancel-title": "Отключение тарифа",
  "tariffs-cancel-text":
    "Внимание! Тариф будет отключен. Средства за неиспользованный период будут возвращены на счет.",
  "tariffs-paid-to-title": "Тариф оплачен до",
  "tariffs-cloud-recording-cameras": "Камеры с записью в облако",
  "tariffs-cameras-without-recording": "Камеры без записи",
  "change-password": "Изменить пароль",
  "change-password-old": "Старый пароль",
  "change-password-wrong": "Неверный пароль",
  "change-password-new": "Новый пароль",
  "change-password-new-repeat": "Повторите новый пароль",
  "change-password-passwords-no-match": "Пароли не совпадают",
  "change-photo": "Изменить фото...",
  "box-added": "Добавлен",
  "box-connected-cameras": "Подключено камер",
  "box-cameras-with-sensor": "Камер с детектором",
  "box-total-bitrate": "Общий битрейт",
  "box-cameras-list-show": "Показать список камер",
  "box-cameras-list": "Список камер",
  "box-scan": "Сканировать",
  "box-remove-title": "Удаление neurobox",
  "box-remove": "Удалить neurobox",
  "box-neurobox": "Neurobox",
  "box-neurobox-title": "Название Neurobox",
  "box-will-be-deleted": "будет удален",
  "box-alert-title": "Обратите внимание!",
  "box-alert-text":
    "Все камеры, подключенные с помощью этого Neurobox, будут также удалены. Если для какой-то из камер была подключена услуга хранения архива в облаке, то архив также будет удален, а сумма неиспользованных средств будет возвращена на ваш счет",
  "box-edit-title": "Редактировать название",
  "connect-camera-new-ip": "Подключить новую ip\xa0камеру",
  "connect-camera-select-method": "Выберите способ подключения",
  "connect-camera-via-neurobox-app": "Подключение с помощью программы Neurobox",
  "connect-camera-via-neurobox-app-text":
    "Программа бесплатна. Её необходимо скачать и установить на компьютер. Компьютер с запущенной программой должен оставаться включённым постоянно",
  "connect-camera-via-neurobox-device":
    "Подключение с помощью устройства Neurobox",
  "connect-camera-via-neurobox-device-text":
    "Приобретите устройство Neurobox в нашем интернет-магазине и подключите к роутеру. Вам не понадобится постоянно работающий компьютер",
  "connect-camera-via-neurobox-device-more": "Подробнее об устройстве Neurobox",
  "connect-camera-without-neurobox": "Подключение камеры без Neurobox",
  "connect-camera-without-neurobox-text":
    "При подключении камеры к сервису напрямую вам потребуется выделенный ip-адрес и настройка портов в вашем роутере. Также будут недоступны модули аналитики.",
  "connect-tariff": "Подключение тарифа",
  "connect-tariff-selected": "Выбранный тариф",
  "connect-tariff-balance": "Баланс после оплаты",
  "connect-tariff-to-pay": "К оплате",
  "connect-tariff-not-enough-money": "Не хватает средств",
  "connect-tariff-not-enough-money-text-1":
    "Для подключения выбранного тарифа на счете не хватает",
  "connect-tariff-not-enough-money-text-2":
    "Вы можете пополнить баланс на указанную сумму и подключить тариф",
  "connect-tariff-top-up-balance-on": "Пополнить баланс на",
  today: "Сегодня",
  yesterday: "Вчера",
  "export-archive": "Экспорт архива",
  "export-archive-text":
    "Выделите на таймлайне отрезок видео продолжительностью не более 4 часов",
  "share-in-social-networks": "Поделиться в соц. сетях",
  "login-agree": "Я принимаю",
  "login-terms": "условия соглашения",
  "login-and": "и",
  "login-policy": "политику конфиденциальности",
  "login-password-recovery": "Восстановление пароля",
  "login-password-set": "Установить пароль",
  "login-password-recovery-text":
    "Ссылка для восстановление пароля отправлена вам на e-mail",
  "login-entrance": "Вход",
  "login-registration": "Регистрация",
  "login-password": "Пароль",
  "login-password-forgot": "Забыли пароль?",
  enter: "Войти",
  name: "Имя",
  surname: "Фамилия",
  register: "Зарегистрироваться",
  "enter-camera-name": "Введите название камеры…",
  "connect-cloud-warning": "Камера должна быть подключена с помощью Neurobox",
  "connect-cloud-balance": "Мой баланс",
  "connect-cloud-refill-balance": "Пополнить баланс",
  "connect-cloud-title": "Подключение записи архива в облако",
  "connect-cloud-select-type": "Выберите тип записи",
  "connect-cloud-full-archiving": "Полная запись в архив",
  "connect-cloud-text":
    "Вам будет доступна полная запись в арихва камеры наблюдения",
  "connect-cloud-important-events": "Запись только важных событий",
  "connect-cloud-detector-events":
    "Будут доступны только события, зафиксированные детектором присутствия",
  "select-payment-period": "Выберите период оплаты",
  "payment-for-month": "Оплата за месяц",
  "monthly-payment": "Помесячная оплата с возможностью подключения автоплатежа",
  "free-months": "месяца бесплатно",
  "payment-for-year": "Оплата за год",
  "connect-cloud-yearly-payment":
    "Оплатите хранение архива за год и\xa0получите 2 месяца в подарок",
  "connect-cloud-archive-retention-period": "Выберите срок хранения архива",
  "connect-cloud-tariffs-differ":
    "Тарифы отличаются только сроком хранения записи, все",
  "connect-cloud-opportunities": "возможности",
  "connect-cloud-will-be-available": "Neurocam будут доступны на любом тарифе.",
  neuroboxes: "Нейробоксы",
  "neuroboxes-buy": "Купить устройство Neurobox",
  "neuroboxes-download-app-for": "Скачать приложение Neurobox для",
  "neuroboxes-or": "или",
  "neuroboxes-any-connected": "Нет подключенных Neurobox",
  "neuroboxes-any-connected-text":
    "В настоящий момент нет подключенных устройств. Вы можете купить устройство Neurobox или скачать программу для MacOS или Windows",
  "neuroboxes-go-to-shop": "Перейти в магазин",
  "neuroboxes-free": "Бесплатно",
  "neuroboxes-download-app": "Скачать программу Neurobox",
  "payment-title": "Счета и оплата",
  "payment-my-balance": "Мой баланс",
  "payment-refill": "Пополнить",
  "payment-active-tariffs": "Действующие тарифы",
  "payment-history": "История операций",
  "profile-password-update-success": "Пароль успешно обновлен",
  "profile-password-update-error": "Ошибка при обновлении пароля",
  "data-update-success": "Данные обновлены",
  "data-update-error": "Ошибка при обновлении данных",
  "profile-language": "Язык",
  "profile-title": "Профиль и настройки",
  "profile-settings": "Настройки профиля",
  "profile-telegram-notifications": "Уведомления в Telegram",
  "profile-telegram-notifications-bot": "Бот",
  "profile-telegram-notifications-connect": "Подключить",
  "profile-telegram-notifications-create": "Создать токен",
  "profile-telegram-notifications-token": "Токен",
  service: "Сервис для облачного видеонаблюдения",
  "types-of-events": "Типы событий",
  "settings-saved-successfully": "Настройки успешно сохранены",
  "login-password-recovery-password": "Пароль",
  "login-password-recovery-confirm": "Подтверждение",
  "login-password-recovery-password-mismatch": "Пароли не совпадают",
  recognition: "Распознавание лиц",
  "recognition-enabled-text":
    "Высокоточная технология распознавания и анализа лиц в реальном времени",
  "recognition-disabled-text":
    "Высокоточная технология распознавания и анализа лиц в реальном времени",
  "more-details": "Подробнее",
  persons: "персон",
  "recognition-yearly-payment":
    "Оплатите распознавание лиц за год и получите 2 месяца в подарок",
  "recognition-max-persons": "Выберите количество лиц в сутки",
  "recognition-info":
    "Тарифы отличаются максимальным количеством детекций уникальных лиц по камере за 24 часа. Если лиц за сутки будет больше, чем предусмотрено тарифом, детекции не прекратятся, но в следующем месяце мы попросим вас перейти на более высокий тариф.",
  "recognition-info-1": "Неограниченное количество детекций",
  "recognition-info-2": "Определение пола и возраста",
  "recognition-info-3": "Создание списков распознавания",
  "recognition-info-4": "Мгновенные уведомления",
  "recognition-info-5": "Работа с аналитической панелью",
  "recognition-info-6": "Поиск в архиве по лицам",
  "connect-recognition-title": "Подключение распознавания лиц",
  "recognition-settings-title": "Настройка распознавания лиц",
  "recognition-settings-enabled": "Распознавание лиц включено",
  "recognition-settings-responce": "Уведомления",
  "recognition-settings-time": "Время работы уведомлений",
  "recognition-settings-hint":
    "Настройте расписание, чтобы избежать ненужных уведомлений",
  "persons-from-base": "Персоны из базы",
  "persons-from-base-text": "Распознавание лиц из базы персон",
  strangers: "Незнакомые персоны",
  "strangers-text": "Распознавание незнакомых лиц",
  search: "Поиск...",
  "search-by-photo": "Поиск по фото",
  "all-persons": "Все персоны",
  "persons-search": "Поиск персон...",
  "visitors-search": "Поиск посетителей...",
  "persons-empty-title": "Нет персон",
  "persons-empty-text":
    "Вы можете добавить неограниченное количество персон. Каждая персона должна быть привязана к списку наблюдения, который, в свою очередь, привязывается к камере.",
  "write-to-us": "напишите нам",
  "not-active": "Не активна",
  "not-active-single": "Неактивен",
  "create-new-list": "Создать новый список",
  "persons-new": "Новая персона",
  "persons-new-list": "Новый список персон",
  "persons-edit": "Редактирование персоны",
  "persons-description": "Описание персоны",
  "persons-description-enter": "Введите описание персоны...",
  "surname-and-name": "Фамилия и имя",
  "surname-and-name-enter": "Введите имя и фамилию персоны...",
  "name-enter": "Введите имя",
  "surname-enter": "Введите фамилию",
  "persons-list": "Список персон",
  "persons-photos": "Фотографии персоны",
  description: "Описание",
  information: "Информация",
  "list-title": "Название списка",
  "list-title-enter": "Введите название списка...",
  "list-label-color": "Цвет метки списка",
  "snap-cameras": "Привязать камеры",
  "connect-face-recognition": "Подключить распознавание лиц",
  "list-active": "Список активен",
  "person-removing": "Персона будет удалена",
  "person-removing-msg": "Вы уверены, что хотите удалить персону",
  "person-remove": "Удалить персону",
  "persons-list-removing": "Удалить список персон",
  "persons-list-removing-msg":
    "Список персон будет удален, привязанные персоны останутся во вкладке «Все персоны»",
  "list-remove": "Удалить список",
  "connected-cameras": "Подключенные камеры",
  "cameras-without-tariff": "Камеры без тарифа",
  "сloud-recording": "Запись в облако",
  "select-persons-list": "Выберите список персон...",
  "place-create-success": "Помещение успешно создано",
  "place-create-error": "Ошибка при создании помещения",
  "place-delete-success": "Помещение успешно удалено",
  "place-delete-error": "Ошибка при удалении помещения",
  "person-create-success": "Персона успешно добавлена",
  "person-create-success-partial":
    "Персона успешно добавлена, не на всех фото найдены лица",
  "person-create-error-faces-not-found":
    "Ошибка при создании персоны, лица на фото не найдены",
  "person-create-error-snapped-list":
    "Выбранные списки персон должны быть привязаны ко всем камерам в данном помещении",
  "person-create-error": "Ошибка при создании персоны",
  "person-delete-success": "Персона успешно удалена",
  "person-delete-error": "Ошибка при удалении персоны",
  "person-list-create-success": "Список успешно создан",
  "person-list-create-error": "Ошибка при создании списка персон",
  "person-list-delete-success": "Список успешно удален",
  "person-list-delete-error": "Ошибка при удалении списка персон",
  "not-chosen": "- Не выбрано -",
  "camera-should-be-connected-via-neurobox":
    "Камера должна быть подключена с помощью Neurobox",
  "unknown-person": "Неизвестная персона",
  "unknown-persons": "Неизвестные персоны",
  "event-type": "Тип события",
  "all-types": "Все типы",
  "for-all-the-time": "За все время",
  matches: "Совпадения",
  "all-selected": "Выбраны все",
  "watch-lists": "Списки наблюдения",
  "not-recognized": "Не опознан",
  "snap-to-person": "Привязать к персоне",
  "create-new": "Создать новую",
  reports: "Отчеты",
  places: "Помещение",
  place: "Помещение",
  "all-places": "Все помещения",
  "add-place": "Добавить помещение",
  "time-tracking": "Учет рабочего времени",
  "visitor-analysis": "Анализ посетителей",
  statistics: "Статистика",
  "avg-time-start-work": "Время начала работы (ср.)",
  "avg-time-end-work": "Время окончания работы (ср.)",
  "avg-time-on-work": "Время на работе (ср.)",
  "time-start-work": "Время начала работы",
  "time-end-work": "Время окончания работы",
  "time-on-work": "Время на работе",
  "avg-start-work": "Начало работы (ср.)",
  "avg-end-work": "Окончание работы (ср.)",
  "start-work": "Начало работы",
  "end-work": "Окончание работы",
  employees: "Сотрудники",
  "employees-plural": "сотрудников",
  status: "Статус",
  person: "Персона",
  "employee-statistics": "Статистика работника",
  "new-places": "Новое помещение",
  "name-places": "Название помещения",
  "name-places-enter": "Введите название помещения...",
  "add-group": "Добавить группу",
  "places-cameras-info":
    "Чтобы добавить помещение, вы должны выбрать камеры, работающие на вход в помещение. Чтобы стали доступны данные по продолжительности и времени окончания работы, добавьте камеру, работающую на выход",
  "entrance-camera": "Камера на вход",
  "exit-camera": "Камера на выход (опционально)",
  "monitoring-schedule": "График мониторинга",
  "reports-setting-work-time":
    "Настройте время работы помещения для отображения в отчетах",
  "exclude-watchlists": "Исключить списки персон из отчетов",
  "select-observation-groups":
    "Выберите списки персон, которых не нужно учитывать в отчетах по посетителям (например, ваши сотрудники)",
  monitoring: "Мониторинг",
  "visitor-filter": "Фильтр посетителей",
  gender: "Пол",
  "all-visitors": "Все посетители",
  "only-men": "Только мужчины",
  "only-woman": "Только женщины",
  age: "Возраст",
  all: "Все",
  "less-18-years": "< 18",
  "between-18-24-years-old": "18-24",
  "between-25-34-years-old": "25-34",
  "between-35-44-years-old": "35-44",
  "between-45-54-years-old": "45-54",
  "more-55-years": "> 55",
  visits: "Визиты",
  "only-unique": "Только уникальные",
  unique: "уникальные",
  "only-new": "Только новые",
  "repeat-only": "Только повторные",
  "upload-report": "Выгрузить отчет",
  "footfall-chart": "График посещаемости",
  "total-footfall": "Общая посещаемость",
  "by-gender": "По полу",
  "by-age": "По возрасту",
  "new-returning": "Новые / вернувшиеся",
  emotions: "Эмоции",
  visitors: "Посетители",
  "visitors-short": "П",
  "highest-value": "самое высокое значение",
  "lowest-value": "самое низкое значение",
  max: "макс.",
  min: "мин.",
  sec: "сек.",
  h: "ч.",
  m: "мин.",
  s: "сек.",
  "man-plural": "мужчины",
  "woman-plural": "женщины",
  "new-visits": "новые визиты",
  "rate-of-return": "коэффициент возврата",
  "repeat-visits": "повторные визиты",
  positive: "положительные",
  neutral: "нейтральные",
  negative: "отрицательные",
  "visit-duration": "Продолжительность визита",
  common: "Общая",
  total: "Всего",
  avg: "ср.",
  "avg-visit": "средняя продолжительность визита",
  "avg-visit-duration-men": "ср. продолжительность визита мужчин",
  "avg-visit-duration-women": "ср. продолжительность визита женщин",
  "footfall-map": "Карта посещаемости",
  "frequency-of-visits": "Общее число визитов",
  daily: "1 раз в день или чаще",
  "weekly-more-often": "4-6 раз в неделю",
  "weekly-less-often": "2-3 раза в неделю",
  monthly: "2-4 раза в месяц",
  quarterly: "2-3 раза в квартал",
  "every-six-months": "1-2 раза в полгода",
  "annually-more-often": "1-2 раза в год",
  "annually-less-often": "1 раз в год или реже",
  "place-removing": "Помещение будет удалено",
  "place-removing-msg": "Вы уверены, что хотите удалить помещение",
  "place-remove": "Удалить помещение",
  "place-empty-title": "Нет помещений для наблюдения",
  "place-empty-text":
    "Добавьте помещение, в котором установлены ваши камеры для анализа посетителей и учета рабочего времени",
  "place-add": "Добавить помещение",
  "reports-no-data": "Данные мониторинга недоступны",
  "reports-no-data-text":
    "Добавьте в настройках помещения камеру, работающую на выход, чтобы видеть время, проведенное посетителем в помещении",
  "go-to-settings": "Перейти в настройки",
  "monitoring-tariff-connected": "Тариф на мониторинг подключен",
  "choose-type-tariff": "Выберите тип тарифа:",
  "recognition-only": "Только распознавание",
  "recognition-only-text": "Вам будут доступны все функции распознавания лиц",
  "recognition-reports": "Распознавание + отчеты",
  "recognition-reports-text":
    "Будут доступны все функции распознавания лиц, а также отчеты по посетителям",
  "data-loading": "Данные загружаются…",
  "no-monitoring-data": "Данные мониторинга отсутствуют",
  "no-monitoring-data-text":
    "Отсутствуют данные за выбранный промежуток времени",
  "tariff-not-connected": "Архив не подключен",
  "tariff-not-connected-text":
    "Для просмотра видео-роликов событий подключите запись архива в облако",
  "token-copied": "Токен скопирован",
  location: "Местоположение",
  timezone: "Ваш часовой пояс",
  "not-identified": "Не опознан",
  "max-person-camera-title": "Количество персон по камере",
  exceeded: "превышено",
  "max-person-by-tariff": "Максимальное количество персон по тарифу",
  "gender-male-letter": "М",
  "gender-female-letter": "Ж",
  "person-lists": "Списки персон",
  "person-lists-text":
    "Выберете списки персон для учета рабочего времени (например, ваши сотрудники)",
  company: "Компания",
  "tariff-up-to": "До",
  "tariff-faces": "лиц",
  "tariff-unlimited": "Безлимитный",
  "person-base": "База персон",
  "online-only": "Только онлайн",
  "first-visit": "Первый визит",
  "last-visit": "Последний визит",
  "avg-time": "Среднее время",
  list: "Список",
  male: "Мужчина",
  female: "Женщина",
  "number-of-visits": "Количество посещений",
  "places-cameras": "Помещение / камеры",
  "date-of-visit": "Дата посещения",
  demography: "Демография",
  "no-results-found": "По вашему запросу ничего не найдено",
  "no-connection-neurobox": "Нет соединения с Neurobox",
  "base-with-celeb": "Своя база + селебрити",
  "base-own": "Своя база лиц",
};
