import React from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import Icon from "../Icon";
import ModalLauncher from "../ModalLauncher";
import ConnectCamera from "../ConnectCamera";

const CamerasEmpty = (props) => {
  const { t } = props;
  return (
    <div className="page">
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic" name="camera-lg" />
        </div>
        <div className="page__title">{t("empty-title")}</div>
        <div className="page__text">{t("empty-text")}</div>
        <div className="page__foot">
          <ModalLauncher
            className="page__btn"
            buttonClassName="btn btn_lg"
            label={t("empty-label-add")}
          >
            <ConnectCamera />
          </ModalLauncher>
        </div>
      </div>
    </div>
  );
};

export default withRouter(translate()(CamerasEmpty));
