import { token } from "./links";
import { parseURL } from "./http";

export const noop = () => {};

export function debounceFactory(method, duration) {
  let timer;

  function debounceWrapper(...args) {
    debounceWrapper.clear();

    timer = setTimeout(() => {
      timer = null;
      method.apply(this, args);
    }, duration);
  }

  debounceWrapper.clear = function () {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };

  return debounceWrapper;
}

export const windowActive = (callback) => {
  let stateKey,
    eventKey,
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange",
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  if (callback != null) return document.addEventListener(eventKey, callback);

  return !document[stateKey];
};

export const preventDefault = (f) => (e) => {
  e.preventDefault();
  f(e);
};

export const demoToken = () =>
  token() ===
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3NCIsImlzcyI6ImRlbW9AbmV1cm9jYW0uY29tIn0.ho3KGqlY73fyPi4fyWgf4vCO_beQbiHlhVK3dw-nlIA";

// filter camera list with priorities: ONVIF source, RTSP protocol
export const FilterCameras = (cameras) => {
  const grouped = cameras
    .map((c) => ({ ...c, url: parseURL(c.stream_url) }))
    .map((c) => {
      let priority = 0;
      if (c.url.scheme === "rtsp") priority += 2;
      if (c.scan_source === "onvif") priority += 1;
      return { ...c, priority };
    })
    .reduce((g, cam) => {
      if (!(cam.url.host in g)) return { ...g, [cam.url.host]: cam };

      if (g[cam.url.host].priority < cam.priority)
        return { ...g, [cam.url.host]: cam };

      return g;
    }, {});

  return Object.values(grouped);
};

// numeralize
export const numeralize = (language, n, titles) => {
  if (language.startsWith("ru")) {
    return titles[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];
  } else {
    return titles[n !== 1 ? 4 : 3];
  }
};

// capitalize first letter
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// k formatter
export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? (Math.abs(num) / 1000).toFixed(1) + "k"
    : parseFloat(Math.abs(num).toFixed(2));
};

// gender
export const gender = (n, t) => {
  switch (n) {
    case 1:
      return t("gender-male-letter");
    case 2:
      return t("gender-female-letter");
    default:
      return "";
  }
};

// age range
export const ageRange = (n) => {
  switch (true) {
    case n < 18:
      return "< 18";
    case n < 25:
      return "18-24";
    case n < 35:
      return "25-34";
    case n < 45:
      return "35-44";
    case n < 55:
      return "45-54";
    case n >= 55:
      return "> 55";
    default:
      return "";
  }
};

// check eurocam
export const isEurocam = () => {
  return process.env.REACT_APP_MODE === "eu";
};

// currency
export const currency = isEurocam() ? "eur" : "rub";

// check permissions
export const checkPerms = (user, key) => {
  if (user.company) {
    if (user.company_owner) {
      return true;
    } else {
      return user.permissions?.includes(key);
    }
  } else {
    return true;
  }
};

// check company owner
export const checkCompanyOwner = (user) => {
  if (user.company) {
    if (user.company_owner) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

// dates
export const MONTHS = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
};

export const WEEKDAYS_SHORT = {
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  ru: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
};

export const FIRST_DAY_OF_WEEK = {
  en: 0,
  ru: 1,
};

// file to base 64
export const toBase64 = function (file) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let dataUrl = reader.result;
      resolve(dataUrl);
    };
  });
};
