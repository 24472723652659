import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Button.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";

const Button = ({
  className,
  icon,
  title,
  to,
  onClick,
  loading,
  disabled,
  large,
  white,
}) => {
  const classes = cn(
    styles.button,
    { [styles.large]: large },
    { [styles.white]: white },
    { [styles.loading]: loading },
    className
  );

  const content = () => (
    <>
      {icon && <Icon name={icon} />}
      {title}
      {loading && <Loader color="white" />}
    </>
  );

  return to ? (
    <Link className={classes} to={to}>
      {content()}
    </Link>
  ) : (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {content()}
    </button>
  );
};

export default Button;
