import React from "react";
import { kFormatter } from "../utils";

const Counter = props => {
  const { className, value } = props;
  return (
    <div className={"counter " + (className ? className : "")}>
      {kFormatter(value)}
    </div>
  );
};

export default Counter;
