import HttpClient from "../http";
import { CamerasList } from "./cameras";
import { clearMarkers } from "./archive";

const initialState = {
  camera: {
    Camera: {},
    shared: [],
    neurobox: "",
  },
  fetched: false,
  events: [],
  eventsCount: 0,
  ranges: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMERA_FETCH:
      return { ...state, camera: action.payload, fetched: true };
    case CAMERA_CLEAR:
      return initialState;
    case CAMERA_FETCH_EVENTS:
      return {
        ...state,
        events: action.payload.events || [],
        eventsCount: action.payload.new,
      };
    case CAMERA_REMOVE:
      return initialState;
    case CAMERA_ADD_EVENT:
      return {
        ...state,
        events: [action.payload, ...state.events],
        eventsCount: state.eventsCount + 1,
      };
    case CAMERA_NEUROBOX_FETCH:
      return {
        ...state,
        camera: { ...state.camera, Neurobox: action.payload },
      };
    default:
      return state;
  }
};

const CAMERA_FETCH = "CAMERA_FETCH";
export const CameraInfo = (id) => async (dispatch) => {
  const response = await HttpClient.get(`/video/cameras/${id}`);
  return dispatch({ type: CAMERA_FETCH, payload: response.data });
};

export const CameraInfoFull = (id) => async (dispatch) => {
  dispatch(clearMarkers);

  dispatch(CameraEvents(id));
  return dispatch(CameraInfo(id));
};

const CAMERA_CLEAR = "CAMERA_CLEAR";
export const CameraClear = () => (dispatch) => {
  return dispatch({ type: CAMERA_CLEAR });
};

const CAMERA_FETCH_EVENTS = "CAMERA_FETCH_EVENTS";
export const CameraEvents = (cameraId) => async (dispatch, getState) => {
  const cid = cameraId || getState().camera.camera.Camera.id;

  const countResponse = await HttpClient.get(
    `/archive/events/count_new?camera_id=${cid}`
  );
  const response = await HttpClient.get(
    `/archive/events?camera_id=${cid}&page_limit=10`
  );

  return dispatch({
    type: CAMERA_FETCH_EVENTS,
    payload: { new: countResponse.data.count, events: response.data.objects },
  });
};

const CAMERA_ADD_EVENT = "CAMERA_ADD_EVENT";
export const CameraAddEvent = (event) => (dispatch) => {
  return dispatch({ type: CAMERA_ADD_EVENT, payload: event });
};

export const CameraPatch = (cameraData, cameraId, sharedUsers) => async (
  dispatch,
  getState
) => {
  const cid = cameraId || getState().camera.camera.Camera.id;
  const response = await HttpClient.patch(`/video/cameras/${cid}`, {
    camera: cameraData,
    shared_users:
      sharedUsers || getState().camera.camera.shared.map((i) => ({ ID: i })),
  });

  if (response.data.Errors) {
    throw new Error(response.data.Errors);
  }

  return dispatch(CameraInfo(cid));
};

const CAMERA_REMOVE = "CAMERA_REMOVE";
export const CameraRemove = (cameraId) => async (dispatch, getState) => {
  const cid = cameraId || getState().camera.camera.Camera.id;
  await HttpClient.delete(`/video/cameras/${cid}`);

  dispatch({ type: CAMERA_REMOVE });

  return dispatch(CamerasList());
};

const CAMERA_ADD_SHARE = "CAMERA_ADD_SHARE";
export const CameraAddShare = (email, cameraId) => async (
  dispatch,
  getState
) => {
  const cameraState = getState().camera.camera;
  const cid = cameraId || cameraState.Camera.id;
  const shared = cameraState.shared
    .map((sh) => ({ id: sh }))
    .concat({
      email: email,
    });

  const response = await HttpClient.patch(`/video/cameras/${cid}`, {
    camera: cameraState.Camera,
    shared_users: shared,
  });

  if (!response.data.Ok) {
    throw new Error(response.data.Errors);
  }

  await dispatch({ type: CAMERA_ADD_SHARE, payload: response.data.shared });

  return dispatch(CameraInfo(cameraId));
};

const CAMERA_REMOVE_SHARE = "CAMERA_REMOVE_SHARE";
export const CameraRemoveShare = (userId, cameraId) => async (
  dispatch,
  getState
) => {
  const cameraState = getState().camera.camera;
  const cid = cameraId || cameraState.Camera.id;
  const shared = cameraState.shared
    .filter((sh) => sh !== userId)
    .map((sh) => ({
      id: sh,
    }));

  const response = await HttpClient.patch(`/video/cameras/${cid}`, {
    camera: cameraState.Camera,
    shared_users: shared,
  });

  if (!response.data.Ok) {
    throw new Error(response.data.Errors);
  }

  await dispatch({ type: CAMERA_REMOVE_SHARE, payload: response.data.Shared });

  return dispatch(CameraInfo(cameraId));
};

const CAMERA_NEUROBOX_FETCH = "CAMERA_NEUROBOX_FETCH";
export const CameraNeuroboxFetch = (neuroboxId) => async (
  dispatch,
  getState
) => {
  const cameraState = getState().camera.camera;
  const id = neuroboxId || cameraState.Camera.neurobox_id;

  if (!id) return;

  const response = await HttpClient.get(`/video/neurobox/${id}`);

  return dispatch({ type: CAMERA_NEUROBOX_FETCH, payload: response.data });
};
