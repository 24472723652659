import React from "react";
import { translate } from "react-i18next";
import InputMask from "react-input-mask";

class Time extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, timeFrom, timeTo, onChangeTime, disabled, t } = this.props;
    return (
      <div className="field">
        {label && <div className="field__label field__label_dark">{label}</div>}
        <div className="field__container">
          <div className="field__time">
            <div className="field__from">{t("from")}</div>
            <div className="filed__wrap">
              <InputMask
                className="field__input"
                type="text"
                name="timeFrom"
                disabled={disabled}
                value={timeFrom}
                mask="99:99"
                onChange={onChangeTime}
              />
            </div>
            <div className="field__to">{t("to")}</div>
            <div className="filed__wrap">
              <InputMask
                className="field__input"
                type="text"
                name="timeTo"
                disabled={disabled}
                value={timeTo}
                mask="99:99"
                onChange={onChangeTime}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(Time);
