import HttpClient from "../http";
import { NOTIFICATION_CONNECT } from "./notifications";
import moment from "moment-timezone";

const STORAGE_TOKEN_VAR = "token";

const setHttpHeaders = (token) => {
  HttpClient.defaults.headers["Authorization"] = `Bearer ${token}`;
};

const initialState = {
  user: null,
  usersInfo: {},
  usersInfoFetched: false,
  token: localStorage.getItem(STORAGE_TOKEN_VAR),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_SIGNIN_SUCCESS:
      localStorage.setItem(STORAGE_TOKEN_VAR, action.payload);
      setHttpHeaders(action.payload);
      return { ...state, token: action.payload };
    case USERS_SIGNOUT_SUCCESS:
      localStorage.removeItem(STORAGE_TOKEN_VAR);
      return initialState;
    case USERS_TOKEN_LOADED:
      setHttpHeaders(action.payload);
      return { ...state, token: action.payload };
    case USERS_ME_LOADED:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

const USERS_SIGNIN_SUCCESS = "USERS_SIGNIN_SUCCESS";
export const SignIn = (username, password) => async (dispatch) => {
  const response = await HttpClient.post("/users/login", {
    username,
    password,
  });
  const { token } = response.data;

  dispatch({ type: USERS_SIGNIN_SUCCESS, payload: token });

  return dispatch(Me());
};

const USERS_SIGNOUT_SUCCESS = "USERS_SIGNOUT_SUCCESS";
export const SignOut = () => (dispatch) => {
  dispatch({ type: USERS_SIGNOUT_SUCCESS });
};

const USERS_TOKEN_LOADED = "USERS_TOKEN_LOADED";
export const LoadToken = () => (dispatch) => {
  const token = localStorage.getItem(STORAGE_TOKEN_VAR);
  dispatch({ type: USERS_TOKEN_LOADED, payload: token });
};

const USERS_ME_LOADED = "USERS_ME_LOADED";
export const Me = () => async (dispatch, getState) => {
  const response = await HttpClient.get("/users/me");
  dispatch({ type: NOTIFICATION_CONNECT });
  return dispatch({ type: USERS_ME_LOADED, payload: response.data });
};

const USER_ME_PATCH = "USER_ME_PATCH";
export const MePatch = (data) => async (dispatch, getState) => {
  await HttpClient.patch("/users/me", data);
  dispatch({ type: USER_ME_PATCH });
  return dispatch(Me());
};

const USER_ME_CHANGE_PASSWORD = "USER_ME_CHANGE_PASSWORD";
export const MeChangePassword = (data) => async (dispatch) => {
  await HttpClient.post("/users/me/password", data);
  dispatch({ type: USER_ME_CHANGE_PASSWORD });
  return dispatch(Me());
};

export const ResetPassword = (email) => async (dispatch) => {
  await HttpClient.post("/users/password-reset", { Email: email });
};

export const ResetPasswordConfirm = (token, newPassword) => async (
  dispatch
) => {
  return await HttpClient.post("/users/password-reset-confirm", {
    Token: token,
    Password: newPassword,
    ConfirmPassword: newPassword,
  });
};

export const Registration = (data) => async (dispatch) => {
  return await HttpClient.post("/users/registration", data);
};

// returns timestamp shifted by user timezone offset
export const tzOffset = (state, ts) => {
  if (!state || !state.users) return ts.getTime() / 1000;

  const timezone = state.users.user && state.users.user.Timezone;
  if (!timezone) return ts.getTime() / 1000;

  const mtz = moment.tz(ts, timezone);
  const shifted =
    mtz.unix() - mtz.utcOffset() * 60 - new Date().getTimezoneOffset() * 60;

  return shifted;
};

// returns since and until shifted by user timezone offset
export const tzOffsetPeriod = (state, since, until) => {
  const newSince = tzOffset(state, since);
  const newUntil = tzOffset(state, until);
  return [newSince, newUntil];
};
