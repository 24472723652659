import React from "react";
import cn from "classnames";

const Icon = ({ className, name }) => {
  const classes = cn("icon", "icon-" + name, className && className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classes}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

export default Icon;
