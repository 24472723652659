import { useEffect } from 'react'

export const MarkersUpdater = ({cameraId, onTick}) => {
    useEffect(() => {
        const token = cameraId && setInterval(() => {
            onTick && onTick(cameraId)
        }, 1000)

        return () => {
            clearInterval(token)
        }
    }, [cameraId])

    return null;
}