import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import Switch from "../Switch";
import Time from "../Time";
// import Dropdown from "../Dropdown";

const defaultSettings = {
  around_clock: true,
  base_persons: true,
  unknown_persons: true,
  notifications: {
    webapp: true,
  },
  working_hours: {},
};

class RecognitionSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sensorOn: true,
      settings:
        props.camera.face_recognition_enabled && props.camera.detector_settings
          ? props.camera.detector_settings.recognition || defaultSettings
          : defaultSettings,
    };
  }

  toggleSensor = () => {
    this.setState({
      sensorOn: !this.state.sensorOn,
    });
  };

  onChange = (key, value) =>
    this.setState({ settings: { ...this.state.settings, [key]: value } });

  onChangeWorkingHours = (day, values) => {
    let workingHours = this.state.settings.working_hours || {};

    workingHours = {
      ...workingHours,
      [day]: {
        ...workingHours[day],
        ...values,
      },
    };

    this.setState({
      settings: { ...this.state.settings, working_hours: workingHours },
    });
  };

  onSave = () =>
    this.props
      .onSave({
        ...this.props.camera,
        detector_settings: {
          ...this.props.camera.detector_settings,
          recognition: this.state.settings,
        },
      })
      .then(() => {
        if (this.props.closeModal) {
          this.props.closeModal();
        }
      });

  getGroups = () =>
    this.props.groups.map((item) => ({ id: item.id, name: item.title }));

  renderWorkingHoursCheckbox = (day, title) => {
    const wh =
      (this.state.settings.working_hours &&
        this.state.settings.working_hours[day]) ||
      {};

    return (
      <Switch
        disabled={this.state.settings.around_clock}
        content={title}
        value={wh.enabled}
        onChange={(e) =>
          this.onChangeWorkingHours(day, {
            enabled: e.target.checked,
          })
        }
      />
    );
  };

  renderWorkingHoursTime = (day) => {
    const wh =
      (this.state.settings.working_hours &&
        this.state.settings.working_hours[day]) ||
      {};

    return (
      <Time
        disabled={this.state.settings.around_clock}
        timeFrom={wh.start}
        timeTo={wh.end}
        onChangeTime={(e) => {
          this.setState({
            settings: {
              ...this.state.settings,
              working_hours: {
                ...this.state.settings.working_hours,
                [day]: {
                  ...this.state.settings.working_hours[day],
                  [e.target.name === "timeFrom" ? "start" : "end"]: e.target
                    .value,
                },
              },
            },
          });
        }}
      />
    );
  };

  render() {
    const { sensorOn, settings } = this.state;
    const { camera, t } = this.props;

    return (
      <div className="sensor">
        <div className="sensor__container">
          <div className="sensor__title title">
            {t("recognition-settings-title")}
          </div>
          <div className="sensor__flex">
            <div className="sensor__info">
              <span className="sensor__label">{t("camera")}:</span>
              {camera.title}
            </div>
            {/* <div className="sensor__info">
              <Switch
                value={sensorOn}
                content={t("recognition-settings-enabled")}
                toggler
                onChange={this.toggleSensor}
              />
            </div> */}
          </div>
          {/* <div className="sensor__field field">
            <div className="field__label">{t("persons-list")}</div>
            <div className="field__wrap">
              <Dropdown
                title={t("select-persons-list")}
                checkboxes={this.getGroups()}
                placeholder={true}
                field
                adding
              />
            </div>
          </div> */}
          <div className={"sensor__area " + (sensorOn ? "" : "disabled")}>
            <div className="sensor__subtitle">
              {t("recognition-settings-responce")}
            </div>
            <div className="sensor__group">
              <div className="sensor__event">
                <div className="sensor__col">
                  <img
                    src="/img/type-face.svg"
                    className={
                      "sensor__pic " + (settings.base_persons ? "" : "disabled")
                    }
                    alt=""
                  />
                </div>
                <div className="sensor__col">
                  <div className="sensor__category">
                    {t("persons-from-base")}
                  </div>
                  <div className="sensor__description">
                    {t("persons-from-base-text")}
                  </div>
                </div>
                <div className="sensor__col">
                  <Switch
                    toggler
                    content={
                      settings.base_persons
                        ? t("settings-on")
                        : t("settings-off")
                    }
                    value={settings.base_persons}
                    onChange={(e) =>
                      this.onChange("base_persons", e.target.checked)
                    }
                  />
                </div>
              </div>
              <div className="sensor__event">
                <div className="sensor__col">
                  <img
                    src="/img/type-strangers.svg"
                    className={
                      "sensor__pic " +
                      (settings.unknown_persons ? "" : "disabled")
                    }
                    alt=""
                  />
                </div>
                <div className="sensor__col">
                  <div className="sensor__category">{t("strangers")}</div>
                  <div className="sensor__description">
                    {t("strangers-text")}
                  </div>
                </div>
                <div className="sensor__col">
                  <Switch
                    toggler
                    content={
                      settings.unknown_persons
                        ? t("settings-on")
                        : t("settings-off")
                    }
                    value={settings.unknown_persons}
                    onChange={(e) =>
                      this.onChange("unknown_persons", e.target.checked)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="sensor__head">
              <div className="sensor__subtitle">
                {t("recognition-settings-time")}
              </div>
              <Switch
                content={t("24-hours")}
                toggler
                value={settings.around_clock}
                onChange={(e) =>
                  this.onChange("around_clock", e.target.checked)
                }
              />
            </div>
            <div className="sensor__hint">{t("recognition-settings-hint")}</div>
            <div className="sensor__group">
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("monday", t("monday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("monday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("tuesday", t("tuesday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("tuesday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("wednesday", t("wednesday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("wednesday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("thursday", t("thursday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("thursday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("friday", t("friday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("friday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("saturday", t("saturday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("saturday")}
                </div>
              </div>
              <div className="sensor__hours">
                <div className="sensor__col">
                  {this.renderWorkingHoursCheckbox("sunday", t("sunday"))}
                </div>
                <div className="sensor__col">
                  {this.renderWorkingHoursTime("sunday")}
                </div>
              </div>
            </div>
            <div className="sensor__subtitle">{t("notifications-title")}</div>
            <div className="sensor__group">
              <div className="sensor__subscribe">
                <div className="sensor__col">
                  <Switch
                    content={t("notifications-site")}
                    value={settings.notifications.webapp}
                    onChange={(e) =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            webapp: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                </div>
                <div className="sensor__col">
                  {/* <Switch
                    content={t("sensor-settings-sound")}
                    toggler
                    value={settings.notifications.webapp_sound}
                    onChange={e =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            webapp_sound: e.target.checked
                          }
                        }
                      })
                    }
                  /> */}
                </div>
              </div>
              <div className="sensor__subscribe">
                <div className="sensor__col">
                  <Switch
                    content={t("notifications-telegram")}
                    value={settings.notifications.telegram}
                    onChange={(e) =>
                      this.setState({
                        settings: {
                          ...this.state.settings,
                          notifications: {
                            ...this.state.settings.notifications,
                            telegram: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sensor__foot">
            <button className="sensor__btn btn" onClick={this.onSave}>
              {t("save-settings")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.faces.groups,
});

export default translate()(connect(mapStateToProps)(RecognitionSettings));
