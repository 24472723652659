import React from "react";
import Icon from "./Icon";

export default function CopyLink({ link }) {
  return (
    <div className="copylink">
      <div className="copylink__content">{link}</div>
      <button className="copylink__btn">
        <Icon className="copylink__icon" name="copy" />
      </button>
    </div>
  );
}
