import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "./Icon";
import ModalLauncher from "./ModalLauncher";
import Confirm from "./Confirm";
import Alert from "./Alert";

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleCameras: false
    };
  }

  toggleCameras = e => {
    e.preventDefault();
    this.setState({
      visibleCameras: !this.state.visibleCameras
    });
  };

  render() {
    const { className, box, t } = this.props;
    const { visibleCameras } = this.state;
    return (
      <div className={cn("box", className, { active: visibleCameras })}>
        <div className="box__preview">
          <Icon className="box__pic" name="box" />
          <div className="box__badge" />
        </div>
        <div className="box__container">
          <div className="box__title">{box.title}</div>
          <div className="box__description">{box.description}</div>
          <div className="box__inner">
            <div className="box__code">{box.code}</div>
            <div className="box__details">
              <div className="box__detail">
                <span className="bold">{t("box-added")}:</span> {box.added}
              </div>
              <div className="box__detail">
                <span className="bold">{t("box-connected-cameras")}: </span>
                {box.connectedCameras}
              </div>
              <div className="box__detail">
                <span className="bold">{t("box-cameras-with-sensor")}:</span>{" "}
                {box.sensor}{" "}
                <span className="color-gray">(макс. {box.sensorMax})</span>
              </div>
              <div className="box__detail">
                <span className="bold">{t("box-total-bitrate")}:</span>{" "}
                {box.bitrate} {t("bitrate-sec")}
              </div>
            </div>
            <a href="#/" className="box__open" onClick={this.toggleCameras}>
              {t("box-cameras-list-show")}
            </a>
            {visibleCameras && (
              <div className="box__cameras">
                <div className="box__head">
                  <div className="box__subtitle">{t("box-cameras-list")}</div>
                  <button
                    className="box__action action action_sm"
                    onClick={this.toggleCameras}
                  >
                    <Icon className="box__icon" name="close-sm" />
                  </button>
                </div>
                <div className="box__scroll">
                  {box.cameras.map(x => (
                    <div className="box__camera">
                      <div className={"box__status " + (x.on ? "active" : "")}>
                        <svg
                          className="box__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="15"
                          viewBox="0 0 22 15"
                        >
                          <path d="M11,0 C6,0 1.73,3.11 0,7.5 C1.73,11.89 6,15 11,15 C16,15 20.27,11.89 22,7.5 C20.27,3.11 16,0 11,0 Z M11,12.5 C8.24,12.5 6,10.26 6,7.5 C6,4.74 8.24,2.5 11,2.5 C13.76,2.5 16,4.74 16,7.5 C16,10.26 13.76,12.5 11,12.5 Z M11,4.5 C9.34,4.5 8,5.84 8,7.5 C8,9.16 9.34,10.5 11,10.5 C12.66,10.5 14,9.16 14,7.5 C14,5.84 12.66,4.5 11,4.5 Z" />
                        </svg>
                      </div>
                      <div className="box__label">{x.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="box__foot">
              <button className="box__btn btn">{t("box-scan")}</button>
              <ModalLauncher
                modalClassName="modal_md"
                buttonClassName="btn_white"
                label={t("remove")}
              >
                <Confirm
                  title={t("box-remove-title")}
                  button={t("box-remove")}
                  onClick
                >
                  <p>
                    {t("box-neurobox")} f2f-23wf-2345-14{" "}
                    {t("box-will-be-deleted")}.
                  </p>
                  <Alert
                    className="alert_mt"
                    title={t("box-alert-title")}
                    content={t("box-alert-text")}
                  />
                </Confirm>
              </ModalLauncher>
            </div>
          </div>
        </div>
        <ModalLauncher
          className="box__action"
          modalClassName="modal_md"
          action
          icon="edit"
        >
          <Confirm title={t("box-edit-title")} button={t("save")} onClick>
            <div className="field">
              <div className="field__label">{t("box-neurobox-title")}</div>
              <div className="field__wrap">
                <input
                  type="text"
                  className="field__input"
                  defaultValue={box.title}
                />
              </div>
            </div>
          </Confirm>
        </ModalLauncher>
      </div>
    );
  }
}

export default translate()(Box);
