import React from "react";
import { translate } from "react-i18next";
import { toast } from "react-toastify";
import Switch from "../Switch";
import { isEurocam, currency } from "../../utils";

const MODE_PERSONAL = 1;
const MODE_COMPANY = 2;

class Refill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: isEurocam() ? MODE_COMPANY : MODE_PERSONAL,
      total: props.total || 1000,
    };
  }

  onPersonalPayment = () => {
    this.props.onPersonalPayment(this.state.total).catch((err) => {
      console.log(err);
      this.props.closeModal();
      toast.error(this.props.t("refill-error"));
    });
  };

  onBankTransferPayment = () => {
    this.props
      .onBankTransferPayment({ total: this.state.total })
      .then(() => {
        toast.success(this.props.t("refill-bank-transfer-success"));
      })
      .catch((err) => {
        console.log(err);
        this.props.closeModal();
        toast.error(this.props.t("refill-error"));
      });
  };

  renderPayForm = () =>
    this.state.mode === MODE_PERSONAL
      ? this.renderPersonalForm()
      : this.renderCompanyForm();

  renderPersonalForm = () => {
    return (
      <div className="refill__foot">
        <button className="btn btn_lg" onClick={() => this.onPersonalPayment()}>
          {this.props.t("continue")}
        </button>
      </div>
    );
  };

  renderCompanyForm = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        {!isEurocam() && (
          <div className="refill__group">
            <div className="refill__field field">
              <div className="field__label">{t("refill-company-name")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="ООО Рога и Копыта"
                />
              </div>
            </div>
            <div className="refill__field field">
              <div className="field__label">{t("refill-legal-address")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="464124, г. Москва, Тверская, 1, оф. 135"
                />
              </div>
            </div>
            <div className="refill__field field">
              <div className="field__label">{t("refill-itn")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="543436777"
                />
              </div>
            </div>
            <div className="refill__field field">
              <div className="field__label">{t("refill-iec")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="3463466777"
                />
              </div>
            </div>
            <div className="refill__field field">
              <div className="field__label">{t("refill-phone-number")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="+7 821 123 32 34"
                />
              </div>
            </div>
            <div className="refill__field field">
              <div className="field__label">{t("refill-mailing-address")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  defaultValue="464124, г. Москва, Тверская, 1, оф. 135"
                />
              </div>
            </div>
          </div>
        )}
        <div className="refill__foot">
          <button
            className="btn btn_lg"
            onClick={() => this.onBankTransferPayment()}
          >
            {this.props.t("continue")}
          </button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { balance, t } = this.props;

    return (
      <div className="refill">
        <div className="refill__form">
          <div className="refill__title title">{t("refill-title")}</div>
          <div className="refill__info">
            <span className="bold">{t("refill-current-balance")}</span>{" "}
            {balance} {t(currency)}
          </div>
          <div className="refill__subtitle subtitle">{t("refill-amount")}</div>
          <div className="refill__field field field_payment">
            <div className="field__wrap">
              <input
                type="text"
                className="field__input"
                value={this.state.total}
                onChange={(e) => this.setState({ total: e.target.value })}
              />
              <div className="field__unit">{t(currency)}</div>
            </div>
          </div>
          <div className="refill__subtitle subtitle">{t("refill-method")}</div>
          <div className="refill__group">
            {!isEurocam() && (
              <Switch
                content={t("refill-credit-card-electronic-money")}
                radio
                name="method"
                value={this.state.mode === MODE_PERSONAL}
                onChange={() => this.setState({ mode: MODE_PERSONAL })}
              />
            )}
            <Switch
              content={t(
                isEurocam()
                  ? "refill-bank-transfer"
                  : "refill-bank-transfer-soon"
              )}
              radio
              name="method"
              value={this.state.mode === MODE_COMPANY}
              onChange={() => this.setState({ mode: MODE_COMPANY })}
              disabled={!isEurocam()}
            />
          </div>
        </div>
        {this.renderPayForm()}
      </div>
    );
  }
}

export default translate()(Refill);
