import React from "react";
import cn from "classnames";

const ReportsChangingsPercentage = (props) => {
  const { baseline, prev, current } = props;

  let value = 0;

  if (prev) {
    value = current - prev;
  }

  return (
    Math.abs(value) !== 0 &&
    !isNaN(value) && (
      <span
        className={cn(
          "chart__change change",
          {
            red: value < 0,
          },
          {
            baseline: baseline,
          }
        )}
      >
        {Math.abs(value)}%
      </span>
    )
  );
};

export default ReportsChangingsPercentage;
