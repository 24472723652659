import React from "react";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import ConnectTariff from "../components/ConnectTariff";
import { BalanceLoad } from "../reducers/payments";
import { CameraInfo } from "../reducers/camera";
import Rates from "../rates";
import { currency } from "../utils";

// const helpUrl =
//   "https://help.neurocam.com/%D0%B2%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%B0";

const FULL_RECORD = "full";
const IMPORTANT_RECORD = "important";

const eventsName = (t, rate) =>
  `${t("events-storage")} (${rate.days} ${t("days")})`;

const fullName = (t, rate) =>
  `${t("archive-storage")} (${rate.days} ${t("days")})`;

const archiveRate = (t, rate) => ({
  ...rate,
  name: rate.archiveOnlyEvents ? eventsName(t, rate) : fullName(t, rate),
});

const Option = ({ children, active, onClick }) => {
  const className = "features__option" + (active ? " active" : "");
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

class ConnectCloud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleTooltip: true,
      showModal: false,
      payPerMonth: true,
      payType: FULL_RECORD,
      tariffs: Rates.archiveFullTarrifs.month,
      selectedTariff: {},
    };
  }

  componentDidMount() {
    if (this.props.camera.id !== this.props.match.params.id) {
      this.props.CameraInfo(this.props.match.params.id);
    }
    this.props.BalanceLoad();
  }

  isFullRecord = () => this.state.payType === FULL_RECORD;

  isImportantRecord = () => this.state.payType === IMPORTANT_RECORD;

  setPayType = (payType) => {
    if (payType === IMPORTANT_RECORD && !this.props.camera.neurobox_id) {
      toast.warn(this.props.t("connect-cloud-warning"));
      return;
    }

    this.setState({
      payType,
      tariffs: this.state.payPerMonth
        ? Rates.archiveTarrifsByID[payType].month
        : Rates.archiveTarrifsByID[payType].year,
    });
  };

  handleToggleModal = (rate) => (e) => {
    e.preventDefault();
    this.setState({ showModal: !this.state.showModal });
    if (!this.state.showModal) {
      this.setState({
        selectedTariff: rate,
      });
    }
  };

  togglePeriod = () => {
    const group = this.isFullRecord()
      ? Rates.archiveFullTarrifs
      : Rates.archiveEventsTarrifs;

    if (this.state.payPerMonth === true) {
      this.setState({
        tariffs: group.year,
      });
    } else {
      this.setState({ tariffs: group.month });
    }

    this.setState({
      visibleTooltip: false,
      payPerMonth: !this.state.payPerMonth,
    });
  };

  renderDetails = () => {
    const { t } = this.props;
    return (
      <div className="features__details">
        <div className="features__detail">
          <span className="bold">{t("camera")}:</span> {this.props.camera.title}
        </div>
        <div className="features__detail">
          <span className="bold">{t("connect-cloud-balance")}:</span>{" "}
          {this.props.balance} {t(currency)}{" "}
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/payment");
            }}
          >
            {t("connect-cloud-refill-balance")}
          </a>
        </div>
      </div>
    );
  };

  renderRate = (rate, index) => {
    const { t } = this.props;
    return (
      <div
        className="features__period"
        onClick={this.handleToggleModal(rate)}
        key={index}
      >
        <div className="features__days">
          <span className="color-red">{rate.days}</span>{" "}
          {rate.daysText ? t("rate-day") : t("rate-days")}
        </div>
        <div className="features__price">
          <div className="features__actual">
            {rate.price} {t(currency)}
          </div>
          {rate.priceOld && (
            <div className="features__before">
              {rate.priceOld} {t(currency)}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderRates = () => {
    const byPayType = this.isFullRecord()
      ? Rates.archiveFullTarrifs
      : Rates.archiveEventsTarrifs;
    const rates = byPayType[this.state.payPerMonth ? "month" : "year"];
    return rates.map((rate, index) =>
      this.renderRate(Rates.archiveTarrifsByID[rate], index)
    );
  };

  render() {
    const { showModal, selectedTariff } = this.state;
    const { t, camera } = this.props;

    if (camera.archiving) {
      return <Redirect to="/cameras" />;
    }

    return (
      <div className="features">
        <div className="features__head">
          <div className="features__title title">
            {t("connect-cloud-title")}
          </div>
          {this.renderDetails()}
          <Link className="features__action action" to="/payment">
            <Icon name="close-sm" />
          </Link>
        </div>
        <div className="features__body">
          {this.renderDetails()}
          <div className="features__steps">
            <div className="features__step">
              <div className="features__heading">
                <span className="color-red">1.</span>{" "}
                {t("connect-cloud-select-type")}
              </div>
              <div className="features__list features__list_flex">
                <Option
                  active={this.state.payType === FULL_RECORD}
                  onClick={() => this.setState({ payType: FULL_RECORD })}
                >
                  <div className="features__subtitle">
                    {t("connect-cloud-full-archiving")}
                  </div>
                  <div className="features__text">
                    {t("connect-cloud-text")}
                  </div>
                </Option>
                {/*<Option
                  active={this.state.payType === IMPORTANT_RECORD}
                  onClick={
                    this.props.camera.neurobox_id
                      ? () => this.setState({ payType: IMPORTANT_RECORD })
                      : () => toast.error(t("connect-cloud-warning"))
                  }
                >
                  <div className="features__subtitle">
                    {t("connect-cloud-important-events")}
                  </div>
                  <div className="features__text">
                    {t("connect-cloud-detector-events")}
                  </div>
                </Option> */}
              </div>
            </div>
            <div className="features__step">
              <div className="features__heading">
                <span className="color-red">2.</span>{" "}
                {t("select-payment-period")}
              </div>
              <div className="features__list features__list_flex">
                <Option
                  active={this.state.payPerMonth === true}
                  onClick={() => this.setState({ payPerMonth: true })}
                >
                  <div className="features__subtitle">
                    {t("payment-for-month")}
                  </div>
                  <div className="features__text">{t("monthly-payment")}</div>
                </Option>
                <Option
                  active={this.state.payPerMonth === false}
                  onClick={() => this.setState({ payPerMonth: false })}
                >
                  <div className="features__free">+ 2 {t("free-months")}</div>
                  <div className="features__subtitle">
                    {t("payment-for-year")}
                  </div>
                  <div className="features__text">
                    {t("connect-cloud-yearly-payment")}
                  </div>
                </Option>
              </div>
            </div>
            <div className="features__step features__step_lg">
              <div className="features__heading">
                <span className="color-red">3.</span>{" "}
                {t("connect-cloud-archive-retention-period")}
              </div>
              <div className="features__list">{this.renderRates()}</div>
              {/* <div className="features__info">
                {t("connect-cloud-tariffs-differ")}{" "}
                <a href={helpUrl} target="_blank" rel="noopener noreferrer">
                  {t("connect-cloud-opportunities")}
                </a>{" "}
                {t("connect-cloud-will-be-available")}
              </div> */}
            </div>
          </div>
          {showModal && (
            <Modal
              className="modal_md"
              onCloseRequest={this.handleToggleModal(false)}
            >
              <ConnectTariff tariff={archiveRate(t, selectedTariff)} />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  camera: state.camera.camera.Camera,
  balance: state.payments.balance,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, { BalanceLoad, CameraInfo })
  )(ConnectCloud)
);
