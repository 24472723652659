import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import i18n from "./i18n";
import registerServiceWorker from "./registerServiceWorker";
import Root from "./screens/Root";
import rootReducer from "./reducers";
import notificationSocket from "./reducers/notifications";
import { DeviceProvider } from "./contexts/DeviceProvider";
import { YMInitializer } from "react-yandex-metrika";
import ReactGA from "react-ga";
import { isEurocam } from "./utils";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://73560a577e1e4596be4d58c22532fc0b@o987583.ingest.sentry.io/5944507",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactGA.initialize(isEurocam() ? "UA-140442024-2" : "UA-140442024-1");
ReactGA.pageview("/cameras");

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, notificationSocket))
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <DeviceProvider>
        <Root />
        {!isEurocam() && (
          <YMInitializer
            accounts={[53703952]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              // trackHash: true
            }}
            version="2"
          />
        )}
      </DeviceProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
registerServiceWorker();
