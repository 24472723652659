import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import styles from "./PlacesCameras.module.sass";
import Switch from "../../../../components/Switch";
import Filter from "../../../Filter";

const PlacesCameras = ({ t, cameras, filters, setFilter }) => {
  const handleChange = (id) => {
    if (filters.camera.includes(id)) {
      const filtered = filters.camera.filter((x) => x !== id);
      setFilter({ camera: filtered });
    } else {
      setFilter({ camera: [...filters.camera, id] });
    }
  };
  return (
    <Filter
      className={styles.places}
      title={t("places-cameras")}
      value={t("select-all")}
    >
      <div className={styles.switches}>
        {cameras?.length > 0 &&
          cameras.map((x, index) => (
            <Switch
              className={styles.switch}
              content={x.title}
              onChange={() => handleChange(x.id)}
              key={index}
            />
          ))}
      </div>
    </Filter>
  );
};

const mapStateToProps = (state) => ({
  places: state.reports.places,
  placesFetched: state.reports.placesFetched,
  cameras: state.cameras.items,
});

export default translate()(connect(mapStateToProps)(PlacesCameras));
