import React, { useState, useRef } from "react";
import cn from "classnames";
import moment from "moment-timezone";

const Location = ({ t, zone, setZone }) => {
  const [value, setValue] = useState("");
  const [visible, setVisible] = useState(false);

  const inputRef = useRef(null);

  const handleOpen = () => {
    inputRef.current.focus();
    setVisible(!visible);
  };

  const handleClick = (x) => {
    setZone(`${x} (UTC${moment.tz(x).format("Z")})`, x);
    setVisible(false);
    setValue("");
  };

  return (
    <div className="field">
      <div className="field__label">{t("timezone")}</div>
      <div className="field__wrap">
        <div
          className={cn("field__head", { active: visible })}
          onClick={() => handleOpen()}
        >
          {zone}
        </div>
        <div className={cn("field__drop", { visible: visible })}>
          <input
            className="field__input"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            ref={inputRef}
          />
          <div className="field__list">
            {moment.tz
              .names()
              .filter(
                (x) =>
                  value === "" || x.toLowerCase().includes(value.toLowerCase())
              )
              .map((x, index) => (
                <div
                  className="field__option"
                  onClick={() => handleClick(x)}
                  key={index}
                >
                  {`${x} (UTC${moment.tz(x).format("Z")})`}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
