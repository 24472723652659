import React from "react";
import { connect } from "react-redux";
import Icon from "../Icon";
import Switch from "../Switch";

import { AvailableObjects, EventsSetFilter } from "../../reducers/events";

class EventsToggler extends React.Component {
  isSelected = object => this.props.objects.find(o => o === object);

  toggleSelect = object =>
    this.props.EventsSetFilter({
      objects: this.isSelected(object)
        ? this.props.objects.filter(o => o !== object)
        : [...this.props.objects, object]
    });

  render() {
    return (
      <div className="events">
        {AvailableObjects.map((x, i) => (
          <div key={i} className={"events__item active " + x.icon}>
            <div className="events__preview">
              <Icon name={x.icon} />
            </div>
            <div className="events__title">{x.title}</div>
            <Switch
              toggler
              value={this.isSelected(x.object)}
              onChange={() => this.toggleSelect(x.object)}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  objects: state.events.filters.objects
});

export default connect(
  mapStateToProps,
  { EventsSetFilter }
)(EventsToggler);
