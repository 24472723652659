import React from "react";
import { connect } from "react-redux";
import Switch from "../Switch";
import { translate } from "react-i18next";

import { EventsSetFilter } from "../../reducers/events";

const eventsWatchLists = ({ groups, filters, EventsSetFilter }) => {
  const isSelected = (group) => filters.groups.find((g) => g.id === group.id);

  const toggleSelect = (group) =>
    EventsSetFilter({
      groups: isSelected(group)
        ? filters.groups.filter((g) => g.id !== group.id)
        : [...filters.groups, group],
    });

  return groups
    .filter((g) => g.persons.length > 0)
    .map((group) => {
      return (
        <Switch
          key={group.id}
          content={group.title}
          value={isSelected(group)}
          onChange={() => toggleSelect(group)}
        />
      );
    });
};

const mapStateToProps = (state, ownProps) => ({
  filters: state.events.filters,
  groups: [
    ...state.faces.groups,
    { id: -1, title: ownProps.t("unknown-persons"), persons: [-1] },
  ],
});

export default translate()(
  connect(mapStateToProps, { EventsSetFilter })(eventsWatchLists)
);
