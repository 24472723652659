import React, {Component, Fragment} from 'react';
import {DateUnit, getTime} from "./utils";

export default class Selection extends Component
{

    /**
     * Last mouse position in dragging. Also used as drag flag
     * @type {null|number}
     * @private
     */
    _drag = null;

    /**
     * Stores the kind of the ruler with which the user interacted
     * @type {"both"|"since"|"until"|null}
     * @private
     */
    _kind = null;

    render() {
        if (this.props.since && this.props.until)
            return this.renderSelection(this.props);
        return null;
    }

    renderSelection({viewport, since, until}) {
        const events = (kind) => ({
            onMouseDown: e => this.onMouseDown(kind, e),
        });

        let duration = getTime(until) - getTime(since);

        return (
            <Fragment>
                <Area width={duration / viewport.density} x={viewport.pointFor(since)} {...events('both')}/>
                <Circle x={viewport.pointFor(since)} {...events('since')} />
                <Circle x={viewport.pointFor(until)} {...events('until')} />
            </Fragment>
        )
    }

    onChanged = (since, until) => {
        if (this.props.onChange)
            this.props.onChange(new Date(since), new Date(until));
    };

    onMouseDown = (kind, event) => {
        event.stopPropagation();
        this._kind = kind;
        this._drag = event.clientX;
    };

    onMouseMove = (event) => {
        event.stopPropagation();

        if (this._drag == null || this._kind == null)
            return;

        const change = event.clientX - this._drag;
        this._drag = event.clientX;

        const range = {
            since: getTime(this.props.since),
            until: getTime(this.props.until),
        };

        if (this._kind === 'both') {
            range.since += change * this.props.viewport.density;
            range.until += change * this.props.viewport.density;
        } else {
            range[this._kind] += change * this.props.viewport.density;
        }

        let duration = range.until - range.since;
        if (duration > DateUnit.Hour * 4 || duration < DateUnit.Minute * 5)
            return;

        this.onChanged(range.since, range.until);

    };

    onMouseUp = (event) => {
        event.stopPropagation();
        this._kind = null;
        this._drag = null;
    };

    componentDidMount() {
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('mousemove', this.onMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('mousemove', this.onMouseMove);
    }

}



const Circle = ({x, ...props}) => (
    <g>
        <circle className='selection-ball' cx={x} cy={0} r={10} fill='#FFF' {...props} />
        <circle className='selection-ball' cx={x} cy={0} r={6} fill='#F30027' {...props} pointerEvents='none' />
    </g>
);

const Area = ({width, x, ...props}) => (
    <Fragment>
        <rect className='selection-area' width={width} height={24} x={x} y={-12} {...props}/>

        <line className='selection-line' x1={x} x2={x} y1={-12} y2={12} />
        <line className='selection-line' x1={x + width} x2={x + width} y1={-12} y2={12} />
    </Fragment>
);
