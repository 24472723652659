import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "../Icon";

const ReportsNoData = (props) => {
  const { t, title, text, timeTracking, employee } = props;
  return (
    <div
      className={cn(
        "page",
        "page_reports",
        { page_tracking: timeTracking },
        { page_employee: employee }
      )}
    >
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic" name="no-data" />
        </div>
        <div className="page__title">
          {title ? title : t("no-monitoring-data")}
        </div>
        <div className="page__text">
          {text ? text : t("no-monitoring-data-text")}
        </div>
      </div>
    </div>
  );
};

export default translate()(ReportsNoData);
