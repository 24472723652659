import React from "react";
import { translate } from "react-i18next";
import styles from "./Dates.module.sass";
import Filter from "../../../Filter";
import Datepicker from "../../../Datepicker";

const Dates = ({ t, filters, setFilter }) => {
  return (
    <Filter
      className={styles.places}
      title={t("date-of-visit")}
      value={t("select-all")}
    >
      <Datepicker
        initRange={filters.period}
        onChangeRange={(from, to) =>
          from && to
            ? setFilter({ period: { from, to } })
            : setFilter({ period: null })
        }
        rangeMode={true}
        time={true}
        inline
      />
    </Filter>
  );
};

export default translate()(Dates);
