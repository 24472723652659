import React from "react";
import { Redirect } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import format from "dateformat";
import Datepicker from "../../Datepicker";
import ReportsPlaces from "./ReportsPlaces";
import ReportsVisitorFilter from "./ReportsVisitorFilter";

import { capitalizeFirstLetter } from "../../../utils";

class ReportsSidebar extends React.Component {
  state = {
    accordion: [
      {
        index: 0,
        title: this.props.t("places"),
        value: this.props.t("all-places"),
        visible: false,
      },
      {
        index: 1,
        title: this.props.t("date"),
        value: this.props.t("today"),
        visible: false,
      },
      {
        index: 2,
        title: this.props.t("visitor-filter"),
        value: this.props.t("all-selected"),
        visible: false,
      },
    ],
  };

  toggleBody = (index) => {
    const { accordion } = { ...this.state };
    const currentState = accordion;
    currentState[index].visible = !currentState[index].visible;

    this.setState({ accordion: currentState });
  };

  renderPlaceTitle(places, filters, type) {
    let id;
    type === 1 ? (id = filters.place) : (id = filters.placeTimeTracking);
    return places.find((x) => x.id.toString() === id).title;
  }

  renderSelectedDate(value) {
    const { t, filters } = this.props;
    const today = format(new Date(), "dd/mm/yy");
    const sinceFormated = format(filters.period.since, "dd/mm/yy");
    const untilFormated = format(filters.period.until, "dd/mm/yy");

    return sinceFormated
      ? sinceFormated === untilFormated
        ? sinceFormated === today
          ? t("today")
          : sinceFormated
        : `${sinceFormated} - ${untilFormated}`
      : value;
  }

  renderSelectedFilters(value) {
    const { t, filters } = this.props;
    let selectedFilters = [];

    switch (filters.gender) {
      case "male":
        selectedFilters.push(t("man-plural"));
        break;
      case "female":
        selectedFilters.push(t("woman-plural"));
        break;
      default:
        break;
    }

    switch (filters.age) {
      case "age0":
        selectedFilters.push(t("less-18-years"));
        break;
      case "age18":
        selectedFilters.push(t("between-18-24-years-old"));
        break;
      case "age25":
        selectedFilters.push(t("between-25-34-years-old"));
        break;
      case "age35":
        selectedFilters.push(t("between-35-44-years-old"));
        break;
      case "age45":
        selectedFilters.push(t("between-45-54-years-old"));
        break;
      case "age55":
        selectedFilters.push(t("more-55-years"));
        break;
      default:
        break;
    }

    filters.unique && selectedFilters.push(t("unique"));

    return selectedFilters.length > 0
      ? capitalizeFirstLetter(selectedFilters.join(", "))
      : value;
  }

  render() {
    const { t, type, setFilter, filters } = this.props;
    const { accordion } = this.state;

    const places = this.props.places.filter((x) => x.type === this.props.type);

    return (
      <>
        {type === 1 &&
          filters.place !== null &&
          filters.place !== undefined && (
            <Redirect to={`/reports/${filters.place}`} />
          )}
        {type === 2 &&
          filters.placeTimeTracking !== null &&
          filters.placeTimeTracking !== undefined && (
            <Redirect
              to={`/reports/time-tracking/${filters.placeTimeTracking}`}
            />
          )}
        <div className="layout__scroll">
          <div className="accordion">
            {accordion.map((x) => (
              <div
                className={cn("accordion__item", {
                  disabled: x.index === 2 && type === 2,
                })}
                key={x.index}
              >
                <div
                  className={cn("accordion__head", { active: x.visible })}
                  onClick={() => this.toggleBody(x.index)}
                >
                  <div className="accordion__title">{x.title}</div>
                  {x.index === 0 && (
                    <div className="accordion__value">
                      {(type === 1 && !filters.place) ||
                      (type === 2 && !filters.placeTimeTracking)
                        ? x.value
                        : places &&
                          this.renderPlaceTitle(places, filters, type)}
                    </div>
                  )}
                  {x.index === 1 && (
                    <div className="accordion__value">
                      {this.renderSelectedDate(x.value)}
                    </div>
                  )}
                  {x.index === 2 && (
                    <div className="accordion__value">
                      {this.renderSelectedFilters(x.value)}
                    </div>
                  )}
                </div>
                <div className="accordion__body">
                  {x.index === 0 && x.visible && (
                    <ReportsPlaces type={type} places={places} />
                  )}
                  {x.index === 1 && x.visible && (
                    <Datepicker
                      inline
                      rangeMode={true}
                      initRange={filters.period}
                      onChangeRange={(since, until) =>
                        since && until
                          ? setFilter({ period: { since, until } }, type)
                          : setFilter(
                              {
                                period: {
                                  since: new Date(
                                    new Date().setHours(0, 0, 0, 0)
                                  ),
                                  until: new Date(
                                    new Date().setHours(23, 59, 0, 0)
                                  ),
                                },
                              },
                              type
                            )
                      }
                    />
                  )}
                  {x.index === 2 && x.visible && (
                    <div className="accordion__group">
                      <ReportsVisitorFilter
                        t={t}
                        filters={filters}
                        setFilter={setFilter}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default translate()(ReportsSidebar);
