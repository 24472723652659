import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import Switch from "../Switch";
import Timestamp from "../Timestamp";
import { eventImage, eventRoiImage, guidMainPhotoUrl } from "../../links";
import { gender, ageRange } from "../../utils";

const EventsItem = ({
  t,
  item,
  camerasMap,
  enableSelection,
  onSelect,
  isSelected,
}) => {
  const renderGroup = (item) => {
    const person = item.Person;
    if (person) {
      const group = item.Group;

      if (!group) return <div />;

      return (
        <div className="grid__group" style={{ background: group.color }}>
          {group && group.title}
        </div>
      );
    }
  };

  const renderFoot = (item) => {
    const person = item.Person;

    return (
      <div className="grid__foot">
        {item.Object === "person" && (
          <div className="grid__preview">
            {person ? (
              <img
                className="grid__avatar"
                src={guidMainPhotoUrl(person.id)}
                alt={person.name}
              />
            ) : (
              <div className="grid__placeholder">
                {item.ROI ? (
                  <img
                    className="grid__avatar"
                    src={eventRoiImage(item.CameraID, item.ID)}
                    alt={item.ROI}
                  />
                ) : (
                  "?"
                )}
              </div>
            )}
          </div>
        )}
        <div className="grid__details">
          <div className="grid__info">
            {camerasMap[item.CameraID] && camerasMap[item.CameraID].title}
          </div>
          <div className="grid__title">
            <Timestamp value={item.Timestamp} />
          </div>
          {item.Object === "person" && (
            <div className="grid__person">
              {person
                ? person.name + " " + person.last_name
                : t("not-recognized")}
              {!person && (
                <span className="grid__age">
                  ({gender(item.Sex, t)}, {ageRange(item.Age)}){" "}
                  {item.Guid !== "-1" && `ID ${item.Guid}`}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={cn("grid__item", { disabled: item.Viewed })}>
      <div className="grid__corner">
        <div
          className={cn("grid__more", {
            grid__more_mr: enableSelection,
          })}
        >
          {item.Object === "person" && renderGroup(item)}
          {item.Viewed && <div className="grid__status">{t("viewed")}</div>}
        </div>
      </div>
      {enableSelection && (
        <Switch
          className="grid__switch"
          value={isSelected(item.ID)}
          onChange={() => onSelect(item.ID)}
        />
      )}
      <NavLink
        className="grid__link"
        to={{
          pathname: `/events/${item.CameraID}/${item.ID}`,
          state: item.ID,
        }}
      >
        <img
          className="grid__pic"
          src={eventImage(item.CameraID, item.ID)}
          alt="Event"
        />
      </NavLink>
      <div className="grid__head no-border">
        <div className="grid__types">
          {item.Object === "person" && (
            <div className="grid__type">
              <img className="grid__icon" src="/img/type-face.svg" alt="" />
            </div>
          )}
          {item.Object === "people" && (
            <div className="grid__type">
              <img className="grid__icon" src="/img/type-man.svg" alt="" />
            </div>
          )}
          {item.Object === "car" && (
            <div className="grid__type blue">
              <img className="grid__icon" src="/img/type-car.svg" alt="" />
            </div>
          )}
        </div>
      </div>
      {renderFoot(item)}
    </div>
  );
};

export default translate()(EventsItem);
