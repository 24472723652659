import React from "react";
import cn from "classnames";

const Switch = ({
  className,
  content,
  radio,
  toggler,
  value,
  name,
  onChange,
  disabled,
  defaultChecked,
  alignCenter,
}) => {
  return (
    <label
      className={cn(
        "switch",
        { switch_radio: radio },
        { switch_toggler: toggler },
        { switch_right: toggler === "right" },
        { switch_vertical: alignCenter },
        className
      )}
    >
      <input
        className="switch__input"
        type={radio ? "radio" : "checkbox"}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <span className="switch__content">
        {content && <span className="switch__label">{content}</span>}
      </span>
    </label>
  );
};

export default Switch;
