import React, { useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { Switch, Route } from "react-router-dom";
import Loader from "../components/Loader";
import Main from "../components/Faces/Main";
import PersonForm from "../components/Faces/PersonForm";
import PersonPage from "../components/Faces/PersonPage";
import PersonsGroup from "../components/Faces/PersonsGroup";

import { LoadGroups } from "../reducers/faces";
import { checkPerms } from "../utils";

const Faces = ({ LoadGroups, groupsFetched, users }) => {
  useEffect(() => {
    LoadGroups();
  }, [LoadGroups]);

  const isPermsPersonCreate = checkPerms(users.user, "person:create");
  const isPermsGroupCreate = checkPerms(users.user, "person_group:create");

  return groupsFetched ? (
    <Switch>
      <Route exact path="/faces" render={() => <Main />} />
      <Route exact path="/faces/visitors" render={() => <Main />} />
      {isPermsPersonCreate && (
        <Route exact path="/faces/add-person" render={() => <PersonForm />} />
      )}
      {isPermsGroupCreate && (
        <Route exact path="/faces/add-list" render={() => <PersonsGroup />} />
      )}
      <Route
        exact
        path={`/faces/edit-list/:id`}
        render={(props) => <PersonsGroup id={props.match.params.id} />}
      />
      <Route
        path={`/faces/:id/:faceId/edit`}
        render={(props) => <PersonForm id={props.match.params.faceId} />}
      />
      <Route
        path={`/faces/:id/:faceId`}
        render={(props) => <PersonPage id={props.match.params.faceId} />}
      />
      <Route
        path={`/faces/:id`}
        render={(props) => <Main id={props.match.params.id} />}
      />
    </Switch>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  groupsFetched: state.faces.groupsFetched,
});

export default translate()(connect(mapStateToProps, { LoadGroups })(Faces));
