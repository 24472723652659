import React from "react";

const Terms = props => {
  return (
    <div className="pages__article">
      <h1>Пользовательское соглашение </h1>
      <p>
        Настоящий документ «Пользовательское соглашение» (далее – Соглашение)
        представляет собой предложение Общества с ограниченной ответственностью
        «Нейрокэм» (далее – «Правообладатель») заключить договор на изложенных
        ниже условиях.
      </p>
      <h3>1. Общие положения</h3>
      <p>
        1.1. В настоящем документе и вытекающих или связанных с ним отношениях
        Сторон применяются следующие термины и определения:
      </p>
      <p>
        а) <strong>Сервис</strong> – совокупность функциональных возможностей
        Приложения, доступ к которым предоставляется Заказчику.
      </p>
      <p>
        б) <strong>Приложение</strong> – интегрированный программный комплекс
        для управления видеонаблюдением, хранения и обработки видеопотока,
        включающий Сайт, Мобильное приложение и иные программы для ЭВМ и/или
        базы данных, на основе которых реализуется Сервис.
      </p>
      <p>
        в) <strong>Сайт</strong> – автоматизированная информационная система,
        доступная в сети Интернет по адресу (включая поддомены):{" "}
        <a href="http://neurocam.com/">http://neurocam.com/</a>
      </p>
      <p>
        г) <strong>Мобильное приложение</strong> – предназначенная для установки
        и использования на мобильном устройстве программа для ЭВМ, позволяющая
        пользоваться различными опциями Сервиса.
      </p>
      <p>
        д) <strong>Пользователь</strong> – лицо, заключившее настоящее
        Соглашение с Правообладателем в своем или чужом интересе в соответствии
        с требованиями действующего законодательства и настоящего Соглашения.
      </p>
      <p>
        е) <strong>Личный кабинет</strong> – персональный раздел Приложения, к
        которому Пользователь получает доступ после прохождения регистрации
        и/или авторизации в Приложении. Личный кабинет предназначен для хранения
        персональных данных Пользователя, просмотра и управления доступными
        функциональными возможностями Сервиса.
      </p>
      <p>
        1.2. В настоящем Соглашении могут быть использованы иные термины и
        определения, не указанные в п.1.1. Соглашения. В этом случае толкование
        такого термина производится в соответствии с текстом Соглашения. В
        случае отсутствия однозначного толкования термина или определения в
        тексте Соглашения следует руководствоваться его толкованием,
        определенным: в первую очередь – указанными в нем Обязательными
        документами, во вторую очередь – законодательством Российской Федерации,
        и в последующем — обычаями делового оборота и научной доктриной.
      </p>
      <p>
        1.3. Использование вами Сервиса любым способом и в любой форме в
        пределах его объявленных функциональных возможностей, включая:
      </p>
      <ul>
        <li>использование Сервиса в демонстрационном режиме;</li>
        <li>регистрация и/или авторизация в Сервисе;</li>
        <li>
          оформление заказа на предоставление доступа к функциональным
          возможностям Сервиса;
        </li>
        <li>иное использование Сервиса,</li>
      </ul>
      <p>
        создает договор на условиях настоящего Соглашения и указанных в нем
        обязательных для Сторон документов в соответствии с положениями ст.437 и
        438 Гражданского кодекса Российской Федерации.
      </p>
      <p>
        1.4. Воспользовавшись любой из указанных выше возможностей по
        использованию Сервиса вы подтверждаете, что:
      </p>
      <p>
        а) Ознакомились с условиями настоящего Соглашения и указанных в нем
        Обязательных документов в полном объеме до начала использования Сервиса.
      </p>
      <p>
        б) Принимаете все условия настоящего Соглашения и указанных в нем
        Обязательных документов в полном объеме без каких-либо изъятий и
        ограничений с вашей стороны и обязуетесь их соблюдать или прекратить
        использование Сервиса. Если вы не согласны с условиями настоящего
        Соглашения и указанных в нем Обязательных документов или не имеете права
        на заключение договора на их основе, вам следует незамедлительно
        прекратить любое использование Сервиса.
      </p>

      <p>
        в) Соглашение (в том числе любая из его частей) и/ или указанные в нем
        Обязательные документы могут быть изменены Правообладателем без
        какого-либо специального уведомления. Новая редакция Соглашения и/или
        указанных в нем Обязательных документов вступает в силу с момента
        размещения на Сайте либо доведения до сведения Пользователя в иной
        удобной форме, если иное не предусмотрено новой редакцией Соглашения
        и/или указанных в нем обязательных для Сторон документов.
      </p>
      <h3>2. Общие условия пользования Сервисом</h3>
      <p>
        2.1. Обязательным условием заключения настоящего Соглашения является
        полное и безоговорочное принятие и соблюдение Пользователем в
        установленных ниже случаях требований и положений, определенных
        следующими документами («Обязательные документы»):
      </p>
      <p>
        а) Политика конфиденциальности, размещенная и/или доступная в сети
        Интернет по адресу{" "}
        <a href="https://neurocam.com/pages/policy">
          https://neurocam.com/pages/policy
        </a>
        , которая содержит правила предоставления и использования персональной
        информации, включая персональные данные Пользователя.
      </p>
      <p>
        б) Правила оказания информационных услуг, которые размещены и//или
        доступны в сети Интернет по адресу{" "}
        <a href="https://neurocam.com/pages/rules">
          https://neurocam.com/pages/rules
        </a>{" "}
        и являются обязательным для Сторон документом в случае оформления
        Пользователем заказа на приобретение доступа к дополнительным
        функциональным возможностям Сервиса.
      </p>
      <p>
        в) Лицензионное соглашение с конечным пользователем - размещенные и/или
        доступные в сети Интернет по адресу{" "}
        <a href="https://neurocam.com/pages/terms">
          https://neurocam.com/pages/terms
        </a>{" "}
        обязательные условия использования Мобильного приложения.
      </p>
      <p>
        2.2. Использование Приложения и/или предоставляемого на его основе
        Сервиса в демонстрационном режиме и в ограниченной части просмотра
        информации о функциональных возможностях Сервиса, а также иной
        информации в открытых для общего пользования разделах Приложения
        возможно без прохождения Пользователем предварительной регистрации и/или
        авторизации в Приложении.{" "}
      </p>
      <p>
        В остальной части применение функциональных возможностей Приложения
        и/или предоставляемого на его основе Сервиса допускается после
        прохождения Пользователем регистрации и/или авторизации в Приложении в
        соответствии с установленными Правообладателем правилами.
      </p>
      <p>
        2.3. Перечень функциональных возможностей Сервиса, использование которых
        требует совершения Пользователем определенных действий (например,
        заключения возмездного договора с Правообладателем) определяется по
        единоличному усмотрению Правообладателя и может время от времени
        изменяться.
      </p>
      <p>
        2.4. Для регистрации Пользователь обязуется предоставить достоверную и
        полную информацию о себе по вопросам, предлагаемым в форме регистрации
        и/ или авторизации в Приложении, а также при оформлении заказа на
        оказание возмездных услуг с использованием его программных средств, и
        поддерживать эту информацию в актуальном состоянии. Если Пользователь
        предоставляет неверную информацию или у Правообладателя есть основания
        полагать, что предоставленная Пользователем информация неполна или
        недостоверна, Правообладатель имеет право по своему усмотрению
        заблокировать либо удалить Личный кабинет Пользователя, а также отказать
        Пользователю в оформлении заказа на услуги и ином использовании Сервиса
        полностью или в определенной части.
      </p>
      <p>
        2.5. Правообладатель оставляет за собой право в любой момент потребовать
        от Пользователя подтверждения данных, указанных при регистрации, и
        запросить в связи с этим подтверждающие документы (в частности
        документы, удостоверяющие личность), непредоставление которых, по
        усмотрению Правообладателя, может быть приравнено к предоставлению
        недостоверной информации и повлечь последствия, предусмотренные п. 2.4.
        Соглашения.{" "}
      </p>
      <p>
        2.6. В случае если данные Пользователя, указанные в предоставленных им
        документах, не соответствуют данным, указанным при регистрации, а также
        в случае, когда данные, указанные при регистрации, не позволяют
        идентифицировать пользователя, Правообладатель вправе применить меры,
        указанные в п.2.4. Соглашения.
      </p>
      <p>
        2.7. Технические, организационные и коммерческие условия использования
        Сервиса могут доводиться до сведения Пользователей путем отдельного
        размещения на Сайте или путем нотификации Пользователей.
      </p>
      <p>
        2.8. Правообладатель вправе устанавливать лимиты и вводить иные
        технические ограничения использования Сервиса, которые время от времени
        будут доводиться до сведения Пользователей в форме и способом по выбору
        Правообладателя.
      </p>
      <h3>3. Гарантии Пользователя</h3>
      <p>
        Принимая условия настоящего Соглашения, вы подтверждаете и гарантируете,
        что:
      </p>
      <p>
        3.1. Вы обладаете всеми необходимыми правами и полномочиями для
        заключения Договора на использование Сервиса и его исполнения;
      </p>
      <p>
        3.2. Использование Сервиса будет осуществляться вами исключительно для
        целей, разрешенных настоящим Соглашением с соблюдением его положений, а
        равно требований применимого права и общепринятой практики;
      </p>
      <p>
        3.3. Вы не будете совершать каких-либо действий, которые вступают в
        противоречие или препятствуют предоставлению Сервиса или работе
        соответствующего оборудования, сетей, или программного обеспечения, с
        помощью которых предоставляется Сервис.
      </p>
      <p>
        3.4. Вами получено надлежащее согласие Пользователей и/или третьих лиц,
        данные которых обрабатываются вами с использованием Сервиса, на
        обработку их персональных данных и передачу третьим лицам, либо что вы
        не обязаны получать такое согласие в соответствии с действующим
        законодательством.
      </p>
      <h3>4. Ограничения</h3>
      <p>
        Соглашаясь с условиями настоящего Соглашения, вы понимаете и признаете,
        что:
      </p>
      <p>
        4.1. К отношениям Сторон по предоставлению Сервиса на безвозмездной
        основе не подлежат применению положения законодательства о защите прав
        потребителей.
      </p>
      <p>
        4.2. Сервис предоставляется на условиях «как есть», в связи с чем вам не
        представляются какие-либо гарантии, что Сервис будет соответствовать
        вашим требованиям; услуги будут предоставляться непрерывно, быстро,
        надежно и без ошибок; результаты, которые могут быть получены с
        использованием Сервиса, будут точными и надежными; качество какого-либо
        продукта, услуги и информации, полученных с использованием Сервиса,
        будет соответствовать вашим ожиданиям; все ошибки в программном
        обеспечении Сервиса будут исправлены.
      </p>
      <p>
        4.3. Поскольку Сервис находится на стадии постоянного дополнения и
        обновления новых функциональных возможностей, форма и характер
        предоставляемых услуг могут время от времени меняться без вашего
        предварительного уведомления. Правообладатель вправе по собственному
        усмотрению прекратить (временно или окончательно) предоставление услуг
        (или каких-либо отдельных функций в рамках услуг) всем Пользователям
        вообще или вам, в частности, без вашего предварительного уведомления.
      </p>
      <p>
        4.4. Вы не имеете права самостоятельно или с привлечением третьих лиц:
      </p>
      <ul>
        <li>
          копировать (воспроизводить) в любой форме и способом входящие в состав
          Сервиса Правообладателя программы для ЭВМ и базы данных, включая любые
          их элементы, без получения предварительного письменного согласия их
          владельца;
        </li>
        <li>
          вскрывать технологию, эмулировать, декомпилировать, дизассемблировать,
          дешифровать, и производить иные аналогичные действия с Сервисом;
        </li>
        <li>
          создавать программные продукты и/или сервисы с использованием Сервиса
          без получения предварительного разрешения Правообладателя.
        </li>
      </ul>
      <p>
        4.5. Правообладатель никак не связан с видеопотоком, загружаемым
        Пользователями в Сервис, и не осуществляет проверку содержания,
        подлинности и безопасности такого видеопотока, а равно его соответствия
        требованиям применимого права, и наличия у Пользователей необходимого
        объема прав на организацию видеонаблюдения, хранения и обработки
        получаемых записей и изображений.
      </p>
      <p>
        Всю ответственность за содержание загружаемого в Сервис видеопотока, и
        соответствие его требованиям применимого права несет лицо,
        организовавшее видеонаблюдение и/или добавившее видеопоток в Сервис
        Правообладателя.
      </p>
      <p>
        4.6. Пользователям запрещается использование Приложения и/или
        предоставляемого на его основе Сервиса для:
      </p>
      <ul>
        <li>размещения и/или распространения контрафактных материалов;</li>
        <li>
          размещения и/или распространения материалов порнографического
          характера, а равно пропаганды порнографии и детской эротики, и рекламы
          интимных услуг;
        </li>
        <li>
          распространения любой иной запрещенной информации, включая материалы,
          экстремистского характера, а также направленных на ущемление прав и
          свобод человека по признакам расовой и национальной принадлежности,
          вероисповедания, языка, и пола, подстрекающие к совершению
          насильственных действий в отношении человека, либо к бесчеловечному
          обращению с животными, призывающие к совершению иных противоправных
          действий, в том числе разъясняющие порядок изготовления и применения
          оружия, наркотических средств и их прекурсов, и т.д.;
        </li>
        <li>
          распространения недостоверной информации, порочащей честь достоинство
          или деловую репутацию третьих лиц;
        </li>
        <li>
          преимущественного или исключительного размещения или распространения
          ссылок на другие сайты;
        </li>
        <li>
          совершения иных противоправных действий, включая получение
          несанкционированного доступа к конфиденциальной информации, ее
          распространение, неправомерный доступ к компьютерной информации,
          использование и распространение вредоносных компьютерных программ,
          нарушение правил эксплуатации средств хранения, обработки или передачи
          компьютерной информации и информационно-телекоммуникационных сетей,
          организации азартных игр, проведения лотерей и иных стимулирующих
          мероприятий в нарушение действующего законодательства.
        </li>
      </ul>
      <p>
        4.7. При обнаружении ошибок в работе Сервиса сообщите об этом
        Правообладателю по адресу, указанному в реквизитах или отдельно в
        Приложении для службы поддержки.
      </p>
      <p>
        4.8. В случае обнаружения нарушения ваших прав и/или интересов в связи с
        предоставлением Сервиса, вам следует сообщить об этом Правообладателю.
        Для этого необходимо направить Правообладателю письменное уведомление с
        подробным изложением обстоятельств нарушения.
      </p>
      <p>
        4.9. Вы несете исключительную ответственность за любое нарушение
        обязательств, установленных настоящим Соглашением и/или применимым
        правом, а также за все последствия таких нарушений (включая любые убытки
        или ущерб, которые может понести Правообладатель и иные третьи лица).
      </p>
      <p>
        4.10. В случае возникновения каких-либо претензий третьих лиц в
        отношении нарушения вами любых имущественных и/или личных
        неимущественных прав третьих лиц, а равно установленных
        законодательством запретов или ограничений вы обязаны по требованию
        Правообладателя пройти официальную идентификацию, предоставив
        Правообладателю нотариально заверенное обязательство урегулировать
        возникшие претензии собственными силами и за свой счет с указанием своих
        паспортных данных.
      </p>
      <p>
        4.11. В связи с установленной законодательством ответственностью за
        размещение и предоставление доступа к нелегальным информационным
        материалам, Правообладатель оставляет за собой право удалить любой
        видеопоток из Сервиса либо ограничить к нему доступ в одностороннем
        порядке до получения от вас указанного выше письменного обязательства.
      </p>
      <p>
        4.12. В случае многократного или грубого нарушения условий настоящего
        Соглашения, включая указанные в нем Обязательные документы, и/или
        требований законодательства, Правообладатель оставляет за собой право
        заблокировать доступ к Сервису либо иным образом ограничить (прекратить)
        предоставление Пользователю услуг с использованием Сервиса.
      </p>
      <p>
        4.13. В случае привлечения Правообладателя к ответственности или
        наложения на него взыскания в связи с допущенными вами нарушениями прав
        и/или интересов третьих лиц, а равно установленных законодательством
        запретов или ограничений, вы обязаны в полном объеме возместить убытки
        Правообладателя.
      </p>
      <p>
        4.14. Правообладатель устраняется от какой бы то ни было ответственности
        в связи с допущенными вами нарушениями, а также причинением вам вреда
        или убытков при указанных выше обстоятельствах.
      </p>
      <p>
        4.15. При любых обстоятельствах ответственность Правообладателя
        ограничена 1 000 (Одной тысячей) рублей и возлагается на него
        исключительно при наличии в его действиях вины.
      </p>
      <h3>5. Уведомления</h3>
      <p>
        5.1. Вы соглашаетесь получать от Правообладателя на указанный при работе
        с Сервисом электронный адрес, а также через Мобильное приложение
        (push-уведомления), информационные электронные сообщения (далее —
        «нотификаторы») с сообщениями о важных событиях, происходящих в рамках
        Сервиса или в связи с ним.
      </p>
      <p>
        5.2. Правообладатель вправе использовать нотификаторы в рамках
        информационного обслуживания для информирования Пользователя об
        изменениях и новых возможностях Сервиса и/или об изменении Соглашения
        или указанных в нем Обязательных документов, а также в связи с
        оформлением Заказов, заключением и исполнением Договоров, либо
        проведением маркетинговых акций, в том числе в рекламных целях.
      </p>
      <h3>6. Соглашение об использовании электронной подписи</h3>
      <p>
        6.1. Во взаимоотношениях между Правообладателем и Пользователем могут
        использоваться электронные документы, удостоверенные простой электронной
        подписью.{" "}
      </p>
      <p>
        6.2. Простой электронной подписью признается электронная подпись,
        которая посредством использования логина и пароля Пользователя или
        указанного при использовании Сервиса адреса электронной почты
        Пользователя (ключ электронной подписи) подтверждает факт формирования
        электронной подписи непосредственно Пользователем.
      </p>
      <p>
        6.3. По соглашению Сторон электронные документы, подписанные простой
        электронной подписью, признаются равнозначными документам на бумажных
        носителях, подписанным собственноручной подписью.
      </p>
      <p>
        6.4. Правообладатель определяет Пользователя, которому соответствует
        простая электронная подпись, по используемому Пользователем логину и
        паролю, указанным при регистрации/или авторизации в Сервисе, - в случае
        совершения любых действий по использованию Сервиса, либо по
        используемому Пользователем адресу электронной почты – в случае
        поступления Правообладателю сообщений с такого адреса.
      </p>
      <p>
        6.5. Любые действия, совершенные с использованием простой электронной
        подписи определенного Пользователя, считаются совершенными таким
        Пользователем.
      </p>
      <p>
        6.6. Пользователь обязуется соблюдать конфиденциальность ключа
        электронной подписи. В частности, Пользователь не имеет права передавать
        свои логин и пароль или предоставлять доступ к своей электронной почте
        третьим лицам, и несет полную ответственность за их сохранность и
        индивидуальное использование, самостоятельно выбирая способ их хранения
        и ограничения к ним доступа.{" "}
      </p>
      <p>
        6.7. В случае несанкционированного доступа к логину и паролю, их утраты
        или раскрытия третьим лицам Пользователь обязан незамедлительно сообщить
        об этом Правообладателю путем направления электронного письма с
        указанного при использовании Сервиса адреса электронной почты.{" "}
      </p>
      <p>
        6.8. В случае утраты или несанкционированного доступа к электронной
        почте, адрес которой указан при использовании Сервиса, Пользователь
        обязан незамедлительно заменить такой адрес на новый и сообщить о данном
        факте Правообладателю путем направления электронного письма с нового
        адреса электронной почты.
      </p>
      <h3>7. Прочие условия</h3>
      <p>
        7.1. Зарегистрированный Пользователь самостоятельно определяет условия и
        порядок использования функциональных возможностей Сервиса, которые
        однако ни при каких условиях не могут противоречить настоящему
        Соглашению.
      </p>
      <p>
        7.2. Применимое право. Настоящее Соглашение, порядок его заключения и
        исполнения, а также вопросы, не урегулированные настоящим Соглашением,
        регулируются действующим законодательством Российской Федерации.
      </p>
      <p>
        7.3. Арбитраж. Все споры по Соглашению или в связи с ним подлежат
        рассмотрению в суде по месту нахождения Правообладателя в соответствии с
        действующим процессуальным правом Российской Федерации.
      </p>
      <p>
        7.4. Изменения. Настоящее Соглашение может быть изменено или прекращено
        Правообладателем в одностороннем порядке без предварительного
        уведомления Пользователя и без выплаты какой-либо компенсации в связи с
        этим.
      </p>
      <p>
        7.5. Редакция Соглашения. Действующая редакция настоящего Соглашения
        размещена на Сайте и доступна в сети Интернет по адресу <a href="http://neurocam.com/">http://neurocam.com/</a>.
      </p>
      <p>7.6. Реквизиты Правообладателя:</p>
      <p>
        Общество с ограниченной ответственностью «Нейрокэм»;
        <br />
        ОГРН: 1177746595960;
        <br />
        ИНН: 77044255108;
        <br />
        Юридический адрес: 119270, г. Москва, Лужнецкая наб, дом 2/4, строение
        <br />
        17, офис 305;
        <br />
        Банковские реквизиты:
        <br />
        Расчетный счет: 40702810602300008134
        <br />
        Банк: АО "АЛЬФА-БАНК"
        <br />
        БИК: 044525593
        <br />
        Корр. счет: 30101810200000000593
      </p>
      <p>
        e-mail: <a href="mailto:info@neurocam.com">info@neurocam.com</a>
      </p>
      <p>Действующая редакция Пользовательского соглашения от 20.01.2019 г.</p>
    </div>
  );
};

export default Terms;
