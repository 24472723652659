import { combineReducers } from "redux";

import users from "./users";
import cameras from "./cameras";
import camera from "./camera";
import archive from "./archive";
import scan from "./scan";
import events from "./events";
import payments from "./payments";
import faces from "./faces";
import reports from "./reports";
import tokens from "./tokens";

export default combineReducers({
  users,
  cameras,
  camera,
  archive,
  scan,
  events,
  payments,
  faces,
  reports,
  tokens
});
