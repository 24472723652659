import React from "react";
import cn from "classnames";

export function Pause({ paused, onClick }) {
    return (
        <button
            className={cn("video__btn", "video__btn_play", {
                paused: !paused
            })}
            onClick={onClick}
        />
    );
}