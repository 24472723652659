import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import { ResetPasswordConfirm } from "../reducers/users";

class UsersPasswordReset extends React.Component {
  state = {
    password: "",
    confirm: "",
    error: "",
    invite: false,
  };

  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("invite")) {
      this.state.invite = true;
    }
  }

  handleResetPassword = (e) => {
    e.preventDefault();

    const { t } = this.props;
    const { password, confirm } = this.state;

    if (password !== confirm) {
      this.setState({ error: t("login-password-recovery-password-mismatch") });
      return;
    }

    this.props
      .ResetPasswordConfirm(this.props.match.params.token, password)
      .then((resp) => {
        if (resp.data.Ok) {
          this.props.history.push("/");
          return;
        }

        this.setState({ error: resp.data.Error });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.toString() });
      });
  };

  render() {
    const { password, confirm, error, invite } = this.state;
    const { t } = this.props;

    return (
      <div className="login">
        <img className="login__bg" src="/img/bg.jpg" alt="" />
        <div className="login__container">
          <div className="login__logo">
            <img className="login__pic" src="/img/neurocam.svg" alt="" />
          </div>
          <div>
            <div className="login__reset">
              <form onSubmit={this.handleResetPassword}>
                <div className="login__title title">
                  {invite
                    ? t("login-password-set")
                    : t("login-password-recovery")}
                </div>
                <div className="login__field field">
                  <div className="field__label">
                    {t("login-password-recovery-password")}
                  </div>
                  <div className="field__wrap">
                    <input
                      required
                      className="field__input"
                      type="password"
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="login__field field">
                  <div className="field__label">
                    {t("login-password-recovery-confirm")}
                  </div>
                  <div className="field__wrap">
                    <input
                      required
                      className="field__input"
                      type="password"
                      value={confirm}
                      onChange={(e) =>
                        this.setState({ confirm: e.target.value })
                      }
                    />
                    <div className="field__error">{error}</div>
                  </div>
                </div>
                <div className="login__foot">
                  <button className="btn btn_lg" type="submit">
                    {invite ? t("save") : t("restore")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = bindActionCreators.bind(null, {
  ResetPasswordConfirm,
});

export default withRouter(
  translate()(connect(null, mapDispatchToProps)(UsersPasswordReset))
);
