import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "./Icon";
import Loader from "./Loader";

const State = props => {
  const { className, type, loaderColor, t } = props;
  return (
    <div className={cn("state", className)}>
      {type === "loader" && (
        <div className="state__wrap">
          <Loader
            className="state__loader"
            width="40"
            color={loaderColor ? loaderColor : "#5A656B"}
          />
        </div>
      )}
      {type === "camera-off" && (
        <div className="state__wrap">
          <div className="state__preview">
            <Icon className="state__pic" name="camera-off" />
          </div>
          <div className="state__title">{t("grid-camera-not-active")}</div>
        </div>
      )}
      {type === "no-connection" && (
        <div className="state__wrap">
          <div className="state__preview">
            <img className="state__pic" src="/img/no-connection.svg" alt="" />
          </div>
          <div className="state__title">{t("grid-camera-disconnected")}</div>
        </div>
      )}
    </div>
  );
};

export default translate()(State);
