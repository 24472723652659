import React from "react";
import styles from "./Empty.module.sass";
import Icon from "../Icon";

const Empty = ({ icon, title, text }) => {
  return (
    <div className={styles.empty}>
      <div className={styles.container}>
        <div className={styles.preview}>
          <Icon name={icon} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default Empty;
