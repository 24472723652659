import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Loader from "../Loader";
import WorkingTime from "./WorkingTime";
import ReportsStatsPerson from "./ReportsStatsPerson";
import ReportsNoData from "./ReportsNoData";
import { personPhotoUrl } from "../../links";
import { selectedDates } from "./utils";

import { TimeTrackingFetch } from "../../reducers/reports";

class ReportsEmployee extends React.Component {
  state = {
    person: null,
    currentPerson: null,
    prevPerson: null,
    loading: true,
  };

  timeTrackingFetch() {
    this.props.TimeTrackingFetch().then(() => {
      const { timeTracking, id } = this.props;
      this.setState({
        person: timeTracking.current.persons_info[id],
        currentPerson:
          timeTracking.current.persons &&
          timeTracking.current.persons.find((x) => x.id.toString() === id),
        prevPerson:
          timeTracking.prev.persons &&
          timeTracking.prev.persons.find((x) => x.id.toString() === id),
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.timeTrackingFetch();
  }

  componentDidUpdate(prevProps) {
    prevProps.filters !== this.props.filters && this.timeTrackingFetch();
  }

  render() {
    const { t, timeTrackingFetched, filters } = this.props;
    const { person, currentPerson, prevPerson, loading } = this.state;

    return (
      <div className="layout__container layout__container_report">
        {timeTrackingFetched && !loading ? (
          <>
            {person && (
              <div className="layout__head">
                <Link
                  className="layout__close action"
                  to={
                    filters.placeTimeTracking
                      ? `/reports/time-tracking/${filters.placeTimeTracking}`
                      : `/reports/time-tracking`
                  }
                >
                  <Icon name="close" />
                </Link>
                <div className="layout__avatar">
                  <img
                    src={personPhotoUrl(person.id, person.main_photo)}
                    alt={person.name}
                  />
                </div>
                <div className="layout__title">
                  {person.name} {person.last_name}
                </div>
              </div>
            )}
            <div className="layout__scroll">
              {currentPerson ? (
                <div className="employee">
                  <ReportsStatsPerson
                    date={selectedDates(t, filters)}
                    current={currentPerson}
                    prev={prevPerson}
                    singleDay={selectedDates(t, filters, "singleDay")}
                  />
                  <WorkingTime
                    current={currentPerson}
                    singleDay={selectedDates(t, filters, "singleDay")}
                  />
                </div>
              ) : (
                <ReportsNoData timeTracking />
              )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  timeTracking: state.reports.timeTracking,
  timeTrackingFetched: state.reports.timeTrackingFetched,
});

export default translate()(
  connect(mapStateToProps, {
    TimeTrackingFetch,
  })(ReportsEmployee)
);
