import React, { useState } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import Search from "../../../components/Search";

import {
  FacesSetFilter,
  SearchGuidByPhoto,
  LoadPersons,
} from "../../../reducers/faces";
import { toBase64 } from "../../../utils";

const FacesSearch = ({
  t,
  file,
  setFile,
  loading,
  setLoading,
  filters,
  FacesSetFilter,
  SearchGuidByPhoto,
  LoadPersons,
}) => {
  const [query, setQuery] = useState(filters.search || "");

  const debounced = useDebouncedCallback(() => {
    FacesSetFilter({ search: query });
  }, 800);

  const handleSearch = (value) => {
    setQuery(value);
    debounced();
  };

  const handleUpload = async (e) => {
    setLoading(true);

    try {
      const image = await toBase64(e.target.files[0]);
      setFile(image);

      await SearchGuidByPhoto(image, filters.persons);
    } catch (e) {
      console.log(e);
      if (e.response.status === 400) {
        toast.error(t(`no-results-found`));
      } else if (e.response.status === 410) {
        toast.error(t(`no-connection-neurobox`));
      } else {
        toast.error(t(`data-update-error`));
      }
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setFile(null);
    LoadPersons();
  };

  return (
    <Search
      value={query}
      placeholder={filters.persons ? t("persons-search") : t("visitors-search")}
      file={file}
      onChange={(e) => handleSearch(e.target.value)}
      onClose={onClose}
      onSearchByPhoto={handleUpload}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => ({
  filters: state.faces.filters,
});

export default translate()(
  connect(mapStateToProps, { FacesSetFilter, SearchGuidByPhoto, LoadPersons })(
    FacesSearch
  )
);
