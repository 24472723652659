import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import styles from "./Photos.module.sass";
import Icon from "../../Icon";
import Button from "../../Button";

import { personPhotoUrl } from "../../../links";
import { toBase64 } from "../../../utils";

const Photos = ({
  photos,
  mainPhoto,
  onChange,
  onRemove,
  onFavorite,
  disabled,
}) => {
  const handleChange = async (e) => {
    const photosPromises = [];
    for (let i = 0; i < e.target.files.length; i++) {
      photosPromises.push(toBase64(e.target.files[i]));
    }
    const imagesBase64 = await Promise.all(photosPromises);
    const newPhotos = [];
    imagesBase64.forEach((x) => newPhotos.push({ image: x }));
    onChange(photos.concat(newPhotos));
  };

  console.log(photos);

  return (
    <div className={cn(styles.upload, { [styles.active]: photos.length > 0 })}>
      <label className={cn(styles.field, { [styles.disabled]: disabled })}>
        <span className={styles.group}>
          <Icon name="photo" />
          <span className={styles.label}>JPEG, PNG</span>
        </span>
        <Button className={styles.btn} icon="plus" />
        {!disabled && (
          <input
            className={styles.file}
            onChange={handleChange}
            type="file"
            multiple
          />
        )}
      </label>
      <div className={styles.list}>
        {photos &&
          photos.length > 0 &&
          photos.map((x, i) => (
            <div className={styles.item} key={i}>
              <img
                className={styles.pic}
                src={
                  x.image_id ? personPhotoUrl(x.person_id, x.image_id) : x.image
                }
                alt="Avatar"
              />
              {!disabled && onRemove && (
                <button
                  className={styles.action}
                  onClick={() => onRemove(x.person_id, x.image_id)}
                >
                  <Icon name="trash" />
                </button>
              )}
              {!disabled && photos.length > 1 && onFavorite && (
                <button
                  className={cn(styles.action, {
                    [styles.active]: mainPhoto === x.image_id,
                  })}
                  onClick={() => onFavorite(x.person_id, x.image_id)}
                >
                  <Icon name="fav" />
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default translate()(Photos);
