import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import { diffDuration, diffTime, NumberToHours } from "./utils";

class ReportsStatsPerson extends React.Component {
  render() {
    const { t, current, prev, date, singleDay } = this.props;

    const empty = "----";
    const stats = [
      {
        value: current.avg_first_in ? current.avg_first_in : empty,
        minus: prev && diffTime(t, current, prev, "avg_first_in", true),
        change: prev && diffTime(t, current, prev, "avg_first_in"),
        content: t(singleDay ? "time-start-work" : "avg-time-start-work"),
      },
      {
        value: current.avg_last_out ? current.avg_last_out : empty,
        minus: prev && diffTime(t, current, prev, "avg_last_out", true),
        change: prev && diffTime(t, current, prev, "avg_last_out"),
        content: t(singleDay ? "time-end-work" : "avg-time-end-work"),
      },
      {
        value:
          current.avg_duration > 0
            ? NumberToHours(t, current.avg_duration)
            : empty,
        minus: prev && diffDuration(t, current, prev, "avg_duration", true),
        change:
          prev &&
          current.avg_duration > 0 &&
          diffDuration(t, current, prev, "avg_duration"),
        content: t(singleDay ? "time-on-work" : "avg-time-on-work"),
      },
    ];

    return (
      <div className="stats stats_simple">
        <div className="stats__head">
          <div className="stats__title">{t("employee-statistics")}</div>
          {date && <div className="stats__title stats__title_date">{date}</div>}
        </div>
        <div className="stats__container">
          {stats.map((x, index) => (
            <div className="stats__cell" key={index}>
              <div className="stats__group">
                <div className="stats__value">{x.value}</div>
                {x.change && (
                  <div
                    className={cn("stats__change change", {
                      red: x.minus,
                      revert: index === 0,
                    })}
                  >
                    {x.change}
                  </div>
                )}
              </div>
              <div className="stats__content">{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default translate()(ReportsStatsPerson);
