import React from "react";
import { translate } from "react-i18next";
import Icon from "../Icon";

const AddedCameras = props => {
  const { t, count, onContinue, onAddMore } = props;
  return (
    <div className="page">
      <div className="page__container">
        <div className="page__preview">
          <Icon className="page__pic page__pic_red" name="camera-lg" />
          <div className="page__counter">+{count}</div>
        </div>
        <div className="page__title">{t("added-cameras-title")}</div>
        <div className="page__text">{t("added-cameras-text")}</div>
        <div className="page__foot">
          <button className="page__btn btn btn_lg" onClick={onContinue}>
            {t("continue")}
          </button>
          <button className="page__btn btn btn_white" onClick={onAddMore}>
            {t("added-cameras-more")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default translate()(AddedCameras);
