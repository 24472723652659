export const archiveTarrifsByID = {
  3: {
    days: "3",
    daysText: "дня",
    price: 400,
    month: 1,
    rate_id: 3,
  },
  4: {
    days: "7",
    price: 500,
    month: 1,
    rate_id: 4,
  },
  5: {
    days: "30",
    price: 900,
    month: 1,
    rate_id: 5,
  },
  6: {
    days: "3",
    daysText: "дня",
    price: 4000,
    month: 12,
    rate_id: 6,
  },
  7: {
    days: "7",
    price: 5000,
    month: 12,
    rate_id: 7,
  },
  8: {
    days: "30",
    price: 9000,
    month: 12,
    rate_id: 8,
  },

  // only events
  //16: {
  //  days: "60",
  //  priceOld: 7188,
  //  price: 5990,
  //  month: 12,
  //  rate_id: 16,
  //  archiveOnlyEvents: true,
  //},
};

export const archiveFullTarrifs = {
  month: [3, 4, 5],
  year: [6, 7, 8],
};

export const archiveEventsTarrifs = {
  month: [],
  year: [],
};

export const recognitionTarrifsByID = {
  // month + report
  9: {
    price: 5000,
    month: 1,
    rate_id: 9,
    reports: true,
  },
  10: {
    price: 10000,
    month: 1,
    rate_id: 10,
    reports: true,
    celebrity: true,
  },

  // year + report
  11: {
    price: 50000,
    month: 12,
    rate_id: 11,
    reports: true,
  },
  12: {
    price: 100000,
    month: 12,
    rate_id: 12,
    reports: true,
    celebrity: true,
  },
};

export const recognitionTariffs = {
  month: [],
  year: [],
};

export const reportTarrifs = {
  month: [9, 10],
  year: [11, 12],
};
