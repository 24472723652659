import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import { toast } from "react-toastify";
import Alert from "./Alert";
import { CameraRatesPurchase } from "../reducers/payments";
import { currency } from "../utils";

class ConnectTariff extends React.Component {
  constructor(props) {
    super(props);
    this.state = { promoCode: false };
  }

  togglePromo = () => {
    this.setState({ promoCode: !this.state.promoCode });
  };

  canBuy = () => this.getFundsAfterPay() >= 0;

  getFundsAfterPay = () =>
    (this.props.balance - this.props.tariff.price).toFixed(2);

  getInsufficientFunds = () => this.getFundsAfterPay() * -1;

  onCameraRateConnect = () => {
    const { t, CameraRatesPurchase, history } = this.props;

    CameraRatesPurchase(this.props.camera.id, this.props.tariff.rate_id)
      .then(() => {
        const { Success, Message } = this.props.purchase;
        if (Success) {
          this.props.tariff.persons
            ? history.push(`/cameras/${this.props.camera.id}?showRecognition=1`)
            : history.push(`/cameras/${this.props.camera.id}`);
          toast.success(t(`camera-rates-purchase-success`));
        } else {
          toast.error(t(`camera-rates-purchase-error-${Message}`));
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("error-occurred"));
      });
  };

  renderBeforeDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + this.props.tariff.month);
    return date.toLocaleDateString();
  };

  renderBalanceAfterPay = () => {
    const result = this.getFundsAfterPay();
    return result > 0 ? (
      <span className="color-black">
        {result} {this.props.t(currency)}
      </span>
    ) : (
      <span className="color-red">
        {result} {this.props.t(currency)}
      </span>
    );
  };

  render() {
    const { tariff, camera, t } = this.props;
    return (
      <div className="connect">
        <div className="connect__title title">{t("connect-tariff")}</div>
        <div className="connect__details">
          <div className="connect__detail">
            <span className="bold">{t("camera")}:</span> {camera.title}
          </div>
          <div className="connect__detail">
            <span className="bold">{t("connect-tariff-selected")}: </span>
            {tariff.name}
          </div>
          <div className="connect__detail">
            <span className="bold">{t("validity")}:</span> {t("to")}{" "}
            {this.renderBeforeDate()} {t("year")}
          </div>
        </div>
        <div className="connect__row">
          <div className="connect__col">
            <div className="connect__payment">
              {t("connect-tariff-to-pay")}: {tariff.price} {t(currency)}
            </div>
            <div className="connect__info">
              {t("connect-tariff-balance")}: {this.renderBalanceAfterPay()}
            </div>
          </div>
          {this.canBuy() && (
            <div className="connect__col">
              <button
                className="btn btn_lg"
                onClick={() => this.onCameraRateConnect()}
              >
                {t("connect")}
              </button>
            </div>
          )}
        </div>
        {!this.canBuy() && (
          <Alert
            className="connect__alert alert alert_action"
            title={t("connect-tariff-not-enough-money")}
            content={
              t("connect-tariff-not-enough-money-text-1") +
              " " +
              this.getInsufficientFunds() +
              " " +
              t(currency) +
              " " +
              t("connect-tariff-not-enough-money-text-2")
            }
          >
            {false && (
              <button
                className="btn"
                onClick={() =>
                  this.props.history.push(
                    `/payment?total=${this.getInsufficientFunds()}`
                  )
                }
              >
                {t("connect-tariff-top-up-balance-on")}{" "}
                {this.getInsufficientFunds()} {t(currency)}
              </button>
            )}
          </Alert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  camera: state.camera.camera.Camera,
  balance: state.payments.balance,
  purchase: state.payments.cameraRatesResult,
});

export default withRouter(
  translate()(
    connect(
      mapStateToProps,
      bindActionCreators.bind(null, { CameraRatesPurchase })
    )(ConnectTariff)
  )
);
