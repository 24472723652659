import React from "react";
import styles from "./Popup.module.sass";
import Action from "../Action";

const Popup = ({ onClose, children }) => {
  return (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <Action
          className={styles.close}
          onClick={onClose}
          icon="close-sm"
          small
        />
        {children}
      </div>
    </div>
  );
};

export default Popup;
