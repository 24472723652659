import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

class Scanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeoutText: ""
    };
  }

  renderTime = () => {
    const { timer } = this.props;

    let minutes = parseInt(timer / 60, 10);
    let seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return <div className="scan__time">
      <span className="scan__cell">{minutes}</span>:<span className="scan__cell">{seconds}</span>
    </div>;
  };

  render() {
    const { t, cameras, timer, stop } = this.props;
    return (
      <div className="scan">
        <div className="scan__loader">
          <div className="scan__pulse"></div>
          <div className="scan__pulse"></div>
          <div className="scan__pulse"></div>
          <div className="scan__circle">
            <img className="scan__pic" src="/img/loader.png" alt=""/>
          </div>
        </div>
        <div className="scan__title">
          {t("scanning")} {cameras > 0 && `${t("cameras-found")}: ${cameras}`}
        </div>
        <div className="scan__text">{t("scanning-text")}</div>
        {timer && this.renderTime()}
        {stop && (
          <div className="scan__foot">
            <button onClick={stop} className="scan__btn btn btn_white">
              {t("complete")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

Scanning.propTypes = {
  cameras: PropTypes.number,
  timer: PropTypes.number,
  stop: PropTypes.func
};

Scanning.defaultProps = {
  cameras: 0
};

export default translate()(Scanning);
