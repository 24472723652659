import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import cn from "classnames";
// import moment from "moment-timezone";
import ReportsLoader from "../ReportsLoader";
import ChartTotalAttendance from "./ChartTotalFootfall";
import ChartBySex from "./ChartBySex";
import ChartByAge from "./ChartByAge";
import ChartNewReturned from "./ChartNewReturned";
// import ChartEmotions from "./ChartEmotions";
import ReportsNoData from "../ReportsNoData";

import { FootfallFetch } from "../../../reducers/reports";
import { EventsSetFilter } from "../../../reducers/events";
import { countPeriod } from "../utils";

class FootfallChart extends React.Component {
  state = {
    activeIndex: 0,
    tabs: [
      {
        index: 0,
        filter: "",
        title: this.props.t("total-footfall"),
      },
      {
        index: 1,
        filter: "gender",
        title: this.props.t("by-gender"),
      },
      {
        index: 2,
        filter: "age",
        title: this.props.t("by-age"),
      },
      {
        index: 3,
        filter: "",
        title: this.props.t("new-returning"),
      },
      // {
      //   index: 4,
      //   title: this.props.t("emotions")
      // }
    ],
  };

  componentDidMount() {
    this.props.FootfallFetch();
  }

  toggleTab = (index) => {
    this.setState({ activeIndex: index });
  };

  setEventsFilter = (key, value) => {
    // set filters
    const { period, age, gender } = this.props.filters;
    const eventsFilters = {};

    if (period) {
      eventsFilters["period"] = { from: period.since, to: period.until };
    }

    if (age) {
      eventsFilters["age"] = age;
    }

    if (gender) {
      eventsFilters["gender"] = gender;
    }

    // set additional param
    if (key) eventsFilters[key] = value;

    this.props.EventsSetFilter(eventsFilters);
  };

  render() {
    const {
      t,
      footfall,
      footfallFetched,
      filters,
      dates,
      labels,
      timezone,
    } = this.props;
    const { activeIndex, tabs } = this.state;

    return (
      <div className="chart chart_footfall">
        <div className="chart__head">
          <div className="chart__title title">{t("footfall-chart")}</div>
        </div>
        <div className="chart__tabs">
          {tabs.map((x) => (
            <button
              className={cn("chart__tab", {
                active: x.index === activeIndex,
              })}
              onClick={() => this.toggleTab(x.index)}
              key={x.index}
            >
              {x.title}
            </button>
          ))}
        </div>
        {footfallFetched ? (
          footfall.current.periods ? (
            <div className="chart__inner">
              {activeIndex === 0 && (
                <ChartTotalAttendance
                  footfall={footfall}
                  period={countPeriod(
                    filters.period.since,
                    filters.period.until
                  )}
                  dates={dates}
                  labels={labels}
                  timezone={timezone}
                  onEventsClick={this.setEventsFilter}
                />
              )}
              {activeIndex === 1 && (
                <ChartBySex
                  footfall={footfall}
                  period={countPeriod(
                    filters.period.since,
                    filters.period.until
                  )}
                  dates={dates}
                  labels={labels}
                  timezone={timezone}
                  onEventsClick={this.setEventsFilter}
                />
              )}
              {activeIndex === 2 && (
                <ChartByAge
                  footfall={footfall}
                  period={countPeriod(
                    filters.period.since,
                    filters.period.until
                  )}
                  dates={dates}
                  labels={labels}
                  timezone={timezone}
                  onEventsClick={this.setEventsFilter}
                />
              )}
              {activeIndex === 3 && (
                <ChartNewReturned
                  footfall={footfall}
                  period={countPeriod(
                    filters.period.since,
                    filters.period.until
                  )}
                  dates={dates}
                  labels={labels}
                  timezone={timezone}
                />
              )}
              {/* {activeIndex === 4 && <ChartEmotions footfall={footfall} />} */}
            </div>
          ) : (
            <ReportsNoData />
          )
        ) : (
          <ReportsLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  timezone: state.users.user && state.users.user.Timezone,
  footfall: state.reports.footfall,
  footfallFetched: state.reports.footfallFetched,
});

export default translate()(
  connect(mapStateToProps, {
    FootfallFetch,
    EventsSetFilter,
  })(FootfallChart)
);
