import React from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "../Icon";
import Loader from "../Loader";
import DropdownActions from "../DropdownActions";
import EventsList from "./EventsList";
import EventsEmpty from "./EventsEmpty";
import { EventsMarkViewed, EventsDelete } from "../../reducers/events";
import { checkPerms } from "../../utils";

class EventsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallView: localStorage.getItem("eventsSmallView") === "true",
      visibleCameras: true,
      selection: false,
      selected: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.setFilter({ cameraId: this.props.match.params.id });
    } else {
      this.props.setFilter({ ...this.props.events.filters, cameraId: null });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.setFilter({ cameraId: this.props.match.params.id });
    }
  }

  toggleView = (e) => {
    if (!e.target.classList.contains("active")) {
      this.setState({ smallView: !this.state.smallView }, () => {
        localStorage.setItem("eventsSmallView", this.state.smallView);
      });
    }
  };

  toggleSelection = () => {
    this.setState({
      selection: !this.state.selection,
    });
  };

  toggleCameras = () => {
    this.setState({ visibleCameras: !this.state.visibleCameras });
  };

  onSelect = (eventID) =>
    this.setState({
      selected: this.state.selected.includes(eventID)
        ? this.state.selected.filter((id) => id !== eventID)
        : [...this.state.selected, eventID],
    });

  onSelectAll = () =>
    this.setState({
      selected: this.props.days
        .map((day) => [...this.props.itemsByDays[day]])
        .reduce((acc, curr) => [...acc, ...curr], [])
        .map((item) => item.ID),
    });

  onUnselectAll = () => this.setState({ selected: [] });

  render() {
    const { smallView, selection, selected } = this.state;
    const { fetched, days, itemsByDays, hasMore, fetch, users, t } = this.props;

    const isPermsDelete = checkPerms(users.user, "event:delete");

    const options = [
      {
        className: "m-show",
        title: t("events-grid-delete-selected"),
        func: () =>
          selected.map((id) =>
            this.props.EventsDelete(id).then(() =>
              this.setState({
                selected: this.state.selected.filter((s) => s !== id),
              })
            )
          ),
        hide: !isPermsDelete,
      },
      {
        title: t("events-grid-mark-viewed"),
        func: () =>
          selected.map((id) =>
            this.props.EventsMarkViewed(id, true, true).then(() =>
              this.setState({
                selected: this.state.selected.filter((s) => s !== id),
              })
            )
          ),
      },
      {
        title: t("events-grid-mark-unread"),
        func: () =>
          selected.map((id) =>
            this.props.EventsMarkViewed(id, false, true).then(() =>
              this.setState({
                selected: this.state.selected.filter((s) => s !== id),
              })
            )
          ),
      },
    ];

    return (
      <div
        className={cn("layout__container", "layout__container_events", {
          "no-scroll": this.props.noScroll,
        })}
      >
        {selection ? (
          <div
            className={cn("layout__head", {
              "only-desktop": this.props.noScroll,
            })}
          >
            <button
              className="layout__action action"
              onClick={this.toggleSelection}
            >
              <Icon name="close-sm" />
            </button>
            <div className="layout__selected">
              <span className="bold">{t("selected")}</span>{" "}
              <span>{selected.length}</span>
            </div>
            {selected.length !== fetched.length && (
              <button
                className="layout__trigger layout__trigger_select"
                onClick={this.onSelectAll}
              >
                {t("select-all")}
              </button>
            )}
            {selected.length > 0 && (
              <button
                className="layout__trigger layout__trigger_select"
                onClick={this.onUnselectAll}
              >
                {t("clear")}
              </button>
            )}
            <div className="layout__actions layout__actions_custom">
              {isPermsDelete && (
                <button
                  className="layout__action action m-hide"
                  onClick={() => options[0].func()}
                >
                  <Icon name="trash" />
                </button>
              )}
              <DropdownActions
                options={options}
                actions
                icon="dots"
                onChange={(option) => option.func()}
              />
            </div>
          </div>
        ) : (
          <div
            className={cn("layout__head", {
              "only-desktop": this.props.noScroll,
            })}
          >
            {!this.props.hideSelection && (
              <button
                className="layout__trigger layout__trigger_toggle"
                onClick={this.toggleSelection}
              >
                {t("select")}{" "}
                <span className="m-hide">{t("events-grid-events")}</span>
              </button>
            )}
            <div className="layout__actions">
              {!this.props.hideSelection && (
                <button
                  className="layout__action action"
                  onClick={this.toggleSelection}
                >
                  <Icon name="selection" />
                </button>
              )}
              <button
                className={
                  "layout__action action" + (smallView ? "" : " active")
                }
                onClick={this.toggleView}
              >
                <Icon name="grid" />
              </button>
              <button
                className={
                  "layout__action action" + (smallView ? " active" : "")
                }
                onClick={this.toggleView}
              >
                <Icon name="grid-sm" />
              </button>
            </div>
          </div>
        )}
        <div
          className={cn(
            { layout__scroll: !this.props.noScroll },
            { "no-scroll": this.props.noScroll }
          )}
        >
          {fetched ? (
            days && days.length > 0 ? (
              <InfiniteScroll
                initialLoad={false}
                loadMore={() => fetch()}
                hasMore={hasMore}
                useWindow={false}
              >
                {days.map((day) => (
                  <EventsList
                    key={day}
                    day={day}
                    items={itemsByDays[day]}
                    className={smallView ? "grid_three" : ""}
                    enableSelection={selection}
                    selected={selected}
                    onSelect={(event, value) => this.onSelect(event, value)}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <EventsEmpty />
            )
          ) : (
            <Loader width="32" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  events: state.events,
});

export default translate()(
  withRouter(
    connect(mapStateToProps, { EventsMarkViewed, EventsDelete })(EventsGrid)
  )
);
