import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import { parseURL } from "../http";
import Icon from "../components/Icon";
import Loader from "../components/Loader";
import Tabs from "../components/Neurobox/Tabs";
import Switch from "../components/Switch";
import Help from "../components/Neurobox/Help";
import Scanning from "../components/Neurobox/Scanning";
import CamerasFound from "../components/Neurobox/CamerasFound";
import CamerasNotFound from "../components/Neurobox/CamerasNotFound";
import {
  ScanDirectlySetTitle,
  ScanDirectlySetURL,
  ScanDirectlyCamera,
  ScanDirectlyClear,
  SCAN_STATE_NEUROBOX,
  SCAN_STATE_SCANNING,
  SCAN_STATE_COMPLETE,
} from "../reducers/scan";
import { CamerasList } from "../reducers/cameras";
import { CameraPatch } from "../reducers/camera";
// import ModalLauncher from "../components/ModalLauncher";
// import ConnectCamera from "../components/ConnectCamera";

class PrepareTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showAuthForm: false,
      showPortForm: false,
      username: "",
      password: "",
      port: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.updateState();
    }
  }

  updateState = () => {
    const { parsed } = this.props;

    this.setState({
      showAuthForm: false,
      showPortForm: false,
      username: "",
      password: "",
      port: "",
    });

    if (parsed.username) {
      this.setState({
        showAuthForm: true,
        username: parsed.username,
        password: parsed.password,
      });
    }

    if (parsed.customPort) {
      this.setState({
        showPortForm: true,
        port: parsed.port,
      });
    }
  };

  onChangePart = (key, e) => {
    this.setState({ [key]: e.target.value }, () => {
      const { parsed } = this.props;

      const schemePart = parsed.scheme ? parsed.scheme + "://" : "";
      const authPart =
        (this.state.username ? this.state.username : "") +
        (this.state.username && this.state.password
          ? ":" + this.state.password
          : "");
      const portPart = this.state.port ? ":" + this.state.port : "";

      const newurl =
        schemePart +
        (authPart ? authPart + "@" : "") +
        parsed.host +
        portPart +
        (parsed.uri ? "/" + parsed.uri : "");

      this.props.onChange(newurl);
    });
  };

  render() {
    const { t, onChange, onScan, parsedUrl, url, error } = this.props;
    return (
      <div className="preparing">
        <div className="preparing__title">{t("camera-settings")}</div>
        <div className="preparing__container">
          <div className="preparing__scan scan">
            <div className="scan__subtitle">{t("enter-id-or-stream")}</div>
            <div className="scan__text">{t("instruction-ip")}</div>
            <div className="scan__fieldset">
              <div className="scan__field field">
                <div className="field__wrap">
                  <input
                    type="text"
                    className="field__input"
                    disabled={this.state.loading}
                    value={url}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {this.state.loading && (
                    <div className="field__icon">
                      <Loader />
                    </div>
                  )}
                  {error && (
                    <div className="field__icon">
                      <Icon className="field__close" name="close-sm" />
                    </div>
                  )}
                  {parsedUrl && !error && (
                    <div className="field__icon">
                      <Icon name="success" />
                    </div>
                  )}
                </div>
              </div>
              <div className="scan__toggle toggle">
                <div className="toggle__head">
                  <Switch
                    content={t("unique-login-and-password")}
                    value={this.state.showAuthForm}
                    onChange={(e) =>
                      this.setState({ showAuthForm: e.target.checked })
                    }
                  />
                </div>
                {this.state.showAuthForm && (
                  <div className="toggle__body">
                    <div className="toggle__row">
                      <div className="toggle__col">
                        <div className="field">
                          <div className="field__label">{t("login")}</div>
                          <div className="field__wrap">
                            <input
                              type="text"
                              className="field__input"
                              value={this.state.username}
                              onChange={(e) => this.onChangePart("username", e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="toggle__col">
                        <div className="field">
                          <div className="field__label">{t("password")}</div>
                          <div className="field__wrap">
                            <input
                              type="text"
                              className="field__input"
                              value={this.state.password}
                              onChange={(e) => this.onChangePart("password", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="scan__toggle toggle">
                <div className="toggle__head">
                  <Switch
                    content={t("port-set")}
                    value={this.state.showPortForm}
                    onChange={(e) =>
                      this.setState({ showPortForm: e.target.checked })
                    }
                  />
                </div>
                {this.state.showPortForm && (
                  <div className="toggle__body">
                    <div className="toggle__port">
                      <div className="field">
                        <div className="field__label">{t("port")}</div>
                        <div className="field__wrap">
                          <input
                            type="text"
                            className="field__input"
                            value={this.state.port}
                            onChange={(e) => this.onChangePart("port", e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="scan__foot">
              <button
                className="scan__btn btn"
                disabled={!this.props.url}
                onClick={onScan}
              >
                {t("scan-start")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CompleteCameraFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
    };
  }

  render() {
    const {
      t,
      token,
      directly,
      onAddCamera,
      onScan,
      ScanDirectlySetTitle,
    } = this.props;
    return (
      <div className="tabs__item">
        <div className="scan scan_lg">
          <div className="scan__title">{t("scan-finish")}</div>
          <div className="scan__text">{t("camera-found")}</div>
          <div className="scan__body">
            <CamerasFound
              singleMode
              t={t}
              token={token}
              cameras={[directly.camera]}
              scanSingle={onScan}
              onChangeState={(connected) => this.setState({ connected })}
              onChangeTitle={(camera, title) => ScanDirectlySetTitle(title)}
            />
          </div>
          <div className="scan__foot">
            <button
              disabled={!this.state.connected}
              className="scan__btn btn"
              onClick={onAddCamera}
            >
              {t("wizard-add-camera")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const CompleteCameraNotFound = (props) => {
  return (
    <div className="tabs__item">
      <div className="scan scan_lg">
        <div className="scan__body">
          <CamerasNotFound
            type="directly"
            onClickRepeat={() => props.ScanDirectlyClear()}
            onClickNeurobox={() => props.history.push("/add")}
          />
        </div>
      </div>
    </div>
  );
};

const CompleteTab = (props) => {
  console.log(props.directly);
  return props.directly.camera.id ? (
    <CompleteCameraFound {...props} />
  ) : (
    <CompleteCameraNotFound {...props} />
  );
};

class AddCameraDirectly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      urlParsed: null,
      urlError: null,
    };
  }

  componentWillUnmount() {
    this.props.ScanDirectlyClear();
  }

  handleChangeURL = (url) => {
    this.setState({
      urlError: null,
    });

    try {
      const parsed = parseURL(url);
      this.setState({ urlParsed: parsed }, () =>
        this.props.ScanDirectlySetURL(url)
      );
    } catch (e) {
      this.setState({ urlError: "bad url" });
    }
  };

  handleScan = (id, login, password, url) => this.props.ScanDirectlyCamera(url);

  handleAddCamera = () => {
    const { directly } = this.props;

    directly.camera.use = true;
    directly.camera.active = true;

    this.props
      .CameraPatch(directly.camera, directly.camera.id, [])
      .then(this.props.CamerasList)
      .then(() => this.props.history.push("/cameras"));
  };

  render() {
    const { t, directly } = this.props;

    return (
      <Tabs
        state={directly.state}
        title={t("connect-camera-directly")}
        // right={
        //   <ModalLauncher
        //     buttonClassName="adding__action"
        //     link
        //     label={t("choose-another-connection-method")}
        //   >
        //     <ConnectCamera />
        //   </ModalLauncher>
        // }
        help={
          <Help
            content={t("connect-camera-directly-help")}
            actions={[
              t("instruction-ports"),
              t("choose-another-connection-method"),
            ]}
          />
        }
        tabs={[
          {
            title: t("ip-address"),
            state: SCAN_STATE_NEUROBOX,
            component: (
              <PrepareTab
                {...this.props}
                url={directly.url}
                parsed={this.state.urlParsed}
                error={this.state.urlError}
                onChange={this.handleChangeURL}
                onScan={this.handleScan}
              />
            ),
          },
          {
            title: t("search-camera"),
            state: SCAN_STATE_SCANNING,
            component: <Scanning />,
          },
          {
            title: t("scan-finish"),
            state: SCAN_STATE_COMPLETE,
            component: (
              <CompleteTab
                {...this.props}
                onScan={this.handleScan}
                onAddCamera={this.handleAddCamera}
              />
            ),
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  directly: state.scan.directly,
  token: state.users.token,
});

export default withRouter(
  translate()(
    connect(
      mapStateToProps,
      bindActionCreators.bind(null, {
        ScanDirectlySetTitle,
        ScanDirectlySetURL,
        ScanDirectlyCamera,
        ScanDirectlyClear,
        CamerasList,
        CameraPatch,
      })
    )(AddCameraDirectly)
  )
);
