import React from "react";
import { translate } from "react-i18next";
import styles from "./Demography.module.sass";
import Filter from "../../../Filter";
import Switch from "../../../Switch";

const Demography = ({ t, filters, setFilter }) => {
  const filtersList = [
    {
      filter: "gender",
      title: t("gender"),
      items: [
        { label: t("all-visitors"), value: null },
        { label: t("only-men"), value: 1 },
        { label: t("only-woman"), value: 2 },
      ],
    },
    {
      filter: "age",
      title: t("age"),
      items: [
        { label: t("all"), value: null },
        { label: t("less-18-years"), value: "age0" },
        { label: t("between-18-24-years-old"), value: "age18" },
        { label: t("between-25-34-years-old"), value: "age25" },
        { label: t("between-35-44-years-old"), value: "age35" },
        { label: t("between-45-54-years-old"), value: "age45" },
        { label: t("more-55-years"), value: "age55" },
      ],
    },
  ];

  const isSelected = (filter, value) => filters[filter] === value;

  const toggleSelect = (filter, value) => {
    filter === "gender" && setFilter({ gender: value });
    filter === "age" && setFilter({ age: value });
  };

  return (
    <Filter
      className={styles.places}
      title={t("demography")}
      value={t("select-all")}
    >
      <div className={styles.demo}>
        {filtersList.map((x, index) => (
          <div key={index}>
            <div className={styles.label}>{x.title}</div>
            {x.items.map((y, i) => (
              <Switch
                className={styles.switch}
                content={y.label}
                value={isSelected(x.filter, y.value)}
                onChange={() => toggleSelect(x.filter, y.value)}
                radio
                key={i}
              />
            ))}
          </div>
        ))}
      </div>
    </Filter>
  );
};

export default translate()(Demography);
