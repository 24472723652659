import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { isEurocam } from "./utils";

export const enDateFormat = {
  dayNames: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

export const ruDateFormat = {
  dayNames: [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Субота",
  ],
  monthNames: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

const en = {
  translations: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    mondayShort: "Mo",
    tuesdayShort: "Tu",
    wednesdayShort: "We",
    thursdayShort: "Th",
    fridayShort: "Fr",
    saturdayShort: "Sa",
    sundayShort: "Su",
    day: "Day",
    month: "Month",
    year: "Year",
    "year-short": "y.",
    hide: "Hide",
    remove: "Remove",
    save: "Save",
    clear: "Clear",
    soon: "Soon",
    rub: "rub.",
    eur: "EUR",
    validity: "Validity",
    time: "Time",
    from: "from",
    to: "to",
    of: "of",
    apply: "Apply",
    download: "Download",
    restore: "Restore",
    cancel: "Cancel",
    events: "Events",
    bitrate: "Bitrate:",
    "bitrate-sec": "kbit/s",
    "video-codec": "Video codec:",
    resolution: "Resolution:",
    framerate: "Framerate:",
    group: "Group",
    "frames-sec": "frames/sec",
    "events-storage": "Event storage",
    "archive-storage": "Archive storage",
    "person-recognition": "Person recognition",
    "error-occurred": "An error occurred, try again later",
    "menu-watch": "Watch",
    "menu-events": "Events",
    "menu-faces": "Faces",
    "menu-monitoring": "Monitoring",
    "menu-profile": "Settings",
    "menu-payment": "Payment",
    "menu-neuroboxes": "Neuroboxes",
    "menu-help": "Help",
    "cameras-layout-title": "MY CAMERAS",
    "empty-title": "No connected cameras",
    "empty-text":
      "At the moment there are no connected cameras, click the add camera button to connect",
    "empty-label-download": "Download Neurobox for PC, Mac",
    "empty-label-buy": "Buy Neurobox unit",
    "empty-label-add": "Add camera",
    auth: "Login",
    login: "Login",
    username: "Username",
    password: "Password",
    signin: "Sign In",
    "added-cameras-title": "Cameras added",
    "added-cameras-text":
      'New cameras have been added to the "Watch" section. Click "Continue" to go to the preview or you can add more cameras',
    "added-cameras-more": "Add more cameras",
    continue: "Continue",
    connect: "Connect",
    disable: "Disconnect",
    "connect-camera-device":
      "Connecting the camera through the device neurobox",
    "connect-camera-app":
      "Connecting the camera through the application neurobox",
    "choose-another-connection-method": "Choose another connection method",
    "connect-camera-neurobox-help":
      "To connect the camera via Neurobox, you will need to purchase a device or install the program on a computer or laptop",
    "connect-camera-directly": "Connect the camera directly",
    "connect-camera-directly-help":
      'In order to connect the camera to the service, you will need a dedicated IP address. An external port of the camera, you are going to connect, must also be assigned and opened. To obtain a dedicated IP address, you can contact your ISP. For easier cameras connection, we recommend using our solution - "Neurobox"',
    "buy-neurobox": "Buy Neurobox",
    "download-app": "Download program for Windows and MacOS",
    "search-neurobox": "Search Neurobox",
    "search-device": "Device search",
    "search-camera": "Camera search",
    "scan-completion": "Scan completion",
    "enter-device-id": "Enter the ID",
    "copy-app-id": "Find the ID on the installed Neurobox application",
    "copy-device-id": "Copy the ID from the Neurobox device back.",
    "example-camera-id": "Example: hGpAe2",
    "connect-neurobox-error":
      "Could not connect to Neurobox. Check if your Neurobox is running and connected to the Internet",
    "scan-start": "Start scanning",
    "scan-finish": "Scanning complete",
    "cameras-found": "Found cameras",
    "camera-found": "Found camera",
    "camera-settings": "Camera settings",
    add: "Add",
    cameras: "cameras",
    "cameras-capitalize": "Cameras",
    date: "Date",
    tariff: "Tariff",
    camera: "Сamera",
    "instruction-ports": "The instruction on ports adjustment",
    "instruction-ip":
      "If you know the address of your camera's video stream, copy it into space below. If you do not know the video stream address, then we will try to find it automatically. To do this, enter your ip-address and camera login / password. If you changed the standard camera port, then also specify it",
    "ip-address": "IP address",
    "ip-address-determined": "The IP address is determined automatically",
    "enter-id-or-stream": "Enter IP Address or link to video stream",
    "unique-login-and-password": "The camera has a unique login/password",
    port: "Port",
    "port-set": "Specify the port manually",
    "camera-secured-password": "Camera is password protected",
    "camera-connect-enter-login-password":
      "To connect to the camera, enter your login/password",
    "camera-connect-enter-link-to-stream":
      "To connect the camera, enter a link to the camera's video stream",
    "camera-connect": "Connect to camera",
    "wrong-login-password": "Invalid username/password",
    "link-to-stream": "Link to video stream",
    "wrong-link-to-stream": "Invalid video stream link",
    "edit-link-to-stream": "Edit video stream link",
    reset: "Reset",
    complete: "Complete",
    "could-not-get-camera-image": "Could not get camera image",
    "what-it-is": "What is it?",
    scanning: "Scanning...",
    online: "online",
    offline: "offline",
    "grid-add-camera": "Connect",
    "grid-camera-disconnected": "Camera disconnected",
    "grid-camera-not-active": "Camera disabled",
    "wizard-activate-internal-error": "Internal error, please try later",
    "wizard-activate-error-token_not_found": "You entered incorrect identifier",
    "wizard-activate-error-offline": "Neurobox offline",
    "wizard-activate-error-not_owner":
      "This Neurobox is already linked to another account",
    "wizard-add-camera": "Add camera",
    "wizard-not-found-scan-complete": "Scan complete",
    "wizard-not-found-cameras": "Cameras not found",
    "wizard-not-found-cameras-app":
      "Make sure the camera is working and connected to the same network as the computer with the Neurobox program, using a cable or via Wi-Fi. If everything is done correctly, but the camera is not still  during scanning, <a href='#'class='js-open-intercom'> let us know </a> the camera model and we will help you connect it.",
    "wizard-not-found-cameras-device":
      "Make sure the camera is working and connected to the same network as the Neurobox device, using a cable or via Wi-Fi. If everything is done correctly, but the camera is not still found during scanning, <a href='#'class='js-open-intercom'> let us know </a> the camera model and we will help you connect it.",
    "wizard-not-found-cameras-directly":
      "Make sure the camera is working and connected to the router via cable or via Wi-Fi. Also check that <a href='https://help.neurocam.com/articles/2756842' target='_blank'> port setting </a> is done in your router. You can check if the port you need is <a href='https://portchecker.co/check' target='_blank'> here </a>. If everything is done correctly, but the camera is still not located when scanning, let us know the model of the camera and we will help you connect it.",
    "wizard-not-found-repeat": "Repeat scan",
    "wizard-not-found-via-neurobox": "Add camera via neurobox",
    "registration-errors-Required minimum 6 symbols":
      "Required minimum 6 symbols",
    "registration-errors-Required": "Required",
    "registration-errors-Email already exist": "Email already exist",
    "login-bad credentials": "Wrong login or password",
    "login-Unauthorized": "Authorization error, wrong login or password",
    "transaction-description-deposit": "Recharge",
    "transaction-description-purchase": "Tariff purchase",
    "transaction-description-cancel": "Tariff cancellation",
    "transaction-description-registration_bonus": "Registration bonus",
    "transaction-description-purchase-archive":
      "Connect recording to the cloud",
    "transaction-description-cancel-archive":
      "Disconnect recording to the cloud",
    "transaction-description-purchase-persons": "Connect face recognition",
    "transaction-description-cancel-persons": "Disconnect face recognition",
    "rate-day": "days",
    "rate-days": "days",
    "nav-not-connected": "Not connected",
    "all-cameras": "All cameras",
    owner: "Owner",
    "camera-rates-purchase-error-not own camera": "You are not a camera owner",
    "camera-rates-purchase-error-not enough money": "Not enough money",
    "camera-rates-purchase-error-camera already have rate":
      "Camera already connected",
    "camera-rates-purchase-success": "Success",
    man: "People",
    "man-lowercase": "people",
    "man-short": "ppl.",
    car: "Car",
    faces: "Faces",
    "camera-access-closing": "Closing the camera access",
    "camera-access-close": "Close access",
    user: "The user",
    "camera-access-will-not-allowed-access-camera":
      "camera access will be denied",
    "camera-access-send-invitation": "Send invitation",
    "camera-access-user-email": "User email...",
    "camera-access-invitee-list": "Invitee list",
    "camera-options-archive-title": "Record archive in cloud",
    "camera-options-archive-enabled": "Record archive in cloud connected",
    "camera-options-archive-disabled":
      "Recording archive in cloud is disconnected",
    "camera-options-connect": "Connect",
    "camera-options-connect-text":
      "Connect continuous archive recording or only important events recording to the cloud",
    "camera-options-tariff-paid": "Tariff paid to:",
    "camera-options-autopayment": "Autopayment",
    "camera-options-tariff-deactivation": "Tariff deactivation",
    "camera-options-attention":
      "Attention! The camera archive will be deleted. Funds for the unused period will be returned to the account.",
    "camera-options-disconnect": "Disconnect",
    "camera-options-archiving-enabled-text":
      "The presence detector reveals important events in the frame, such as a person or car appearance.",
    "camera-options-archiving-disabled-text":
      "First you need to connect the archive recording to the cloud.",
    "camera-options-detector-title": "Presence detection",
    "camera-options-detector-enabled": "Presence detection enabled",
    "camera-options-detector-disabled": "Presence detector disabled",
    "camera-options-term-storage": "Term storage",
    "camera-options-events": "events",
    "camera-options-archive": "archive",
    "camera-options-days": "days",
    "camera-options-configure-detector": "Configure",
    "go-to-date": "Go to date...",
    "cameras-item-tabs-options": "Customize options",
    "cameras-item-tabs-events": "Events",
    "cameras-item-tabs-access": "Camera access",
    "cameras-item-tabs-settings": "Camera settings",
    "cameras-settings-camera-on": "The camera is on",
    "cameras-settings-camera-removing": "Camera removing",
    "cameras-settings-camera-remove": "Remove camera",
    "cameras-settings-camera-remove-msg":
      "The camera will be deleted, the archive and events will also be deleted.",
    "cameras-settings-owner": "Camera owner:",
    "cameras-settings-connected-neurobox": "Connected via Neurobox:",
    "cameras-settings-notifications": "Notifications",
    "cameras-settings-notifications-text":
      "You can enable e-mail alerts about the camera turning on / off",
    "cameras-settings-notifications-on": "Enable notifications",
    "cameras-settings-basic": "Basic settings",
    "camera-title": "Camera title",
    "cameras-settings-link-video-stream": "Link to video stream",
    additionally: "Additionally",
    "cameras-settings-additionally-text":
      "You can remotely connect to the camera web interface and make the necessary settings. Only available when the camera is connected using Neurobox",
    "cameras-settings-open-web-interface": "Open camera web interface",
    "sensor-settings-title": "Presence detector setup",
    "sensor-settings-detector-enabled": "Presence detector enabled",
    "sensor-settings-event-responce": "Event Response",
    "man-in-frame": "Person in frame",
    "man-in-frame-text":
      "The detector will respond to the person appearance in the frame.",
    "settings-on": "On",
    "settings-off": "Off",
    "car-in-frame": "Car in frame",
    "car-in-frame-text":
      "The detector will respond to the car appearance in the frame",
    "face-in-frame": "Face in frame",
    "sensor-settings-time": "Detector time",
    "24-hours": "24 hours a day",
    "sensor-settings-hint":
      "Configure time detector to avoid unnecessary notifications.",
    "notifications-title": "Types of notifications",
    "notifications-site": "Notifications on the website",
    "notifications-email": "E-mail notifications",
    "notifications-telegram": "Telegram notifications",
    "sensor-settings-sound": "Sound",
    "save-settings": "Save settings",
    "events-alert-watch": "Watch",
    "events-empty-title": "No events",
    "events-empty-text":
      "The events archive is available for cameras with connected analytics modules: face recognition and presence detector. If archive recording to the cloud is also connected, you can view short video clips of events",
    "events-empty-connect": "Tariff connection and detector setup",
    "events-grid-delete-selected": "Delete selected",
    "events-grid-mark-viewed": "Mark as viewed",
    "events-grid-mark-unread": "Mark as unviewed",
    selected: "Selected:",
    "select-all": "Select all",
    "select-date-range": "Select date range...",
    select: "Select",
    "events-grid-events": "events",
    "event-time": "Event time",
    "events-item-share": "Share",
    "events-item-link-access": "Link access",
    viewed: "Viewed",
    "preparing-to-connect-title": "Preparing to connect",
    "preparing-to-connect-text-app-1":
      "Install the program on a computer with Internet access",
    "preparing-to-connect-text-app-2":
      "Turn on the camera and connect it to the same network",
    "preparing-to-connect-text-app-3":
      "A computer with a running Neurobox program must remain on all the time!",
    "preparing-to-connect-device-text-1":
      "Turn on your Neurobox device and connect it to a router with the Internet access",
    "preparing-to-connect-device-text-2":
      "Turn on the camera and connect it to the same network",
    "preparing-to-connect-": "",
    "scanning-text": "This may take a few minutes",
    "refill-error": "Error creating payment, please try again later",
    "refill-company-name": "Company name",
    "refill-legal-address": "Legal address",
    "refill-itn": "ITN",
    "refill-iec": "IEC",
    "refill-phone-number": "Phone number",
    "refill-mailing-address": "Email address",
    "refill-invoice": "Invoice",
    "refill-invoice-billed": "billed",
    "refill-invoice-text":
      "The money will be automatically credited to your account within 1-2 business days after payment. The closing documents will be generated and also available for downloading in the “Transaction History” page",
    "refill-invoice-download": "Download invoice",
    "refill-title": "Account replenishment",
    "refill-current-balance": "Current balance:",
    "refill-amount": "Amount",
    "refill-method": "Replenishment method",
    "refill-credit-card-electronic-money": "Credit card / Electronic money",
    "refill-bank-transfer": "Bank transfer for legal entities",
    "refill-bank-transfer-soon":
      "Bank transfer for legal entities (coming soon)",
    "refill-bank-transfer-success": "The invoice request has been sent",
    "tariffs-event-recording": "Event recording",
    "tariffs-full-record": "Full recording",
    "tariffs-paid-to": "Paid to",
    "tariffs-auto-renewal": "Auto renewal",
    "tariffs-cancel-title": "Tariff deactivation",
    "tariffs-cancel-text":
      "Attention! Tariff will be disabled. Funds for the unused period will be returned to the account.",
    "tariffs-paid-to-title": "Tariff paid to:",
    "tariffs-cloud-recording-cameras": "Cloud recording cameras",
    "tariffs-cameras-without-recording": "Cameras without recording",
    "change-password": "Change password",
    "change-password-old": "Old password",
    "change-password-wrong": "Wrong password",
    "change-password-new": "New password",
    "change-password-new-repeat": "Repeat new password",
    "change-password-passwords-no-match": "Passwords do not match",
    "change-photo": "Change photo...",
    "box-added": "Added",
    "box-connected-cameras": "Connected cameras",
    "box-cameras-with-sensor": "Cameras with a detector",
    "box-total-bitrate": "Total bitrate",
    "box-cameras-list-show": "Show camera list",
    "box-cameras-list": "Camera list",
    "box-scan": "Scan",
    "box-remove-title": "Neurobox removal",
    "box-remove": "Remove neurobox",
    "box-neurobox": "Neurobox",
    "box-neurobox-title": "Neurobox name",
    "box-will-be-deleted": "will be deleted",
    "box-alert-title": "Attention!",
    "box-alert-text":
      "All cameras connected using this Neurobox will also be deleted. If an archive storage service in the cloud has been activated for one of the cameras, the archive will also be deleted and the amount of unused funds will be returned to your account.",
    "box-edit-title": "Edit title",
    "connect-camera-new-ip": "Connect new ip\xa0camera",
    "connect-camera-select-method": "Choose connection method",
    "connect-camera-via-neurobox-app": "Connect using Neurobox App",
    "connect-camera-via-neurobox-app-text":
      "The program is free. It must be downloaded and installed on the computer. The computer, running the program, must remain on constantly",
    "connect-camera-via-neurobox-device": "Connecting with Neurobox device",
    "connect-camera-via-neurobox-device-text":
      "Purchase Neurobox device in our online store and connect it to the router. Constantly working computer is not needed.",
    "connect-camera-via-neurobox-device-more": "More about Neurobox device",
    "connect-camera-without-neurobox": "Connecting camera without Neurobox",
    "connect-camera-without-neurobox-text":
      "When you connect camera to the service directly, you will need a dedicated ip-address and port setting in your router. Analytics modules will also be unavailable.",
    "connect-tariff": "Tariff connection",
    "connect-tariff-selected": "Selected fare",
    "connect-tariff-balance": "Balance after payment",
    "connect-tariff-to-pay": "To pay",
    "connect-tariff-not-enough-money": "Not enough money",
    "connect-tariff-not-enough-money-text-1":
      "To connect the selected tariff, the account does not have enough",
    "connect-tariff-not-enough-money-text-2":
      "You can top up the balance by the specified amount and connect the tariff",
    "connect-tariff-top-up-balance-on": "Top up balance by",
    today: "Today",
    yesterday: "Yesterday",
    "export-archive": "Export archive",
    "export-archive-text":
      "Highlight a video on the timeline no longer than 4 hours",
    "share-in-social-networks": "Share in social networks",
    "login-agree": "I accept",
    "login-terms": "terms of agreement",
    "login-and": "and",
    "login-policy": "privacy policy",
    "login-password-recovery": "Password recovery",
    "login-password-set": "Set password",
    "login-password-recovery-text":
      "Password reset link was sent to your e-mail",
    "login-entrance": "Enter",
    "login-registration": "Registration",
    "login-password": "Password",
    "login-password-forgot": "Forgot your password?",
    enter: "Enter",
    name: "Name",
    surname: "Surname",
    register: "Register",
    "enter-camera-name": "Enter camera name...",
    "connect-cloud-warning": "Camera must be connected via Neurobox",
    "connect-cloud-balance": "My balance",
    "connect-cloud-refill-balance": "Top up balance",
    "connect-cloud-title": "Connecting the archive recording to the cloud",
    "connect-cloud-select-type": "Choose record type",
    "connect-cloud-full-archiving": "Full archive",
    "connect-cloud-text":
      "You will have access to the full recording in arihive surveillance cameras",
    "connect-cloud-important-events": "Record only important events",
    "connect-cloud-detector-events":
      "Only events detected by video analytics modules will be available",
    "select-payment-period": "Select payment period",
    "payment-for-month": "Monthly payment",
    "monthly-payment": "Monthly payment with optional autopayment",
    "free-months": "month free",
    "payment-for-year": "Pay per year",
    "connect-cloud-yearly-payment":
      "Pay for the year and get 2 months for free",
    "connect-cloud-archive-retention-period": "Select archive retention period",
    "connect-cloud-tariffs-differ":
      "The only tariffs difference is in the recording storage period",
    "connect-cloud-opportunities": "opportunities",
    "connect-cloud-will-be-available":
      "Neurocam will be available at any rate.",
    neuroboxes: "Neuroboxes",
    "neuroboxes-buy": "Buy Neurobox device",
    "neuroboxes-download-app-for": "Download the Neurobox app for",
    "neuroboxes-or": "or",
    "neuroboxes-any-connected": "No connected Neurobox",
    "neuroboxes-any-connected-text":
      "There are currently no devices connected. You can buy a Neurobox device or download a program for MacOS or Windows.",
    "neuroboxes-go-to-shop": "Go to the store",
    "neuroboxes-free": "Free",
    "neuroboxes-download-app": "Download Neurobox",
    "payment-title": "Bills and payments",
    "payment-my-balance": "My balance",
    "payment-refill": "Refill",
    "payment-active-tariffs": "Current rates",
    "payment-history": "Transaction history",
    "profile-password-update-success": "Password successfully updated",
    "profile-password-update-error": "Error updating password",
    "data-update-success": "Data updated",
    "data-update-error": "Error updating data",
    "profile-language": "Language",
    "profile-title": "Profile and settings",
    "profile-settings": "Profile settings",
    "profile-telegram-notifications": "Telegram notifications",
    "profile-telegram-notifications-bot": "Bot",
    "profile-telegram-notifications-connect": "Connect",
    "profile-telegram-notifications-create": "Create token",
    "profile-telegram-notifications-token": "Token",
    service: "Service for cloud video surveillance",
    "types-of-events": "Types of events",
    "settings-saved-successfully": "Settings saved successfully",
    "login-password-recovery-password": "Password",
    "login-password-recovery-confirm": "Confirm",
    "login-password-recovery-password-mismatch": "Password mismatch",
    recognition: "Face recognition",
    "recognition-enabled-text":
      "Ultra-precise real-time face recognition and analysis technology",
    "recognition-disabled-text":
      "Ultra-precise real-time face recognition and analysis technology",
    "more-details": "More details",
    "recognition-yearly-payment": "Pay for a year and get 2 months for free",
    "recognition-max-persons": " Choose the number of faces per day",
    "recognition-info":
      "The rates differ in the maximum number of unique face detections per camera per 24 hours. If there are more faces per day than the tariff provides, the detection will not stop, but next month we will ask you to switch to a higher tariff.",
    "recognition-info-1": "Unlimited number of detections",
    "recognition-info-2": "Determination of gender and age",
    "recognition-info-3": "Creation of recognition lists",
    "recognition-info-4": "Instant notifications",
    "recognition-info-5": "Working with the dashboard",
    "recognition-info-6": "Search the archive by faces",
    "connect-recognition-title": "Face recognition connection",
    "recognition-settings-title": "Configuring face recognition",
    "recognition-settings-enabled": "Face recognition enabled",
    "recognition-settings-responce": "Notifications",
    "recognition-settings-time": "Notifications working hours",
    "recognition-settings-hint":
      "Set up a schedule to avoid unnecessary notifications",
    "persons-from-base": "Persons from the base",
    "persons-from-base-text": "Face recognition from the person database",
    strangers: "Unfamiliar persons",
    "strangers-text": "Recognition of unfamiliar faces",
    search: "Search...",
    "search-by-photo": "Search by photo",
    "all-persons": "All persons",
    "persons-search": "Person search...",
    "visitors-search": "Visitors search...",
    "persons-empty-title": "No persons",
    "persons-empty-text":
      "You can add an unlimited number of persons. Each person must be snapped to the watchlist, which in turn must be linked to the camera",
    "write-to-us": "write to us",
    "not-active": "Not active",
    "not-active-single": "Not active",
    "create-new-list": "Create new watch list",
    "persons-new": "New person",
    "persons-new-list": "New watch list",
    "persons-edit": "Edit person",
    "persons-description": "Description of the person",
    "persons-description-enter": "Enter the person's description...",
    "surname-and-name": "Surname and name",
    "surname-and-name-enter": "Enter the person's name and surname...",
    "name-enter": "Enter the name",
    "surname-enter": "Enter the surname",
    "persons-list": "Watch list",
    "persons-photos": "Person photos",
    description: "Description",
    information: "Information",
    "list-title": "List title",
    "list-title-enter": "Enter a list name...",
    "list-label-color": "List label color",
    "snap-cameras": "Snap cameras",
    "connect-face-recognition": "Connect face recognition",
    "list-active": "List active",
    "person-removing": "Person will be deleted",
    "person-removing-msg": "Are you sure you want to delete the person",
    "person-remove": "Delete person",
    "persons-list-removing": "Delete watch list",
    "persons-list-removing-msg":
      "The watch list will be deleted, the attached persons will remain in the tab “All persons”",
    "list-remove": "Delete watch list",
    "connected-cameras": "Connected cameras",
    "cameras-without-tariff": "Cameras without tariff",
    "сloud-recording": "Cloud recording",
    "select-persons-list": "Select a watch list...",
    "place-create-success": "Places successfully created",
    "place-create-error": "Places creation error",
    "place-delete-success": "Places successfully deleted",
    "place-delete-error": "Removing places error",
    "person-create-success": "Person successfully added",
    "person-create-success-partial":
      "Person was added successfully, not all photos have faces",
    "person-create-error-faces-not-found":
      "An error occurred while creating a person, no faces were found in the photo",
    "person-create-error-snapped-list":
      "The selected watch lists must be snapped to all cameras in this place",
    "person-create-error": "Adding person error",
    "person-delete-success": "Person successfully deleted",
    "person-delete-error": "Error removing person",
    "person-list-create-success": "Watch list created successfully",
    "person-list-create-error": "Watch list creation error",
    "person-list-delete-success": "Watch list deleted successfully",
    "person-list-delete-error": "Watch list deletion error",
    persons: "persons",
    "not-chosen": "- Not chosen -",
    "camera-should-be-connected-via-neurobox":
      "Camera should be connected via Neurobox",
    "unknown-person": "Unknown person",
    "unknown-persons": "Unknown persons",
    "event-type": "Event type",
    "all-types": "All types",
    "for-all-the-time": "For all the time",
    matches: "Matches",
    "all-selected": "All selected",
    "watch-lists": "Watch lists",
    "not-recognized": "Not recognized",
    "snap-to-person": "Snap to person",
    "create-new": "Create a new one",
    reports: "Reports",
    places: "Places",
    place: "Place",
    "all-places": "All places",
    "add-place": "Add place",
    "time-tracking": "Time tracking",
    "visitor-analysis": "Visitor analysis",
    statistics: "Statistics",
    "avg-time-start-work": "Start working time (avg.)",
    "avg-time-end-work": "End working time (avg.)",
    "avg-time-on-work": "Working time (avg.)",
    "time-start-work": "Start time",
    "time-end-work": "End time",
    "time-on-work": "Working time",
    "avg-start-work": "Working start (avg.)",
    "avg-end-work": "Working end (avg.)",
    "start-work": "Working start",
    "end-work": "Working end",
    employees: "employees",
    "employees-plural": "employees",
    status: "Status",
    person: "Person",
    "employee-statistics": "Employee statistics",
    "new-places": "New places",
    "name-places": "Name of the place",
    "name-places-enter": "Enter the name of the place",
    "add-group": "Add group",
    "places-cameras-info":
      "To add a place, you must select cameras that work at the entrance to the place. To make available analytical data on the duration of the visit and working time ending, add an exit camera",
    "entrance-camera": "Entrance camera",
    "exit-camera": "Exit camera (optional)",
    "monitoring-schedule": "Monitoring schedule",
    "reports-setting-work-time":
      "Set the place opening time for displaying in reports",
    "exclude-watchlists": "Exclude watch list from monitoring",
    "select-observation-groups":
      "Select watch lists to exclude from monitoring",
    monitoring: "Monitoring",
    "visitor-filter": "Visitor filter",
    gender: "Gender",
    "all-visitors": "All visitors",
    "only-men": "Only men",
    "only-woman": "Only women",
    age: "Age",
    all: "All",
    "less-18-years": "< 18 years",
    "between-18-24-years-old": "18-24 years old",
    "between-25-34-years-old": "25-34 years old",
    "between-35-44-years-old": "25-44 years old",
    "between-45-54-years-old": "35-54 years old",
    "more-55-years": "> 55 years",
    visits: "Visits",
    "only-unique": "Only unique",
    unique: "unique",
    "only-new": "Only new",
    "repeat-only": "Only repeated",
    "upload-report": "Upload report",
    "footfall-chart": "Footfall chart",
    "total-footfall": "Total footfall",
    "by-gender": "By gender",
    "by-age": "By age",
    "new-returning": "New / returning",
    emotions: "Emotions",
    visitors: "Visitors",
    "visitors-short": "V",
    "highest-value": "highest value",
    "lowest-value": "lowest value",
    max: "max.",
    min: "min.",
    sec: "sec.",
    h: "h.",
    m: "m.",
    s: "s.",
    "man-plural": "men",
    "woman-plural": "women",
    "new-visits": "new visits",
    "rate-of-return": "rate of return",
    "repeat-visits": "repeat visits",
    positive: "positive",
    neutral: "neutral",
    negative: "negative",
    "visit-duration": "Visit duration",
    common: "Total",
    total: "Total",
    avg: "avg.",
    "avg-visit": "average visit duration",
    "avg-visit-duration-men": "avg. male visit duration",
    "avg-visit-duration-women": "avg. female visit duration",
    "footfall-map": "Footfall map",
    "frequency-of-visits": "Total visits number",
    daily: "1 time per day or more often",
    "weekly-more-often": "4-6 times a week",
    "weekly-less-often": "2-3 times a week",
    monthly: "2-4 times a month",
    quarterly: "2-3 times a quarter",
    "every-six-months": "1-2 times in half a year",
    "annually-more-often": "1-2 times a year",
    "annually-less-often": "Once a year or less",
    "place-removing": "Place will be deleted",
    "place-removing-msg": "Are you sure you want to delete the place",
    "place-remove": "Delete place",
    "place-empty-title": "No monitoring places",
    "place-empty-text":
      "Add a place where your cameras for visitor analysis and time tracking are installed",
    "place-add": "Add place",
    "reports-no-data": "Monitoring data not available",
    "reports-no-data-text":
      "Add an exit camera in the place settings to see the time spent by the visitor in the place",
    "go-to-settings": "Go to settings",
    "monitoring-tariff-connected": "Monitoring tariff is connected",
    "choose-type-tariff": "Choose the type of tariff:",
    "recognition-only": "Recognition only",
    "recognition-only-text": "All face recognition features will be available",
    "recognition-reports": "Recognition + reports",
    "recognition-reports-text":
      "All face recognition features, visitor reports and employee time reports will be available",
    "data-loading": "Data is loading…",
    "no-monitoring-data": "No monitoring data",
    "no-monitoring-data-text": "No data for the selected time period",
    "tariff-not-connected": "Tariff not connected",
    "tariff-not-connected-text":
      "To view events video clips, connect archive recording to the cloud",
    "token-copied": "Token was copied",
    location: "Location",
    timezone: "Your timezone",
    "not-identified": "Not identified",
    "max-person-camera-title": "Number of persons per camera",
    exceeded: "exceeded",
    "max-person-by-tariff": "Maximum number of persons per tariff",
    "gender-male-letter": "M",
    "gender-female-letter": "F",
    "person-lists": "Watch lists",
    "person-lists-text":
      "Select watch lists for time tracking (for example, your employees)",
    company: "Company",
    "tariff-up-to": "Up to",
    "tariff-faces": "faces",
    "tariff-unlimited": "Unlimited",
    "person-base": "Person base",
    "online-only": "Online only",
    "first-visit": "First visit",
    "last-visit": "Last visit",
    "avg-time": "Average time",
    list: "List",
    male: "Male",
    female: "Female",
    "number-of-visits": "Number of visits",
    "places-cameras": "Places / cameras",
    "date-of-visit": "Date of visit",
    demography: "Demography",
    "no-results-found": "No results were found",
    "no-connection-neurobox": "No connection to Neurobox",
  },
};

const ru = !isEurocam() ? require("./i18n.ru.js") : {};

export const languages = [
  {
    title: "English",
    lng: "en",
    recource: en,
    available: true,
  },
  {
    title: "Русский",
    lng: "ru",
    recource: ru,
    available: !isEurocam(),
  },
];

i18n.use(LanguageDetector).init({
  resources: isEurocam() ? { en } : { en, ru },
  supportedLngs: isEurocam() ? ["en"] : ["en", "ru"],

  detection: {
    order: isEurocam() ? ["localStorage"] : ["localStorage", "navigator"],
  },

  debug: true,
  fallbackLng: "en",

  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
