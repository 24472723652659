import React from "react";
import cn from "classnames";
import Icon from "./Icon";

const Alert = props => {
  return (
    <div className={cn("alert", props.className)}>
      <div className="alert__icon">
        <Icon className="alert__pic" name="alert" />
      </div>
      <div className="alert__wrap">
        <div className="alert__title">{props.title}</div>
        <div className="alert__text">{props.content}</div>
        {props.children}
      </div>
    </div>
  );
};

export default Alert;
