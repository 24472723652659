import HttpClient from "../http";
import { BASE_WS_URL } from "../http";

const SCAN_TIMEOUT = 5 * 60;

export const SCAN_STATE_NEUROBOX = 1;
export const SCAN_STATE_SCANNING = 2;
export const SCAN_STATE_COMPLETE = 3;

function APIError(message, errors, cause) {
  this.name = "APIError";
  this.message = message;
  this.errors = errors;
  this.stack = cause && cause.stack;
}

const initialState = {
  neurobox: {
    item: {},
    state: SCAN_STATE_NEUROBOX,
    cameras: [],
    selected: {},
    error: null,
    timer: SCAN_TIMEOUT
  },
  directly: {
    url: "",
    camera: {},
    state: SCAN_STATE_NEUROBOX,
    error: null
  }
};

let scanSocket = null;
let scanInterval = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SCAN_NEUROBOX_FETCH:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          item: action.payload
        }
      };
    case SCAN_NEUROBOX_START:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          state: SCAN_STATE_SCANNING,
          cameras: initialState.neurobox.cameras,
          error: null,
          timer: initialState.neurobox.timer
        }
      };
    case SCAN_NEUROBOX_TICK:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          timer: state.neurobox.timer - 1
        }
      };
    case SCAN_NEUROBOX_ERROR:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          state: SCAN_STATE_NEUROBOX,
          error: action.payload
        }
      };
    case SCAN_NEUROBOX_STOP:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          state: state.neurobox.error
            ? SCAN_STATE_NEUROBOX
            : SCAN_STATE_COMPLETE
        }
      };
    case SCAN_NEUROBOX_ADD_CAMERA:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          cameras: [...state.neurobox.cameras, action.payload],
          selected: { ...state.neurobox.selected, [action.payload.id]: false }
        }
      };
    case SCAN_NEUROBOX_SELECT_CAMERA:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          selected: {
            ...state.neurobox.selected,
            [action.payload.id]: action.payload.selected
          }
        }
      };
    case SCAN_NEUROBOX_CLEAR:
      return {
        ...state,
        neurobox: initialState.neurobox
      };
    case SCAN_NEUROBOX_SINGLE:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          cameras: state.neurobox.cameras.map(
            c => (c.id === action.payload.Camera.id ? action.payload.Camera : c)
          )
        }
      };
    case SCAN_NEUROBOX_REMOVE_CAMERA:
      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          cameras: state.neurobox.cameras.filter(c => c.id !== action.payload),
          selected: Object.keys(state.neurobox.selected).reduce(
            (ns, currKey) =>
              parseInt(currKey, 10) === action.payload
                ? ns
                : { ...ns, [currKey]: state.neurobox.selected[currKey] },
            {}
          )
        }
      };
    case SCAN_NEUROBOX_SET_CAMERA:
      const idx = state.neurobox.cameras.findIndex(
        c => c.id === action.payload.id
      );

      const newCameras = [...state.neurobox.cameras];
      newCameras[idx] = action.payload;

      return {
        ...state,
        neurobox: {
          ...state.neurobox,
          cameras: newCameras
        }
      };
    case SCAN_DIRECTLY_SET_TITLE:
      return {
        ...state,
        directly: {
          ...state.directly,
          camera: { ...state.directly.camera, title: action.payload }
        }
      };
    case SCAN_DIRECTLY_SET_URL:
      return {
        ...state,
        directly: {
          ...state.directly,
          url: action.payload
        }
      };
    case SCAN_DIRECTLY_CAMERA:
      return {
        ...state,
        directly: {
          ...state.directly,
          state: SCAN_STATE_SCANNING,
          error: null
        }
      };
    case SCAN_DIRECTLY_CAMERA_COMPLETE:
      return {
        ...state,
        directly: {
          ...state.directly,
          state: SCAN_STATE_COMPLETE,
          camera: action.payload
        }
      };
    case SCAN_DIRECTLY_CAMERA_ERROR:
      return {
        ...state,
        directly: {
          ...state.directly,
          state: SCAN_STATE_COMPLETE,
          error: action.payload
        }
      };
    case SCAN_DIRECTLY_CLEAR:
      return {
        ...state,
        directly: initialState.directly
      };
    default:
      return state;
  }
};

export const NeuroboxActivate = token => async dispatch => {
  const response = await HttpClient.post("/video/neurobox", { token });

  if (!response.data.Ok) {
    throw new APIError("response not ok", response.data.Errors);
  }

  return dispatch(NeuroboxFetch(response.data.Neurobox.id));
};

const SCAN_NEUROBOX_FETCH = "SCAN_NEUROBOX_FETCH";
export const NeuroboxFetch = id => async dispatch => {
  const response = await HttpClient.get(`/video/neurobox/${id}`);
  return dispatch({ type: SCAN_NEUROBOX_FETCH, payload: response.data });
};

const SCAN_NEUROBOX_START = "SCAN_NEUROBOX_START";
const SCAN_NEUROBOX_TICK = "SCAN_NEUROBOX_TICK";
export const NeuroboxScanStart = () => async (dispatch, getState) => {
  if (scanSocket) {
    throw new Error("scan process already started");
  }

  const { token } = getState().users;
  const { id } = getState().scan.neurobox.item;

  scanSocket = new WebSocket(
    `${BASE_WS_URL}/video/ws/neurobox/${id}/scan/?token=${token}`
  );
  scanSocket.onerror = e => {
    dispatch(NeurboxScanError(e.reason));
  };
  scanSocket.onmessage = e => {
    const data = JSON.parse(e.data);
    if (data.camera) {
      dispatch(NeuroboxScanAddCamera(data.camera));
    }
  };

  scanInterval = setInterval(() => {
    dispatch({ type: SCAN_NEUROBOX_TICK });
    if (getState().scan.neurobox.timer === 0) {
      dispatch(NeuroboxScanStop());
    }
  }, 1000);

  return dispatch({ type: SCAN_NEUROBOX_START });
};

const SCAN_NEUROBOX_STOP = "SCAN_NEUROBOX_STOP";
export const NeuroboxScanStop = () => (dispatch, getState) => {
  clearInterval(scanInterval);

  if (scanSocket !== null) {
    scanSocket.close();
    scanSocket = null;
  }

  return dispatch({ type: SCAN_NEUROBOX_STOP });
};

const SCAN_NEUROBOX_ERROR = "SCAN_NEUROBOX_ERROR";
export const NeurboxScanError = err => async (dispatch, getState) => {
  await dispatch({ type: SCAN_NEUROBOX_ERROR, payload: err });
  return dispatch(NeuroboxScanStop());
};

const SCAN_NEUROBOX_ADD_CAMERA = "SCAN_NEUROBOX_ADD_CAMERA";
export const NeuroboxScanAddCamera = camera => async dispatch => {
  return dispatch({ type: SCAN_NEUROBOX_ADD_CAMERA, payload: camera });
};

const SCAN_NEUROBOX_SELECT_CAMERA = "SCAN_NEUROBOX_SELECT_CAMERA";
export const NeuroboxScanSelectCamera = (cameraId, selected) => (
  dispatch,
  getState
) => {
  const { neurobox } = getState().scan;

  if (neurobox.cameras.some(c => c.id === cameraId)) {
    dispatch({
      type: SCAN_NEUROBOX_SELECT_CAMERA,
      payload: { id: cameraId, selected }
    });
  }
};

const SCAN_NEUROBOX_CLEAR = "SCAN_NEUROBOX_CLEAR";
export const NeuroboxScanClear = () => dispatch => {
  clearInterval(scanInterval);

  if (scanSocket !== null) {
    scanSocket.close();
    scanSocket = null;
  }

  return dispatch({ type: SCAN_NEUROBOX_CLEAR });
};

const SCAN_NEUROBOX_SINGLE = "SCAN_NEUROBOX_SINGLE";
export const NeuroboxScanSingle = (
  cameraId,
  username,
  password,
  streamURL
) => async (dispatch, getState) => {
  const { id } = getState().scan.neurobox.item;

  const response = await HttpClient.post(
    `/video/neurobox/${id}/scan_single/${cameraId}`,
    {
      username,
      password,
      stream_url: streamURL
    }
  );

  if (!response.data.Ok) {
    throw new Error(JSON.stringify(response.data.Errors));
  }

  return dispatch({ type: SCAN_NEUROBOX_SINGLE, payload: response.data });
};

const SCAN_NEUROBOX_REMOVE_CAMERA = "SCAN_NEUROBOX_REMOVE_CAMERA";
export const NeuroboxScanRemoveCamera = cameraId => dispatch => {
  return dispatch({ type: SCAN_NEUROBOX_REMOVE_CAMERA, payload: cameraId });
};

const SCAN_NEUROBOX_SET_CAMERA = "SCAN_NEUROBOX_SET_CAMERA";
export const NeuroboxScanSetCamera = camera => dispatch =>
  dispatch({ type: SCAN_NEUROBOX_SET_CAMERA, payload: camera });

const SCAN_DIRECTLY_SET_TITLE = "SCAN_DIRECTLY_SET_TITLE";
export const ScanDirectlySetTitle = title => dispatch => {
  return dispatch({ type: SCAN_DIRECTLY_SET_TITLE, payload: title });
};

const SCAN_DIRECTLY_SET_URL = "SCAN_DIRECTLY_SET_URL";
export const ScanDirectlySetURL = url => dispatch => {
  return dispatch({ type: SCAN_DIRECTLY_SET_URL, payload: url });
};

const SCAN_DIRECTLY_CAMERA = "SCAN_DIRECTLY_CAMERA";
const SCAN_DIRECTLY_CAMERA_COMPLETE = "SCAN_DIRECTLY_CAMERA_COMPLETE";
const SCAN_DIRECTLY_CAMERA_ERROR = "SCAN_DIRECTLY_CAMERA_ERROR";
export const ScanDirectlyCamera = checkUrl => async (dispatch, getState) => {
  await dispatch({ type: SCAN_DIRECTLY_CAMERA });

  const { directly } = getState().scan;
  const url = checkUrl || directly.url.trim();

  try {
    const response = await HttpClient.post(`/video/cameras/detect/`, {
      URL: url
    });

    if (response.data.Error) {
      return dispatch({
        type: SCAN_DIRECTLY_CAMERA_ERROR,
        payload: new Error(response.data.Error)
      });
    }

    if (response.data.DiscoverResult && response.data.DiscoverResult.NeedAuth) {
      return dispatch({
        type: SCAN_DIRECTLY_CAMERA_ERROR,
        payload: new Error("need auth")
      });
    }

    return dispatch({
      type: SCAN_DIRECTLY_CAMERA_COMPLETE,
      payload: response.data.Camera
    });
  } catch (e) {
    return dispatch({ type: SCAN_DIRECTLY_CAMERA_ERROR, payload: e });
  }
};

const SCAN_DIRECTLY_CLEAR = "SCAN_DIRECTLY_CLEAR";
export const ScanDirectlyClear = () => dispatch => {
  return dispatch({ type: SCAN_DIRECTLY_CLEAR });
};
