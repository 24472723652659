import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
import Icon from "../Icon";
import EventsList from "../Events/EventsList";
import CameraOptions from "./CameraOptions";
import CamerasSettings from "./CamerasSettings";
import Counter from "../Counter";

import {
  CameraInfoFull,
  CameraPatch,
  CameraRemove,
  CameraClear,
  CameraAddShare,
  CameraRemoveShare,
  CameraAddEvent,
} from "../../reducers/camera";
import {
  EventsFetch,
  EventsSetFilter,
  EventsClear,
} from "../../reducers/events";
import CameraPlayer from "./CameraPlayer";
import { GetCameraMarkers } from "../../reducers/archive";
import { CameraRatesCancel } from "../../reducers/payments";
import { videoArchiveUrl, videoExportUrl, videoStreamUrl } from "../../links";
import { checkPerms, checkCompanyOwner } from "../../utils";

// const isLiveUrl = u => u.includes("/streamer/");

class CamerasItem extends React.Component {
  state = {
    visibleSidebar: false,
    activeTab: null,
    url: "",
    redirect: false,
    showRecognitionSettings: false,
  };

  componentDidMount() {
    const cameraId = this.props.id;

    this.props
      .CameraInfoFull(this.props.id)
      .then(() => this.setState({ url: videoStreamUrl(cameraId) }))
      .catch((err) => {
        if (err.response.status === 404) {
          this.setState({ redirect: true });
        } else {
          console.log(err);
        }
      });

    this.props.EventsSetFilter({ cameraId });

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("showRecognition")) {
      this.setState({
        showRecognitionSettings: true,
        activeTab: 0,
        visibleSidebar: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.setState({ url: "" });
      this.props.CameraInfoFull(this.props.id).then(() => {
        this.onPlayOnline();
      });
      this.props.EventsSetFilter({ cameraId: this.props.id });
    }
  }

  componentWillUnmount() {
    this.props.CameraClear();
    this.props.EventsClear();
  }

  onPlayOnline = () =>
    this.setState({ url: videoStreamUrl(this.props.cameraId) });

  onSeekTimeline = (ts1, ts2) =>
    this.setState({
      url: videoArchiveUrl(this.props.cameraId, ts1, ts2),
    });

  onExport = (ts1, ts2) => {
    const url = videoExportUrl(this.props.cameraId, ts1, ts2);
    window.open(`${url}&token=${this.props.users.token}`);
  };

  onCancel = (cameraRate) => {
    const { t } = this.props;

    this.props
      .CameraRatesCancel(cameraRate.ID)
      .then(() => {
        this.props.CameraInfoFull(this.props.id);
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("error-occurred"));
      });
  };

  changeTab = (index, title) => {
    if (index !== this.state.activeTab) {
      this.setState({
        visibleSidebar: true,
        activeTab: index,
        activeTitle: title,
      });
    } else {
      this.closeTabs();
    }
  };

  closeTabs = () => {
    this.setState({
      visibleSidebar: false,
      activeTab: null,
    });
  };

  render() {
    const { visibleSidebar, activeTab, activeTitle } = this.state;
    const { users, camera, events, EventsFetch } = this.props;

    const isCompanyOwner = checkCompanyOwner(users.user);
    const isPermsUpdate = checkPerms(users.user, "camera:update");
    const isPermsDelete = checkPerms(users.user, "camera:delete");

    const tabs = [
      {
        index: 0,
        title: "cameras-item-tabs-options",
        icon: "options",
        visible: true,
      },
      {
        index: 1,
        title: "cameras-item-tabs-events",
        icon: "notifications",
        visible:
          camera.camera.Camera.face_recognition_enabled ||
          camera.camera.Camera.detector_enabled,
      },
      {
        index: 2,
        title: "cameras-item-tabs-settings",
        icon: "settings",
        visible: true,
      },
    ];

    if (this.state.redirect) {
      return <Redirect to="/cameras" />;
    }

    const { Camera, neurobox } = camera.camera;
    const { t } = this.props;

    return (
      <div className="layout__container layout__container_camera">
        <div className="layout__head">
          <Link className="layout__close action" to="/cameras">
            <Icon name="close" />
          </Link>
          <div className="layout__title">
            {camera.fetched ? Camera.title : ""}
          </div>
          <div className="layout__actions">
            {tabs
              .filter((tab) => tab.visible)
              .map((tab) => (
                <button
                  className={
                    "layout__action action " +
                    (activeTab === tab.index ? "active" : "")
                  }
                  onClick={() => this.changeTab(tab.index, tab.title)}
                  key={tab.index}
                >
                  <Icon name={tab.icon} />
                  {tab.index === 1 && this.props.camera.eventsCount && (
                    <Counter
                      className="action__counter"
                      value={this.props.camera.eventsCount}
                    />
                  )}
                </button>
              ))}
          </div>
        </div>
        <div className="layout__scroll">
          <div className={"inner " + (visibleSidebar ? "sidebar-visible" : "")}>
            <div className="inner__container">
              {Camera && (
                <CameraPlayer
                  key={Camera.id}
                  className={
                    (visibleSidebar ? "video_wide " : "") +
                    (Camera.archiving ? "" : "video_simple")
                  }
                  url={this.state.url}
                  onLoadMarkers={this.props.GetCameraMarkers}
                  onPlayOnline={this.onPlayOnline}
                  onSeekTimeline={this.onSeekTimeline}
                  ranges={this.props.markers}
                  camera={Camera}
                />
              )}
            </div>
            <div className="inner__sidebar inner__sidebar_head">
              <div className="inner__head">
                <button
                  className="inner__action action"
                  onClick={this.closeTabs}
                >
                  <Icon name="next-circle" />
                </button>
                <div className="inner__title">{t(activeTitle)}</div>
              </div>
              <div className="inner__body">
                {activeTab === 0 && Camera && (
                  <CameraOptions
                    isCompanyOwner={isCompanyOwner}
                    isPermsUpdate={isPermsUpdate}
                    camera={Camera}
                    onSave={this.props.CameraPatch}
                    onCancel={this.onCancel}
                    showRecognitionSettings={this.state.showRecognitionSettings}
                  />
                )}
                {activeTab === 1 && (
                  <InfiniteScroll
                    initialLoad={false}
                    loadMore={EventsFetch}
                    hasMore={events.hasMore}
                    useWindow={false}
                  >
                    {events.days.map((day) => (
                      <EventsList
                        key={day}
                        day={day}
                        items={events.itemsByDays[day]}
                        columnMode={true}
                      />
                    ))}
                  </InfiniteScroll>
                )}
                {activeTab === 2 && (
                  <CamerasSettings
                    camera={Camera}
                    neurobox={neurobox}
                    isShared={this.props.camera.camera.is_shared}
                    isPermsUpdate={isPermsUpdate}
                    isPermsDelete={isPermsDelete}
                    user={this.props.users.user}
                    token={this.props.users.token}
                    onSave={this.props.CameraPatch}
                    onRemove={this.props.CameraRemove}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  markers: state.archive.markers,
  camera: state.camera,
  users: state.users,
  cameraId: state.camera.camera.Camera.id,
  events: state.events,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, {
      CameraInfoFull,
      CameraClear,
      CameraPatch,
      CameraRemove,
      CameraAddShare,
      CameraRemoveShare,
      CameraAddEvent,
      GetCameraMarkers,
      CameraRatesCancel,
      EventsFetch,
      EventsSetFilter,
      EventsClear,
    })
  )(CamerasItem)
);
