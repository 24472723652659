import HttpClient from "../http";
import { countPeriod } from "../components/Reports/utils";
import { tzOffsetPeriod } from "./users";

const initialState = {
  places: [],
  placesFetched: false,
  filters: {
    place: null,
    placeTimeTracking: null,
    age: null,
    gender: null,
    unique: 0,
    period: {
      since: new Date(new Date().setHours(0, 0, 0, 0)),
      until: new Date(new Date().setHours(23, 59, 0, 0)),
    },
    groups: [],
  },
  footfall: [],
  footfallFetched: false,
  visitDuration: [],
  visitDurationFetched: false,
  visitFrequency: [],
  visitFrequencyFetched: false,
  visitMap: [],
  visitMapFetched: false,
  timeTracking: [],
  timeTrackingFetched: false,
};

const updateUrl = (filters, url) => {
  const { place, age, gender, unique } = filters;
  if (place) {
    url = url + `&place=${place}`;
  }

  if (age) {
    if (age === "age0") {
      url = url + `&age_from=0&age_to=17`;
    } else if (age === "age18") {
      url = url + `&age_from=18&age_to=24`;
    } else if (age === "age25") {
      url = url + `&age_from=25&age_to=34`;
    } else if (age === "age35") {
      url = url + `&age_from=35&age_to=44`;
    } else if (age === "age45") {
      url = url + `&age_from=45&age_to=54`;
    } else if (age === "age55") {
      url = url + `&age_from=55&age_to=110`;
    }
  }

  if (gender) {
    url = url + `&gender=${gender}`;
  }

  if (unique) {
    url = url + `&unique=${unique}`;
  }

  return url;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLACES_FETCH:
      return { ...state, places: action.places, placesFetched: true };
    case FOOTFALL_FETCH:
      return { ...state, footfall: action.footfall, footfallFetched: true };
    case FOOTFALL_FETCH_START:
      return { ...state, footfallFetched: false };
    case VISIT_DURATION_FETCH:
      return {
        ...state,
        visitDuration: action.visitDuration,
        visitDurationFetched: true,
      };
    case VISIT_DURATION_FETCH_START:
      return { ...state, visitDurationFetched: false };
    case VISIT_FREQUENCY_FETCH:
      return {
        ...state,
        visitFrequency: action.visitFrequency,
        visitFrequencyFetched: true,
      };
    case VISIT_FREQUENCY_FETCH_START:
      return { ...state, visitFrequencyFetched: false };
    case VISIT_MAP_FETCH:
      return { ...state, visitMap: action.visitMap, visitMapFetched: true };
    case VISIT_MAP_FETCH_START:
      return { ...state, visitMapFetched: false };
    case TIMETRACKING_FETCH:
      return {
        ...state,
        timeTracking: action.timeTracking,
        timeTrackingFetched: true,
      };
    case REPORTS_SET_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
};

// places
const PLACES_FETCH = "PLACES_FETCH";
export const PlacesFetch = () => async (dispatch) => {
  const response = await HttpClient.get("/places");

  const places = response.data;

  return dispatch({ type: PLACES_FETCH, places: places });
};

export const CreatePlace = (data) => async (dispatch) => {
  await HttpClient.post("/places", data);
  return dispatch(PlacesFetch());
};

export const SavePlace = (data) => async (dispatch) => {
  if (!data || !data.id || data <= 0) {
    throw Error("empty id");
  }
  await HttpClient.put(`/places/${data.id}`, data);
  return dispatch(PlacesFetch());
};

export const DeletePlace = (placeId) => async (dispatch) => {
  if (placeId <= 0) {
    throw Error("empty id");
  }

  await HttpClient.delete(`/places/${placeId}`);

  return dispatch(PlacesFetch());
};

// footfall
const FOOTFALL_FETCH = "FOOTFALL_FETCH";
const FOOTFALL_FETCH_START = "FOOTFALL_FETCH_START";
export const FootfallFetch = () => async (dispatch, getState) => {
  dispatch({ type: FOOTFALL_FETCH_START });

  const { reports } = getState();
  const { since, until } = reports.filters.period;

  const [sinceTz, untilTz] = tzOffsetPeriod(getState(), since, until);

  let url = `/reports/footfall?since=${sinceTz}&until=${untilTz}&period=${countPeriod(
    since,
    until
  )}`;

  const response = await HttpClient.get(updateUrl(reports.filters, url));

  const footfall = response.data;

  return dispatch({ type: FOOTFALL_FETCH, footfall: footfall });
};

// visit duration
const VISIT_DURATION_FETCH = "VISIT_DURATION_FETCH";
const VISIT_DURATION_FETCH_START = "VISIT_DURATION_FETCH_START";
export const VisitDurationFetch = () => async (dispatch, getState) => {
  dispatch({ type: VISIT_DURATION_FETCH_START });

  const { reports } = getState();
  const { since, until } = reports.filters.period;

  const [sinceTz, untilTz] = tzOffsetPeriod(getState(), since, until);
  let url = `/reports/visitduration?since=${sinceTz}&until=${untilTz}&period=${countPeriod(
    since,
    until
  )}`;

  try {
    const response = await HttpClient.get(updateUrl(reports.filters, url));

    const visitDuration = response.data;

    return dispatch({
      type: VISIT_DURATION_FETCH,
      visitDuration: visitDuration,
    });
  } catch (e) {
    return dispatch({
      type: VISIT_DURATION_FETCH,
      visitDuration: null,
    });
  }
};

// visit frequency
const VISIT_FREQUENCY_FETCH = "VISIT_FREQUENCY_FETCH";
const VISIT_FREQUENCY_FETCH_START = "VISIT_FREQUENCY_FETCH_START";
export const VisitFrequencyFetch = () => async (dispatch, getState) => {
  dispatch({ type: VISIT_FREQUENCY_FETCH_START });

  const { reports } = getState();
  const { since, until } = reports.filters.period;

  const [sinceTz, untilTz] = tzOffsetPeriod(getState(), since, until);
  let url = `/reports/visitfrequency?since=${sinceTz}&until=${untilTz}&period=${countPeriod(
    since,
    until
  )}`;

  const response = await HttpClient.get(updateUrl(reports.filters, url));

  const visitFrequency = response.data;

  return dispatch({
    type: VISIT_FREQUENCY_FETCH,
    visitFrequency: visitFrequency,
  });
};

// visit map
const VISIT_MAP_FETCH = "VISIT_MAP_FETCH";
const VISIT_MAP_FETCH_START = "VISIT_MAP_FETCH_START";
export const VisitMapFetch = () => async (dispatch, getState) => {
  dispatch({ type: VISIT_MAP_FETCH_START });

  const { reports } = getState();
  const { since, until } = reports.filters.period;

  const [sinceTz, untilTz] = tzOffsetPeriod(getState(), since, until);
  let url = `/reports/visitmap?since=${sinceTz}&until=${untilTz}&period=${countPeriod(
    since,
    until
  )}`;

  const response = await HttpClient.get(updateUrl(reports.filters, url));

  const visitMap = response.data;

  return dispatch({ type: VISIT_MAP_FETCH, visitMap: visitMap });
};

// trackings
const TIMETRACKING_FETCH = "TIMETRACKING_FETCH";
export const TimeTrackingFetch = () => async (dispatch, getState) => {
  const { reports } = getState();
  const { placeTimeTracking } = reports.filters;
  const { since, until } = reports.filters.period;

  const [sinceTz, untilTz] = tzOffsetPeriod(getState(), since, until);

  let url = `/archive/reports/timetracking?since=${sinceTz}&until=${untilTz}`;
  if (placeTimeTracking) {
    url = url + `&place=${placeTimeTracking}`;
  }

  let response;
  try {
    response = await HttpClient.get(url);
  } catch (e) {
    response = false;
  } finally {
    // console.log("success");
  }

  const timeTracking = response ? response.data : false;

  return dispatch({ type: TIMETRACKING_FETCH, timeTracking: timeTracking });
};

// filters
const REPORTS_SET_FILTER = "REPORTS_SET_FILTER";
export const ReportsSetFilter = (filter, type) => async (dispatch) => {
  await dispatch({ type: REPORTS_SET_FILTER, payload: filter });
  if (type === 1) {
    dispatch(FootfallFetch());
    dispatch(VisitDurationFetch());
    dispatch(VisitFrequencyFetch());
    return dispatch(VisitMapFetch());
  } else {
    return dispatch(TimeTrackingFetch());
  }
};
