import React from "react";

const padding = 10;

const height = {
  minor: 24,
  major: 32,
  total: 50,
};

export default ({ left, time, type, major }) => {
  let h = major ? height.major : height.minor;
  let y1 = height.total - h + padding;
  let y2 = height.total + padding;

  return (
    <line
      className={`marker marker-${type} marker-${major ? "major" : "minor"}`}
      x1={left}
      x2={left}
      y2={y2}
      y1={y1}
    />
  );
};
