import React from "react";
import Slider from "react-slider";
import Duration from "../../Duration";

export const Progress = ({played, duration, onSeekStart, onSeekEnd, onSeek}) => {
  return (
    <div className="video__seek">
      <div className="slider">
        <Slider
          min={0}
          max={duration}
          step={.1}
          onBeforeChange={onSeekStart}
          onChange={onSeek}
          onAfterChange={onSeekEnd}
          value={played}
          className="slider-rail"
          trackClassName="slider-track"
          thumbClassName="slider-handle"
          defaultValue={10}
        />
      </div>
      <div className="video__duration">
        <Duration seconds={played}/>
        <span> / </span>
        <Duration seconds={duration}/>
      </div>
    </div>
  )
}

