import screenfull from "screenfull";
import {useCallback, useEffect, useState} from "react";

export const useFullscreen = (ref) => {

    const [enabled, setEnabled] = useState(false);

    const toggle = useCallback(() => {
        if (screenfull.isEnabled && ref.current != null) {
            screenfull.toggle(ref.current);
        }
    }, [ref]);

    useEffect(() => {

        const onChange = () => {
            setEnabled(!enabled)
        };

        if (screenfull && typeof screenfull.on === 'function')
            screenfull.on("change", onChange);

        return () => {
            if (screenfull && typeof screenfull.off === 'function')
                screenfull.off("change", onChange);
        }
    });

    return [enabled, toggle];
};
