import React from 'react';

export default function ({left}) {
    return (
        <g transform={`translate(${left - 10}, 0)`}>
            <linearGradient id='circle-grad' x1={0} y1={0} x2={0} y2={1}>
                <stop stopColor="#FFFFFF" offset="0%"/>
                <stop stopColor="#C3C7C9" offset="100%"/>
            </linearGradient>
            <circle fill="#FFFFFF" cx="10" cy="13" r="10" />
            <circle fill="url(#circle-grad)" cx="10" cy="13" r="10" />
            <circle fill="#F30027" cx="10" cy={13} r={4}/>
        </g>
    )
}