import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import styles from "./Nav.module.sass";
import { checkPerms } from "../../../../utils";

const Nav = ({ t, personsTotal, users, groups }) => {
  const isPermsGroupCreate = checkPerms(users.user, "person_group:create");

  const RenderLink = ({ to, color, title, details, items }) => {
    return (
      <NavLink
        className={styles.link}
        activeClassName={styles.active}
        to={to}
        exact
      >
        <div className={styles.wrap}>
          {color && (
            <div className={styles.color} style={{ backgroundColor: color }} />
          )}
          <div className={styles.title}>{title}</div>
          <div className={styles.details}>
            {details &&
              details.length > 0 &&
              details.map(
                (x, index) =>
                  x && (
                    <div className={styles.detail} key={index}>
                      {x}
                    </div>
                  )
              )}
          </div>
        </div>
        <div className={styles.items}>{items}</div>
      </NavLink>
    );
  };

  return (
    <>
      <RenderLink to={"/faces"} title={t("all-persons")} items={personsTotal} />
      {groups.map((x, index) => (
        <RenderLink
          to={`/faces/${x.id}`}
          color={x.color}
          title={x.title}
          details={[
            !x.active && t("not-active-single"),
            x.cameras.length > 0 &&
              `${t("connected-cameras")}: ${x.cameras.length}`,
          ]}
          items={x.persons.length}
          key={index}
        />
      ))}
      {isPermsGroupCreate && (
        <NavLink className={styles.action} to="/faces/add-list">
          {t("create-new-list")}
        </NavLink>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  groups: state.faces.groups,
  personsTotal: state.faces.persons.length,
});

export default translate()(withRouter(connect(mapStateToProps)(Nav)));
