import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import Form from "./Form";

import HttpClient from "../../../http";

const PersonForm = () => {
  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (params.faceId) {
      const url = `/guids-api/${params.faceId}`;

      HttpClient.get(url)
        .then(function (response) {
          setPerson(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          setPerson(null);
        });
    } else {
      setLoading(false);
    }
  }, [params.faceId]);

  return loading ? <Loader /> : <Form person={person} />;
};

export default PersonForm;
