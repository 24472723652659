import React from "react";
import cn from "classnames";

class Loader extends React.Component {
  render() {
    const { className, width, color } = this.props;
    return (
      <span className={cn("loader", className)} style={{ width: width + "px" }}>
        <svg
          className="loader__pic"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            fill={color}
            d="M11.6789641,1.66849616 C11.6789641,3.8931577 8.3412389,3.8931577 8.3412389,1.66849616 C8.3412389,-0.556165386 11.6789641,-0.556165386 11.6789641,1.66849616 Z M10.8445328,18.3534577 C10.8445328,19.4657885 9.17567014,19.4657885 9.17567014,18.3534577 C9.17567014,17.241127 10.8445328,17.241127 10.8445328,18.3534577 Z M7.1593133,2.01591414 C8.17917374,3.78303696 5.52939082,5.31249177 4.50953035,3.54573973 C3.48966986,1.78010001 6.13945282,0.250645206 7.1593133,2.01591414 Z M14.7863862,16.8922258 C15.2499592,17.6960702 14.046153,18.391277 13.58258,17.5874326 C13.1190071,16.7850714 14.3228133,16.0898647 14.7863862,16.8922258 Z M3.47668982,4.64064399 C5.08176479,5.56758629 3.69104595,7.97615319 2.08597099,7.04921088 C0.479412585,6.12226857 1.87013143,3.71370167 3.47668982,4.64064399 Z M17.5170163,13.6968703 C18.1589721,14.0676473 17.6026846,15.0309258 16.9607288,14.6601488 C16.3176604,14.2893718 16.8739479,13.3260935 17.5170163,13.6968703 Z M2.91743542,10.010977 C2.91743542,11.6794731 0.414141521,11.6794731 0.414141521,10.010977 C0.414141521,8.34248083 2.91743542,8.34248083 2.91743542,10.010977 Z M18.7716301,10.010977 C18.7716301,10.5671423 17.9371989,10.5671423 17.9371989,10.010977 C17.9371989,9.45481152 18.7716301,9.45481152 18.7716301,10.010977 Z M3.74185355,13.6290182 C4.48357025,14.9122771 2.55659024,16.0246079 1.81487352,14.7413489 C1.07315682,13.4566069 3.00013683,12.3442761 3.74185355,13.6290182 Z M17.4810431,5.697729 C17.6664723,6.01919259 17.1854689,6.29727528 17.0000397,5.97581169 C16.8146106,5.6543481 17.2956139,5.3762654 17.4810431,5.697729 Z M6.32896144,16.3953848 C7.45303312,17.0442444 6.47952993,18.7301671 5.35545826,18.0813075 C4.23138658,17.4324478 5.20488977,15.7465252 6.32896144,16.3953848 Z"
          />
        </svg>
      </span>
    );
  }
}

export default Loader;
