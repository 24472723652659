import React from 'react';

// const DefaultEvent = 'archive';

const style = {
    'archive': {
        height: 16
    },
    'person': {
        height: 4,
        offset: 3
    },
    'car': {
        height: 4,
        offset: 9
    },
    'people': {
        height: 4,
        offset: 15
    },
};

export default function Event({type, left, width}) {
    let event = (type || '').toLowerCase();

    let height = style[event].height;
    let spacer = style[event].offset || 0;

    return <rect
        className={`event event-${event}`}
        height={height}
        width={width}
        y={spacer}
        x={left}
    />
}