import React from "react";
import { translate } from "react-i18next";
import Icon from "./Icon";

const shareList = [
  {
    url: "",
    icon: "google-plus"
  },
  {
    url: "",
    icon: "facebook"
  },
  {
    url: "",
    icon: "vkontakte"
  },
  {
    url: "",
    icon: "twitter"
  },
  {
    url: "",
    icon: "odnoklassniki"
  }
];

class ShareButtons extends React.Component {
  render() {
    return (
      <div className="socials">
        <div className="socials__title">
          {this.props.t("share-in-social-networks")}
        </div>
        <div className="socials__list">
          {shareList.map((item, i) => (
            <a href={item.url} className="socials__item" key={i}>
              <Icon className="socials__icon" name={item.icon} />
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default translate()(ShareButtons);
