import HttpClient from "../http";
import { tzOffset } from "./users";

const initialState = {
  groups: [],
  groupsFetched: false,

  persons: [],
  personsTotal: 0,
  personsFetched: false,
  personsQuery: "",

  hasMore: true,
  page: 1,

  filters: {
    persons: true,
    person_list: null,
    online: false,
    camera: [],
    gender: null,
    age: null,
    period: null,
    events_count: null,
    sort_by: "last_visit",
    sort_desc: true,
    search: null,
  },

  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERSONS:
      return {
        ...state,
        persons: [...state.persons, ...(action.persons.items || [])],
        personsTotal: action.persons.total,
        personsEvents: action.persons.events,
        page: state.page + 1,
        personsFetched: true,
      };
    case FACES_SET_FILTER:
      return {
        ...state,
        persons: [],
        personsFetched: false,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case LOAD_GROUPS:
      return { ...state, groups: action.groups, groupsFetched: true };
    case SEARCH_GUID_BY_PHOTO:
      return { ...state, persons: action.persons, personsFetched: true };
    default:
      return state;
  }
};

const LOAD_PERSONS = "LOAD_PERSONS";
export const LoadPersons = () => async (dispatch, getState) => {
  const { faces } = getState();
  const {
    persons,
    person_list,
    online,
    camera,
    gender,
    age,
    period,
    events_count,
    sort_by,
    sort_desc,
    search,
  } = faces.filters;

  let url = `/guids-api/list`;
  if (persons) {
    url += `?persons=1`;
  } else {
    url += `?persons=0`;

    if (gender) {
      url = url + `&gender=${gender}`;
    }

    if (age) {
      if (age === "age0") {
        url = url + `&age_from=0&age_to=17`;
      } else if (age === "age18") {
        url = url + `&age_from=18&age_to=24`;
      } else if (age === "age25") {
        url = url + `&age_from=25&age_to=34`;
      } else if (age === "age35") {
        url = url + `&age_from=35&age_to=44`;
      } else if (age === "age45") {
        url = url + `&age_from=45&age_to=54`;
      } else if (age === "age55") {
        url = url + `&age_from=55&age_to=110`;
      }
    }

    if (period) {
      const { from, to } = period;
      url += `&since=${tzOffset(getState(), from.getTime())}`;
      url += `&until=${tzOffset(getState(), to.getTime())}`;
    }

    if (events_count) {
      url += `&events_count=${events_count[0]},${events_count[1]}`;
    }
  }

  if (person_list) {
    url += `&person_list=${person_list}`;
  }

  if (online) {
    url += `&online=1`;
  }

  if (camera.length !== 0) {
    const cameras = camera.join(",");
    url += `&camera=${cameras}`;
  }

  if (sort_by) {
    url += `&sort_by=${sort_by}`;
  }

  if (sort_desc) {
    url += `&sort_desc=${sort_desc}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  url += `&page=${faces.page}`;

  const response = await HttpClient.get(url);

  return dispatch({ type: LOAD_PERSONS, persons: response.data });
};

const FACES_SET_FILTER = "FACES_SET_FILTER";
export const FacesSetFilter = (filter) => async (dispatch) => {
  await dispatch({ type: FACES_SET_FILTER, payload: filter });
  return dispatch(LoadPersons());
};

const LOAD_GROUPS = "LOAD_GROUPS";
export const LoadGroups = () => async (dispatch) => {
  const response = await HttpClient.get("/guids/groups");

  const groups = response.data;

  return dispatch({ type: LOAD_GROUPS, groups });
};

const SEARCH_GUID_BY_PHOTO = "SEARCH_GUID_BY_PHOTO";
export const SearchGuidByPhoto = (image, persons) => async (dispatch) => {
  const response = await HttpClient.post("/guids-api/find/image", {
    image: image,
    persons: persons,
  });
  return dispatch({
    type: SEARCH_GUID_BY_PHOTO,
    persons: response.data,
  });
};

export const CreateGroup = (data) => async (dispatch) => {
  await HttpClient.post("/guids/groups", data);
  return dispatch(LoadGroups());
};

export const SaveGroup = (data) => async (dispatch) => {
  if (!data || !data.id || data.id <= 0) {
    throw Error("empty id");
  }

  await HttpClient.patch(`/guids/groups/${data.id}`, data);

  return dispatch(LoadGroups());
};

export const DeleteGroup = (groupId) => async (dispatch) => {
  if (groupId <= 0) throw Error("empty id");

  await HttpClient.delete(`/guids/groups/${groupId}`);

  await dispatch(LoadGroups());
  return dispatch(LoadPersons());
};

export const CreatePerson = (person, photos) => async (dispatch) => {
  let response = await HttpClient.post("/guids-api", person);

  try {
    const promises = photos.map((f) =>
      HttpClient.post(`/guids-api/${response.data.guid}/photo`, {
        data: f.image,
      })
    );

    await Promise.all(promises);
  } catch (err) {
    console.error("upload photo error", err);
  }

  const { data } = response;
  response = await HttpClient.post(
    `/guids-api/${data.guid}/photo/finish`,
    JSON.stringify({ total: photos.length })
  );

  dispatch(LoadPersons());
  dispatch(LoadGroups());

  return response.status;
};

export const MovePerson = (person) => async (dispatch) => {
  if (!person || !person.id || person <= 0) {
    throw Error("empty id");
  }

  let response = await HttpClient.post(
    `/guids-api/${person.id}/convert`,
    person
  );

  // dispatch(LoadPersons());
  dispatch(LoadGroups());

  return response.status;
};

export const SavePerson = (data) => async (dispatch) => {
  if (!data || !data.guid) {
    throw Error("empty guid");
  }
  await HttpClient.post(`/guids-api/${data.guid}`, data);
  await dispatch(LoadPersons());
  return dispatch(LoadGroups());
};

export const DeletePerson = (personId) => async (dispatch) => {
  if (personId <= 0) {
    throw Error("empty id");
  }

  await HttpClient.delete(`/guids-api/${personId}`);
  await dispatch(LoadPersons());
  return dispatch(LoadGroups());
};

export const DeletePersonPhoto = (personId, id) => async (dispatch) => {
  if (personId <= 0) throw Error("empty person id");
  if (id === "") throw Error("empty id");

  await HttpClient.delete(`/guids-api/${personId}/photo/${id}`);

  return dispatch(LoadPersons());
};

export const FavoritePersonPhoto = (personId, id) => async (dispatch) => {
  if (personId <= 0) throw Error("empty person id");
  if (id === "") throw Error("empty id");

  await HttpClient.post(`/guids-api/${personId}/photo/${id}/favorite`);

  return dispatch(LoadPersons());
};

// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
