// import moment from "moment-timezone";

// Private helper for range struct
const range = (scale, step) => ({ scale, step });

/**
 * Date units. (relax, js compiler optimize this to precomputed values :) )
 * @type {object}
 */
export const DateUnit = {
  Millisecond: 1,
  Second: 1000,
  Minute: 1000 * 60,
  Hour: 1000 * 60 * 60,
  Day: 1000 * 60 * 60 * 24,
  Week: 1000 * 60 * 60 * 24 * 7,
  Month: 1000 * 60 * 60 * 24 * 7 * 31,
};

export const DateKind = {
  Millisecond: "Millisecond",
  Second: "Second",
  Minute: "Minute",
  Hour: "Hour",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Year: "Year",
};

export const DateRanges = {
  [DateUnit.Month]: range(DateKind.Month, DateUnit.Day),
  [DateUnit.Week * 2]: range(DateKind.Month, DateUnit.Day),
  [DateUnit.Week]: range(DateKind.Day, DateUnit.Day / 2),
  [DateUnit.Day * 2]: range(DateKind.Day, DateUnit.Hour * 3),
  [DateUnit.Day]: range(DateKind.Day, DateUnit.Hour),
  [DateUnit.Day / 2]: range(DateKind.Hour, DateUnit.Hour / 2),
  [DateUnit.Hour]: range(DateKind.Hour, DateUnit.Minute * 5),
  [DateUnit.Minute * 20]: range(DateKind.Hour, DateUnit.Minute * 1),
  [DateUnit.Minute * 3]: range(DateKind.Minute, DateUnit.Second * 15),

  Default: range(DateKind.Minute, DateUnit.Second * 15),
};

export const DateFormats = {
  Minor: {
    [DateKind.Millisecond]: "l",
    [DateKind.Second]: "s",
    [DateKind.Minute]: "HH:MM",
    [DateKind.Hour]: "HH:MM",
    weekday: "ddd d",
    [DateKind.Day]: "dd",
    [DateKind.Week]: "w",
    [DateKind.Month]: "mmm",
    [DateKind.Year]: "yyyy",
  },
  Major: {
    [DateKind.Millisecond]: "HH:MM:ss",
    [DateKind.Second]: "d mmmm HH:MM",
    [DateKind.Minute]: "HH:MM",
    [DateKind.Hour]: "HH:MM",
    weekday: "mmmm yyyy",
    [DateKind.Day]: "d mmm",
    [DateKind.Week]: "mmm yyyy",
    [DateKind.Month]: "mmm yyyy",
    [DateKind.Year]: "yyyy",
  },
};

/**
 * Returns local mouse event position for X axis
 * @param {HTMLDivElement} element
 * @param {MouseEvent} event
 * @return {number}
 */
export const getLocalX = (event, element) => {
  return event.clientX - element.getBoundingClientRect().left;
};

export const getTime = (date) => {
  if (date instanceof Date) return date.getTime();

  return date;
};

/**
 * Returns array of dates inside range with fixed interval
 * @param {number} since
 * @param {number} until
 * @param {number} interval
 * @return {Date[]}
 */
export const getDates = (since, until, interval, scale) => {
  let tz = new Date().getTimezoneOffset();
  let date = resetDate(new Date(since - (since % interval) - tz), interval);
  let time = date.getTime();
  let dates = [];

  if (time < since) {
    // Subtract non renderable time
    time += interval * Math.floor((since - time) / interval);
  }

  while (time <= until) {
    dates.push(new Date(time));
    time += interval;
  }

  return dates;
};

export const resetDate = (date, interval) => {
  // if (scale === DateKind.Day)

  if (interval > DateUnit.Minute) date.setHours(0, 0, 0, 0);

  return date;
};

/**
 * Returns date kind
 * @param {Date|number} date
 * @return {string}
 */
export const getDateKind = (date) => {
  date = new Date(date);
  date.setMilliseconds(0);

  let m = date.getMonth(),
    d = date.getDate(),
    h = date.getHours(),
    min = date.getMinutes(),
    sec = date.getSeconds(),
    total = date.getTime();
  if (m === 0 && d === 1 && h === 0 && min === 0 && sec === 0)
    return DateKind.Year;

  if (d === 1 && h === 0 && min === 0 && sec === 0) return DateKind.Month;

  if (total % DateUnit.Week === 0 && h === 0 && min === 0 && sec === 0)
    return DateKind.Week;

  if (h === 0 && min === 0 && sec === 0) return DateKind.Day;

  if (min === 0 && sec === 0) return DateKind.Hour;

  if (sec === 0) return DateKind.Minute;

  // if (total % DateUnit.Second === 0)

  return DateKind.Second;
  // return DateKind.Millisecond
};

/**
 * Returns best scale configuration
 * @param {number} window Viewport size in `ms`
 * @return {{scale: string, step: number}}
 */
export const getDateRangeScale = (window) => {
  return Object.keys(DateRanges)
    .map(Number)
    .reverse()
    .filter((x) => x > window)
    .map((x) => DateRanges[x])
    .reduce((_, item) => item);
};

/**
 * Returns value inside min & max values
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export const clampNumber = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

/**
 {
  minorLabels: {
    millisecond:'SSS',
    second:     's',
    minute:     'HH:mm',
    hour:       'HH:mm',
    weekday:    'ddd D',
    day:        'D',
    week:       'w',
    month:      'MMM',
    year:       'YYYY'
  },
  majorLabels: {
    millisecond:'HH:mm:ss',
    second:     'D MMMM HH:mm',
    minute:     'ddd D MMMM',
    hour:       'ddd D MMMM',
    weekday:    'MMMM YYYY',
    day:        'MMMM YYYY',
    week:       'MMMM YYYY',
    month:      'YYYY',
    year:       ''
  }
}
 */

const animations = {};

export const animate = ({ timing, draw, duration }) => {
  let start = performance.now();

  if (animations.hasOwnProperty(draw)) cancelAnimationFrame(animations[draw]);

  animations[draw] = requestAnimationFrame(function animate(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    let progress = timing(timeFraction);

    draw(progress); // draw it

    if (timeFraction < 1) {
      animations[draw] = requestAnimationFrame(animate);
    } else {
      delete animations[draw];
    }
  });
};
