import React from "react";
import { noop, preventDefault } from "../../utils";
import Icon from "../Icon";

// const curve = (value, min, max) => (min / value).toFixed(3) * 100;

export default function Zoom({ value, min = 0, max = 100, onZoom = noop }) {
  // const width = curve(value, min, max);
  const width = 100 - ((value - min) * 100) / (max - min);
  const step = value * 0.5;

  const onZoomIn = () => onZoom(-step);
  const onZoomOut = () => onZoom(step);

  return (
    <div className="zoom">
      <button
        className="zoom__btn"
        onClick={preventDefault(onZoomOut)}
        disabled={width === 100}
      >
        <Icon className="zoom__pic" name="zoom-out" />
      </button>
      <div className="zoom__line">
        <div className="zoom__progress" style={{ width: `${width}%` }} />
      </div>
      <button
        className="zoom__btn"
        onClick={preventDefault(onZoomIn)}
        disabled={width === 0}
      >
        <Icon className="zoom__pic" name="zoom-in" />
      </button>
    </div>
  );
}
