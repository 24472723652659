import { connect } from "react-redux";
import PropTypes from 'prop-types'
import moment from "moment-timezone";

const Timestamp = ({ value, timezone }) => {
  return moment.tz(value * 1000, timezone).format("DD.MM.YYYY, HH:mm:ss");
};

Timestamp.propTypes = {
  value: PropTypes.number
}

const mapStateToProps = (state) => ({
  timezone: (state.users.user && state.users.user.Timezone) || "",
});

export default connect(mapStateToProps)(Timestamp);
