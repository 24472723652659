import React from "react";
import { Route, NavLink } from "react-router-dom";

import Terms from "./Pages/Terms";
import TermsAI from "./Pages/TermsAI";
import Policy from "./Pages/Policy";
import PolicyAI from "./Pages/PolicyAI";
import Rules from "./Pages/Rules";
import { isEurocam } from "../utils";

const Pages = (props) => {
  return (
    <div className="pages">
      <nav className="pages__nav">
        {isEurocam() ? (
          <>
            <NavLink className="pages__link" to="/pages/terms">
              Terms of agreement
            </NavLink>
            <NavLink className="pages__link" to="/pages/policy">
              Privacy Policy
            </NavLink>
          </>
        ) : (
          <>
            <NavLink className="pages__link" to="/pages/terms">
              Условия соглашения
            </NavLink>
            <NavLink className="pages__link" to="/pages/policy">
              Политика конфиденциальности
            </NavLink>
            <NavLink className="pages__link" to="/pages/rules">
              Правила оказания информационных услуг
            </NavLink>
          </>
        )}
      </nav>
      <div className="pages__container">
        {isEurocam() ? (
          <>
            <Route path="/pages/terms" render={() => <TermsAI />} />
            <Route path="/pages/policy" render={() => <PolicyAI />} />
          </>
        ) : (
          <>
            <Route path="/pages/terms" render={() => <Terms />} />
            <Route path="/pages/policy" render={() => <Policy />} />
            <Route path="/pages/rules" render={() => <Rules />} />
          </>
        )}
      </div>
    </div>
  );
};

export default Pages;
