import React from "react";
import { toast } from "react-toastify";
import { translate } from "react-i18next";
import moment from "moment-timezone";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cn from "classnames";
import dateFormat from "dateformat";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "../components/Icon";
import Loader from "../components/Loader";
import ModalLauncher from "../components/ModalLauncher";
import Logo from "../components/Logo";
import Switch from "../components/Switch";
import ChangePassword from "../components/Profile/ChangePassword";
import TimeZone from "../components/TimeZone";

import { Me, MePatch, MeChangePassword, SignOut } from "../reducers/users";
import { loadTokens, createToken, deleteToken } from "../reducers/tokens";
import { enDateFormat, ruDateFormat, languages } from "../i18n";
import { isEurocam } from "../utils";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();

    this.state = {
      loading: false,
      user: props.users.user,
    };
  }

  componentDidMount() {
    this.props.loadTokens();
    this.loadZone(this.props.users.user);
  }

  componentDidUpdate(prevProps) {
    if (this.props.users.user !== prevProps.users.user) {
      const { user } = this.props.users;
      if (user) {
        this.setState({
          user: {
            FirstName: user.FirstName,
            LastName: user.LastName,
            Timezone: user.Timezone,
          },
        });
        this.loadZone(user);
      }
    }
  }

  loadZone = (user) => {
    if (!user || !user.Timezone) {
      this.setState({
        zone: `${moment.tz.guess()} (UTC${moment
          .tz(moment.tz.guess())
          .format("Z")})`,
      });
      return;
    }

    const tz = moment.tz(user.Timezone);
    this.setState({ zone: `${user.Timezone} (UTC${tz.format("Z")})` });
  };

  setZone = (zone, z) => {
    this.setState({
      zone: zone,
      user: { ...this.state.user, Timezone: z },
    });
  };

  onChangeLanuage = (i18n, language) => {
    i18n.changeLanguage(language);
    if (language === "ru") {
      dateFormat.i18n = ruDateFormat;
    } else {
      dateFormat.i18n = enDateFormat;
    }
  };

  onChange = (field, value) =>
    this.setState({ user: { ...this.state.user, [field]: value } });

  onChangePassword = (current, password, confirm) =>
    this.props.MeChangePassword({
      Current: current,
      Password: password,
      Confirm: confirm,
    });

  onChangePasswordComplete = (success) => {
    if (success) {
      this.modalRef.current.handleToggleModal();
      toast.success(this.props.t("profile-password-update-success"));
    } else {
      toast.error(this.props.t("profile-password-update-error"));
    }
  };

  onSave = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    this.props
      .MePatch(this.state.user)
      .then(() => {
        this.setState({ loading: false });
        toast.success(this.props.t("data-update-success"));
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        toast.error(this.props.t("data-update-error"));
      });
  };

  onLogout = (e) => {
    e.preventDefault();
    this.props.SignOut();
    window.location = "/";
  };

  onDeleteToken = async (id) => {
    try {
      await this.props.deleteToken(id);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { user } = this.props.users;

    if (!this.state.user) {
      return <Loader color="#ffffff" />;
    }

    const { loading } = this.state;
    const { FirstName, LastName } = this.state.user;
    const { t, i18n, createToken } = this.props;
    const { tokens } = this.props.tokens;

    return (
      <div className="profile">
        <div className="profile__head">
          <Logo />
          <div className="title">{t("profile-title")}</div>
          <a
            href="/"
            className="profile__action action"
            onClick={this.onLogout}
          >
            <Icon name="exit" />
          </a>
        </div>
        <div className="profile__body">
          <div className="profile__container">
            <div className="profile__subtitle">{t("profile-language")}</div>
            <div className="profile__section">
              {languages
                .filter((l) => l.available)
                .map((l) => {
                  return (
                    <Switch
                      className="profile__switch"
                      content={l.title}
                      radio
                      name="language"
                      value={i18n.language.slice(0, 2) === l.lng}
                      onChange={() => this.onChangeLanuage(i18n, l.lng)}
                    />
                  );
                })}
            </div>
            <div className="profile__subtitle">{t("profile-settings")}</div>
            <div className="profile__section">
              <div className="profile__info">
                <span className="bold">ID</span> {user.ID}
              </div>
              <div className="profile__info">
                <span className="bold">Email:</span> {user.Email}
              </div>
              {user.company && user.company_name && (
                <div className="profile__info">
                  <span className="bold">{t("company")}:</span>{" "}
                  {user.company_name}
                </div>
              )}
              <div className="profile__field">
                <div className="field__label">{t("name")}</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={FirstName}
                    onChange={(e) => this.onChange("FirstName", e.target.value)}
                  />
                </div>
                {/* <div className="field__error">Error message</div> */}
              </div>
              <div className="profile__field">
                <div className="field__label">{t("surname")}</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={LastName}
                    onChange={(e) => this.onChange("LastName", e.target.value)}
                  />
                </div>
              </div>
              <ModalLauncher
                label={t("change-password")}
                modalClassName="modal_sm"
                ref={this.modalRef}
                link
              >
                <ChangePassword
                  onSave={this.onChangePassword}
                  onComplete={this.onChangePasswordComplete}
                />
              </ModalLauncher>
            </div>
            <div className="profile__subtitle">{t("location")}</div>
            <div className="profile__section">
              <TimeZone t={t} zone={this.state.zone} setZone={this.setZone} />
            </div>
            <div className="profile__subtitle">
              {t("profile-telegram-notifications")}
            </div>
            <div className="profile__section">
              <div className="profile__info">
                <a
                  href={
                    isEurocam()
                      ? "https://t.me/neurocam_alert_bot"
                      : "https://t.me/neuro_cam_bot"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("profile-telegram-notifications-connect")}
                </a>
              </div>
              <div className="profile__info">
                <a onClick={createToken} href="#/">
                  {t("profile-telegram-notifications-create")}
                </a>
              </div>
              <div className="profile__info">
                <table className="tokens">
                  <tbody>
                    {tokens.length > 0 &&
                      tokens.map((x, index) => (
                        <tr key={index}>
                          <td>
                            {x.activated ? (
                              <div className="action action_sm active">
                                <Icon name="success" />
                              </div>
                            ) : (
                              <CopyToClipboard
                                text={x.token}
                                onCopy={() => toast.success(t("token-copied"))}
                              >
                                <button className="action action_sm">
                                  <Icon name="copy" />
                                </button>
                              </CopyToClipboard>
                            )}
                          </td>
                          <td>{x.token}</td>
                          <td>
                            <button
                              className="action action_sm"
                              onClick={() => this.onDeleteToken(x.id)}
                            >
                              <Icon name="trash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="profile__foot">
              <button
                className={cn("btn", "btn_lg", { btn_loading: loading })}
                onClick={this.onSave}
              >
                {t("save")}
                {loading && <Loader color="white" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  tokens: state.tokens,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, {
      Me,
      MePatch,
      MeChangePassword,
      SignOut,
      loadTokens,
      createToken,
      deleteToken,
    })
  )(Profile)
);
