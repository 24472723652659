import React from "react";
// import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { Bar } from "react-chartjs-2";
import update from "immutability-helper";
import { chartOptions } from "../utils";
import Icon from "../../Icon";
import ReportsChangingsPercentage from "../ReportsChangingsPercentage";

class ChartBySex extends React.Component {
  render() {
    const {
      t,
      footfall,
      dates,
      labels,
      // onEventsClick,
    } = this.props;

    const newChartOptions = update(chartOptions, {
      plugins: {
        $set: {
          datalabels: {
            offset: 0,
            font: {
              family: "'Aeroport-Bold', sans-serif",
              size: 10,
            },
          },
        },
      },
      tooltips: {
        enabled: { $set: true },
        position: { $set: "average" },
        mode: { $set: "index" },
        intersect: { $set: false },
        titleFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        titleFontSize: { $set: 10 },
        titleFontStyle: { $set: "normal" },
        bodyFontFamily: { $set: "'Aeroport-Regular', sans-serif" },
        bodyFontSize: { $set: 10 },
        bodyFontStyle: { $set: "normal" },
        callbacks: {
          $set: {
            title: function (tooltipItems, data) {
              var total = 0;
              tooltipItems.forEach(function (tooltipItem) {
                total +=
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
              });
              return `${t("total")} ${total} ${t("man-short")}`;
            },
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: { $set: true },
            ticks: {
              padding: {
                $set: 20,
              },
            },
          },
        ],
        yAxes: [
          {
            stacked: { $set: true },
            ticks: {
              suggestedMax: {
                $set: footfall.current.max + 1,
              },
            },
          },
        ],
      },
    });

    const current = footfall.current;
    const prev = footfall.prev;
    console.log(prev);

    let dataMales = [];
    let dataFemales = [];

    dates.forEach((date) => {
      let males = 0;
      let females = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          males = x.males;
          females = x.females;
        }
      });

      dataMales.push(males);
      dataFemales.push(females);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: t("woman-plural"),
          maxBarThickness: 4,
          backgroundColor: "#F30027",
          hoverBackgroundColor: "#F30027",
          data: dataFemales,
          datalabels: { anchor: "start", align: "bottom", color: "#F30027" },
        },
        {
          label: t("man-plural"),
          maxBarThickness: 4,
          backgroundColor: "#1DA1F2",
          hoverBackgroundColor: "#1DA1F2",
          data: dataMales,
          datalabels: { anchor: "end", align: "top", color: "#1DA1F2" },
        },
      ],
    };

    const malePercent = Math.round(
      (current.males * 100) / (current.males + current.females)
    );
    const malePercentPrev = Math.round(
      (prev.males * 100) / (prev.males + prev.females)
    );
    const femalePercent = 100 - malePercent;
    const femalePercentPrev = 100 - malePercentPrev;

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Bar data={chartData} options={newChartOptions} height={360} />
        </div>
        <div className="chart__foot">
          <div className="chart__row">
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="male" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {malePercent}%
                  <span className="chart__total"> | {current.males}</span>
                  <ReportsChangingsPercentage
                    current={malePercent}
                    prev={malePercentPrev}
                  />
                </div>
                <div className="chart__label">{t("man-plural")}</div>
                {/* <Link
                  to="/events"
                  onClick={() => onEventsClick("gender", "male")}
                  className="chart__label"
                >
                  {t("man-plural")}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="female" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {femalePercent}%
                  <span className="chart__total"> | {current.females}</span>
                  <ReportsChangingsPercentage
                    current={femalePercent}
                    prev={femalePercentPrev}
                  />
                </div>
                <div className="chart__label">{t("woman-plural")}</div>
                {/* <Link
                  to="/events"
                  onClick={() => onEventsClick("gender", "female")}
                  className="chart__label"
                >
                  {t("woman-plural")}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
          </div>
          <div className="chart__lines">
            <div
              className="chart__line"
              style={{
                flex: `1 1 ${malePercent}%`,
                background: chartData.datasets[1].backgroundColor,
              }}
            />
            <div
              className="chart__line"
              style={{
                flex: `1 1 ${femalePercent}%`,
                background: chartData.datasets[0].backgroundColor,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartBySex);
