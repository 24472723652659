import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon";
import Loader from "../Loader";

class Activate extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      loading: false,
      error: null,
      complete: false,
      token: ""
    };
  }

  handleChangeToken = () => {
    const { t } = this.props;

    this.setState({
      loading: true,
      complete: false,
      error: null
    });

    this.props
      .handleActivate(this.state.token)
      .then(() => {
        this.setState({ loading: false, complete: true });
      })
      .catch(err => {
        if (err.name === "APIError" && err.errors.neurobox) {
          this.setState({
            loading: false,
            error: t(`wizard-activate-error-${err.errors.neurobox}`)
          });
        } else {
          this.setState({
            loading: false,
            error: t("wizard-activate-internal-error")
          });
        }
      });
  };

  onChangeToken = e => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({ token: e.target.value });

    this.timeout = setTimeout(this.handleChangeToken, 1000);
  };

  render() {
    const { t, type } = this.props;
    return (
      <div className="preparing">
        <div className="preparing__title">
          {t("preparing-to-connect-title")}
        </div>
        <div className="preparing__container">
          <div className="preparing__scan scan">
            <div className="scan__preview">
              {type === "app" ? (
                <img
                  className="scan__pic"
                  src="/img/neurobox-app.png"
                  width="150"
                  alt=""
                />
              ) : (
                <img
                  className="scan__pic"
                  src="/img/preparing-neurobox.png"
                  width="160"
                  alt=""
                />
              )}
            </div>
            <div className="scan__title">{t("enter-device-id")}</div>
            {type === "app" ? (
              <div className="scan__text">{t("copy-app-id")}</div>
            ) : (
              <div className="scan__text">{t("copy-device-id")}</div>
            )}
            <div className="scan__fieldset">
              <div className="scan__field field">
                <div className="field__wrap">
                  <input
                    type="text"
                    name="token"
                    className="field__input"
                    placeholder={t("example-camera-id")}
                    onChange={this.onChangeToken}
                    value={this.state.token}
                  />
                  {this.state.loading && (
                    <div className="field__icon">
                      <Loader />
                    </div>
                  )}
                  {this.state.complete && (
                    <div className="field__icon">
                      <Icon name="success" />
                    </div>
                  )}
                  {this.state.error && (
                    <React.Fragment>
                      <div
                        className="field__icon"
                        data-tip={t("connect-neurobox-error")}
                        data-class="tooltip"
                        data-effect="solid"
                        data-place="right"
                      >
                        <Icon name="close-sm" className="field__close" />
                      </div>
                      <ReactTooltip ref="tooltip" />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="scan__foot">
              <button
                className="scan__btn btn"
                onClick={this.props.handleScan}
                disabled={!this.state.complete}
              >
                {t("scan-start")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Activate.propTypes = {
  handleActivate: PropTypes.func.isRequired,
  handleScan: PropTypes.func.isRequired
};

export default translate()(Activate);
