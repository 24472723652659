import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import styles from "./Persons.module.sass";
import { checkPerms } from "../../../utils";
import Loader from "../../Loader";
import Action from "../../Action";
import Empty from "../../Empty";
import GridView from "./GridView";
import TableView from "./TableView";

import { LoadPersons } from "../../../reducers/faces";

const Persons = ({
  t,
  personsFetched,
  persons,
  filters,
  setFilter,
  items,
  total,
  tableView,
  selection,
  users,
  groupByID,
  match,
  selected,
  onSelect,
  LoadPersons,
}) => {
  const groupName = (id) => groupByID[id] && groupByID[id].title;
  const groupColor = (id) => groupByID[id] && groupByID[id].color;
  const isSelected = (id) => selected.find((v) => v === id);

  const isPermsGroupUpdate = checkPerms(users.user, "person_group:update");

  const isPersons = filters.persons;

  const isDesktop = useMediaQuery({ query: "(min-width: 1240px)" });

  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={() => LoadPersons()}
      hasMore={total > persons.length}
      useWindow={false}
    >
      <div className={styles.persons}>
        <div className={styles.head}>
          {isPermsGroupUpdate && match && match.params.id && (
            <Action
              className={styles.action}
              to={`/faces/edit-list/${match.params.id}`}
              icon="edit"
              small
            />
          )}
          <div className={cn("title", styles.title)}>
            {match.params.id
              ? groupName(match.params.id)
              : isPersons
              ? t("all-persons")
              : t("all-visitors")}
            <span className={styles.total}>
              {personsFetched ? (total > 0 ? total : "") : "..."}
            </span>
          </div>
        </div>
        {personsFetched ? (
          persons && persons.length ? (
            tableView && isDesktop ? (
              <TableView
                items={items}
                isPersons={isPersons}
                filters={filters}
                setFilter={setFilter}
                selection={selection}
                onSelect={onSelect}
                isSelected={isSelected}
                groupName={groupName}
                groupColor={groupColor}
              />
            ) : (
              <GridView
                items={items}
                isPersons={isPersons}
                selection={selection}
                onSelect={onSelect}
                isSelected={isSelected}
                groupName={groupName}
                groupColor={groupColor}
              />
            )
          ) : (
            <Empty
              icon="persons"
              title={t("persons-empty-title")}
              text={t("persons-empty-text")}
            />
          )
        ) : (
          <Loader />
        )}
      </div>
    </InfiniteScroll>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  groupByID: state.faces.groups.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {}
  ),
});

export default translate()(
  withRouter(connect(mapStateToProps, { LoadPersons })(Persons))
);
