import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import Header from "../../Header";
import PopupConfirm from "../../PopupConfirm";
import EventsGrid from "../../Events/EventsGrid";
import Layout from "../Layout";
import Info from "./Info";

import HttpClient from "../../../http";

import { DeletePerson } from "../../../reducers/faces";

const renderDay = (t, day) => {
  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  switch (day) {
    case today.toLocaleDateString():
      return t("today");
    case yesterday.toLocaleDateString():
      return t("yesterday");
    default:
      return day;
  }
};

const PersonPage = ({ t, DeletePerson, history }) => {
  const [person, setPerson] = useState(null);
  const [events, setEvents] = useState([]);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const params = useParams();

  useEffect(() => {
    const url = `/guids-api/${params.faceId}`;

    HttpClient.get(url)
      .then(function (response) {
        setPerson(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    HttpClient.get(`/guids-api/${params.faceId}/events`)
      .then((response) => setEvents(response.data))
      .catch((err) => console.log(err));
  }, [params.faceId]);

  const onDelete = async () => {
    try {
      await DeletePerson(person.guid);
      toast.success(t("person-delete-success"));
    } catch (e) {
      console.log(e);
      toast.error(t("person-delete-error"));
    } finally {
      history.push("/faces/visitors");
    }
  };

  const [days, eventsByDays] = convertDays(t, events);

  return person ? (
    <Layout
      header={
        <>
          <Header
            back={{ icon: "close", onClick: () => history.goBack() }}
            title={
              person.person_info
                ? `${person.person_info.name} ${person.person_info.last_name}`
                : `id: ${person.guid}`
            }
            actions={[
              person.person_info
                ? {
                    icon: "edit",
                    to: `/faces/${person.group_info?.id || 0}/${
                      person.guid
                    }/edit`,
                  }
                : {
                    icon: "trash",
                    onClick: () => setVisibleConfirm(true),
                  },
            ]}
            rightButton={
              !person.person_info && {
                icon: "plus",
                title: t("add"),
                to: `/faces/0/${person.guid}/edit`,
              }
            }
          />
          {visibleConfirm && (
            <PopupConfirm
              title={t("person-removing")}
              content={`${t("person-removing-msg")} «${person.guid}»?`}
              buttonTitle={t("person-remove")}
              onClick={onDelete}
              onClose={() => setVisibleConfirm(false)}
            />
          )}
        </>
      }
    >
      <>
        <Info person={person} />
        <EventsGrid
          fetched
          setFilter={() => {}}
          days={days}
          itemsByDays={eventsByDays}
          noScroll
          hideSelection
        />
      </>
    </Layout>
  ) : (
    <Loader />
  );
};

const convertDays = (t, { days, events_days: eventsDays }) => {
  if (!days) return [[], {}];

  const r = days.reduce(
    (acc, v) => {
      const d = renderDay(t, new Date(v).toLocaleDateString());

      return {
        ...acc,
        days: [...acc.days, d],
        eventsDays: { ...acc.eventsDays, [d]: eventsDays[v] },
      };
    },
    { days: [], eventsDays: {} }
  );

  return [r.days, r.eventsDays];
};

const mapStateToProps = (state, ownProps) => ({});

export default translate()(
  withRouter(
    connect(mapStateToProps, {
      DeletePerson,
    })(PersonPage)
  )
);
