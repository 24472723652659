import React from "react";
// import PropTypes from "prop-types";
import { translate } from "react-i18next";
// import ReactTooltip from "react-tooltip";

// import Loader from "../Loader";
import DownloadApp from "./../DownloadApp";

class PreparingToConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, type } = this.props;
    return (
      <div className="preparing preparing_connect">
        <div className="preparing__title">
          {t("preparing-to-connect-title")}
        </div>
        <div className="preparing__container">
          <div className="preparing__row">
            <div className="preparing__col">
              <div className="preparing__animation">
                <svg
                  className="preparing__svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="550"
                  height="64"
                  viewBox="0 0 550 64"
                >
                  <path
                    fill="none"
                    stroke="#F30027"
                    strokeWidth="2"
                    d="M0,62 C0,27.7583455 27.7583455,0 62,0 C96.2416545,0 124,27.7583455 124,62 L212,62 C212,27.7583455 239.758346,0 274,0 C308.241654,0 336,27.7583455 336,62 L424,62 C424,27.7583455 451.758346,0 486,0 C520.241654,0 548,27.7583455 548,62"
                  />
                </svg>
                <svg
                  className="preparing__svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="550"
                  height="64"
                  viewBox="0 0 550 64"
                >
                  <path
                    fill="none"
                    stroke="#F30027"
                    strokeWidth="2"
                    d="M0,62 C0,27.7583455 27.7583455,0 62,0 C96.2416545,0 124,27.7583455 124,62 L212,62 C212,27.7583455 239.758346,0 274,0 C308.241654,0 336,27.7583455 336,62 L424,62 C424,27.7583455 451.758346,0 486,0 C520.241654,0 548,27.7583455 548,62"
                  />
                </svg>
              </div>
              {type === "app" && (
                <img
                  src="/img/preparing-computer.png"
                  width="126"
                  className="preparing__pic"
                  alt=""
                />
              )}
              {type === "device" && (
                <img
                  src="/img/preparing-neurobox.png"
                  width="131"
                  className="preparing__pic"
                  alt=""
                />
              )}
            </div>
            <div className="preparing__col">
              <img
                src="/img/preparing-router.png"
                width="146"
                className="preparing__pic"
                alt=""
              />
            </div>
            <div className="preparing__col">
              <img
                src="/img/preparing-camera.png"
                width="124"
                className="preparing__pic"
                alt=""
              />
            </div>
          </div>
          {type === "app" && (
            <div className="preparing__list">
              <div className="preparing__content">
                <div className="preparing__text">
                  {t("preparing-to-connect-text-app-1")}
                </div>
                <DownloadApp className="preparing__programm" />
              </div>
              <div className="preparing__content">
                <div className="preparing__text">
                  <p>{t("preparing-to-connect-text-app-2")}</p>
                  <p>
                    <strong>{t("preparing-to-connect-text-app-3")}</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
          {type === "device" && (
            <div className="preparing__list">
              <div className="preparing__content">
                <div className="preparing__text">
                  {t("preparing-to-connect-device-text-1")}
                </div>
              </div>
              <div className="preparing__content">
                <div className="preparing__text">
                  {t("preparing-to-connect-device-text-2")}
                </div>
              </div>
            </div>
          )}
          <div className="preparing__foot">
            <button
              className="preparing__btn btn btn_lg"
              onClick={this.props.onContinue}
            >
              {t("continue")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PreparingToConnect.propTypes = {};

export default translate()(PreparingToConnect);
