import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { HorizontalBar } from "react-chartjs-2";
import update from "immutability-helper";
import ReportsLoader from "./ReportsLoader";
import ReportsNoData from "./ReportsNoData";
import { chartOptions } from "./utils";
import { numeralize } from "../../utils";

import { VisitFrequencyFetch } from "../../reducers/reports";

class FrequencyVisits extends React.Component {
  componentDidMount() {
    this.props.VisitFrequencyFetch();
  }

  render() {
    const { t, i18n, visitFrequency, visitFrequencyFetched } = this.props;

    const newChartOptions = update(chartOptions, {
      plugins: {
        $set: {
          datalabels: {
            anchor: "end",
            align: "top",
            offset: 0,
            formatter: function (value, context) {
              return value + "%\n" + context.dataset.people[context.dataIndex];
            },
            font: {
              family: "'Aeroport-Bold', sans-serif",
              size: 10,
            },
            color: "#F30027",
          },
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              max: { $set: 125 },
              stepSize: { $set: 25 },
              fontSize: { $set: 10 },
              callback: {
                $set: function (value) {
                  return value === 125 ? "" : value + "%";
                },
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontSize: { $set: 14 },
              fontColor: { $set: "#0D1C26" },
            },
          },
        ],
      },
    });

    let chartData = {};

    if (visitFrequencyFetched) {
      const current = visitFrequency.current;
      const prev = visitFrequency.prev;

      const percentage = (value) => {
        if (value === 0) {
          return 0;
        } else {
          return Math.round((value / current.total) * 100);
        }
      };

      const countChangings = (option) => {
        if (current[option] !== 0 && prev[option] !== 0) {
          return (
            Math.round((current[option] / current.total) * 100) -
            Math.round((prev[option] / prev.total) * 100)
          );
        } else {
          return 0;
        }
      };

      chartData = {
        labels: [
          "1",
          "2-3",
          "4-5",
          "6-10",
          "11-15",
          "16-25",
          "26-50",
          "51-100",
          "100+",
        ],
        datasets: [
          {
            datalabels: {
              labels: {
                change: {
                  align: "-15",
                  anchor: "end",
                  offset: 5,
                  font: { family: "'Aeroport-Bold', sans-serif", size: 14 },
                  color: "#0D1C26",
                  formatter: function (value, ctx) {
                    return value + "%";
                  },
                },
                value: {
                  align: "-28",
                  anchor: "end",
                  offset: 25,
                  font: { family: "'Aeroport-Regular', sans-serif", size: 12 },
                  color: function (ctx) {
                    return ctx.dataset.people[ctx.dataIndex].change > 0
                      ? "#42d475"
                      : "#f30027";
                  },
                  formatter: function (value, ctx) {
                    const change = ctx.dataset.people[ctx.dataIndex].change;
                    if (!isNaN(change)) {
                      if (change > 0) {
                        return "+" + change + "%";
                      } else {
                        if (change !== 0) {
                          return change + "%";
                        } else {
                          return " ";
                        }
                      }
                    }
                  },
                },
                name: {
                  align: "30",
                  anchor: "end",
                  offset: 5,
                  font: { family: "'Aeroport-Regular', sans-serif", size: 12 },
                  color: "#868D92",
                  formatter: function (value, ctx) {
                    const people = ctx.dataset.people[ctx.dataIndex].value;
                    return (
                      people +
                      " " +
                      numeralize(i18n.language, people, [
                        "человек",
                        "человека",
                        "человек",
                        "visitor",
                        "visitors",
                      ])
                    );
                  },
                },
              },
            },
            maxBarThickness: 4,
            backgroundColor: "#F30027",
            data: [
              percentage(current.freq1),
              percentage(current.freq3),
              percentage(current.freq5),
              percentage(current.freq10),
              percentage(current.freq15),
              percentage(current.freq25),
              percentage(current.freq50),
              percentage(current.freq100),
              percentage(current.freq101),
            ],
            people: [
              {
                value: current.freq1,
                change: countChangings("freq1"),
              },
              {
                value: current.freq3,
                change: countChangings("freq3"),
              },
              {
                value: current.freq5,
                change: countChangings("freq5"),
              },
              {
                value: current.freq10,
                change: countChangings("freq10"),
              },
              {
                value: current.freq15,
                change: countChangings("freq15"),
              },
              {
                value: current.freq25,
                change: countChangings("freq25"),
              },
              {
                value: current.freq50,
                change: countChangings("freq50"),
              },
              {
                value: current.freq100,
                change: countChangings("freq100"),
              },
              {
                value: current.freq101,
                change: countChangings("freq101"),
              },
            ],
          },
        ],
      };
    }

    return (
      <div className="chart">
        <div className="chart__head">
          <div className="chart__title title">{t("frequency-of-visits")}</div>
        </div>
        <div className="chart__inner">
          {visitFrequencyFetched ? (
            visitFrequency.current.total > 0 ? (
              <div className="chart__body">
                <div className="chart__container">
                  <HorizontalBar
                    data={chartData}
                    options={newChartOptions}
                    height={500}
                  />
                </div>
              </div>
            ) : (
              <ReportsNoData />
            )
          ) : (
            <ReportsLoader />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visitFrequency: state.reports.visitFrequency,
  visitFrequencyFetched: state.reports.visitFrequencyFetched,
});

export default translate()(
  connect(mapStateToProps, {
    VisitFrequencyFetch,
  })(FrequencyVisits)
);
