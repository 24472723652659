import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import Switch from "../../Switch";

const TrackingGroups = ({
  t,
  isPermsGroupCreate,
  groups,
  trackingGroups,
  updateTrackingGroups,
}) => {
  return (
    <div className="create__lists">
      <div className="create__title create__title_mb0">{t("person-lists")}</div>
      <div className="create__info">{t("person-lists-text")}</div>
      {groups.length > 0
        ? groups.map((x) => (
            <Switch
              className="create__switch"
              content={x.title}
              key={x.id}
              value={trackingGroups.includes(x.id)}
              onChange={(e) => updateTrackingGroups(e.target.checked, x.id)}
            />
          ))
        : isPermsGroupCreate && (
            <NavLink to="/faces/add-list">{t("create-new-list")}</NavLink>
          )}
    </div>
  );
};

export default translate()(TrackingGroups);
