import React from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "../Icon";
import Logo from "../Logo";
import ReportsSidebar from "./ReportsSidebar";

class ReportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleFilters: false,
      hideSidebarReports: localStorage.getItem("hideSidebarReports") === "true",
    };
  }

  toggleSidebar = () => {
    this.setState(
      { hideSidebarReports: !this.state.hideSidebarReports },
      () => {
        localStorage.setItem(
          "hideSidebarReports",
          this.state.hideSidebarReports
        );
      }
    );
  };

  toggleFilters = () => {
    this.setState({ visibleFilters: !this.state.visibleFilters });
  };

  render() {
    const { hideSidebarReports, visibleFilters } = this.state;
    const { t, type, places, filters, setFilter, children } = this.props;

    const classes = cn(
      "layout",
      "layout_reports",
      hideSidebarReports && "hide-sidebar",
      visibleFilters && "show-filters"
    );

    return (
      <div className={classes}>
        <div className="layout__sidebar">
          <div className="layout__head">
            <Logo />
            <button
              className="layout__action action"
              onClick={this.toggleSidebar}
            >
              <Icon name="menu" />
            </button>
            <div className="layout__title">{t("monitoring")}</div>
            <button
              className="layout__toggle action"
              onClick={this.toggleFilters}
            >
              <Icon name="filters" />
            </button>
          </div>
          <ReportsSidebar
            type={type}
            places={places}
            filters={filters}
            setFilter={setFilter}
          />
        </div>
        {children}
      </div>
    );
  }
}

export default translate()(ReportsPage);
