import HttpClient from "../http";

const initialState = {
  tokens: [],
  tokensFetched: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOKENS_FETCH:
      return { ...state, tokens: action.tokens, tokensFetched: true };
    default:
      return state;
  }
};

// tokens
const TOKENS_FETCH = "TOKENS_FETCH";
export const loadTokens = () => async (dispatch) => {
  const response = await HttpClient.get("/users/tokens");

  const tokens = response.data;

  return dispatch({ type: TOKENS_FETCH, tokens: tokens });
};

export const createToken = () => async (dispatch) => {
  await HttpClient.post("/users/tokens");
  return dispatch(loadTokens());
};


export const deleteToken = (id) => async (dispatch) => {
  if (id <= 0) throw Error("empty id");
  await HttpClient.delete(`/users/tokens/${id}`);
  return dispatch(loadTokens());
};
