import React from "react";
import cn from "classnames";
import styles from "./Field.module.sass";

const Field = ({
  className,
  label,
  value,
  placeholder,
  onChange,
  required,
  children,
}) => {
  return (
    <div className={cn(styles.field, className)}>
      {label && (
        <div className={styles.label}>
          {label} {required && <span className="color-red">*</span>}
        </div>
      )}
      <div className={styles.wrap}>
        {onChange ? (
          <input
            className={styles.input}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Field;
