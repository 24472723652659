import React from "react";
import { translate } from "react-i18next";
import dateFormat from "dateformat";
import EventsToggler from "./EventsToggler";
import Datepicker from "../Datepicker";
import EventsSearch from "./EventsSearch";
import EventsNav from "./EventsNav";
import EventsWatchLists from "./EventsWatchLists";

class EventsSidebar extends React.Component {
  state = {
    accordion: [
      {
        index: 0,
        title: this.props.t("cameras-capitalize"),
        value: this.props.t("all-cameras"),
        visible: false,
      },
      {
        index: 1,
        title: this.props.t("event-type"),
        value: this.props.t("all-types"),
        visible: false,
      },
      {
        index: 2,
        title: this.props.t("date"),
        value: this.props.t("for-all-the-time"),
        visible: false,
      },
      {
        index: 3,
        title: this.props.t("watch-lists"),
        value: this.props.t("all-selected"),
        visible: false,
      },
    ],
  };

  toggleBody = (index) => {
    const { accordion } = { ...this.state };
    const currentState = accordion;
    currentState[index].visible = !currentState[index].visible;

    this.setState({ accordion: currentState });
  };

  render() {
    const { t, items, setFilter, filters } = this.props;
    const { accordion } = this.state;

    return (
      <div className="layout__scroll">
        <EventsSearch />
        <div className="accordion">
          {accordion.map((x) => (
            <div className="accordion__item" key={x.index}>
              <div
                className={"accordion__head " + (x.visible && "active")}
                onClick={() => this.toggleBody(x.index)}
              >
                <div className="accordion__title">{x.title}</div>
                <div className="accordion__value">
                  {x.index === 0 &&
                    (filters.cameraId ? t("selected") + " 1" : x.value)}
                  {x.index === 1 &&
                    (filters.objects.length > 0
                      ? t("selected") + " " + filters.objects.length
                      : x.value)}
                  {x.index === 2 &&
                    (filters.period
                      ? dateFormat(filters.period.from, "dd/mm/yyyy") +
                        " - " +
                        dateFormat(filters.period.to, "dd/mm/yyyy")
                      : x.value)}
                  {x.index === 3 &&
                    (filters.groups.length > 0
                      ? t("selected") + " " + filters.groups.length
                      : x.value)}
                </div>
              </div>
              <div className="accordion__body">
                {x.index === 0 && x.visible && (
                  <EventsNav items={items} setFilter={setFilter} />
                )}
                {x.index === 1 && x.visible && <EventsToggler />}
                {x.index === 2 && x.visible && (
                  <Datepicker
                    inline
                    time={true}
                    rangeMode={true}
                    initRange={filters.period}
                    onChangeRange={(from, to) =>
                      from && to
                        ? setFilter({ period: { from, to } })
                        : setFilter({ period: null })
                    }
                  />
                )}
                {x.index === 3 && x.visible && (
                  <div className="accordion__group">
                    <EventsWatchLists />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default translate()(EventsSidebar);
