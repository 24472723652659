import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import cn from "classnames";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalLauncher from "../ModalLauncher";
import SensorSettings from "./SensorSettings";
import RecognitionSettings from "./RecognitionSettings";
import { Archive, Persons, CameraRatesPatch } from "../../reducers/payments";

class CameraOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalArchive: false,
      showModalRecognition: false,
      recognition_enabled: false,
    };
  }

  handleToggleModalArchive = (showModalArchive) =>
    this.setState({ showModalArchive });

  handleToggleModalRecognition = (showModalRecognition) =>
    this.setState({ showModalRecognition });

  onDetectorEnabled = (e, value) => {
    e.preventDefault();

    this.props.onSave({
      ...this.props.camera,
      detector_enabled: value,
    });
  };

  onCancel = (e, cameraRate) => {
    e.preventDefault();
    this.props.onCancel(cameraRate);
    this.handleToggleModalArchive(false);
    this.handleToggleModalRecognition(false);
  };

  onSetAutoUpdate = (rate) =>
    this.props.CameraRatesPatch(rate.ID, {
      AutoUpdate: !rate.AutoUpdate,
      SetAutoUpdate: true,
    });

  until = (cameraRate) =>
    new Date(cameraRate.Until * 1000).toLocaleDateString();

  renderArchiveEnabled = (cameraRate) => {
    const { t, isCompanyOwner } = this.props;
    const { id, archiving } = this.props.camera || {};
    const { Rate, Until } = cameraRate || {};

    return (
      <div className="info__item">
        <div className="info__preview">
          <Icon name={archiving ? "success" : "cloud"} />
        </div>
        <div className="info__wrap">
          <div className="info__title">{t("camera-options-archive-title")}</div>
          <div className="info__content">
            {archiving
              ? `${t("camera-options-term-storage")} ${
                  Rate && Rate.ArciveOnlyEvents
                    ? t("camera-options-events")
                    : t("camera-options-archive")
                }: ${Rate && Rate.ArchiveDays} ${t("camera-options-days")}`
              : t("camera-options-connect-text")}
          </div>
          {archiving && (
            <div className="info__row">
              <div className="info__col">
                <div className="info__date">
                  {t("camera-options-tariff-paid")}{" "}
                  {new Date(Until * 1000).toLocaleDateString()}
                </div>
              </div>
            </div>
          )}
          {isCompanyOwner && (
            <>
              {archiving ? (
                <div className="info__actions">
                  {this.state.showModalArchive && (
                    <Modal
                      className="modal_md"
                      onCloseRequest={() =>
                        this.handleToggleModalArchive(false)
                      }
                    >
                      <div className="connect">
                        <div className="connect__title title">
                          {t("camera-options-tariff-deactivation")}
                        </div>
                        <div className="connect__details">
                          <div className="connect__detail">
                            {t("camera-options-attention")}
                          </div>
                          <div className="connect__detail">
                            <span className="bold">
                              {t("camera-options-tariff-paid")}
                            </span>{" "}
                            {this.until(cameraRate)}
                          </div>
                        </div>
                        <div className="connect__col">
                          <button
                            className="btn btn_lg"
                            onClick={(e) => this.onCancel(e, cameraRate)}
                          >
                            {t("camera-options-disconnect")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  )}
                  <a
                    href="#/"
                    className="info__link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleToggleModalArchive(true);
                    }}
                  >
                    {t("camera-options-disconnect")}
                  </a>
                </div>
              ) : (
                <div className="info__actions">
                  <Link className="info__btn btn" to={`/connect-cloud/${id}`}>
                    {t("camera-options-connect")}
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  renderDetectorEnabledText = () => {
    const { t, camera } = this.props;
    const { neurobox_id } = camera;

    return (
      <div>
        <div className="info__content">
          {t("camera-options-archiving-enabled-text")}
        </div>
        {!neurobox_id && (
          <div className="info__msg">
            {t("camera-should-be-connected-via-neurobox")}
          </div>
        )}
      </div>
    );
  };

  renderDetectorEnabled = () => {
    const { t, isCompanyOwner } = this.props;
    const { detector_enabled, neurobox_id } = this.props.camera || {};

    return (
      <div className="info__item">
        <div className={cn("info__preview", { active: detector_enabled })}>
          <Icon name={detector_enabled ? "success" : "eye"} />
        </div>
        <div className="info__wrap">
          <div className="info__title">
            {t("camera-options-detector-title")}
          </div>
          {this.renderDetectorEnabledText()}
          {detector_enabled
            ? isCompanyOwner && (
                <div className="info__actions">
                  <ModalLauncher
                    className="info__cell"
                    buttonClassName="info__link"
                    link
                    label={t("camera-options-configure-detector")}
                  >
                    <SensorSettings
                      camera={this.props.camera}
                      onSave={this.props.onSave}
                    />
                  </ModalLauncher>
                  <a
                    href="#/"
                    className="info__link"
                    onClick={(e) => this.onDetectorEnabled(e, false)}
                  >
                    {t("camera-options-disconnect")}
                  </a>
                </div>
              )
            : neurobox_id &&
              isCompanyOwner && (
                <div className="info__actions">
                  <ModalLauncher
                    disabled
                    buttonClassName="info__btn"
                    label={t("connect")}
                  >
                    <SensorSettings
                      camera={this.props.camera}
                      onSave={this.props.onSave}
                    />
                  </ModalLauncher>
                </div>
              )}
        </div>
      </div>
    );
  };

  renderRecognitionConnect = () => {
    const { t, isCompanyOwner, camera } = this.props;
    const { id, neurobox_id } = camera;

    return neurobox_id && isCompanyOwner ? (
      <div className="info__actions">
        <Link className="info__btn btn" to={`/connect-recognition/${id}`}>
          {t("camera-options-connect")}
        </Link>
        {/* <a href="#/" className="info__link">
          {t("more-details")}
        </a> */}
      </div>
    ) : (
      <div className="info__msg">
        {t("camera-should-be-connected-via-neurobox")}
      </div>
    );
  };

  renderRecognitionRateTitle = (cameraRate) => {
    const { t } = this.props;
    return cameraRate.Rate.Reports && cameraRate.Rate.Celebrity ? t("base-with-celeb") : t("base-own");
  };

  renderRecognition = (cameraRate) => {
    const { t, isCompanyOwner, isPermsUpdate } = this.props;
    const { face_recognition_enabled } = this.props.camera;

    return (
      <div className="info__item">
        <div
          className={cn("info__preview", { active: face_recognition_enabled })}
        >
          <Icon name={face_recognition_enabled ? "success" : "face"} />
        </div>
        <div className="info__wrap">
          <div className="info__title">{t("recognition")}</div>
          <div className="info__content">
            {cameraRate
              ? this.renderRecognitionRateTitle(cameraRate)
              : t("recognition-enabled-text")}
          </div>
          {cameraRate && (
            <div className="info__row">
              <div className="info__col">
                <div className="info__date">
                  {t("camera-options-tariff-paid")}{" "}
                  {new Date(cameraRate.Until * 1000).toLocaleDateString()}
                </div>
              </div>
            </div>
          )}
          {isPermsUpdate && (
            <>
              {face_recognition_enabled ? (
                <div className="info__actions">
                  {isCompanyOwner && this.state.showModalRecognition && (
                    <Modal
                      className="modal_md"
                      onCloseRequest={() =>
                        this.handleToggleModalRecognition(false)
                      }
                    >
                      <div className="connect">
                        <div className="connect__title title">
                          {t("camera-options-tariff-deactivation")}
                        </div>
                        <div className="connect__details">
                          <div className="connect__detail">
                            {t("camera-options-attention")}
                          </div>
                          <div className="connect__detail">
                            <span className="bold">
                              {t("camera-options-tariff-paid")}
                            </span>{" "}
                            {this.until(cameraRate)} {t("year")}
                          </div>
                        </div>
                        <div className="connect__col">
                          <button
                            className="btn btn_lg"
                            onClick={(e) => this.onCancel(e, cameraRate)}
                          >
                            {t("camera-options-disconnect")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  )}
                  <div className="info__cell">
                    <ModalLauncher
                      show={this.props.showRecognitionSettings}
                      buttonClassName="info__link"
                      link
                      label={t("camera-options-configure-detector")}
                      onClose={() =>
                        this.props.history.push(
                          `/cameras/${this.props.camera.id}`
                        )
                      }
                    >
                      <RecognitionSettings
                        camera={this.props.camera}
                        onSave={this.props.onSave}
                      />
                    </ModalLauncher>
                  </div>
                  {isCompanyOwner && (
                    <a
                      href="#/"
                      className="info__link"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggleModalRecognition(true);
                      }}
                    >
                      {t("camera-options-disconnect")}
                    </a>
                  )}
                </div>
              ) : (
                this.renderRecognitionConnect()
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { ratesArchive, ratesPersons } = this.props;
    const { id } = this.props.camera;

    return (
      <div className="info">
        {this.renderArchiveEnabled(ratesArchive[id])}
        {this.renderRecognition(ratesPersons[id])}
        {this.renderDetectorEnabled()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ratesArchive: state.payments.cameraRatesMap[Archive],
  ratesPersons: state.payments.cameraRatesMap[Persons],
});

export default withRouter(
  translate()(
    connect(
      mapStateToProps,
      bindActionCreators.bind(null, { CameraRatesPatch })
    )(CameraOptions)
  )
);
