import React from "react";
import styles from "./Wrapper.module.sass";
import Nav from "../Nav";

const Wrapper = ({ user, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <a className={styles.logo} href="/">
          <img src="/img/logo.svg" alt="Neurocam" />
        </a>
        <Nav user={user} />
      </div>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default Wrapper;
