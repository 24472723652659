import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { translate } from "react-i18next";
import { Fullscreen, Online, Pause, Volume } from "../Player/Controls";
import Timeline from "../Timeline";
import Dropdown from "../Dropdown";
import { AvailableObjects } from "../../reducers/events";
import Datepicker from "../Datepicker";
import { DatePart } from "../Timeline2";
import Zoom from "../Timeline/Zoom";
import { DateUnit } from "../Timeline/utils";
import { Export, ExportButton } from "../Export";
import screenfull from "screenfull";
import { debounceFactory } from "../../utils";
import { EventBadge } from "../EventBadge";
import State from "../State";
import { useBodyClass } from "../../hooks";
import { Video } from "../Player/Video";
import { videoExportUrl } from "../../links";
import { withTabVisibility } from "../TabVisibility";
import { MarkersUpdater } from "./MarkersUpdater";

const ts = (date) => Math.floor(date.getTime() / 1000);

const playsNow = (events, playingSeconds) => {
  return events
    .filter((x) => x.type !== "archive")
    .filter((x) => x.since < playingSeconds && x.until >= playingSeconds)
    .filter((item, index, all) => {
      return all.find((x) => x.type === item.type) === item;
    });
};

class CameraPlayer extends Component {
  state = {
    scale: null,
    muted: true,
    stream: null,
    volume: 0.9,
    online: true,
    paused: false,
    loaded: 0,
    played: 0,
    export: null,
    events: AvailableObjects.map((x) => x.object),
    loading: true,
    error: false,
    playing: Date.now(),
    maximized: false,
  };

  videoRef = createRef();

  playerRef = createRef();

  timelineRef = createRef();

  onSeek = (ts) => {
    let sec = Math.floor(ts / 1000) * 1000;

    this.setState(
      { playing: ts, played: 0, online: false, loading: true },
      () => {
        this.props.onSeekTimeline(sec, sec + DateUnit.Second * 3600);
      }
    );
  };

  /**
   * Played in seconds (float)
   */
  onPlaying = (played) => {
    this.setState({ played: played * 1000 });
  };

  render() {
    const archive = this.props.camera.archiving === true;
    const { active } = this.props.camera;

    const classes = cn(["video", this.props.className], {
      video_fullscreen: this.state.maximized,
    });

    const playingSeconds = Math.ceil(
      (this.state.playing + this.state.played) / 1000
    );

    const events = this.props.ranges
      .filter((x) => {
        return x.type === "archive" || this.state.events.indexOf(x.type) >= 0;
      })
      .map((x) => {
        if (x.until !== 0) return x;

        let ms = this.state.online
          ? this.state.playing + this.state.played
          : Date.now();

        return {
          ...x,
          until: Math.ceil(ms / 1000),
          opened: true,
        };
      });

    const eventsNow = playsNow(events, playingSeconds);

    const timeline = (
      <Timeline
        ref={this.timelineRef}
        range={this.state.export}
        items={events}
        timezone={this.props.timezone}
        playing={this.state.playing + this.state.played}
        onSeek={this.onSeek}
        onMove={this.onMove}
        onScaleChanged={this.onScaleChanged}
        onRangeChanged={this.onRangeChanged}
      />
    );

    return (
      <div className={classes} ref={this.videoRef}>
        <CameraPage />
        <MarkersUpdater
          cameraId={this.props.camera.id}
          onTick={this.updateMarkers}
        />

        <div className="video__events">
          {!this.state.online &&
            eventsNow.map((x) => (
              <EventBadge key={`${x.type}-${x.since}`} type={x.type} />
            ))}
        </div>
        <div className="video__container">
          {this.props.camera.active && (
            <div className="video__screen">
              <Video
                url={this.props.url}
                muted={this.state.muted}
                volume={this.state.volume}
                pause={this.state.paused}
                onStart={this.onStart}
                onError={this.onError}
                onPlaying={this.onPlaying}
                onWaiting={this.onWaiting}
              />
              <div className="video__controls">
                <div className="video__group">
                  <Pause paused={this.state.paused} onClick={this.togglePlay} />

                  <div className="video__relative">
                    <Volume
                      muted={this.state.muted}
                      volume={this.state.volume}
                      onChange={this.onVolumeChanged}
                      vertical={this.state.maximized}
                    />
                  </div>
                </div>
                {archive && (
                  <Fragment>
                    {this.state.maximized && (
                      <div className="video__group video__group_timeline">
                        {timeline}
                      </div>
                    )}
                    {this.state.maximized && (
                      <div className="video__group">
                        <Zoom
                          min={DatePart.Minute * 2}
                          max={DatePart.Day * 25}
                          value={this.state.scale}
                          onZoom={this.onZoom}
                        />
                      </div>
                    )}
                  </Fragment>
                )}

                <div className="video__group">
                  <Online
                    online={this.state.online}
                    paused={this.state.paused}
                    disabled={!archive}
                    onClick={this.onPlayOnline}
                  />
                  <Fullscreen
                    active={this.state.maximized}
                    onToggle={this.toggleFullscreen}
                  />
                </div>
              </div>
            </div>
          )}
          {this.renderState()}
        </div>
        {active && archive && (
          <div className="video__tools">
            <div className="video__timeline">
              {this.state.maximized === false && timeline}
            </div>
            <div className="video__foot">
              {this.state.export == null
                ? this.renderTools()
                : this.renderExport()}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderState = () => {
    const { camera } = this.props;
    if (camera.active === false) {
      return <State type="camera-off" />;
    }

    if (this.state.error) {
      return <State type="no-connection" />;
    }

    if (this.state.loading) {
      return <State type="loader" loaderColor="#F30027" />;
    }

    return null;
  };

  renderExport = () => {
    return <Export onClick={this.downloadExport} onClose={this.cancelExport} />;
  };

  renderTools = () => {
    const { detector_enabled } = this.props.camera;
    const { t } = this.props;
    return (
      <Fragment>
        {/* Select events type & Jump to date & Zoom */}
        {detector_enabled && (
          <Dropdown
            className="dropdown_video"
            title={t("types-of-events")}
            checkboxes={AvailableObjects}
            selected={this.state.events}
            onChange={this.onFilterEvents}
          />
        )}
        <Datepicker
          className="dp_video"
          title={t("go-to-date")}
          onChange={this.onChangeDate}
          resetOnSubmit
        />

        <Zoom
          min={DatePart.Minute * 2}
          max={DatePart.Day * 25}
          value={this.state.scale}
          onZoom={this.onZoom}
        />

        <ExportButton onClick={this.initiateExport} />
      </Fragment>
    );
  };

  cancelExport = () => {
    this.setState({ export: null });
  };

  initiateExport = () => {
    // Default range duration is 2 hours
    let duration = DateUnit.Hour * 2;

    let range = {
      since: new Date(this.state.center - duration / 2),
      until: new Date(this.state.center + duration / 2),
    };

    this.setState({ export: range });
  };

  downloadExport = () => {
    const { camera } = this.props;
    const { since, until } = this.state.export;
    window.open(videoExportUrl(camera.id, ts(since), ts(until)));
  };

  togglePlay = () => this.setState({ paused: !this.state.paused });

  toggleMute = () => this.setState({ muted: !this.state.muted });

  onTabVisibleChange = (isVisible) => {
    if (this.state.paused) return;

    if (isVisible) {
      this.setState({ playing: Date.now(), online: true, loading: true });
      if (this.timelineRef.current) this.timelineRef.current.moveTo(Date.now());
    }
  };

  onChangeDate = (data) => {
    if (data != null)
      this.timelineRef.current.moveTo(
        data.getTime(),
        DateUnit.Day + DateUnit.Hour * 4
      );
  };

  onMove = (time, since, until) => {
    this.setState({ center: time, since, until }, (_) => {
      this.loadEvents(since, until);
    });
  };

  onZoom = (zoom) => {
    this.timelineRef.current.zoom(zoom);
  };

  onPlayOnline = () => {
    this.props.onPlayOnline && this.props.onPlayOnline();
    if (this.state.online) return;

    this.setState({ playing: Date.now(), online: true, loading: true });

    if (this.timelineRef.current) this.timelineRef.current.moveTo(Date.now());
  };

  onWaiting = () => {
    this.setState({ loading: true });
  };

  onScaleChanged = (scale, since, until) => {
    this.setState({ scale, since, until }, (_) => {
      this.loadEvents(since, until);
    });
  };

  onStart = () => {
    this.setState({ loading: false });

    if (this.state.online && this.timelineRef.current) {
      this.setState({ playing: Date.now() });
      this.timelineRef.current.moveTo(Date.now());
    }
  };

  onError = () => this.setState({ loading: false, error: true });

  onRangeChanged = (since, until) => {
    this.setState({ export: { since, until } });
  };

  onVolumeChanged = (mute, volume) => {
    this.setState({ muted: mute, volume: volume });
  };

  onFilterEvents = (types) => {
    this.setState({ events: types });
  };

  toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(this.videoRef.current);
    }
  };

  handleFullscreen = () => {
    this.setState({ maximized: screenfull.isFullscreen });
  };

  componentDidMount() {
    this.loadEvents = debounceFactory((since, until) => {
      this.props.onLoadMarkers &&
        this.props.onLoadMarkers(this.props.camera.id, since, until);
    }, 100);

    if (screenfull && typeof screenfull.on === "function") {
      screenfull.on("change", this.handleFullscreen);
    }
  }

  updateMarkers = () => {
    if (this.timelineRef.current == null) return;

    this.loadEvents && this.loadEvents(this.state.since, this.state.until);
  };

  componentWillUnmount() {
    if (screenfull && typeof screenfull.off === "function") {
      screenfull.off("change", this.handleFullscreen);
    }
  }
}

const mapStateToProps = (state) => ({
  timezone: state.users.user && state.users.user.Timezone,
});

export default translate()(
  withTabVisibility(connect(mapStateToProps)(CameraPlayer))
);

// TODO: Move all logic here
const CameraPage = () => {
  useBodyClass("without-menu");

  return null;
};
