import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastContainer } from "react-toastify";

import "url-search-params-polyfill";

import "react-toastify/dist/ReactToastify.css";
import "../sass/app.sass";

import Loader from "../components/Loader";
import Wrapper from "../components/Wrapper";
import UsersLogin from "../components/UsersLogin";
import UsersPasswordReset from "../components/UsersPasswordReset";
import PrivateRoute from "../components/PrivateRoute";

import Cameras from "./Cameras";
import AddCamera from "./AddCamera";
import AddCameraDirectly from "./AddCameraDirectly";
import Events from "./Events";
import Faces from "./Faces";
import Profile from "./Profile";
import Payment from "./Payment";
import ConnectCloud from "./ConnectCloud";
import ConnectRecognition from "./ConnectRecognition";
import Neuroboxes from "./Neuroboxes";
import Reports from "./Reports";
import Pages from "./Pages";

import SharingEvent from "./SharingEvent";
import Fade from "../components/Fade";

import { LoadToken, Me } from "../reducers/users";
import { EventsCountsNew } from "../reducers/events";
import { CamerasList } from "../reducers/cameras";
import { CameraRatesLoad } from "../reducers/payments";
import { checkPerms, demoToken, isEurocam } from "../utils";

const App = (props) => {
  const user = props.users.user;
  const isPermsCameras = user && checkPerms(user, "camera:page");
  const isPermsEvents = user && checkPerms(user, "event:page");
  const isPermsFaces = user && checkPerms(user, "person:page");
  const isPermsReports = user && checkPerms(user, "reports:page");
  const isPermsPayment = user && checkPerms(user, "payment:page");

  useEffect(() => {
    // load cameras, rates
    if (!props.cameras.fetched) {
      props.CamerasList();
    }
    if (!props.rates.cameraRatesFetched) {
      props.CameraRatesLoad();
    }
  }, [props]);

  return user ? (
    <Wrapper user={user}>
      <Switch>
        {isPermsCameras ? (
          <Redirect exact from="/" to="/cameras" />
        ) : isPermsEvents ? (
          <Redirect exact from="/" to="/events" />
        ) : isPermsFaces ? (
          <Redirect exact from="/" to="/faces" />
        ) : (
          <Redirect exact from="/" to="/profile" />
        )}
        {isPermsCameras && <Route path="/cameras" component={Cameras} />}
        {isPermsCameras && <Route path="/add/:type" component={AddCamera} />}
        {isPermsCameras && (
          <Route path="/add-directly" component={AddCameraDirectly} />
        )}
        {isPermsEvents && <Route path="/events" component={Events} />}
        {isPermsFaces && <Route path="/faces" component={Faces} />}
        <Route path="/profile" component={Profile} />
        {isPermsPayment && <Route path="/payment" component={Payment} />}
        {isPermsPayment && (
          <Route path="/connect-cloud/:id" component={ConnectCloud} />
        )}
        {isPermsPayment && (
          <Route
            path="/connect-recognition/:id"
            component={ConnectRecognition}
          />
        )}
        <Route path="/neuroboxes" component={Neuroboxes} />
        {isPermsReports && <Route path="/reports" component={Reports} />}
        <Route path="/share" component={SharingEvent} />
      </Switch>
      <ToastContainer
        transition={Fade}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        draggable={false}
        closeOnClick={false}
      />
    </Wrapper>
  ) : (
    <Loader />
  );
};

const AppConnected = connect(
  (state) => ({
    cameras: state.cameras,
    users: state.users,
    rates: state.payments,
  }),
  { CamerasList, CameraRatesLoad }
)(App);

class Root extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(window.location.search);

    const token = queryParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      window.location = "/";
    }

    const clear = queryParams.get("clear");
    if (clear && demoToken()) {
      localStorage.removeItem("token");
      window.location = "/";
    }

    props.LoadToken();
    props.Me().then(
      () => props.EventsCountsNew(),
      (reason) => {
        const tokenIsSet = localStorage.getItem("token");
        if (tokenIsSet && reason.response.status === 401) {
          localStorage.removeItem("token");
          window.location = "/";
        }
      }
    );
  }

  authenticated = () => this.props.users.token && this.props.users.token !== "";

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/pages" component={Pages} />
          <Route path="/users/login" component={UsersLogin} />
          <Route
            path="/users/reset-password/:token"
            component={UsersPasswordReset}
          />
          <PrivateRoute
            path="/"
            component={AppConnected}
            authenticated={this.authenticated()}
          />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default translate()(
  connect(
    mapStateToProps,
    bindActionCreators.bind(null, { LoadToken, Me, EventsCountsNew })
  )(Root)
);
