import React from "react";
// import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { Bar } from "react-chartjs-2";
import update from "immutability-helper";
import { chartOptions } from "../utils";
import Icon from "../../Icon";
import ReportsChangings from "../ReportsChangings";
import { numeralize } from "../../../utils";

class ChartTotalAttendance extends React.Component {
  render() {
    const { t, i18n, footfall, dates, labels } = this.props;

    const newChartOptions = update(chartOptions, {
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMax: {
                $set: footfall.current.max + 1,
              },
            },
          },
        ],
      },
      plugins: {
        $set: {
          datalabels: {
            anchor: "end",
            align: "top",
            offset: 0,
            font: {
              family: "'Aeroport-Bold', sans-serif",
              size: 10,
            },
            color: "#F30027",
          },
        },
      },
    });

    const current = footfall.current;
    const prev = footfall.prev;

    let data = [];

    dates.forEach((date) => {
      let total = 0;

      current.periods.forEach((x) => {
        if (date === x.period) {
          total = x.total;
        }
      });

      data.push(total);
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Total",
          maxBarThickness: 4,
          backgroundColor: "#F30027",
          data: data,
        },
      ],
    };

    return (
      <div className="chart__body">
        <div className="chart__container">
          <Bar data={chartData} options={newChartOptions} height={360} />
        </div>
        <div className="chart__foot">
          <div className="chart__row">
            <div className="chart__cell">
              <div className="chart__preview">
                <Icon name="group" />
              </div>
              <div className="chart__group">
                <div className="chart__value">
                  {current.total}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option="total"
                  />
                </div>
                <div className="chart__label">
                  {numeralize(i18n.language, current.total, [
                    "посетитель",
                    "посетителя",
                    "посетителей",
                    "visitor",
                    "visitors",
                  ])}
                </div>
                {/* <Link to="/events" onClick={onEventsClick} className="chart__label">
                  {numeralize(i18n.language, current.total, [
                    "посетитель",
                    "посетителя",
                    "посетителей",
                    "visitor",
                    "visitors",
                  ])}
                  <span className="chart__chevron" />
                </Link> */}
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview up" />
              <div className="chart__group">
                <div className="chart__value">
                  {current.max} {t("max")}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option="max"
                  />
                </div>
                <div className="chart__label">{t("highest-value")}</div>
              </div>
            </div>
            <div className="chart__cell">
              <div className="chart__preview down" />
              <div className="chart__group">
                <div className="chart__value">
                  {current.min} {t("min")}
                  <ReportsChangings
                    current={current}
                    prev={prev}
                    option="min"
                  />
                </div>
                <div className="chart__label">{t("lowest-value")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ChartTotalAttendance);
