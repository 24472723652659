import React, { useState } from "react";
import { translate } from "react-i18next";
import cn from "classnames";
import Period from "./Period";

const WorkingTime = ({ t, current }) => {
  const [sortField, setSortField] = useState("period");
  const [direction, setDirection] = useState("asc");

  const head = [
    {
      title: "date",
      field: "period",
    },
    {
      title: "start-work",
      field: "first_in",
    },
    {
      title: null,
      field: null,
    },
    {
      title: "end-work",
      field: "last_out",
    },
    {
      title: "time-on-work",
      field: "duration",
    },
  ];

  const handleSort = (field) => {
    setDirection("asc");
    field === sortField && direction === "asc" && setDirection("desc");
    setSortField(field);
  };

  const updateValue = (field, value) => {
    if (field === "period") {
      return new Date(value);
    }
    if (field === "first_in" || field === "last_out") {
      return parseInt(value.replace(":", ""));
    }
    return value;
  };

  return (
    <div className="tracking">
      <div className="tracking__head">
        <div className="tracking__title">{t("time-tracking")}</div>
      </div>
      <div className="tracking__table">
        <div className="tracking__thead">
          <div className="tracking__row">
            {head.map((x, index) => (
              <div className="tracking__cell" key={index}>
                {x.title && (
                  <button
                    className={cn("action-sort", {
                      active: x.field === sortField,
                      desc: x.field === sortField && direction === "desc",
                    })}
                    onClick={() => handleSort(x.field)}
                  >
                    {t(x.title)}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="tracking__tbody">
          {current.periods
            .sort((a, b) =>
              direction === "asc"
                ? updateValue(sortField, a[sortField]) -
                  updateValue(sortField, b[sortField])
                : updateValue(sortField, b[sortField]) -
                  updateValue(sortField, a[sortField])
            )
            .map((x, index) => (
              <Period t={t} item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default translate()(WorkingTime);
