import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";
import Loader from "../Loader";
import { Video } from "./Video";
import { Fullscreen, Pause, Progress, Volume } from "./Controls";
import { useFullscreen } from "./hooks";

export const SimplePlayer = ({ url }) => {
  const container = useRef();

  const [visibleLoader, hideLoader] = useState(() => true);

  const [fullscreen, toggleFullscreen] = useFullscreen(container);

  const [seek, setSeek] = useState(() => null);
  const [muted, setMuted] = useState(() => true);
  const [volume, setVolume] = useState(() => 0);
  const [paused, setPaused] = useState(() => false);
  const [played, setPlayed] = useState(() => 0);
  const [duration, setDuration] = useState(() => 0);

  const handleLoadStart = useCallback(() => {
    hideLoader(false);
  }, []);

  const handleVolume = useCallback((muted, volume) => {
    setMuted(muted);
    setVolume(volume);
  }, []);

  const togglePause = useCallback(
    () => {
      setPaused(!paused);
    },
    [paused]
  );

  const handlePlaying = useCallback(seconds => {
    setPlayed(seconds);
  }, []);

  const handleDuration = useCallback(seconds => {
    setDuration(seconds);
  }, []);

  const onSeek = useCallback(seconds => {
    setSeek(seconds);
    setPlayed(seconds)
  }, []);

  const onSeekEnd = useCallback(() => {
    setPaused(false)
  }, []);

  const classes = cn("video", "video_simple", "video_event", {
    video_fullscreen: fullscreen
  });

  return (
    <div className={classes}>
      <div ref={container} className="video__container">
        <div className="video__screen">
          {url && (
            <Video
              url={url}
              muted={muted}
              pause={paused}
              volume={volume}
              playing={seek}
              onStart={handleLoadStart}
              onPlaying={handlePlaying}
              onDuration={handleDuration}
            />
          )}
          {visibleLoader && <Loader width="32" color="#F30027" />}
        </div>
        <div className="video__controls">
          <div className="video__group">
            <Pause paused={paused} onClick={togglePause} />
            <div className="video__relative">
              <Volume muted={muted} volume={volume} onChange={handleVolume} />
            </div>
          </div>
          <Progress
            played={played}
            duration={duration}
            onSeek={onSeek}
            onSeekEnd={onSeekEnd}
          />
          <div className="video__group">
            <Fullscreen active={fullscreen} onToggle={toggleFullscreen} />
          </div>
        </div>
      </div>
    </div>
  );
};
