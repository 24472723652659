import React from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import Icon from "./../Icon";
import ReportsNoData from "./ReportsNoData";
import ReportsLoader from "./ReportsLoader";

import { VisitMapFetch } from "../../reducers/reports";

const hoursArray = Array(24)
  .fill()
  .map(() => 0);

class FootfallMap extends React.Component {
  componentDidMount() {
    this.props.VisitMapFetch();
  }

  renderDay(name, values) {
    // let updValues = [];
    // values ? (updValues = values) : (updValues = hoursArray);
    return (
      <div className="map__row">
        <div className="map__col">
          <div className="map__cell map__cell_day">
            <div className="map__day">{name}</div>
          </div>
        </div>
        {values.map((x, i) => (
          <div className="map__col" key={i}>
            <div className="map__cell map__cell_bg">
              <div
                className="map__bg"
                style={{ opacity: x / this.props.visitMap.max.value }}
              />
              <div className="map__tooltip">
                <div className="map__hours">
                  {name} {i}
                  :00 - {i + 1}
                  :00
                </div>
                {x} {this.props.t("man-short")}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { t, visitMap, visitMapFetched } = this.props;

    const days = [
      this.props.t("mondayShort"),
      this.props.t("tuesdayShort"),
      this.props.t("wednesdayShort"),
      this.props.t("thursdayShort"),
      this.props.t("fridayShort"),
      this.props.t("saturdayShort"),
      this.props.t("sundayShort"),
    ];

    return (
      <div className="chart chart_map">
        <div className="chart__head">
          <div className="chart__title title">{t("footfall-map")}</div>
          {visitMapFetched && visitMap.max.value > 0 && (
            <div className="chart__max">
              <Icon name="hot" />
              <div className="chart__span">
                {t(visitMap.max.day.toLowerCase())}, {visitMap.max.hour}
                :00 — {visitMap.max.hour + 1}
                :00, {visitMap.max.value} {t("man-lowercase")}
              </div>
            </div>
          )}
        </div>
        <div className="chart__inner">
          {visitMapFetched ? (
            visitMap.max.value > 0 ? (
              <div className="chart__body">
                <div className="chart__container">
                  <div className="map">
                    {this.renderDay(days[0], visitMap.days.Monday)}
                    {this.renderDay(days[1], visitMap.days.Tuesday)}
                    {this.renderDay(days[2], visitMap.days.Wednesday)}
                    {this.renderDay(days[3], visitMap.days.Thursday)}
                    {this.renderDay(days[4], visitMap.days.Friday)}
                    {this.renderDay(days[5], visitMap.days.Saturday)}
                    {this.renderDay(days[6], visitMap.days.Sunday)}
                    <div className="map__row map__row_numbers">
                      {hoursArray.map((x, i) => (
                        <div className="map__col" key={i}>
                          <div className="map__cell map__cell_number">
                            <div className="map__number">{i}</div>
                          </div>
                        </div>
                      ))}
                      <div className="map__col">
                        <div className="map__cell map__cell_number map__cell_last">
                          <div className="map__number">24</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ReportsNoData />
            )
          ) : (
            <ReportsLoader />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visitMap: state.reports.visitMap,
  visitMapFetched: state.reports.visitMapFetched,
});

export default translate()(
  connect(mapStateToProps, {
    VisitMapFetch,
  })(FootfallMap)
);
