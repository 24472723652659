import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import DownloadApp from "./DownloadApp";

class ConnectCamera extends React.Component {
  render() {
    const { closeModal, t } = this.props;
    return (
      <div className="add">
        <div className="add__title title">{t("connect-camera-new-ip")}</div>
        <div className="add__text">{t("connect-camera-select-method")}</div>
        <div className="add__row">
          <div className="add__col">
            <div className="add__preview">
              <img
                src="/img/preparing-neurobox.png"
                alt=""
                className="add__pic"
              />
            </div>
            <div className="add__wrap">
              <div className="add__subtitle">
                {t("connect-camera-via-neurobox-device")}
              </div>
              <div className="add__content">
                {t("connect-camera-via-neurobox-device-text")}
              </div>
              <div className="add__info">
                <a
                  href="https://neurocam.com/neurobox"
                  className="add__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("connect-camera-via-neurobox-device-more")}
                </a>
              </div>
              <div className="add__foot">
                <span onClick={closeModal}>
                  <Link
                    className="add__btn btn"
                    to={{ pathname: "/add/device", state: { type: "device" } }}
                  >
                    {t("continue")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="add__col">
            <div className="add__preview">
              <img
                src="/img/preparing-camera.png"
                alt=""
                className="add__pic"
              />
            </div>
            <div className="add__wrap">
              <div className="add__subtitle">
                {t("connect-camera-without-neurobox")}
              </div>
              <div className="add__content">
                {t("connect-camera-without-neurobox-text")}
              </div>
              <div className="add__foot">
                <span onClick={closeModal}>
                  <Link className="add__btn btn" to={"/add-directly"}>
                    {t("continue")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ConnectCamera);
